import React, { ReactNode, useEffect, useMemo, Fragment, useCallback, useState, useRef } from 'react';
import { useParams, useLocation, Navigate, Link, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import { TypedUseQueryStateResult, BaseQueryFn, FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query/react';
import { DateTime, DayNumbers, MonthNumbers, type DateTimeUnit, DateTimeFormatOptions, ToISOTimeOptions } from 'luxon';
import { Button, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledAlert, UncontrolledDropdown, List, ListInlineItem, Collapse } from 'reactstrap';

import { Calendar, CalendarToolbar, toCalendarPathname, toTodayPathname } from '../calendar/Calendar';
import { useGetEventsMapQuery, useGetEventsQuery, useGetEventQuery, useCreateEventMutation, useUpdateEventMutation, useUpdateOrganizerMutation, useUpdateAttendeeListMutation, useUpdateServiceListMutation, useDeleteEventMutation } from '../calendar/calendarApi';
import { servicesApi, useGetServicesQuery } from '../services/servicesApi';
import { EventDetailsPanel, EventForm } from '../calendar/Event';
import { contactsApi } from '../contacts/contactsApi';
import { contactsToReactSelectOptions } from '../contacts/Contact';
import { ServiceExpenses, ServicePayment, servicesToReactSelectOptions } from '../services/Service';
import { getEventSchema } from '../calendar/schema';
import { BaseToolbar } from '../core/BaseToolbar';
import { QueryError } from '../core/QueryError';
import { templatesApi } from '../templates/templatesApi';
import { businessUsersApi } from '../business_user/businessUsersApi';
import { LoadingIndicator } from '../core/LoadingIndicator';
import Joi from '../core/ExtendedJoi';
import { useCreateFinancialRecordMutation, useDeleteFinancialRecordMutation, useGetFinancialRecordsQuery } from '../finance/financeApi';
import { FinancePanel, FinancialRecordForm } from '../finance/Finance';
import useFinanceForm, { preparePayload } from '../finance/useFinanceForm';
import { toReactSelectOption } from '../core/CurrencySelect';
import { LoadingBar } from '../core/LoadingBar';
import { formSubmitHandler } from '../core/formSubmitHandler';
import useHeaderAndFooterHeight from '../core/useHeaderAndFooterHeight';
import { DemoUserDisclaimer } from '../core/DemoUserDisclaimer';
import type { ApiErrorResponse, ContactBasic, GetEventsParams, EventsMapQueryResult, EventsQueryResult, Event, EventFormValues, ApiCollection, Service, Template, BusinessUser, PaginationParams, GetFinancialRecordsParams, FinancialRecord, FinancialRecordFormValues, UsageLimit } from '../types/api';

export const ValidateCalendarLink: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { year: paramYear, month: paramMonth, day: paramDay } = useParams();

  if (paramYear && paramMonth && paramDay) {
    const urlDate = DateTime.fromObject({
      year: parseInt(paramYear, 10),
      month: parseInt(paramMonth, 10),
      day: parseInt(paramDay, 10),
    });

    if (!urlDate.isValid) {
      // broken link
      const { year, month, day } = DateTime.now().toObject();
      const defaultPath = toCalendarPathname({ year, month, day });
      return <Navigate to={defaultPath} replace />;
    }
  }

  return children;
};

interface CalendarScreenProps {
  year: number;
  month?: MonthNumbers | null;
  day?: DayNumbers | null;
  eventsMap?: EventsMapQueryResult | null;
  events?: EventsQueryResult | null;
  userResult: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
}

export const CalendarScreen: React.FC<CalendarScreenProps> = ({
  year,
  month = null,
  day = null,
  eventsMap = null,
  events = null,
  userResult,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);
  const location = useLocation();
  const backDateFormat: DateTimeFormatOptions = (day) ? { month: 'long' } : { year: 'numeric' };
  const backLinkTo = location.pathname.replace(/\/[^/]+\/$/, '/');
  const date = DateTime.fromObject({
    year,
    month: typeof month === 'number' ? month : undefined,
    day: typeof day === 'number' ? day : undefined,
  });
  const serverErrors = [eventsMap?.error, events?.error]
    .filter((e) => Boolean(e)) as Array<FetchBaseQueryError | ApiErrorResponse>;
  const search = (date.isValid && !DateTime.now().hasSame(date, 'day')) ? `start_date=${date.toISODate()}` : undefined;

  return (
    <div id="calendar-screen">
      <header ref={headerRef}>
        <CalendarToolbar
          year={year}
          month={month}
          day={day}
          eventsMap={eventsMap}
          back={month && (
            <Button
              to={{
                pathname: backLinkTo,
              }}
              className="back-link"
              tag={Link}
              color="link"
            >
              <FontAwesomeIcon icon={solid('chevron-left')} />
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              {' '}
              {date.toLocaleString(backDateFormat)}
            </Button>
          )}
          forward={(
            <Button
              to={{
                pathname: '/events/new',
                search,
              }}
              tag={Link}
              color="link"
            >
              <FontAwesomeIcon icon={solid('plus')} />
              <span className="visually-hidden">
                <FormattedMessage defaultMessage="Add new event" />
              </span>
            </Button>
          )}
        />
        <LoadingBar loadings={[eventsMap, events]} />
      </header>
      <main style={mainPaddings}>
        <Container className="pt-3" fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            {userResult.data?.is_demo && <DemoUserDisclaimer />}
            <QueryError error={serverErrors} />
          </Col>
          <Calendar
            year={year}
            month={month}
            day={day}
            eventsMap={eventsMap}
            events={events}
          />
        </Container>
      </main>
    </div>
  );
};

export const CalendarRouteElement: React.FC = () => {
  const params = useParams();
  const now = DateTime.now();

  const eventsMapFilters = {
    filter_from: now.startOf('week').toISODate(),
    filter_to: now.endOf('week').toISODate(),
  } as GetEventsParams;
  const eventsFilters = {
    filter_from: now.startOf('day').toISO(),
    filter_to: now.endOf('day').toISO(),
  } as GetEventsParams;
  let urlDate = DateTime.now();
  const year = (typeof params?.year === 'string') ? parseInt(params.year, 10) : now.year;
  const month = (typeof params?.month === 'string') ? parseInt(params.month, 10) as MonthNumbers : null;
  const day = (typeof params?.day === 'string') ? parseInt(params.day, 10) as DayNumbers : null;
  let range: DateTimeUnit = 'week';

  if (year && month && day) {
    // week view
    urlDate = DateTime.fromObject({ year, month, day }, { zone: 'utc' });
    eventsFilters.filter_from = urlDate.startOf('day').toISO();
    eventsFilters.filter_to = urlDate.endOf('day').toISO();
  } else if (year && month) {
    // month view
    urlDate = DateTime.fromObject({ year, month, day: 1 }, { zone: 'utc' });
    range = 'month';
  } else {
    // year view
    urlDate = DateTime.fromObject({ year, month: 1, day: 1 }, { zone: 'utc' });
    range = 'year';
  }

  eventsMapFilters.filter_from = urlDate.startOf(range).toISODate();
  eventsMapFilters.filter_to = urlDate.endOf(range).toISODate();

  const eventsMap = useGetEventsMapQuery(eventsMapFilters);
  const events = useGetEventsQuery(eventsFilters, {
    skip: range !== 'week',
  });
  const userResult = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();

  return (
    <CalendarScreen
      year={year}
      month={month}
      day={day}
      eventsMap={eventsMap}
      events={events}
      userResult={userResult}
    />
  );
};

interface EventDetailsScreenProps {
  eventId: string;
  userResult: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  limitsResult: TypedUseQueryStateResult<UsageLimit, void, BaseQueryFn>;
  eventDetails: TypedUseQueryStateResult<Event, string, BaseQueryFn>;
  contactsResult: TypedUseQueryStateResult<ApiCollection<ContactBasic>, PaginationParams, BaseQueryFn>;
  servicesResult: TypedUseQueryStateResult<ApiCollection<Service>, PaginationParams, BaseQueryFn>;
  templatesResult: TypedUseQueryStateResult<ApiCollection<Template>, PaginationParams, BaseQueryFn>;
  financialRecords: TypedUseQueryStateResult<ApiCollection<FinancialRecord>, PaginationParams, BaseQueryFn>;
}

export const EventDetailsScreen: React.FC<EventDetailsScreenProps> = ({
  eventId,
  userResult,
  limitsResult,
  eventDetails,
  contactsResult,
  servicesResult,
  templatesResult,
  financialRecords,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);
  const [finFormVisible, setFinFormVisible] = useState<boolean>(false);
  const [createFinancialRecord, creationState] = useCreateFinancialRecordMutation();
  const [deleteFinancialRecord, deletionState] = useDeleteFinancialRecordMutation();
  const intl = useIntl();
  const gotHistory = window?.history?.length > 0;
  const eventStartDate = (eventDetails.isSuccess) ? DateTime.fromISO(eventDetails.data.start, { zone: eventDetails.data.timezone }) : null;
  const backHref = {
    pathname: (eventStartDate instanceof DateTime) ? toCalendarPathname(eventStartDate.toObject()) : toTodayPathname(),
  };
  const service: Service | undefined = eventDetails.data?.service?.[0];
  const hasItems = financialRecords.isLoading || (financialRecords.isSuccess && financialRecords.data?.items_count > 0);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  query.set('filter_event', eventId);

  const { hookForm, defaultValues } = useFinanceForm({
    schema: Joi.object({
      date: Joi.date().required(),
      time: Joi.string().required(),
      currency: Joi.object({
        value: Joi.string().length(3).required(),
      }).required(),
      user_amount: Joi.number().required(),
      business_user: Joi.object().required(),
    }),
    userResult,
    eventResult: eventDetails,
  });

  const onSubmit = async (formData: FinancialRecordFormValues) => {
    const payload = preparePayload(formData);
    try {
      await createFinancialRecord(payload).unwrap();
    } catch (e) {
      return false;
    }
    setFinFormVisible(false);
    return true;
  };

  const isSaving: boolean = hookForm.formState.isSubmitting || creationState.isLoading || deletionState.isLoading;
  const limitReached: boolean = false || (limitsResult.isSuccess && limitsResult.data?.max_new_financial_records <= limitsResult.data?.current_new_financial_records);
  const nextReset = DateTime.fromISO(limitsResult.data?.next_reset);
  const upgradeLink: React.FC<ReactNode> = (chunk) => <Link to={{ pathname: '/plans' }}>{chunk}</Link>;

  return (
    <div id="event-details-screen">
      <header ref={headerRef}>
        <BaseToolbar
          back={(
            <Button className="back-link" tag={Link} to={backHref} color="link">
              <FontAwesomeIcon icon={solid('chevron-left')} />
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              {' '}
              {gotHistory && <FormattedMessage defaultMessage="Back" />}
              {!gotHistory && <FormattedMessage defaultMessage="Calendar" />}
            </Button>
          )}
          title={<FormattedMessage defaultMessage="Event Details" />}
          forward={(
            <Button tag={Link} to={`/events/${eventId}/edit`} color="link">
              <FormattedMessage defaultMessage="Edit" />
            </Button>
          )}
        />
        {!isSaving && <LoadingBar loadings={[userResult, limitsResult, eventDetails, contactsResult, servicesResult, templatesResult, financialRecords]} />}
        {/* always 25% while submitting form */}
        {isSaving && <LoadingBar loadings={[{}]} />}
      </header>
      <main style={mainPaddings}>
        <Container className="pt-3" fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            <EventDetailsPanel eventDetails={eventDetails} templates={templatesResult} />
          </Col>
        </Container>
        {eventDetails.isSuccess && (
          <Container className="pt-5" fluid="sm">
            <Col md={{ size: 6, offset: 3 }}>
              <div className="h2">
                {!hasItems && <FormattedMessage defaultMessage="No Budget Records" />}
                {hasItems && <FormattedMessage defaultMessage="Recent Budget Records" />}
              </div>
              {service && (
                <List type="inline">
                  {service.price_equal && (
                  <ListInlineItem className="mb-1">
                    <ServicePayment
                      service={service}
                      onClick={() => {
                        hookForm.reset({
                          ...defaultValues,
                          currency: toReactSelectOption(service.price_currency),
                          user_amount: Math.abs(service.price_equal).toString(),
                          description: intl.formatMessage({ defaultMessage: 'Payment for service.' }),
                        });
                        setFinFormVisible(true);
                      }}
                    />
                  </ListInlineItem>
                  )}
                  {service.price_from && (
                    <ListInlineItem className="mb-1">
                      <ServicePayment
                        service={service}
                        priceFields={['price_from']}
                        onClick={() => {
                          hookForm.reset({
                            ...defaultValues,
                            currency: toReactSelectOption(service.price_currency),
                            user_amount: Math.abs(service.price_from).toString(),
                            description: intl.formatMessage({ defaultMessage: 'Payment for service.' }),
                          });
                          setFinFormVisible(true);
                        }}
                      />
                    </ListInlineItem>
                  )}
                  {service.price_to && (
                    <ListInlineItem className="mb-1">
                      <ServicePayment
                        service={service}
                        priceFields={['price_to']}
                        onClick={() => {
                          hookForm.reset({
                            ...defaultValues,
                            currency: toReactSelectOption(service.price_currency),
                            user_amount: Math.abs(service.price_to).toString(),
                            description: intl.formatMessage({ defaultMessage: 'Payment for service.' }),
                          });
                          setFinFormVisible(true);
                        }}
                      />
                    </ListInlineItem>
                  )}
                  {service.default_expenses && (
                    <ListInlineItem className="mb-1">
                      <ServiceExpenses
                        service={service}
                        onClick={() => {
                          hookForm.reset({
                            ...defaultValues,
                            currency: toReactSelectOption(service.price_currency),
                            user_amount: (Math.abs(service.default_expenses) * -1).toString(),
                            description: intl.formatMessage({ defaultMessage: 'Service expenses.' }),
                          });
                          setFinFormVisible(true);
                        }}
                      />
                    </ListInlineItem>
                  )}
                </List>
              )}
              <Collapse isOpen={finFormVisible} className="py-3 bg-light px-3">
                {limitReached && (
                  <UncontrolledAlert color="warning" className="alert-sm">
                    <FormattedMessage
                      defaultMessage="Ouch, looks like ''{name}'' plan limit of {max, number, ::compact-short} budget items per month reached. It will be cleared after {date}."
                      values={{
                        name: limitsResult.data?.subscription?.plan?.name,
                        max: limitsResult.data?.max_new_financial_records,
                        date: (
                          <time dateTime={nextReset?.toISO()} title={nextReset?.toLocaleString(DateTime.DATETIME_HUGE)}>
                            {nextReset?.toLocaleString(DateTime.DATETIME_SHORT)}
                          </time>
                        ),
                      }}
                    />
                    {limitsResult.data?.subscription?.plan?.level < 3 && (
                      <Fragment>
                        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                        {' '}
                        <FormattedMessage
                          defaultMessage="It's possible to <a>upgrade plan</a> if you need more records each month."
                          values={{
                            a: upgradeLink,
                          }}
                        />
                      </Fragment>
                    )}
                  </UncontrolledAlert>
                )}
                <FinancialRecordForm
                  hookForm={hookForm}
                  onSubmit={onSubmit}
                  contactsResult={contactsResult}
                  servicesResult={servicesResult}
                  disabled={limitReached || isSaving}
                  inputs={{
                    date: {
                      disabled: true,
                    },
                    time: {
                      disabled: true,
                    },
                    service: {
                      isClearable: false,
                      disabled: true,
                    },
                    contact: {
                      isClearable: eventDetails.data?.attendee?.length === 0,
                      disabled: eventDetails.data?.attendee?.length === 1,
                    },
                  }}
                />
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => setFinFormVisible(false)}
                  >
                    <FormattedMessage defaultMessage="Cancel" />
                  </Button>
                  <Button
                    className="ms-3"
                    color="primary"
                    disabled={limitReached || isSaving}
                    onClick={hookForm.handleSubmit(onSubmit)}
                  >
                    {hookForm.formState.isSubmitting && <FormattedMessage defaultMessage="Adding..." />}
                    {!hookForm.formState.isSubmitting && <FormattedMessage defaultMessage="Add" />}
                  </Button>
                </div>
              </Collapse>
              {hasItems && (
                <FinancePanel
                  items={financialRecords}
                  page_size={5}
                  // itemsToGroup={null}
                  sort_order="DESC"
                  onLoadMore={null}
                  fields={{
                    date: {
                      dateFormat: DateTime.DATETIME_SHORT,
                    },
                    description: {
                      visible: false,
                    },
                    contact: {
                      visible: true,
                    },
                    event: {
                      visible: false,
                    },
                  }}
                  onDelete={async (item: FinancialRecord) => {
                    try {
                      await deleteFinancialRecord(item.id).unwrap();
                    } catch (e) {
                      return false;
                    }
                    return true;
                  }}
                />
              )}
            </Col>
          </Container>
        )}
      </main>
    </div>
  );
};

export const EventDetailsRouteElement: React.FC = () => {
  const { eventId } = useParams();
  const eventDetails = useGetEventQuery(eventId ?? skipToken);
  const userResult = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();
  const limitsResult = businessUsersApi.endpoints.getUsageLimits.useQueryState();
  const contactsResult = contactsApi.endpoints.getContacts.useQueryState({ page_size: 1000 });
  const servicesResult = servicesApi.endpoints.getServices.useQueryState({ page_size: 300, sort_order: 'DESC' });
  const templatesResult = templatesApi.endpoints.getTemplates.useQueryState({ page_size: 100, sort_order: 'DESC' });
  const defaultParams = {
    page: 1,
    page_size: 10,
    sort_order: 'DESC',
    filter_event: eventId,
  } as GetFinancialRecordsParams;
  const finRecords = useGetFinancialRecordsQuery(eventId ? defaultParams : skipToken);

  if (!eventId) return <Navigate to={{ pathname: toTodayPathname() }} replace />;

  return (
    <EventDetailsScreen
      eventId={eventId}
      userResult={userResult}
      limitsResult={limitsResult}
      eventDetails={eventDetails}
      contactsResult={contactsResult}
      servicesResult={servicesResult}
      templatesResult={templatesResult}
      financialRecords={finRecords}
    />
  );
};

interface NewEventScreenProps {
  businessUser: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  contacts: TypedUseQueryStateResult<ApiCollection<ContactBasic>, PaginationParams, BaseQueryFn>;
  services: TypedUseQueryStateResult<ApiCollection<Service>, PaginationParams, BaseQueryFn>;
  limitsResult: TypedUseQueryStateResult<UsageLimit, void, BaseQueryFn>;
}

export const NewEventScreen: React.FC<NewEventScreenProps> = ({
  businessUser,
  contacts,
  services,
  limitsResult,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);
  const intl = useIntl();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const navigate = useNavigate();
  const todayPathname = toTodayPathname();
  const gotHistory = window?.history?.length > 0;
  const backHref = gotHistory ? -1 : { pathname: todayPathname };
  const [createEvent, createEventState] = useCreateEventMutation();
  const [overwriteOrganizer, organizerEventState] = useUpdateOrganizerMutation();
  const [overwriteAttendee, attendeeEventState] = useUpdateAttendeeListMutation();
  const [overwriteService, serviceEventState] = useUpdateServiceListMutation();
  const serverErrors = [contacts?.error, createEventState?.error, organizerEventState?.error, attendeeEventState?.error, serviceEventState?.error]
    .filter((e) => Boolean(e)) as Array<FetchBaseQueryError | ApiErrorResponse>;
  const isLoading = businessUser?.isLoading || contacts?.isLoading || services?.isLoading;
  const allDataLoaded = businessUser?.isSuccess && limitsResult?.isSuccess && contacts?.isSuccess && services?.isSuccess;
  const limitReached = false || limitsResult.data?.max_new_events <= limitsResult.data?.current_new_events;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const schemaMemoized = useMemo(() => getEventSchema(intl), [intl]);
  // summary is only optional field
  const schema = schemaMemoized.concat(Joi.object({
    start_date: Joi.date().raw().required(),
    start_time: Joi.string().required(),
    end_date: Joi.date().raw().required(),
    end_time: Joi.string().required(),
    timezone: Joi.string().required(),
  }).unknown());
  // const validationMessages = useMemo(() => getJoiMessages(intl), [intl]);
  const timeOptions: ToISOTimeOptions = { suppressSeconds: true, includeOffset: false };
  const defaultStartDate = (query.has('start_date') && query.get('start_date') !== null && DateTime.fromISO(query.get('start_date')).isValid)
    ? DateTime.fromISO(query.get('start_date')).set({ hour: 9, minute: 0, millisecond: 0 })
    : DateTime.now();

  const defaultFormValues = useMemo(() => ({
    id: '',
    start_date: defaultStartDate.toISODate(),
    start_time: defaultStartDate.startOf('hour').toISOTime(timeOptions),
    end_date: defaultStartDate.plus({ hours: 1 }).toISODate(),
    end_time: defaultStartDate.plus({ hours: 1 }).startOf('hour').toISOTime(timeOptions),
    timezone: '',
    summary: '',
    organizer: undefined,
    attendee: [],
    service: undefined,
    description: '',
    class: 'PRIVATE',
    geo: null,
    location: '',
    priority: '0',
    status: 'TENTATIVE',
    transp: 'OPAQUE',
  } as EventFormValues), []);
  const form = useForm<EventFormValues>({
    defaultValues: defaultFormValues,
    resolver: joiResolver(schema, {
      // messages: validationMessages,
      abortEarly: false,
    }),
  });

  const onDataLoad = () => {
    if (allDataLoaded) {
      const timezone = businessUser.data?.timezone ?? '';
      const organizer = contacts.data?.items?.find(({ id }) => id === businessUser.data?.contact_id);
      const organizerOption = (organizer) ? contactsToReactSelectOptions(intl, [organizer], businessUser.data?.contact_id) : undefined;

      form.resetField('timezone', { defaultValue: timezone });
      form.resetField('organizer', { defaultValue: organizerOption?.[0] });
    }
  };

  useEffect(onDataLoad, [allDataLoaded]);

  const onSubmit = async (formData: EventFormValues) => {
    const { summary, start_date, start_time, end_date, end_time, timezone, organizer, attendee, service, description, geo, location, priority, status } = formData;
    let newEventId: string | null = null;
    try {
      const response = await createEvent({
        summary,
        timezone,
        start: DateTime.fromISO(`${start_date}T${start_time}`, { zone: timezone }).toISO(),
        end: DateTime.fromISO(`${end_date}T${end_time}`, { zone: timezone }).toISO(),
        description,
        geo,
        location,
        priority,
        status,
        class: formData.class,
      }).unwrap();
      newEventId = response.id;
    } catch {
      return false;
    }

    if (newEventId && organizer?.value) {
      try {
        await overwriteOrganizer({
          event_id: newEventId,
          items_ids: [organizer.value],
        }).unwrap();
      } catch {
        return false;
      }
    }

    if (newEventId && attendee.length) {
      try {
        await overwriteAttendee({
          event_id: newEventId,
          items_ids: attendee.map(({ value }) => value),
        }).unwrap();
      } catch {
        return false;
      }
    }

    if (newEventId && service?.value) {
      try {
        await overwriteService({
          event_id: newEventId,
          items_ids: [service.value],
        }).unwrap();
      } catch {
        return false;
      }
    }

    navigate({ pathname: `/events/${newEventId}` }, { replace: true });
    return Boolean(newEventId);
  };
  const onReset = () => form.reset();
  const upgradeLink: React.FC<ReactNode> = (chunk) => <Link to={{ pathname: '/plans' }}>{chunk}</Link>;
  const nextReset = DateTime.fromISO(limitsResult.data?.next_reset);

  return (
    <div id="new-event-screen">
      <header ref={headerRef}>
        <BaseToolbar
          back={(
            <Button
              className="back-link"
              tag={Link}
              to={backHref}
              color="link"
              disabled={isLoading || form?.formState?.isSubmitting}
            >
              <FontAwesomeIcon icon={solid('chevron-left')} />
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              {' '}
              {!form.formState.isDirty && <FormattedMessage defaultMessage="Cancel" />}
              {form.formState.isDirty && <FormattedMessage defaultMessage="Discard" />}
            </Button>
          )}
          forward={(
            <Button
              type="button"
              color="link"
              onClick={form.handleSubmit(onSubmit)}
              disabled={limitReached || isLoading || form?.formState?.isSubmitting}
            >
              {!form?.formState?.isSubmitting && <FormattedMessage defaultMessage="Add" />}
              {form?.formState?.isSubmitting && <FormattedMessage defaultMessage="Adding..." />}
            </Button>
          )}
          title={<FormattedMessage defaultMessage="New Event" tagName="strong" />}
        />
        {form?.formState?.isSubmitting && <LoadingBar loadings={[{}]} />}
      </header>
      <main style={mainPaddings}>
        <Container className="pt-3" fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            {limitReached && (
              <UncontrolledAlert color="warning" className="alert-sm">
                <FormattedMessage
                  defaultMessage="Ouch, looks like ''{name}'' plan limit of {max, number, ::compact-short} events per month reached. It will be cleared after {date}."
                  values={{
                    name: limitsResult.data?.subscription?.plan?.name,
                    max: limitsResult.data?.max_new_events,
                    date: (
                      <time dateTime={nextReset?.toISO()} title={nextReset?.toLocaleString(DateTime.DATETIME_HUGE)}>
                        {nextReset?.toLocaleString(DateTime.DATETIME_SHORT)}
                      </time>
                    ),
                  }}
                />
                {limitsResult.data?.subscription?.plan?.level < 3 && (
                  <Fragment>
                    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                    {' '}
                    <FormattedMessage
                      defaultMessage="It's possible to <a>upgrade plan</a> if you need more events each month."
                      values={{
                        a: upgradeLink,
                      }}
                    />
                  </Fragment>
                )}
              </UncontrolledAlert>
            )}
            <QueryError error={serverErrors} />
            <EventForm
              hookForm={form}
              onSubmit={onSubmit}
              contacts={contacts}
              services={services}
              me={businessUser.data?.contact_id}
              disabled={limitReached || form?.formState?.isSubmitting}
            />
            {form.formState.isDirty && (
              <div className="mt-3">
                <Button
                  color="danger"
                  block
                  type="button"
                  onClick={onReset}
                >
                  <FormattedMessage defaultMessage="Reset form" />
                </Button>
              </div>
            )}
          </Col>
        </Container>
      </main>
    </div>
  );
};

export const NewEventRouteElement: React.FC = () => {
  const businessUser = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();
  const limitsResult = businessUsersApi.endpoints.getUsageLimits.useQueryState();
  const contactsResult = contactsApi.endpoints.getContacts.useQueryState({ page_size: 1000 });
  const servicesResult = useGetServicesQuery({ page_size: 300, sort_order: 'DESC' });

  return (
    <NewEventScreen
      businessUser={businessUser}
      limitsResult={limitsResult}
      contacts={contactsResult}
      services={servicesResult}
    />
  );
};

interface EditEventScreenProps {
  eventId: Event['id'];
  eventDetails: TypedUseQueryStateResult<Event, string, BaseQueryFn>;
  businessUser: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  contacts: TypedUseQueryStateResult<ApiCollection<ContactBasic>, PaginationParams, BaseQueryFn>;
  services: TypedUseQueryStateResult<ApiCollection<Service>, PaginationParams, BaseQueryFn>;
}

export const EditEventScreen: React.FC<EditEventScreenProps> = ({
  eventId,
  eventDetails,
  businessUser,
  contacts,
  services,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);
  const intl = useIntl();
  const navigate = useNavigate();
  const backHref = { pathname: `/events/${eventId}` };
  const [updateEvent, updateEventState] = useUpdateEventMutation();
  const [overwriteOrganizer, organizerEventState] = useUpdateOrganizerMutation();
  const [overwriteAttendee, attendeeEventState] = useUpdateAttendeeListMutation();
  const [overwriteService, serviceEventState] = useUpdateServiceListMutation();
  const [deleteEvent, deleteEventState] = useDeleteEventMutation();
  const serverErrors = [eventDetails?.error, businessUser?.error, contacts?.error, updateEventState?.error, organizerEventState?.error, attendeeEventState?.error, serviceEventState?.error, deleteEventState?.error]
    .filter((e) => Boolean(e)) as Array<FetchBaseQueryError | ApiErrorResponse>;
  const allDataLoaded = eventDetails?.isSuccess && businessUser?.isSuccess && contacts?.isSuccess && services?.isSuccess;
  const isLoading = [eventDetails, businessUser, contacts, services]
    .some((state) => state?.isLoading);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const schemaMemoized = useMemo(() => getEventSchema(intl), [intl]);
  // title is only optional field
  const schema = schemaMemoized.concat(Joi.object({
    start_date: Joi.date().raw().required(),
    start_time: Joi.string().required(),
    end_date: Joi.date().raw().required(),
    end_time: Joi.string().required(),
    timezone: Joi.string().required(),
  }).unknown());

  const defaultFormValues: EventFormValues = useMemo(() => ({
    id: '',
    start_date: '',
    start_time: '',
    end_date: '',
    end_time: '',
    timezone: '',
    summary: '',
    description: '',
    class: 'PRIVATE',
    priority: '0',
    geo: null,
    location: '',
    transp: 'OPAQUE',
    status: 'TENTATIVE',
    organizer: undefined,
    attendee: [],
    service: undefined,
  }), []);
  const form = useForm<EventFormValues>({
    defaultValues: defaultFormValues,
    resolver: joiResolver(schema, {
      // messages: validationMessages,
      abortEarly: false,
    }),
  });
  const { formState } = form;
  const isSaving = formState?.isSubmitting;

  const onDataLoad = () => {
    const timeFormatOpt: ToISOTimeOptions = { suppressMilliseconds: true, includeOffset: false };
    if (allDataLoaded && !formState.isDirty && !formState.isSubmitting) {
      const { data: event } = eventDetails;
      const organizer = contacts.data.items.find(({ id }) => id === event.organizer?.[0]?.id);
      const organizerOption = organizer ? contactsToReactSelectOptions(intl, [organizer], businessUser.data?.contact_id) : undefined;
      const attendeeIds = event.attendee.map(({ id }) => id);
      const attendee = contacts.data.items.filter(({ id }) => attendeeIds.includes(id));
      const attendeeOption = contactsToReactSelectOptions(intl, attendee, businessUser.data?.contact_id);
      const service = services.data.items.find(({ id }) => id === event.service?.[0]?.id);
      const serviceOption = (service) ? servicesToReactSelectOptions(intl, [service]) : undefined;

      form.reset({
        id: event.id,
        summary: event.summary ?? '',
        start_date: DateTime.fromISO(event.start, { zone: event.timezone }).toISODate(),
        start_time: DateTime.fromISO(event.start, { zone: event.timezone }).toISOTime(timeFormatOpt),
        end_date: DateTime.fromISO(event.end, { zone: event.timezone }).toISODate(),
        end_time: DateTime.fromISO(event.end, { zone: event.timezone }).toISOTime(timeFormatOpt),
        timezone: event.timezone,
        description: event.description ?? '',
        class: event.class ?? 'PRIVATE',
        priority: event.priority ?? '0',
        geo: event.geo ?? null,
        location: event.location ?? '',
        transp: event.transp ?? 'OPAQUE',
        status: event.status ?? 'TENTATIVE',
        organizer: (organizerOption) || undefined,
        attendee: attendeeOption ?? [],
        service: serviceOption,
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onDataLoad, [allDataLoaded]);

  const onSubmit = useCallback(async (formData: EventFormValues) => {
    const { organizer: organizerUpdated, attendee: attendeeUpdated, service: serviceUpdated, ...mainFields } = formState.dirtyFields;

    if (Object.keys(mainFields).length > 0) {
      // main contact fields updated
      const { id, start_date, start_time, end_date, end_time, timezone, summary, description, geo, location, priority, status } = formData;
      try {
        await updateEvent({
          start: DateTime.fromISO(`${start_date}T${start_time}`, { zone: timezone }).toISO(),
          end: DateTime.fromISO(`${end_date}T${end_time}`, { zone: timezone }).toISO(),
          id,
          summary,
          timezone,
          description,
          geo,
          location,
          priority,
          status,
          class: formData.class,
        }).unwrap();
      } catch (e) {
        return false;
      }
    }

    if (organizerUpdated) {
      try {
        await overwriteOrganizer({
          event_id: formData.id,
          items_ids: (formData.organizer) ? [formData.organizer.value] : [],
        }).unwrap();
      } catch (e) {
        return false;
      }
    }

    if (attendeeUpdated) {
      try {
        await overwriteAttendee({
          event_id: formData.id,
          items_ids: formData.attendee.map(({ value }) => value),
        }).unwrap();
      } catch {
        return false;
      }
    }

    if (serviceUpdated) {
      try {
        await overwriteService({
          event_id: formData.id,
          items_ids: (formData.service) ? [formData.service.value] : [],
        }).unwrap();
      } catch {
        return false;
      }
    }

    navigate({ pathname: `/events/${formData.id}` }, { replace: true });
    return true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.dirtyFields]);

  const onReset = () => form.reset();

  const onDelete = async () => {
    if (!eventDetails.data) return false;
    try {
      // await deleteContact(contactId).unwrap();
      // make redirect to wipe off old contact link
      await deleteEvent(eventDetails.data.id).unwrap();
      const start = DateTime.fromISO(eventDetails?.data?.start);
      navigate({ pathname: toCalendarPathname(start.toObject()) }, { replace: true });
    } catch {
      return false;
    }
    return true;
  };

  const formCallback = useMemo(() => formSubmitHandler<EventFormValues>({ handleSubmit: form.handleSubmit, onSubmit }), [form.handleSubmit, onSubmit]);

  return (
    <div id="edit-event-screen">
      <header ref={headerRef}>
        <BaseToolbar
          back={(
            <Button
              className="back-link"
              tag={Link}
              to={backHref}
              color="link"
              disabled={isLoading || isSaving}
            >
              <FontAwesomeIcon icon={solid('chevron-left')} />
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              {' '}
              {form.formState.isDirty && <FormattedMessage defaultMessage="Discard, back to event" />}
              {!form.formState.isDirty && <FormattedMessage defaultMessage="Back to event" />}
            </Button>
          )}
          forward={(
            <Button
              type="button"
              color="link"
              disabled={form.formState.isDirty === false || isSaving}
              onClick={formCallback}
            >
              {!isSaving && <FormattedMessage defaultMessage="Save" />}
              {isSaving && <FormattedMessage defaultMessage="Saving..." />}
            </Button>
          )}
        />
        {isSaving && <LoadingBar loadings={[{}]} />}
      </header>
      <main style={mainPaddings}>
        <Container className="pt-3" fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            <QueryError error={serverErrors} />
            {isLoading && <LoadingIndicator />}
            {!isLoading && (
              <Fragment>
                <EventForm
                  hookForm={form}
                  onSubmit={onSubmit}
                  contacts={contacts}
                  services={services}
                  me={businessUser.data?.contact_id}
                  disabled={isSaving}
                />
                {form.formState.isDirty && (
                  <div className="mt-3">
                    <Button
                      color="danger"
                      block
                      type="button"
                      onClick={onReset}
                    >
                      <FormattedMessage defaultMessage="Discard All Changes" />
                    </Button>
                  </div>
                )}
                <div className="mt-3">
                  <UncontrolledDropdown className="w-100" direction="up" disabled={isSaving}>
                    <DropdownToggle color="danger" block>
                      <FormattedMessage defaultMessage="Delete Event" />
                    </DropdownToggle>
                    <DropdownMenu className="w-100 text-center">
                      <DropdownItem onClick={onDelete}>
                        <FormattedMessage defaultMessage="Confirm Deletion" />
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>
                        <FormattedMessage defaultMessage="Cancel Deletion" />
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Fragment>
            )}
          </Col>
        </Container>
      </main>
    </div>
  );
};

export const EditEventRouteElement: React.FC = () => {
  const { eventId } = useParams();
  const eventDetails = useGetEventQuery(eventId ?? skipToken);
  const businessUser = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();
  const contactsResult = contactsApi.endpoints.getContacts.useQueryState({ page_size: 1000 });
  const servicesResult = useGetServicesQuery(eventId ? { page_size: 300, sort_order: 'DESC' } : skipToken);

  if (!eventId) return <Navigate to={{ pathname: toTodayPathname() }} replace />;

  return (
    <EditEventScreen
      eventId={eventId}
      eventDetails={eventDetails}
      businessUser={businessUser}
      contacts={contactsResult}
      services={servicesResult}
    />
  );
};

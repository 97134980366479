import { useId } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';
import { Col, Row, FormGroup, FormFeedback, Label } from 'reactstrap';

import { YearSelect, MonthSelect, DaySelect } from '../form/DateFormComponents';
import type { ContactFormValues, FormMethods } from '../types/api';

type DobControlProps = FormMethods<ContactFormValues>;

export const DobControl: React.FC<DobControlProps> = ({
  hookForm,
  disabled,
}) => {
  const px = useId();
  const { control, watch, formState: { errors } } = hookForm;
  const { year: nowYear } = DateTime.now();
  const watchYear = watch('birth_year');
  const watchMonth = watch('birth_month');

  return (
    <Row>
      <Col>
        <FormGroup>
          <Label htmlFor={`${px}doby`} hidden>
            <FormattedMessage defaultMessage="Birth year" />
          </Label>
          <Controller
            name="birth_year"
            control={control}
            render={({ field }) => (
              <YearSelect
                field={{
                  ...field,
                  id: `${px}doby`,
                  invalid: Boolean(errors?.birth_year),
                  disabled,
                }}
                min={nowYear - 100}
                max={nowYear}
              />
            )}
          />
          <FormFeedback>
            {errors?.birth_year?.message}
          </FormFeedback>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Label htmlFor={`${px}dobm`} hidden>
            <FormattedMessage defaultMessage="Birth month" />
          </Label>
          <Controller
            name="birth_month"
            control={control}
            render={({ field }) => (
              <MonthSelect
                field={{
                  ...field,
                  id: `${px}dobm`,
                  invalid: Boolean(errors?.birth_month),
                  disabled,
                }}
              />
            )}
          />
          <FormFeedback>
            {errors?.birth_month?.message}
          </FormFeedback>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Label htmlFor={`${px}dobd`} hidden>
            <FormattedMessage defaultMessage="Birth day" />
          </Label>
          <Controller
            name="birth_day"
            control={control}
            render={({ field }) => (
              <DaySelect
                field={{
                  ...field,
                  id: `${px}dobd`,
                  invalid: Boolean(errors?.birth_day),
                  disabled,
                }}
                year={typeof watchYear === 'string' ? parseInt(watchYear, 10) : null}
                month={typeof watchMonth === 'string' ? parseInt(watchMonth, 10) : null}
              />
            )}
          />
          <FormFeedback>
            {errors?.birth_day?.message}
          </FormFeedback>
        </FormGroup>
      </Col>
    </Row>
  );
};

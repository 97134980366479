import { ReactNode, useEffect, Fragment, useMemo, useCallback, useState, useRef } from 'react';
import { TypedUseQueryStateResult, BaseQueryFn, skipToken, FetchBaseQueryError, TypedUseQueryHookResult } from '@reduxjs/toolkit/dist/query/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import { useNavigate, useParams, Link, Navigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { CountryCode, isSupportedCountry } from 'libphonenumber-js/max';
import { Button, Card, CardBody, CardFooter, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledAlert, UncontrolledDropdown } from 'reactstrap';

import { DateTime } from 'luxon';
import { BaseToolbar } from '../core/BaseToolbar';
import { ContactPanel, ContactDetailsPanel, ContactForm } from '../contacts/Contact';
import { ImportVcfForm } from '../imports/Import';
import { getVcfSchema } from '../imports/schema';
import { type PhoneValidationOptions, getContactSchema, getJoiMessages } from '../contacts/schema';
import { useGetContactDetailsQuery, useCreateContactMutation, useUpdateContactMutation, useDeleteContactMutation, useCreateContactPropertyMutation, useUpdateContactPropertyMutation, useDeleteContactPropertyMutation, useGetContactsQuery } from '../contacts/contactsApi';
import { FilterInput } from '../core/FilterInput';
import { LoadingIndicator } from '../core/LoadingIndicator';
import { QueryError } from '../core/QueryError';
import { useGetImportsQuery, useImportVCardMutation } from '../imports/importsApi';
import { businessUsersApi } from '../business_user/businessUsersApi';
import Joi, { defineJoiMessages } from '../core/ExtendedJoi';
import { useDeleteFinancialRecordMutation, useGetFinancialRecordsQuery } from '../finance/financeApi';
import useHeaderAndFooterHeight from '../core/useHeaderAndFooterHeight';
import { BalanceList, FinancePanel, reduceUsersBalances } from '../finance/Finance';
import { EventsPanel } from '../calendar/Event';
import { useGetEventsQuery } from '../calendar/calendarApi';
import { mergeRtkQueryStates } from '../api/fetchBaseQuery';
import { LoadingBar } from '../core/LoadingBar';
import { DemoUserDisclaimer } from '../core/DemoUserDisclaimer';
import type { Contact, ContactBasic, ContactFormValues, FinancialRecord, ApiCollection, ApiErrorResponse, ContactProperty, BusinessUser, FinancialRecordsResponse, GetFinancialRecordsParams, UsageLimit, PaginationParams, GetEventsParams, Import, GetImportsParams } from '../types/api';

interface ContactsScreenProps {
  businessUser: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  usageLimits: TypedUseQueryStateResult<UsageLimit, void, BaseQueryFn>;
  importsResult: TypedUseQueryHookResult<ApiCollection<Import>, GetImportsParams, BaseQueryFn>;
  contacts: TypedUseQueryStateResult<ApiCollection<ContactBasic>, PaginationParams, BaseQueryFn>;
}

export const ContactsScreen: React.FC<ContactsScreenProps> = ({
  businessUser,
  usageLimits,
  importsResult,
  contacts,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);
  const [importFile, importState] = useImportVCardMutation();
  const isLoading = contacts?.isLoading;
  const { error } = mergeRtkQueryStates(businessUser, usageLimits, importsResult, importState);

  const intl = useIntl();
  const joiMessages = useMemo(() => defineJoiMessages(intl), [intl]);
  const searchSchema = useMemo(() => Joi.object({
    filterSearch: Joi.string().max(50),
  }), []);
  const searchForm = useForm({
    defaultValues: { filterSearch: '' },
    mode: 'onChange',
    resolver: joiResolver(searchSchema, {
      messages: joiMessages,
      abortEarly: false,
    }),
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const schemaMemoized = useMemo(() => getVcfSchema(intl), [intl]);
  const form = useForm<{ file: FileList | '' }>({
    defaultValues: { file: '' },
    resolver: joiResolver(schemaMemoized, {
      messages: joiMessages,
      abortEarly: false,
    }),
  });

  const onSubmit = async ({ file }: { file: FileList }) => {
    try {
      await importFile(file?.[0]).unwrap();
    } catch (e) {
      return false;
    }
    return true;
  };
  const newContactLink = (chunk: ReactNode) => <Link to="/contacts/new">{chunk}</Link>;
  const servicesLink: React.FC<ReactNode> = (chunk) => <Link to="/services">{chunk}</Link>;
  const templatesLink: React.FC<ReactNode> = (chunk) => <Link to="/templates">{chunk}</Link>;

  // render flags
  const noContacts: boolean = contacts?.isSuccess && contacts?.data?.items?.length === 0;
  const stagedImport: Import | null = importsResult?.data?.items?.find(({ status }) => status === 'initial') ?? null;
  const contactsLeft: number = (usageLimits.data?.max_contacts - usageLimits.data?.current_contacts) ?? 0;
  const importPossible: boolean = noContacts && stagedImport === null && contactsLeft > 0;

  return (
    <div id="contacts-screen">
      <header ref={headerRef}>
        <BaseToolbar
          title={<FormattedMessage defaultMessage="Contacts" />}
          forward={(
            <Button tag={Link} to="/contacts/new" color="link">
              <FontAwesomeIcon icon={solid('plus')} />
              <span className="visually-hidden">
                <FormattedMessage defaultMessage="Add new contact" />
              </span>
            </Button>
          )}
        />
        <Container fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            <FilterInput
              hookForm={searchForm}
              disabled={contacts.isLoading || contacts.isFetching}
            />
          </Col>
        </Container>
        <LoadingBar loadings={[businessUser, importsResult, usageLimits, contacts]} />
      </header>
      <main style={mainPaddings}>
        <Container className="pt-3" fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            {/* in case of server error */}
            <QueryError error={error} />

            {isLoading && <LoadingIndicator />}
            {!isLoading && (
              <Fragment>
                {businessUser.data?.is_demo && <DemoUserDisclaimer />}
                {stagedImport && (
                  <UncontrolledAlert color="info">
                    <FormattedMessage
                      defaultMessage="There is an import created {date}. Usually it takes about 15 minutes to extract contacts, meanwhile you can take a look at <t>templates</t> and <s>services</s> sections."
                      values={{
                        date: (
                          <time dateTime={stagedImport.created} title={DateTime.fromISO(stagedImport.created).toLocaleString()}>
                            {DateTime.fromISO(stagedImport.created).toRelativeCalendar()}
                          </time>
                        ),
                        s: servicesLink,
                        t: templatesLink,
                      }}
                    />
                  </UncontrolledAlert>
                )}
                {importPossible && (
                  <Fragment>
                    <UncontrolledAlert color="info">
                      <FormattedMessage
                        defaultMessage="No contacts so far. You can <a>create one</a> or import up to {max, number, ::compact-short} contacts via form below."
                        values={{
                          a: newContactLink,
                          max: contactsLeft,
                        }}
                      />
                    </UncontrolledAlert>
                    {!importState?.isSuccess && (
                      <Card>
                        <ImportVcfForm
                          hookForm={form}
                          onSubmit={onSubmit}
                          components={{ body: CardBody }}
                          disabled={importState?.isSuccess}
                        />
                        <CardFooter>
                          <Button
                            block
                            color="primary"
                            onClick={form.handleSubmit(onSubmit)}
                            disabled={form.formState.isSubmitting || importState?.isSuccess}
                            size="sm"
                          >
                            <FormattedMessage defaultMessage="Import Contacts" />
                          </Button>
                        </CardFooter>
                      </Card>
                    )}
                  </Fragment>
                )}
                {!noContacts && <ContactPanel contacts={contacts} searchForm={searchForm} me={businessUser.data?.contact_id} searchVisible={false} />}
              </Fragment>
            )}
          </Col>
        </Container>
      </main>
    </div>
  );
};

export const ContactsRouteElement: React.FC = () => {
  const businessUser = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();
  const usageLimits = businessUsersApi.endpoints.getUsageLimits.useQueryState();
  const skip = businessUser.isSuccess ? null : skipToken;
  const [pollingInterval, setPollingInterval] = useState<number>(0);
  const contactsResult = useGetContactsQuery(skip ?? {
    page: 1,
    page_size: 1000,
    sort_order: 'ASC',
  }, { pollingInterval });
  const importsResult = useGetImportsQuery(skip ?? {
    page: 1,
    page_size: 1,
    sort_order: 'DESC',
    filter_status: 'initial',
  }, { pollingInterval });

  // start polling each minute when we have unfinished import
  useEffect(() => {
    const hasStagedImport = importsResult.isSuccess && importsResult.data?.items_count > 0;
    const noStagedImports = importsResult.isSuccess && importsResult.data?.items_count < 1;
    if (hasStagedImport && pollingInterval === 0) {
      // turnon polling
      setPollingInterval(1000 * 60);
    } else if (pollingInterval > 0 && noStagedImports) {
      // turnoff polling
      setPollingInterval(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importsResult.data?.items_count]);

  return (
    <ContactsScreen
      businessUser={businessUser}
      importsResult={importsResult}
      contacts={contactsResult}
      usageLimits={usageLimits}
    />
  );
};

interface ContactDetailsScreenProps {
  contactId: NonNullable<Contact['id']>;
  businessUser: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  contactDetails: TypedUseQueryStateResult<Contact, string, BaseQueryFn>;
  financialRecords: TypedUseQueryStateResult<FinancialRecordsResponse, GetFinancialRecordsParams, BaseQueryFn>;
  overallIncome: TypedUseQueryStateResult<FinancialRecordsResponse, GetFinancialRecordsParams, BaseQueryFn>;
  overallExpenses: TypedUseQueryStateResult<FinancialRecordsResponse, GetFinancialRecordsParams, BaseQueryFn>;
  eventsResult: TypedUseQueryStateResult<ApiCollection<Event>, GetEventsParams, BaseQueryFn>;
}

export const ContactDetailsScreen: React.FC<ContactDetailsScreenProps> = ({
  contactId,
  businessUser,
  contactDetails,
  financialRecords,
  overallIncome,
  overallExpenses,
  eventsResult,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);
  const colMd = { size: 6, offset: 3 };
  const [deleteRecord, deletionState] = useDeleteFinancialRecordMutation();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  query.set('filter_contact', contactId);
  const hasItems = financialRecords?.data?.items_total > 0;
  const hasMoreRecords = financialRecords?.data?.items_total > financialRecords?.data?.items_count ?? false;
  const incomeAllUsers = (overallIncome.data?.balances)
    ? reduceUsersBalances(overallIncome.data.balances)
    : null;
  const expensesAllUsers = (overallExpenses.data?.balances)
    ? reduceUsersBalances(overallExpenses.data.balances)
    : null;

  return (
    <div id="contacts-details-screen">
      <header ref={headerRef}>
        <BaseToolbar
          back={(
            <Button className="back-link" tag={Link} to="/contacts" color="link">
              <FontAwesomeIcon icon={solid('chevron-left')} />
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              {' '}
              <FormattedMessage defaultMessage="Contacts" />
            </Button>
          )}
          forward={(
            <Button tag={Link} to={`/contacts/${contactId}/edit`} color="link">
              <FormattedMessage defaultMessage="Edit" />
            </Button>
          )}
        />
        <LoadingBar loadings={[businessUser, contactDetails, financialRecords, overallIncome, overallExpenses, eventsResult]} />
      </header>
      <main style={mainPaddings}>
        <Container className="pt-3" fluid="sm">
          <Col md={colMd}>
            <ContactDetailsPanel
              contactDetails={contactDetails}
            />
          </Col>
        </Container>
        {/* financial history */}
        {contactDetails.isSuccess && (
          <Fragment>
            <Container className="pt-4" fluid="sm">
              <Col md={colMd}>
                <div className="h2">
                  <FormattedMessage
                    defaultMessage="{num, plural, =0 {No Budget Records} one {Recent Budget Record} other {Recent Budget Records}}"
                    values={{ num: financialRecords.data?.items_count ?? 100 }}
                  />
                </div>
                <div className="text-end">
                  {(incomeAllUsers?.size > 0 || expensesAllUsers?.size > 0) && (
                    <FormattedMessage
                      defaultMessage="Total income {income}, expenses {expenses}"
                      values={{
                        income: (incomeAllUsers?.size > 0) ? <strong><BalanceList balances={Object.fromEntries(incomeAllUsers)} type="inline" /></strong> : '0',
                        expenses: (expensesAllUsers?.size > 0) ? <strong><BalanceList balances={Object.fromEntries(expensesAllUsers)} type="inline" /></strong> : '0',
                      }}
                    />
                  )}
                </div>
                {(financialRecords.isLoading || financialRecords.data?.items_count > 0) && (
                  <FinancePanel
                    items={financialRecords}
                    page_size={10}
                    sort_order="DESC"
                    onLoadMore={null}
                    fields={{
                      date: {
                        dateFormat: DateTime.DATETIME_SHORT,
                      },
                      description: {
                        visible: false,
                      },
                      contact: {
                        visible: false,
                      },
                    }}
                    onDelete={async (item: FinancialRecord) => {
                      try {
                        await deleteRecord(item.id).unwrap();
                      } catch (e) {
                        return false;
                      }
                      return true;
                    }}
                  />
                )}
              </Col>
            </Container>
            {/* events history */}
            <Container className="pt-5" fluid="sm">
              <Col md={colMd}>
                <div className="h2">
                  <FormattedMessage
                    defaultMessage="{num, plural, =0 {No Events} one {Recent Event} other {Recent Events}}"
                    values={{ num: eventsResult.data?.items_count ?? 100 }}
                  />
                </div>
                {(eventsResult.isLoading || eventsResult.data?.items_count > 0) && (
                  <EventsPanel
                    items={eventsResult}
                    components={{
                      counter: CardFooter,
                    }}
                  />
                )}
              </Col>
            </Container>
          </Fragment>
        )}
      </main>
    </div>
  );
};

interface NewContactScreenProps {
  businessUser: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  limitsResult: TypedUseQueryStateResult<UsageLimit, void, BaseQueryFn>;
}

export const NewContactScreen: React.FC<NewContactScreenProps> = ({
  businessUser,
  limitsResult,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);
  const [createContact, createContactState] = useCreateContactMutation();
  const [createProperty, createPropertyState] = useCreateContactPropertyMutation();
  const loadingState = mergeRtkQueryStates(businessUser, limitsResult, createContactState, createPropertyState);
  const limitReached = false || limitsResult.data?.max_contacts <= limitsResult.data?.current_contacts;
  const intl = useIntl();
  const navigate = useNavigate();
  const countryCode = (typeof businessUser?.data?.country === 'string' && isSupportedCountry(businessUser?.data?.country as CountryCode)) ? businessUser?.data?.country as CountryCode : 'US';
  const phoneOptions: PhoneValidationOptions = {
    country: countryCode,
    allowedCountry: [countryCode],
    allowedType: ['PERSONAL_NUMBER', 'FIXED_LINE_OR_MOBILE', 'FIXED_LINE', 'MOBILE'],
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const schemaMemoized = useMemo(() => getContactSchema(intl, phoneOptions), [intl, phoneOptions]);
  const schema = schemaMemoized.concat(Joi.object({
    first_name: Joi.string().required(),
  }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validationMessages = useMemo(() => getJoiMessages(intl), [intl]);
  const defaultFormValues = {
    kind: 'individual',
    first_name: '',
    last_name: '',
    middle_name: '',
    gender: [''],
    birth_year: '',
    birth_month: '',
    birth_day: '',
    properties: [],
  } as Omit<ContactFormValues, 'id'>;
  const form = useForm<ContactFormValues>({
    defaultValues: defaultFormValues,
    resolver: joiResolver(schema, {
      messages: validationMessages,
      abortEarly: false,
    }),
  });
  const isSaving = form.formState.isSubmitting;

  const onSubmit = async (formData: Omit<ContactFormValues, 'id'>) => {
    const { properties, ...contact } = formData;
    const newProperties = properties
      // ignore deleted and empty props
      ?.filter((prop) => prop?.deleted !== true && !['', null, [], {}, undefined].includes(prop?.value))
      // strip form logic out of payload
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ?.map(({ id, skip_validation, read_only, deleted, ...propPayload }) => propPayload) ?? [];

    let contactId: string | null = null;
    let createdCounter = 0;
    try {
      const response = await createContact(contact).unwrap();
      contactId = response?.id;
    } catch (e) {
      return false;
    }

    if (contactId && newProperties?.length > 0) {
      // .forEach doesn't work with async
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < newProperties?.length; i++) {
        const prop = newProperties[i];
        // create new
        try {
          /**
           * TODO: run all prop creation instantly
           * @link https://eslint.org/docs/latest/rules/no-await-in-loop
           */
          // eslint-disable-next-line no-await-in-loop
          const response = await createProperty({
            ...prop,
            contact_id: contactId,
            value: [prop?.value],
          }).unwrap();
          createdCounter += (response?.id) ? 1 : 0;
        } catch (e) {
          // do nothing
        }
      }
    }

    if (createdCounter === newProperties?.length) {
      navigate(`/contacts/${contactId}`, { replace: true });
      return true;
    }
    return false;
  };

  const onReset = () => form.reset();

  const upgradeLink: React.FC<ReactNode> = (chunk) => <Link to={{ pathname: '/plans' }}>{chunk}</Link>;

  return (
    <div id="new-contact-screen">
      <header ref={headerRef}>
        <BaseToolbar
          back={(
            <Button
              className="back-link"
              tag={Link}
              to="/contacts"
              color="link"
              disabled={isSaving}
            >
              <FontAwesomeIcon icon={solid('chevron-left')} />
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              {' '}
              {!form.formState.isDirty && <FormattedMessage defaultMessage="Contacts" />}
              {form.formState.isDirty && <FormattedMessage defaultMessage="Cancel" />}
            </Button>
          )}
          forward={(
            <Button
              type="button"
              color="link"
              onClick={form.handleSubmit(onSubmit)}
              disabled={limitReached || isSaving}
            >
              {!isSaving && <FormattedMessage defaultMessage="Add" />}
              {isSaving && <FormattedMessage defaultMessage="Adding..." />}
            </Button>
          )}
          title={<FormattedMessage defaultMessage="New Contact" tagName="strong" />}
        />
        {!isSaving && <LoadingBar loadings={[businessUser, limitsResult]} />}
        {/* always 25% while submitting form */}
        {isSaving && <LoadingBar loadings={[{}]} />}
      </header>
      <main style={mainPaddings}>
        <Container className="pt-3" fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            {loadingState.isLoading && <LoadingIndicator />}

            <QueryError error={loadingState.error} />
            {limitReached && (
              <UncontrolledAlert color="warning" className="alert-sm">
                <FormattedMessage
                  defaultMessage="Ouch, looks like ''{name}'' plan limit of {max, number, ::compact-short} contacts reached."
                  values={{
                    name: limitsResult.data?.subscription?.plan?.name,
                    max: limitsResult.data?.max_contacts,
                  }}
                />
                {limitsResult.data?.subscription?.plan?.level < 3 && (
                  <Fragment>
                    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                    {' '}
                    <FormattedMessage
                      defaultMessage="It's possible to <a>upgrade plan</a> if you need more contacts."
                      values={{
                        a: upgradeLink,
                      }}
                    />
                  </Fragment>
                )}
              </UncontrolledAlert>
            )}

            {!loadingState.isLoading && (
              <Fragment>
                <ContactForm
                  hookForm={form}
                  onSubmit={onSubmit}
                  disabled={limitReached || isSaving}
                />
                {form.formState.isDirty && (
                  <div className="mt-3">
                    <Button
                      color="danger"
                      block
                      type="button"
                      onClick={onReset}
                    >
                      <FormattedMessage defaultMessage="Reset form" />
                    </Button>
                  </div>
                )}
              </Fragment>
            )}
          </Col>
        </Container>
      </main>
    </div>
  );
};

interface EditContactScreenProps {
  contactId: NonNullable<Contact['id']>;
  businessUser: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  contactDetails: TypedUseQueryStateResult<Contact, string, BaseQueryFn>;
}

export const EditContactScreen: React.FC<EditContactScreenProps> = ({
  contactId,
  businessUser,
  contactDetails,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);
  const intl = useIntl();
  const navigate = useNavigate();
  const { data } = contactDetails;
  const [deleteContact, deleteContactState] = useDeleteContactMutation();
  const [updateContact, updateContactState] = useUpdateContactMutation();
  const [createProperty, createPropertyState] = useCreateContactPropertyMutation();
  const [updateProperty, updatePropertyState] = useUpdateContactPropertyMutation();
  const [deleteProperty, deletePropertyState] = useDeleteContactPropertyMutation();
  const isLoading = businessUser.isLoading || contactDetails.isLoading;
  const serverErrors = [businessUser?.error, contactDetails?.error, deleteContactState?.error, updateContactState?.error, createPropertyState?.error, updatePropertyState?.error, deletePropertyState?.error]
    .filter((e) => Boolean(e)) as Array<FetchBaseQueryError | ApiErrorResponse>;
  const countryCode = (typeof businessUser?.data?.country === 'string' && isSupportedCountry(businessUser?.data?.country as CountryCode)) ? businessUser?.data?.country as CountryCode : 'US';
  const phoneOptions: PhoneValidationOptions = {
    country: countryCode,
    allowedCountry: [countryCode],
    allowedType: ['PERSONAL_NUMBER', 'FIXED_LINE_OR_MOBILE', 'FIXED_LINE', 'MOBILE'],
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const schemaMemoized = useMemo(() => getContactSchema(intl, phoneOptions), [intl, phoneOptions]);
  // since contact might be created via vCard import we should perform
  // less strict validation
  // all basic props(n, fn, gender, bday) might be invalid but we should allow to save them
  // that's why only id is required field
  const schema = schemaMemoized.concat(Joi.object({
    id: Joi.string().required(),
  }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validationMessages = useMemo(() => getJoiMessages(intl), [intl]);
  const defaultFormValues = {
    id: '',
    kind: 'individual',
    first_name: '',
    last_name: '',
    middle_name: '',
    gender: [''],
    birth_year: '',
    birth_month: '',
    birth_day: '',
    properties: [],
  } as ContactFormValues;
  const form = useForm<ContactFormValues>({
    defaultValues: defaultFormValues,
    resolver: joiResolver(schema, {
      messages: validationMessages,
      abortEarly: false,
    }),
  });
  const isSaving = form.formState.isSubmitting;
  const { dirtyFields } = form.formState;
  const hasDirtyFields = Object.keys(dirtyFields).length > 0;

  const onSubmit = useCallback(async (contact: ContactFormValues) => {
    const { properties: dirtyProperties = [], ...mainFields } = dirtyFields;
    let deletedCounter = 0;
    let modifiedCounter = 0;
    let createdCounter = 0;
    let deletedKeys: string[] = [];
    let modifiedKeys: string[] = [];
    let createdKeys = [];

    if (Object.keys(mainFields).length > 0) {
      // main contact fields updated
      try {
        await updateContact(contact).unwrap();
      } catch (e) {
        return false;
      }
    }

    if (Array.isArray(dirtyProperties)) {
      type PropsMap = {
        propsDeleted: ContactProperty[];
        propsModified: ContactProperty[];
        propsCreated: ContactProperty[];
      }
      const { propsDeleted, propsModified, propsCreated }: PropsMap = dirtyProperties.reduce((prev, current, index: number) => {
        if (!contact?.properties?.[index]) return prev;
        // strip logic util flags
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { skip_validation, read_only, deleted, value, ...prop } = contact?.properties?.[index] ?? {};

        const sanitizedProp = {
          ...prop,
          value: (['', null, [], {}, undefined].includes(value)) ? [] : [value],
        };

        if (deleted === true && prop?.id) {
          // eslint-disable-next-line no-param-reassign
          prev.propsDeleted[index] = sanitizedProp;
        } else if (prop?.id) {
          // eslint-disable-next-line no-param-reassign
          prev.propsModified[index] = sanitizedProp;
        } else if (deleted !== true) {
          // eslint-disable-next-line no-param-reassign
          prev.propsCreated[index] = sanitizedProp;
        }

        return prev;
      }, { propsDeleted: [], propsModified: [], propsCreated: [] } as PropsMap);

      // keep strict order, delete, update then create
      deletedKeys = Object.keys(propsDeleted);
      modifiedKeys = Object.keys(propsModified);
      createdKeys = Object.keys(propsCreated);

      // eslint-disable-next-line no-plusplus
      for (let d = 0; d < deletedKeys.length; d++) {
        const key = parseInt(deletedKeys[d], 10);
        const prop = propsDeleted?.[key];
        // create new
        try {
          /**
           * TODO: run all prop creation instantly
           * @link https://eslint.org/docs/latest/rules/no-await-in-loop
           */
          // eslint-disable-next-line no-await-in-loop
          await deleteProperty({
            id: prop?.id,
            contact_id: prop?.contact_id,
          }).unwrap();
          deletedCounter += 1;
        } catch (e) {
          // do nothing
        }
      }

      // eslint-disable-next-line no-plusplus
      for (let m = 0; m < modifiedKeys.length; m++) {
        const key = parseInt(modifiedKeys[m], 10);
        const prop = propsModified?.[key];
        try {
          /**
           * TODO: run all prop creation instantly
           * @link https://eslint.org/docs/latest/rules/no-await-in-loop
           */
          // eslint-disable-next-line no-await-in-loop
          const response = await updateProperty(prop).unwrap();
          modifiedCounter += (response?.id) ? 1 : 0;
        } catch (e) {
          // do nothing
        }
      }

      // eslint-disable-next-line no-plusplus
      for (let c = 0; c < createdKeys.length; c++) {
        const key = parseInt(createdKeys[c], 10);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, ...prop } = propsCreated?.[key] ?? {};
        try {
          /**
           * TODO: run all prop creation instantly
           * @link https://eslint.org/docs/latest/rules/no-await-in-loop
           */
          // eslint-disable-next-line no-await-in-loop
          const response = await createProperty(prop).unwrap();
          createdCounter += (response?.id) ? 1 : 0;
        } catch (e) {
          // do nothing
        }
      }
    }

    if (
      deletedCounter === deletedKeys?.length
      && modifiedCounter === modifiedKeys?.length
      && createdCounter === createdKeys?.length
    ) {
      navigate(`/contacts/${contactId}`);
      return true;
    }

    return false;
  }, [dirtyFields]);

  const onReset = () => form.reset();

  const onDelete = async () => {
    try {
      await deleteContact(contactId).unwrap();
      // make redirect to wipe off old contact link
      navigate('/contacts', { replace: true });
    } catch (e) {
      // do nothing
    }
  };

  useEffect(() => {
    if (!isLoading && data?.id === contactId) {
      const genderToSelectValue = (gender: Contact['gender']): [string, string] | [string] => {
        if (!Array.isArray(gender) || gender?.length < 1) return [''];

        return gender.map((g) => {
          if (typeof g !== 'string') return '';

          return ['m', 'f', 'o', 'n', 'u'].includes(g) ? g.toUpperCase() : g;
        }).slice(0, 2) as [string, string];
      };

      form.reset({
        id: data.id,
        kind: data?.kind ?? 'individual',
        first_name: data?.first_name ?? '',
        last_name: data?.last_name ?? '',
        middle_name: data?.middle_name ?? '',
        gender: genderToSelectValue(data?.gender),
        birth_year: data?.birth_year ?? '',
        birth_month: data?.birth_month ?? '',
        birth_day: data?.birth_day ?? '',
        properties: data?.properties
          // let's handle tel, email, url and note so far
          ?.filter(({ name }) => ['tel', 'email', 'url', 'note'].includes(name?.toLowerCase()))
          ?.map((prop) => ({
            ...prop,
            // don't apply validation to masked values
            skip_validation: ['tel', 'email', 'url'].includes(prop?.name),
            read_only: ['tel', 'email', 'url'].includes(prop?.name),
            deleted: false,
          })) ?? [],
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId, isLoading]);

  return (
    <div id="edit-contact-screen">
      <header ref={headerRef}>
        <BaseToolbar
          back={(
            <Button
              className="back-link"
              tag={Link}
              to={`/contacts/${contactId}`}
              color="link"
              disabled={isLoading || isSaving}
            >
              <FontAwesomeIcon icon={solid('chevron-left')} />
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              {' '}
              {hasDirtyFields && <FormattedMessage defaultMessage="Discard, back to contact" />}
              {!hasDirtyFields && <FormattedMessage defaultMessage="Back to contact" />}
            </Button>
          )}
          forward={(
            <Button
              type="button"
              color="link"
              disabled={hasDirtyFields === false || isSaving}
              onClick={form.handleSubmit(onSubmit)}
            >
              {!isSaving && <FormattedMessage defaultMessage="Save" />}
              {isSaving && <FormattedMessage defaultMessage="Saving..." />}
            </Button>
          )}
        />
        {!isSaving && <LoadingBar loadings={[businessUser, contactDetails]} />}
        {/* always 25% while submitting form */}
        {isSaving && <LoadingBar loadings={[{}]} />}
      </header>
      <main style={mainPaddings}>
        <Container className="pt-3" fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            <QueryError error={serverErrors} />
            {isLoading && <LoadingIndicator />}
            {!isLoading && (
              <Fragment>
                <ContactForm
                  hookForm={form}
                  onSubmit={onSubmit}
                  disabled={isSaving}
                />
                {hasDirtyFields && (
                  <div className="mt-3">
                    <Button
                      color="danger"
                      block
                      type="button"
                      onClick={onReset}
                    >
                      <FormattedMessage defaultMessage="Discard All Changes" />
                    </Button>
                  </div>
                )}
                <div className="mt-3">
                  <UncontrolledDropdown className="w-100" direction="up">
                    <DropdownToggle color="danger" block>
                      <FormattedMessage defaultMessage="Delete Contact" />
                    </DropdownToggle>
                    <DropdownMenu className="w-100 text-center">
                      <DropdownItem onClick={onDelete}>
                        <FormattedMessage defaultMessage="Confirm Deletion" />
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>
                        <FormattedMessage defaultMessage="Cancel Deletion" />
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Fragment>
            )}
          </Col>
        </Container>
      </main>
    </div>
  );
};

export const ContactDetailsRouteElement: React.FC = () => {
  const { contactId } = useParams();
  const businessUser = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();
  const contactDetailsResult = useGetContactDetailsQuery(contactId?.toString() ?? skipToken);
  const skipUntilLoaded = { skip: !contactDetailsResult.isSuccess };
  const defaultParams = {
    page: 1,
    page_size: 10,
    sort_order: 'DESC',
    filter_contact: contactId,
  } as GetFinancialRecordsParams;
  const finRecords = useGetFinancialRecordsQuery(defaultParams, skipUntilLoaded);
  const overallIncome = useGetFinancialRecordsQuery({
    ...defaultParams,
    page_size: 1,
    filter_user_min: 0,
  }, skipUntilLoaded);
  const overallExpenses = useGetFinancialRecordsQuery({
    ...defaultParams,
    page_size: 1,
    filter_user_max: 0,
  }, skipUntilLoaded);
  const eventsResult = useGetEventsQuery({
    page: 1,
    page_size: 10,
    sort_order: 'DESC',
    filter_attendee: contactId,
  }, skipUntilLoaded);

  // can't use redirect before second hook
  if (typeof contactId !== 'string') return <Navigate to={{ pathname: '/contacts' }} />;

  return (
    <ContactDetailsScreen
      businessUser={businessUser}
      contactId={contactId}
      contactDetails={contactDetailsResult}
      financialRecords={finRecords}
      overallIncome={overallIncome}
      overallExpenses={overallExpenses}
      eventsResult={eventsResult}
    />
  );
};

export const NewContactRouteElement: React.FC = () => {
  const businessUser = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();
  const limitsResult = businessUsersApi.endpoints.getUsageLimits.useQueryState();

  return <NewContactScreen businessUser={businessUser} limitsResult={limitsResult} />;
};

export const EditContactRouteElement: React.FC = () => {
  const { contactId } = useParams();
  const contactDetailsResult = useGetContactDetailsQuery(contactId?.toString() ?? skipToken);
  const businessUser = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();

  // can't use redirect before second hook
  if (typeof contactId !== 'string') return <Navigate to={{ pathname: '/contacts' }} />;

  return (
    <EditContactScreen
      contactId={contactId}
      businessUser={businessUser}
      contactDetails={contactDetailsResult}
    />
  );
};

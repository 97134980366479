import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { TypedUseQueryStateResult, BaseQueryFn, FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, Navigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import { useStore } from 'react-redux';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Button, Container, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledAlert, UncontrolledDropdown } from 'reactstrap';

import { QueryError } from '../core/QueryError';
import { BaseToolbar } from '../core/BaseToolbar';
import { businessUsersApi } from '../business_user/businessUsersApi';
import { useCreateServiceMutation, useDeleteServiceMutation, useGetServiceDetailsQuery, useGetServicesQuery, useUpdateServiceMutation } from '../services/servicesApi';
import { ServiceForm, ServicesPanel } from '../services/Service';
import Joi, { defineJoiMessages } from '../core/ExtendedJoi';
import { LoadingIndicator } from '../core/LoadingIndicator';
import { toReactSelectOption } from '../core/CurrencySelect';
import { getServiceSchema } from '../services/schema';
import { loadBinary, mergeRtkQueryStates } from '../api/fetchBaseQuery';
import { LoadingBar } from '../core/LoadingBar';
import useHeaderAndFooterHeight from '../core/useHeaderAndFooterHeight';
import { DemoUserDisclaimer } from '../core/DemoUserDisclaimer';
import type { ApiCollection, ApiErrorResponse, BusinessUser, PaginationParams, Service, UsageLimit, ServiceFormValues } from '../types/api';

interface ServicesScreenProps {
  businessUser: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  services: TypedUseQueryStateResult<ApiCollection<Service>, PaginationParams, BaseQueryFn>;
}

const parseContentDisposition = (
  header: string,
): string | null | undefined => header.split(';').reduce(
  (carry: string | null, item: string) => {
    if (carry) return carry;

    const q = new URLSearchParams(item?.trim());
    if (q.has('filename')) return q.get('filename')?.replace(/^("|')/, '')?.replace(/("|')$/, '');

    return carry;
  },
  null,
);

export const ServicesScreen: React.FC<ServicesScreenProps> = ({
  businessUser,
  services,
  onLoadMore,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);
  const [pdfLink, setPdfLink] = useState<{ href?: string; download?: string; label?: string; isLoading?: boolean; }>({});
  const [binaryError, setBinaryError] = useState<Error | null>(null);
  const loadingState = mergeRtkQueryStates(businessUser, services);
  const store = useStore();
  const noServices: boolean = services.isLoading === false && services.data?.items_count < 1;

  const onDownloadClick = async () => {
    if (pdfLink.href) return true;

    try {
      setPdfLink({ isLoading: true });
      const { data, meta: { response } } = await loadBinary('/services/price_list', store, 'application/pdf');
      if (response?.ok && data instanceof Blob) {
        const filename = parseContentDisposition(response?.headers?.get('content-disposition') ?? '') ?? 'price_list.pdf';
        setPdfLink({
          href: URL.createObjectURL(data),
          download: filename ?? undefined,
          label: filename ?? undefined,
          isLoading: false,
        });
      }
    } catch (e) {
      setBinaryError(e);
    }

    return true;
  };

  return (
    <div id="services-screen">
      <header ref={headerRef}>
        <BaseToolbar
          title={(
            <Fragment>
              <FormattedMessage defaultMessage="Services" />
              <Button
                tag="a"
                color="link"
                href={pdfLink.href}
                download={pdfLink.download}
                className="text-nowrap py-0"
                onClick={onDownloadClick}
                disabled={services.isLoading || services.data?.items_total < 1 || pdfLink?.isLoading}
              >
                <span className="visually-hidden">
                  <FormattedMessage defaultMessage="Download PDF" />
                </span>
                {!pdfLink?.isLoading && <FormattedMessage defaultMessage="{filename} " values={{ filename: pdfLink?.label }} />}
                {pdfLink.isLoading && <FormattedMessage defaultMessage="Loading..." />}
                <FontAwesomeIcon icon={solid('file-pdf')} />
              </Button>
            </Fragment>
          )}
          forward={(
            <Button
              tag={Link}
              to="/services/new"
              color="link"
            >
              <FontAwesomeIcon icon={solid('plus')} />
              <span className="visually-hidden">
                <FormattedMessage defaultMessage="Add new service" />
              </span>
            </Button>
          )}
        />
        <LoadingBar loadings={[businessUser, services]} />
      </header>
      <main style={mainPaddings}>
        <Container className="pt-3" fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            <QueryError error={[loadingState, binaryError]} />
            {businessUser.data?.is_demo && <DemoUserDisclaimer />}
            {noServices && (
              <div className="h2 text-center">
                <FormattedMessage defaultMessage="No Services" />
              </div>
            )}
            {!noServices && (
              <ServicesPanel
                items={services}
                onLoadMore={onLoadMore}
              />
            )}
          </Col>
        </Container>
      </main>
    </div>
  );
};

export const ServicesRouteElement: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const servicesResult = useGetServicesQuery({ page, page_size: 300, sort_order: 'DESC' });
  const businessUser = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();
  const onLoadMore = () => setPage(page + 1);

  return (
    <ServicesScreen
      businessUser={businessUser}
      services={servicesResult}
      onLoadMore={onLoadMore}
    />
  );
};

interface NewServiceScreenProps {
  userResult: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  limitsResult: TypedUseQueryStateResult<UsageLimit, void, BaseQueryFn>;
}

export const NewServiceScreen: React.FC<NewServiceScreenProps> = ({
  userResult,
  limitsResult,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);
  const [createService, creationState] = useCreateServiceMutation();
  const serverErrors = [userResult?.error, creationState?.error]
    .filter((e) => Boolean(e)) as Array<FetchBaseQueryError | ApiErrorResponse>;
  const intl = useIntl();
  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const schemaMemoized = useMemo(() => getServiceSchema(intl), [intl]);
  const schema = schemaMemoized.concat(Joi.object({
    name: Joi.string().required(),
    duration: Joi.number().required(),
  }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const joiMessages = useMemo(() => defineJoiMessages(intl), [intl]);

  const defaultFormValues = {
    name: '',
    template_var: '',
    set_name: '',
    duration: '60',
    price_currency: toReactSelectOption('USD'),
    price_equal: '',
    price_from: '',
    price_to: '',
    default_expenses: '',
    description: '',
    use_range: '',
  } as Omit<ServiceFormValues, 'id'>;
  const form = useForm<ServiceFormValues>({
    defaultValues: defaultFormValues,
    resolver: joiResolver(schema, {
      messages: joiMessages,
      abortEarly: false,
    }),
  });

  useEffect(() => {
    // predefine currency
    if (userResult.data?.default_currency) {
      const { default_currency } = userResult.data;
      form.resetField(
        'price_currency',
        {
          defaultValue: toReactSelectOption(default_currency ?? 'USD'),
          keepDirty: true,
          keepTouched: true,
        },
      );
    }
  }, [userResult.data?.country]);

  const onSubmit = async (formData: Omit<ServiceFormValues, 'id'>) => {
    const { price_currency, ...data } = formData;

    let serviceId: string | null = null;
    const hasPrice = data.price_equal || data.price_from || data.price_to;
    try {
      const payload = {
        ...data,
        price_currency: (hasPrice && price_currency?.value) ? price_currency?.value : null,
      };
      const response = await createService(payload).unwrap();
      serviceId = response?.id;
    } catch (e) {
      return false;
    }

    if (serviceId) {
      navigate('/services', { replace: true });
      return true;
    }
    return false;
  };

  const onReset = () => form.reset();
  const isSaving = form.formState.isSubmitting;
  const limitReached = false || limitsResult.data?.max_services <= limitsResult.data?.current_services;
  const upgradeLink: React.FC<ReactNode> = (chunk) => <Link to={{ pathname: '/plans' }}>{chunk}</Link>;

  return (
    <div id="new-service-screen">
      <header ref={headerRef}>
        <BaseToolbar
          back={(
            <Button
              className="back-link"
              tag={Link}
              to="/services"
              color="link"
              disabled={isSaving}
            >
              <FontAwesomeIcon icon={solid('chevron-left')} />
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              {' '}
              {!form.formState.isDirty && <FormattedMessage defaultMessage="Services" />}
              {form.formState.isDirty && <FormattedMessage defaultMessage="Cancel" />}
            </Button>
          )}
          forward={(
            <Button
              type="button"
              color="link"
              onClick={form.handleSubmit(onSubmit)}
              disabled={isSaving || limitReached}
            >
              {!form.formState.isSubmitting && <FormattedMessage defaultMessage="Add" />}
              {form.formState.isSubmitting && <FormattedMessage defaultMessage="Adding..." />}
            </Button>
          )}
          title={<FormattedMessage defaultMessage="New Service" tagName="strong" />}
        />
        {!isSaving && <LoadingBar loadings={[userResult]} />}
        {/* always 25% while submitting form */}
        {isSaving && <LoadingBar loadings={[{}]} />}
      </header>
      <main style={mainPaddings}>
        <Container className="pt-3" fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            <QueryError error={serverErrors} />
            {limitReached && (
              <UncontrolledAlert color="warning" className="alert-sm">
                <FormattedMessage
                  defaultMessage="Ouch, looks like ''{name}'' plan limit of {max, number, ::compact-short} services reached."
                  values={{
                    name: limitsResult.data?.subscription?.plan?.name,
                    max: limitsResult.data?.max_services,
                  }}
                />
                {limitsResult.data?.subscription?.plan?.level < 3 && (
                  <Fragment>
                    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                    {' '}
                    <FormattedMessage
                      defaultMessage="It's possible to <a>upgrade plan</a> if you need more services."
                      values={{
                        a: upgradeLink,
                      }}
                    />
                  </Fragment>
                )}
              </UncontrolledAlert>
            )}
            <ServiceForm
              hookForm={form}
              onSubmit={onSubmit}
              disabled={limitReached}
            />
            {form.formState.isDirty && (
              <div className="mt-3">
                <Button
                  color="danger"
                  block
                  type="button"
                  onClick={onReset}
                >
                  <FormattedMessage defaultMessage="Reset form" />
                </Button>
              </div>
            )}
          </Col>
        </Container>
      </main>
    </div>
  );
};

export const NewServiceRouteElement: React.FC = () => {
  const userResult = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();
  const usageLimits = businessUsersApi.endpoints.getUsageLimits.useQueryState();

  return (
    <NewServiceScreen
      userResult={userResult}
      limitsResult={usageLimits}
    />
  );
};

interface EditServiceScreenProps {
  serviceId: Service['id'];
  userResult: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  serviceResult: TypedUseQueryStateResult<Service, Service['id'], BaseQueryFn>;
}

export const EditServiceScreen: React<EditServiceScreenProps> = ({
  serviceId,
  userResult,
  serviceResult,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);
  const intl = useIntl();
  const navigate = useNavigate();
  const [updateService, updateServiceState] = useUpdateServiceMutation();
  const [deleteService, deleteServiceState] = useDeleteServiceMutation();
  const isLoading = userResult.isLoading || serviceResult.isLoading;
  const serverErrors = [userResult?.error, serviceResult?.error, updateServiceState?.error, deleteServiceState?.error]
    .filter((e) => Boolean(e)) as Array<FetchBaseQueryError | ApiErrorResponse>;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const schemaMemoized = useMemo(() => getServiceSchema(intl), [intl]);
  const schema = schemaMemoized.concat(Joi.object({
    name: Joi.string().required(),
    duration: Joi.number().required(),
  }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const joiMessages = useMemo(() => defineJoiMessages(intl), [intl]);
  const defaultFormValues = {
    id: '',
    name: '',
    template_var: '',
    set_name: '',
    duration: '60',
    price_currency: toReactSelectOption('USD'),
    price_equal: '',
    price_from: '',
    price_to: '',
    default_expenses: '',
    description: '',
    use_range: '',
  } as ServiceFormValues;
  const form = useForm<ServiceFormValues>({
    defaultValues: defaultFormValues,
    resolver: joiResolver(schema, {
      messages: joiMessages,
      abortEarly: false,
    }),
  });
  const { formState: { dirtyFields } } = form;
  const isSaving = form.formState.isSubmitting || deleteServiceState.isLoading;
  const hasDirtyFields = Object.keys(dirtyFields).length > 0;

  const onSubmit = useCallback(async (formData: ServiceFormValues) => {
    const { price_currency, ...data } = formData;
    const hasPrice = data.price_equal || data.price_from || data.price_to;
    try {
      const payload = {
        ...data,
        price_currency: (hasPrice && price_currency?.value) ? price_currency?.value : null,
      };
      await updateService(payload).unwrap();
    } catch (e) {
      return false;
    }

    navigate('/services', { replace: true });
    return true;
  }, []);

  const onReset = () => form.reset();

  const onDelete = async () => {
    try {
      await deleteService(serviceId).unwrap();
      // make redirect to wipe off old service link
      navigate('/services', { replace: true });
    } catch (e) {
      // do nothing
    }
  };

  // on data load
  useEffect(() => {
    if (serviceResult.isSuccess) {
      const { data } = serviceResult;
      const userCurrency = userResult.data?.default_currency ?? 'USD';
      const currencyOption = toReactSelectOption(data.price_currency || userCurrency || 'USD');
      form.reset({
        id: data.id,
        name: data.name ?? '',
        duration: data.duration ?? '60',
        price_currency: currencyOption,
        price_equal: data.price_equal?.toString() ?? '',
        price_from: data.price_from?.toString() ?? '',
        price_to: data.price_to?.toString() ?? '',
        default_expenses: data.default_expenses?.toString() ?? '',
        description: data.description ?? '',
        template_var: data.template_var ?? '',
        set_name: data.set_name ?? '',
        use_range: Boolean(data.price_from || data.price_to),
      });
    }
  }, [serviceResult.isSuccess]);

  return (
    <div id="edit-service-screen">
      <header ref={headerRef}>
        <BaseToolbar
          back={(
            <Button
              className="back-link"
              tag={Link}
              to={{
                pathname: '/services',
              }}
              color="link"
              disabled={isLoading || isSaving}
            >
              <FontAwesomeIcon icon={solid('chevron-left')} />
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              {' '}
              {hasDirtyFields && <FormattedMessage defaultMessage="Discard" />}
              {!hasDirtyFields && <FormattedMessage defaultMessage="Back to services" />}
            </Button>
          )}
          forward={(
            <Button
              type="button"
              color="link"
              disabled={hasDirtyFields === false || isSaving}
              onClick={form.handleSubmit(onSubmit)}
            >
              {!isSaving && <FormattedMessage defaultMessage="Save" />}
              {isSaving && <FormattedMessage defaultMessage="Saving..." />}
            </Button>
          )}
        />
        {!isSaving && <LoadingBar loadings={[userResult, serviceResult]} />}
        {/* always 25% while submitting form */}
        {isSaving && <LoadingBar loadings={[{}]} />}
      </header>
      <main style={mainPaddings}>
        <Container className="pt-3" fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            <QueryError error={serverErrors} />
            {isLoading && <LoadingIndicator />}
            {!isLoading && (
              <Fragment>
                <ServiceForm
                  hookForm={form}
                  onSubmit={onSubmit}
                  disabled={isSaving}
                />
                {hasDirtyFields && (
                  <div className="mt-3">
                    <Button
                      color="danger"
                      block
                      type="button"
                      onClick={onReset}
                    >
                      <FormattedMessage defaultMessage="Discard All Changes" />
                    </Button>
                  </div>
                )}
                <div className="mt-3">
                  <UncontrolledDropdown className="w-100" direction="up" disabled={isSaving}>
                    <DropdownToggle color="danger" block disabled={isSaving}>
                      <FormattedMessage defaultMessage="Delete Service" />
                    </DropdownToggle>
                    <DropdownMenu className="w-100 text-center">
                      <DropdownItem onClick={onDelete}>
                        <FormattedMessage defaultMessage="Confirm Deletion" />
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>
                        <FormattedMessage defaultMessage="Cancel Deletion" />
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Fragment>
            )}
          </Col>
        </Container>
      </main>
    </div>
  );
};

export const EditServiceRouteElement: React.FC = () => {
  const { serviceId } = useParams();
  const userResult = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();
  const queryParams = serviceId?.toString() ?? skipToken;
  const serviceResult = useGetServiceDetailsQuery((userResult.isSuccess) ? queryParams : skipToken);

  // can't use redirect before second hook
  if (typeof serviceId !== 'string') return <Navigate to={{ pathname: '/services' }} />;

  return (
    <EditServiceScreen
      serviceId={serviceId}
      userResult={userResult}
      serviceResult={serviceResult}
    />
  );
};

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import type { OAuthToken } from '../types/api';

const initialState = {
  token_type: null,
  expires_in: null,
  access_token: null,
  refresh_token: null,
} as Partial<OAuthToken>;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    tokenReceived(state, { payload }: PayloadAction<OAuthToken>) {
      Object.assign(state, payload);
      localStorage.setItem('t', JSON.stringify(payload));
    },
    loggedOut(state) {
      Object.assign(state, initialState);
      localStorage.removeItem('t');
    },
    /* eslint-enable */
  },
});

export const { tokenReceived, loggedOut } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectIsAuthorized = (state: RootState) => Boolean(state.auth.access_token);

export default authSlice.reducer;

import { useId, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { NavLink as RouterNavLink } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import { FormMethods, TagNames } from '../types/api';
import { formSubmitHandler } from './formSubmitHandler';

interface FormValues {
  email: string;
  password: string;
}
interface SigninFormProps extends FormMethods<FormValues>, TagNames<'head' | 'body' | 'foot'> {}

export const SigninForm: React.FC<SigninFormProps> = ({
  hookForm: {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  },
  onSubmit,
  onSubmitError,
  components,
}) => {
  // form prefix
  const px = useId();
  const HeaderWrapper = components?.head ?? 'div';
  const BodyWrapper = components?.body ?? 'div';
  const FooterWrapper = components?.foot ?? 'div';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formCallback = useMemo(() => formSubmitHandler({ handleSubmit, onSubmit, onSubmitError }), [handleSubmit]);

  return (
    <Form
      onSubmit={formCallback}
      noValidate
    >
      {components?.head !== undefined && (
        <HeaderWrapper>
          <div className="h5">
            <FormattedMessage defaultMessage="Sign In" />
          </div>
        </HeaderWrapper>
      )}
      <BodyWrapper>
        <fieldset>
          <FormGroup>
            <Label htmlFor={`${px}email`}>
              <FormattedMessage defaultMessage="Email" />
            </Label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  bsSize="sm"
                  disabled={isSubmitting}
                  id={`${px}email`}
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.email)}
                  type="email"
                />
              )}
            />
            <FormFeedback>
              {errors?.email?.type !== 'invalid_credentials' && errors?.email?.message}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={`${px}pass`}>
              <FormattedMessage defaultMessage="Password" />
            </Label>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  bsSize="sm"
                  disabled={isSubmitting}
                  id={`${px}pass`}
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.password)}
                  type="password"
                />
              )}
            />
            <FormFeedback>
              {errors?.password?.message}
            </FormFeedback>
          </FormGroup>
        </fieldset>
        <Nav>
          <NavItem>
            <NavLink
              tag={RouterNavLink}
              to={{
                pathname: '/register',
              }}
              className="p-0"
            >
              <small>
                <FormattedMessage defaultMessage="Don't have account yet?" />
              </small>
            </NavLink>
          </NavItem>
        </Nav>
      </BodyWrapper>
      {components?.foot !== undefined && (
        <FooterWrapper className="d-grid">
          <Button
            type="submit"
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting && <FormattedMessage defaultMessage="Entering..." />}
            {!isSubmitting && <FormattedMessage defaultMessage="Enter" />}
          </Button>
        </FooterWrapper>
      )}
    </Form>
  );
};

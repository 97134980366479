import { useMemo, useRef } from 'react';
import { BaseQueryFn, skipToken, TypedUseMutationResult, TypedUseQueryHookResult, TypedUseQueryStateResult } from '@reduxjs/toolkit/dist/query/react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, UncontrolledAlert } from 'reactstrap';
import { DateTime } from 'luxon';

import { QueryError } from '../core/QueryError';
import { PlansPanel } from '../plans/Plan';
import { useGetPlansQuery } from '../plans/plansApi';
import { businessUsersApi } from '../business_user/businessUsersApi';
import { CountryName } from '../core/CurrencySelect';
import { useCreateSubscriptionMutation, useGetSubscriptionsQuery } from '../subscriptions/subscriptionsApi';
import { LoadingBar } from '../core/LoadingBar';
import { LoadingIndicator } from '../core/LoadingIndicator';
import useHeaderAndFooterHeight from '../core/useHeaderAndFooterHeight';
import type { Plan, BusinessUser, GetPlansParams, Currency, ApiCollection, Subscription, GetSubscriptionsParams, SubscriptionPayload } from '../types/api';

type SupportEmails = Record<Currency | 'fallback', string>

const SUPPORT_EMAILS_JSON: string | undefined = process.env.SUPPORT_EMAILS;
const supportEmails: SupportEmails = JSON.parse(SUPPORT_EMAILS_JSON ?? '{"fallback": "support@och.onl"}');

interface PlansScreenProps {
  userResult: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  plansResult: TypedUseQueryHookResult<Plan[], GetPlansParams, BaseQueryFn>;
  subscriptionsResult: TypedUseQueryHookResult<ApiCollection<Subscription>, GetSubscriptionsParams, BaseQueryFn>;
  onPayClick: (id: Plan['id']) => void;
  createResult: TypedUseMutationResult<Subscription, SubscriptionPayload, BaseQueryFn>;
}

const PlansScreen: React.FC<PlansScreenProps> = ({
  userResult,
  plansResult,
  subscriptionsResult,
  onPayClick,
  createResult,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);

  const userCountry: string = userResult.data?.country ?? 'US';
  const supportEmail: string = (userCountry in supportEmails) ? supportEmails[userCountry] : supportEmails.fallback;
  const activeSubscription = subscriptionsResult.data?.items?.find(
    (item: Subscription) => DateTime.fromISO(item.start) <= DateTime.now() && (item.end === null || DateTime.now() < DateTime.fromISO(item.end)),
  ) ?? undefined;
  const activePlan = plansResult.data?.find((plan: Plan) => plan.id === activeSubscription?.plan_id);
  const premiumActive = activePlan && activePlan?.level > 0;
  const dataLoaded: boolean = userResult.isSuccess && plansResult.isSuccess && subscriptionsResult.isSuccess;

  return (
    <div id="plans-screen">
      <header ref={headerRef}>
        <div className="h5 text-center pt-3">
          <FormattedMessage defaultMessage="Plans" />
        </div>
        <LoadingBar loadings={[userResult, plansResult, subscriptionsResult, createResult]} />
      </header>
      <main style={mainPaddings}>
        <Container className="" fluid="sm">
          <Col md={{ size: 6, offset: 3 }} className="">
            {/* in case of server error */}
            <QueryError error={createResult?.error} />

            {!dataLoaded && <LoadingIndicator />}
            {dataLoaded && !createResult.isSuccess && premiumActive && (
              <UncontrolledAlert color="warning">
                <FormattedMessage
                  defaultMessage="Sorry, can't select another plan until current premium expired. Please, try again after {date}."
                  values={{
                    date: (
                      <time dateTime={activeSubscription?.end} title={DateTime.fromISO(activeSubscription?.end).toLocaleString(DateTime.DATETIME_HUGE)}>
                        {DateTime.fromISO(activeSubscription?.end).toLocaleString(DateTime.DATE_SHORT)}
                      </time>
                    ),
                  }}
                />
              </UncontrolledAlert>
            )}
            {createResult.isSuccess && (
              <UncontrolledAlert color="success">
                <FormattedMessage
                  defaultMessage="You upgraded subscription. Hurray!"
                />
              </UncontrolledAlert>
            )}
          </Col>
          {dataLoaded && (
            <PlansPanel
              itemsResult={plansResult}
              activeSubscription={activeSubscription}
              onPayClick={onPayClick}
              createResult={createResult}
              disabled={premiumActive}
            />
          )}
          {dataLoaded && (
            <Col md={{ size: 6, offset: 3 }} className="mb-3 text-muted text-center">
              <small>
                <FormattedMessage
                  defaultMessage="These are {num, plural, one {# premium plan} other {# premium plans}} for {country}. If it's not your country, please contact {mail}."
                  values={{
                    num: (plansResult.data?.length ?? 4) - 1,
                    country: <strong><CountryName isoCode={userCountry} /></strong>,
                    mail: <a href={`mailto:${supportEmail}`}>{supportEmail}</a>,
                  }}
                />
              </small>
            </Col>
          )}
        </Container>
      </main>
    </div>
  );
};

export const PlansRouteElement: React.FC = () => {
  const userResult = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();
  const plansResult = useGetPlansQuery(userResult.isLoading ? skipToken : {
    filter_country: userResult.data?.country,
  });
  const subscriptionsResult = useGetSubscriptionsQuery(userResult.isLoading ? skipToken : {
    filter_active: true,
    page_size: 1,
    sort_order: 'DESC',
  });
  const [createSubscription, createResult] = useCreateSubscriptionMutation();
  const onPayClick = (id: Plan['id']) => {
    createSubscription({
      plan_id: id,
      months: 1,
      promo_code: null,
    });
  };

  return (
    <PlansScreen
      userResult={userResult}
      plansResult={plansResult}
      subscriptionsResult={subscriptionsResult}
      onPayClick={onPayClick}
      createResult={createResult}
    />
  );
};

import { Fragment, useRef, useEffect } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { NavigationToolbar } from './NavigationToolbar';
import { selectIsAuthorized } from '../api/authSlice';
import { useGetCurrentBusinessUserQuery, useGetUsageLimitsQuery } from '../business_user/businessUsersApi';
import { useGetContactsQuery } from '../contacts/contactsApi';
import { useGetServicesQuery } from '../services/servicesApi';
import { useGetTemplatesQuery } from '../templates/templatesApi';
import { useAppDispatch, useAppSelector } from '../hooks';
import { localeChanged } from '../api/intlSlice';
import useHeaderAndFooterHeight from '../core/useHeaderAndFooterHeight';
import useGaPageview from '../ga/useGaPageview';

interface RequireAuthProps {
  changeLanguage: (locale: string, locale_messages: string) => void;
}

export const RequireAuth: React.FC<RequireAuthProps> = ({
  changeLanguage,
}) => {
  const location = useLocation();
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const { locale: storeLocale, locale_messages: storeLocaleMessages } = useAppSelector((state) => state.intl);
  const dispatch = useAppDispatch();
  const queryOptions = { skip: isAuthorized !== true };
  // preload data
  const { data: user, isSuccess: userIsSuccess } = useGetCurrentBusinessUserQuery(undefined, queryOptions);
  const skip = userIsSuccess ? null : skipToken;
  useGetContactsQuery(skip ?? { page_size: 1000 }, queryOptions);
  useGetServicesQuery(skip ?? { page_size: 300, sort_order: 'DESC' }, queryOptions);
  useGetTemplatesQuery(skip ?? { page_size: 100, sort_order: 'DESC' }, queryOptions);
  useGetUsageLimitsQuery(skip ?? undefined, queryOptions);
  const footerRef = useRef<HTMLDivElement>(null);
  useHeaderAndFooterHeight({ footerRef });

  useGaPageview();

  // apply intl from store first until data loaded
  useEffect(() => {
    if (storeLocale && storeLocaleMessages) {
      changeLanguage(storeLocale, storeLocaleMessages);
    }
  }, [storeLocale, storeLocaleMessages]);

  // on user load update locale in storage
  useEffect(() => {
    if (userIsSuccess && user?.locale && user?.locale_messages) {
      dispatch(localeChanged({
        locale: user?.locale,
        locale_messages: user?.locale_messages,
        skipLocaleSelect: true,
      }));
    }
  }, [userIsSuccess]);

  if (!isAuthorized) {
    // Redirect them to the /signin page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    const navState = (typeof location?.state === 'object') ? { ...location.state, from: location } : { from: location };
    return <Navigate to="/signin" state={navState} replace />;
  }

  /* important key={headerHeight} usage! */
  /* cause remount of all child components */
  /* better use redux or context */
  return (
    <Fragment>
      <Outlet />
      {location.pathname !== '/signout' && (
        <footer ref={footerRef}>
          <NavigationToolbar />
        </footer>
      )}
    </Fragment>
  );
};

import { useEffect, useState, useMemo } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { BaseQueryFn, TypedUseQueryHookResult, TypedUseQueryStateResult } from '@reduxjs/toolkit/dist/query/react';
import { useIntl } from 'react-intl';

import Joi, { defineJoiMessages } from '../core/ExtendedJoi';
import { getBusinessUserSchema } from './schema';
import { toCountryLabelText, toCurrencyLabelText, toLanguageLabelText } from '../core/CurrencySelect';
import { toContactName } from '../contacts/Contact';
import type { BusinessUser, BusinessUserPayload, BusinessUserFormValues, FinancialRecordFormValues, ApiCollection, ContactBasic, PaginationParams } from '../types/api';

export const preparePayload = (data: BusinessUserFormValues): BusinessUserPayload => {
  const { id, email, mobile, country, timezone, default_currency, locale, locale_messages, contact, default_calendar, default_contacts_book } = data;

  return {
    id,
    email,
    mobile,
    country: country?.value,
    timezone,
    default_currency: default_currency?.value,
    locale: locale?.value,
    locale_messages: locale_messages?.value,
    contact_id: contact?.value ?? null,
    default_calendar_id: default_calendar?.value ?? null,
    default_contacts_book_id: default_contacts_book?.value ?? null,
  };
};

interface Props {
  defaultValues?: BusinessUserFormValues;
  messages?: Joi.LanguageMessages;
  schema?: Joi.ObjectSchema;
  userResult?: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  contactsResult?: TypedUseQueryHookResult<ApiCollection<ContactBasic>, PaginationParams, BaseQueryFn>;
}

interface FnReturn {
  hookForm: UseFormReturn<BusinessUserFormValues>;
  defaultValues: BusinessUserFormValues;
}

export default function useBusinessUserForm({
  defaultValues,
  messages,
  schema,
  userResult,
}: Props = {}): FnReturn {
  const intl = useIntl();

  const overwriteDefault = defaultValues ?? {};
  const [defaults, setDefaults] = useState<BusinessUserFormValues>({
    email: '',
    mobile: '',
    country: null,
    timezone: '',
    locale: null,
    locale_messages: null,
    contact: null,
    default_currency: null,
    default_calendar: null,
    default_contacts_book: null,
    ...overwriteDefault,
  });

  const defaultSchema = getBusinessUserSchema(intl, {
    country: defaultValues?.country?.value ?? 'US',
    allowedCountry: [defaultValues?.country?.value ?? 'US'],
    allowedType: ['MOBILE'],
  });
  const mergedSchema = (schema) ? defaultSchema.concat(schema) : defaultSchema;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const joiMessages = useMemo(() => defineJoiMessages(intl), [intl]);

  const hookForm = useForm<FinancialRecordFormValues>({
    defaultValues: defaults,
    resolver: joiResolver(mergedSchema, {
      messages: messages ?? joiMessages,
      abortEarly: false,
    }),
  });

  const onDataLoaded = () => {
    let newDefaults = {};
    const dataReady = userResult?.isSuccess && userResult?.isLoading === false && userResult.isFetching === false;
    if (dataReady) {
      const {
        id,
        email,
        mobile,
        timezone,
        country: countryIso,
        default_currency: currencyIso,
        locale: localeIso,
        locale_messages: messagesIso,
        contact: contactValue,
      } = userResult.data;
      const country = {
        label: toCountryLabelText({ isoCode: countryIso, intl }),
        value: countryIso,
      };
      const default_currency = {
        label: toCurrencyLabelText({ isoCode: currencyIso, intl }),
        value: currencyIso,
      };
      const locale = {
        label: toLanguageLabelText({ isoCode: localeIso, intl }),
        value: localeIso,
      };
      const locale_messages = {
        label: toLanguageLabelText({ isoCode: messagesIso, intl }),
        value: messagesIso,
      };
      const contact = (contactValue) ? {
        label: toContactName(intl, contactValue),
        value: contactValue.id,
      } : undefined;

      newDefaults = {
        ...defaults,
        id,
        email,
        mobile,
        country,
        timezone,
        default_currency,
        locale,
        locale_messages,
        contact,
      };
      hookForm.reset(newDefaults);
      setDefaults(newDefaults);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onDataLoaded, [userResult?.isSuccess, userResult?.isFetching]);

  return {
    hookForm,
    defaultValues: defaults,
  };
}

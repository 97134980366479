import { Fragment, ReactNode, useCallback, useEffect, useId, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedDisplayName, FormattedNumber, FormatNumberOptions, FormattedMessage, useIntl, IntlShape } from 'react-intl';
import { DateTime } from 'luxon';
import ReactSelect, { CommonProps, GroupBase } from 'react-select';
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import Cldr from 'cldr-core/availableLocales.json';
import CldrDefaultContent from 'cldr-core/defaultContent.json';
import CldrCurrencyData from 'cldr-core/supplemental/currencyData.json';
import CldrTerritoryInfo from 'cldr-core/supplemental/territoryInfo.json';

import { FormMethods, Currency, Country, OptionReactSelect } from '../types/api';

const { defaultContent: CldrDefaultLocales } = CldrDefaultContent;
const { availableLocales: { modern: CldrModernLocales } } = Cldr;
const { supplemental: { currencyData } } = CldrCurrencyData;
const { supplemental: { territoryInfo } } = CldrTerritoryInfo;

interface CountryNameProps {
  isoCode: Country;
}

export const CountryName: React.FC<CountryNameProps> = ({
  isoCode,
}) => (
  <span>
    <FormattedDisplayName
      value={isoCode}
      type="region"
    />
  </span>
);

interface CldrCurrencyNode {
  _tender?: 'false';
  _from?: string;
  _to?: string;
}

export const CountryList: Set<string> = new Set(Object.keys(territoryInfo));

export const LanguageList: Set<string> = CldrModernLocales.reduce(
  (set: Set<string>, locale: string, i: number, keys: string[]) => {
    const lang = locale.split('-')?.[0];
    if (lang) {
      set.add(lang);
    }

    if (i === keys.length - 1) {
      // last iteration
      return new Set(Array.from(set).sort());
    }

    return set;
  },
  new Set(),
);

export const CurrencyList: Set<string> = Object.keys(currencyData.region).reduce(
  (set: Set<string>, region: string, i: number, list: string[]) => {
    // last currency
    const now = DateTime.now();
    currencyData.region[region].forEach((item: string) => {
      const currencyCode = Object.keys(item)[0];
      /* eslint-disable no-underscore-dangle */
      const from = DateTime.fromISO(item[currencyCode]?._from);
      const to = DateTime.fromISO(item[currencyCode]?._to ?? '9999-01-01');
      /* eslint-enable no-underscore-dangle */
      if (
        from.isValid
        && from < now
        && to.isValid
        && now < to
      ) {
        set.add(currencyCode);
      }
    });

    if (i === list.length - 1) {
      // last iteration
      return new Set(Array.from(set).sort());
    }

    return set;
  },
  new Set(),
);

export const LocalesList: Set<string> = new Set(
  [].reduce((carry) => {
    let browserLocales = [];
    if (window?.navigator?.language) {
      browserLocales.push(window?.navigator?.language);
    }
    if (Array.isArray(window?.navigator?.languages)) {
      browserLocales = [...browserLocales, ...window?.navigator?.languages];
    }

    if (browserLocales.length > 0 && typeof Intl?.getCanonicalLocales === 'function') {
      try {
        browserLocales = Intl.getCanonicalLocales(browserLocales);
      } catch {
        // do nothing
      }
    }

    return [...carry, ...browserLocales].sort();
  }, CldrDefaultLocales),
);

export const getCurrencyByCountry = (
  countryCode: Country | string,
): Currency | string | null => currencyData.region[countryCode].reduce(
  (result: string, item: Array<Record<string, CldrCurrencyNode>>) => {
    if (result) return result;

    const currencyCode = Object.keys(item)[0];
    const now = DateTime.now();
    /* eslint-enable no-underscore-dangle */
    const from = DateTime.fromISO(item[currencyCode]?._from);
    const to = DateTime.fromISO(item[currencyCode]?._to ?? '9999-01-01');
    /* eslint-disable no-underscore-dangle */

    if (from.isValid && from < now && to.isValid && now < to) {
      return currencyCode;
    }

    return result;
  },
  null,
);

export const getLangByCountry = (
  countryCode: Country | string,
): string | null => Object.keys(territoryInfo[countryCode].languagePopulation)
  .sort((a, b) => {
    /* eslint-disable no-underscore-dangle */
    const aPercent = parseFloat(territoryInfo[countryCode].languagePopulation[a]._populationPercent);
    const bPercent = parseFloat(territoryInfo[countryCode].languagePopulation[b]._populationPercent);
    if (aPercent < bPercent) return 1;
    if (aPercent > bPercent) return -1;
    return 0;
  })
  .find((langCode: string, i: number, arr: string[]) => arr.length === 1 || ['official', 'de_facto_official'].includes(territoryInfo[countryCode].languagePopulation[langCode]?._officialStatus)) ?? null;
  /* eslint-enable no-underscore-dangle */

export const getLocaleByCountry = (
  countryCode: Country | string,
): string | null => {
  const lang = getLangByCountry(countryCode);
  const locale = `${lang}-${countryCode}`;
  if (LocalesList.has(locale)) {
    return locale;
  }

  // find closest one
  return Array.from(LocalesList).find((l: string) => l.indexOf(lang) === 0) ?? null;
};

interface CurrencyLabelProps {
  isoCode: string;
  nameVisible?: boolean;
  exampleVisible?: boolean;
}

export const CurrencyLabel: React.FC<CurrencyLabelProps> = ({
  isoCode,
  nameVisible = true,
  exampleVisible = false,
}) => (
  <Fragment>
    {isoCode}

    {nameVisible && (
      <Fragment>
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
        {' '}
        <FormattedDisplayName
          type="currency"
          value={isoCode}
        />
      </Fragment>
    )}

    {exampleVisible && (
      <Fragment>
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
        {' '}
        <FormattedNumber
          value={99.99}
          style="currency"
          currency={isoCode}
          currencyDisplay="symbol"
        />
      </Fragment>
    )}
  </Fragment>
);

interface LocaleLabelProps {
  isoCode: string;
  nameVisible?: boolean;
}

/** TODO: fix react select search, "australian" gives no options */
export const LocaleLabel: React.FC<LocaleLabelProps> = ({
  isoCode,
  nameVisible = true,
}) => (
  <Fragment>
    {!nameVisible && isoCode}

    {nameVisible && (
      <FormattedMessage
        defaultMessage="{name} ({iso_code})"
        values={{
          name: (
            <FormattedDisplayName
              type="language"
              value={isoCode}
            />
          ),
          iso_code: isoCode,
        }}
      />
    )}
  </Fragment>
);

export const toReactSelectOption = (
  isoCode: string,
): OptionReactSelect => ({
  label: <CurrencyLabel isoCode={isoCode} />,
  value: isoCode,
});

interface CountrySelectProps extends
  CommonProps<OptionReactSelect, false, GroupBase<OptionReactSelect>>,
  FormMethods<Partial<Record<string, unknown>>>
{
  fieldName?: string;
  isClearable?: boolean;
  disabled?: boolean;
  filter?: (isoCode: string) => boolean;
  filterDisabled?: (isoCode: string) => boolean;
  placeholder?: string;
}

export const CountrySelect: React.FC<CountrySelectProps> = ({
  hookForm: { control, getValues, setValue },
  fieldName = 'country',
  isClearable,
  disabled,
  filter,
  filterDisabled,
  placeholder,
}) => {
  const intl = useIntl();
  const isoCodes = (typeof filter === 'function') ? Array.from(CountryList).filter(filter) : Array.from(CountryList);
  const options = isoCodes.map((iso_code) => ({
    label: toCountryLabelText({ isoCode: iso_code, intl }),
    value: iso_code,
    isDisabled: (typeof filterDisabled === 'function') ? filterDisabled(iso_code) : false,
  }));
  const selectPlaceholder = placeholder ?? intl.formatMessage({ defaultMessage: 'Select a country...' });
  const loadingMessage = useCallback(() => intl.formatMessage({ defaultMessage: 'Loading...' }), [intl]);
  const noOptionsMessage = useCallback(() => intl.formatMessage({ defaultMessage: 'No options' }), [intl]);

  useEffect(() => {
    const values = getValues();
    if (values?.[fieldName]?.value) {
      const value = values?.[fieldName]?.value;
      setValue(
        fieldName,
        {
          label: toCountryLabelText({ isoCode: value, intl }),
          value,
        },
        {
          shouldDirty: false,
          shouldTouch: false,
          shouldValidate: false,
        },
      );
    }
  }, [intl]);

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <ReactSelect
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
          isClearable={isClearable}
          isDisabled={disabled}
          options={options}
          placeholder={selectPlaceholder}
          loadingMessage={loadingMessage}
          noOptionsMessage={noOptionsMessage}
        />
      )}
    />
  );
};

interface CurrencySelectProps extends FormMethods<Partial<Record<string, unknown>>> {
  fieldName?: string;
  isClearable?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export const CurrencySelect: React.FC<CurrencySelectProps> = ({
  hookForm: { control, getValues, setValue },
  fieldName = 'currency',
  isClearable,
  disabled,
  placeholder,
}) => {
  const intl = useIntl();
  const options = Array.from(CurrencyList).map((isoCode) => ({
    label: toCurrencyLabelText({ isoCode, intl }),
    value: isoCode,
  }));
  const selectPlaceholder = placeholder ?? intl.formatMessage({ defaultMessage: 'Select a currency...' });
  const loadingMessage = useCallback(() => intl.formatMessage({ defaultMessage: 'Loading...' }), [intl]);
  const noOptionsMessage = useCallback(() => intl.formatMessage({ defaultMessage: 'No options' }), [intl]);

  useEffect(() => {
    const values = getValues();
    if (values?.[fieldName]?.value) {
      const value = values?.[fieldName]?.value;
      setValue(
        fieldName,
        {
          label: toCurrencyLabelText({ isoCode: value, intl }),
          value,
        },
        {
          shouldDirty: false,
          shouldTouch: false,
          shouldValidate: false,
        },
      );
    }
  }, [intl]);

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <ReactSelect
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
          isClearable={isClearable}
          isDisabled={disabled}
          options={options}
          placeholder={selectPlaceholder}
          loadingMessage={loadingMessage}
          noOptionsMessage={noOptionsMessage}
        />
      )}
    />
  );
};

export const toLocaleSelectOption = (
  isoCode: string,
): OptionReactSelect => ({
  label: <LocaleLabel isoCode={isoCode} />,
  value: isoCode,
});

interface LocaleSelectProps extends FormMethods<Partial<Record<string, unknown>>> {
  fieldName?: string;
  isClearable?: boolean;
  useNativeSelect?: boolean;
  disabled?: boolean;
  id?: string;
  options?: string[];
  placeholder?: string;
}

export const LocaleSelect: React.FC<LocaleSelectProps> = ({
  hookForm,
  fieldName = 'locale',
  useNativeSelect = false,
  isClearable,
  disabled,
  id,
  options,
  placeholder,
}) => {
  const intl = useIntl();
  const { control } = hookForm;
  const localesList = options ?? Array.from(LocalesList);
  const localizedOptions = useMemo(
    () => localesList.map((isoCode) => ({
      label: toLanguageLabelText({ isoCode, intl }),
      value: isoCode,
    })),
    [localesList, intl],
  );
  const selectPlaceholder = placeholder ?? intl.formatMessage({ defaultMessage: 'Select a locale...' });
  const loadingMessage = useCallback(() => intl.formatMessage({ defaultMessage: 'Loading...' }), [intl]);
  const noOptionsMessage = useCallback(() => intl.formatMessage({ defaultMessage: 'No options' }), [intl]);

  useEffect(() => {
    const updateOps = {
      shouldValidate: false,
      shouldTouch: false,
      shouldDirty: false,
    };

    const values = hookForm.getValues();
    if (values?.[fieldName]) {
      const locale = values[fieldName];
      hookForm.setValue(fieldName, {
        ...locale,
        label: toLanguageLabelText({ isoCode: locale.value, intl }),
      }, updateOps);
    }
  }, [intl]);

  const render = ({ field }) => (
    <ReactSelect
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...field}
      id={id}
      isClearable={isClearable}
      isDisabled={disabled}
      options={localizedOptions}
      placeholder={selectPlaceholder}
      loadingMessage={loadingMessage}
      noOptionsMessage={noOptionsMessage}
    />
  );

  const renderNative = ({ field }) => (
    <Input
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...field}
      id={id}
      type="select"
      disabled={disabled}
    >
      {localizedOptions.map(({ label, value }) => (
        <option key={value} value={value}>{label}</option>
      ))}
    </Input>
  );

  return (
    <Controller
      name={fieldName}
      control={control}
      render={(useNativeSelect) ? renderNative : render}
    />
  );
};

interface ToLanguageLabelTextProps {
  isoCode: string;
  intl: IntlShape;
}

export const toLanguageLabelText = ({
  isoCode,
  intl,
}: ToLanguageLabelTextProps): string | null => {
  try {
    const name = intl.formatDisplayName(isoCode, { type: 'language' });
    return intl.formatMessage({ defaultMessage: '{name} ({iso_code})' }, { name, iso_code: isoCode });
  } catch (e) {
    // do nothing
  }
  return null;
};

interface ToCountryLabelTextProps {
  isoCode: string;
  intl: IntlShape;
}

export const toCountryLabelText = ({
  isoCode,
  intl,
}: ToCountryLabelTextProps): string | null => {
  try {
    const name = intl.formatDisplayName(isoCode, { type: 'region' });
    return intl.formatMessage({ defaultMessage: '{name} ({iso_code})' }, { name, iso_code: isoCode });
  } catch (e) {
    // do nothing
  }
  return null;
};

interface ToCurrencyLabelTextProps {
  isoCode: string;
  intl: IntlShape;
}

export const toCurrencyLabelText = ({
  isoCode,
  intl,
}: ToCurrencyLabelTextProps): string | null => {
  try {
    const name = intl.formatDisplayName(isoCode, { type: 'currency' });
    return intl.formatMessage({ defaultMessage: '({iso_code}) {name}' }, { name, iso_code: isoCode });
  } catch (e) {
    // do nothing
  }
  return null;
};

interface LanguageSelectProps extends FormMethods<Partial<Record<string, unknown>>> {
  fieldName?: string;
  isClearable?: boolean;
  placeholder?: string;
}

export const LanguageSelect: React.FC<LanguageSelectProps> = ({
  hookForm: { control },
  fieldName = 'language',
  isClearable,
  placeholder,
}) => {
  const intl = useIntl();
  const options = Array.from(LanguageList)
    .filter((isoCode) => toLanguageLabelText({ isoCode, intl }))
    .map((isoCode) => ({
      label: toLanguageLabelText({ isoCode, intl }),
      value: isoCode,
    }));

  const selectPlaceholder = placeholder ?? intl.formatMessage({ defaultMessage: 'Select a language...' });
  const loadingMessage = useCallback(() => intl.formatMessage({ defaultMessage: 'Loading...' }), [intl]);
  const noOptionsMessage = useCallback(() => intl.formatMessage({ defaultMessage: 'No options' }), [intl]);

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <ReactSelect
          isClearable={isClearable}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
          options={options}
          placeholder={selectPlaceholder}
          loadingMessage={loadingMessage}
          noOptionsMessage={noOptionsMessage}
        />
      )}
    />
  );
};

interface AmountProps {
  amount: number;
  currency: Currency | string;
  discount?: number | null;
  colors?: boolean;
  formatOps?: FormatNumberOptions,
}

export const Amount: React.FC<AmountProps> = ({
  amount,
  currency,
  discount = null,
  colors = true,
  formatOps,
}) => (
  <span
    className={`
      text-nowrap
      ${colors && amount > 0 && 'text-success'}
      ${colors && amount < 0 && 'text-danger'}
    `}
  >
    <FormattedNumber
      value={amount}
      currency={currency}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      currencySign={formatOps?.currencySign ?? 'standard'}
      signDisplay={formatOps?.signDisplay ?? 'exceptZero'}
      currencyDisplay={formatOps?.currencyDisplay ?? 'symbol'}
      minimumFractionDigits={formatOps?.minimumFractionDigits}
      maximumFractionDigits={formatOps?.maximumFractionDigits}
    />
    {/* old price */}
    {discount && (
      <span>
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
        {' ('}
        <del>
          <FormattedNumber
            value={amount + discount}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency={currency}
            currencySign="standard"
            signDisplay="never"
            currencyDisplay="narrowSymbol"
            minimumFractionDigits={formatOps?.minimumFractionDigits}
            maximumFractionDigits={formatOps?.maximumFractionDigits}
          />
        </del>
        {/* eslint-disable formatjs/no-literal-string-in-jsx */}
        )
      </span>
    )}
  </span>
);
/* eslint-enable formatjs/no-literal-string-in-jsx */

interface AmountInputProps extends FormMethods<Record<string, string>> {
  fieldName?: string;
  label?: ReactNode | string;
  px?: string;
}

export const AmountInput: React.FC<AmountInputProps> = ({
  hookForm: {
    control,
    formState: { errors },
  },
  fieldName,
  label,
  px,
}) => (
  <FormGroup>
    <Label hidden={label === undefined}>
      {label ?? <FormattedMessage defaultMessage="Amount" />}
    </Label>
    <Controller
      name={fieldName ?? 'amount'}
      control={control}
      render={({ field }) => (
        <Input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
          type="number"
          id={`${px}${fieldName ?? 'amount'}`}
          innerRef={(e) => field.ref(e)}
          invalid={Boolean(fieldName && errors?.[fieldName])}
          placeholder="foobar"
        />
      )}
    />
    <FormFeedback>
      {fieldName && errors?.[fieldName]}
    </FormFeedback>
  </FormGroup>
);

interface LocaleSwitcherProps extends FormMethods<Partial<Record<string, unknown>>> {
  size: 'sm' | 'lg';
  translations: string[];
}

export const LocaleSwitcher: React.FC<LocaleSwitcherProps> = ({
  hookForm,
  translations,
}) => {
  const intl = useIntl();
  const px = useId();

  useEffect(() => {
    const updateOps = {
      shouldValidate: false,
      shouldTouch: false,
      shouldDirty: false,
    };

    const { locale, locale_messages } = hookForm.getValues();
    if (locale?.value) {
      hookForm.setValue('locale', {
        ...locale,
        label: toLanguageLabelText({ isoCode: locale.value, intl }),
      }, updateOps);
    }

    if (locale_messages?.value) {
      hookForm.setValue('locale_messages', {
        ...locale_messages,
        label: toLanguageLabelText({ isoCode: locale_messages.value, intl }),
      }, updateOps);
    }
  }, [intl]);

  return (
    <fieldset>
      <FormGroup row>
        <Col className="d-flex align-items-center">
          <Label htmlFor={`${px}locale`} className="mb-0">
            <FormattedMessage defaultMessage="Locale" />
          </Label>
        </Col>
        <Col>
          <LocaleSelect
            hookForm={hookForm}
            id={`${px}locale`}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col className="d-flex align-items-center">
          <Label htmlFor={`${px}translation`} className="mb-0">
            <span>
              <FormattedMessage defaultMessage="Translation" />
            </span>
          </Label>
        </Col>
        <Col>
          <LocaleSelect
            hookForm={hookForm}
            id={`${px}locale`}
            fieldName="locale_messages"
            options={translations}
          />
        </Col>
      </FormGroup>
    </fieldset>
  );
};

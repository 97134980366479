import { ObjectSchema } from 'joi';
import { IntlShape } from 'react-intl';

import Joi from '../core/ExtendedJoi';

export const getEventSchema = (intl: IntlShape): ObjectSchema => Joi.object({
  /* eslint-disable newline-per-chained-call */
  id: Joi.string().trim().empty('').default(null),
  summary: Joi.string().trim().max(100).empty('').default(null)
    .label(intl.formatMessage({ defaultMessage: 'Summary' })),
  start_date: Joi.date().empty('').max(Joi.ref('end_date')).default(null)
    .label(intl.formatMessage({ defaultMessage: 'Start date' })),
  start_time: Joi.string().empty('').default(null)
    .label(intl.formatMessage({ defaultMessage: 'Start time' })),
  end_date: Joi.date().empty('').default(null)
    .label(intl.formatMessage({ defaultMessage: 'End date' })),
  end_time: Joi.string().empty('').default(null)
    .label(intl.formatMessage({ defaultMessage: 'End time' })),
  timezone: Joi.string().trim().empty('').default(null)
    .label(intl.formatMessage({ defaultMessage: 'Timezone' })),
  description: Joi.string().trim().max(500).empty('').default(null)
    .label(intl.formatMessage({ defaultMessage: 'Description' })),

  // can't make more strict validation, because not all vcards follow
  // exact tules, check string length instead
  class: Joi.string().trim().max(100).empty('').default('PRIVATE')
    .label(intl.formatMessage({ defaultMessage: 'Class' })),
  geo: Joi.array().length(2).items(Joi.number()).empty(['', null]).default(null)
    .label(intl.formatMessage({ defaultMessage: 'Geo' })),
  location: Joi.string().trim().max(100).empty('').default(null)
    .label(intl.formatMessage({ defaultMessage: 'Geo' })),
  priority: Joi.number().integer().min(0).empty('').default(0)
    .label(intl.formatMessage({ defaultMessage: 'Priority' })),
  status: Joi.string().trim().max(20).empty('').default('TENTATIVE')
    .label(intl.formatMessage({ defaultMessage: 'Status' })),
  transp: Joi.string().trim().max(20).empty('').default('OPAQUE')
    .label(intl.formatMessage({ defaultMessage: 'Transparent' })),
});

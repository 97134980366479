import { useEffect, useCallback, useMemo, Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, Navigate, Outlet, NavLink as RouterNavLink, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useForm, ErrorOption } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import ReactGA from 'react-ga4';
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  Row,
  Alert,
  Nav,
  NavItem,
  NavLink,
  UncontrolledAlert,
} from 'reactstrap';

import { SystemZone } from 'luxon';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { SigninForm } from '../core/SigninForm';
import { resetPasswordQuery, obtainTokenQuery, recoverQuery, emptySplitApi } from '../api/fetchBaseQuery';
import { tokenReceived, selectIsAuthorized, loggedOut } from '../api/authSlice';
import { toTodayPathname } from '../calendar/Calendar';
import Joi, { defineJoiMessages } from '../core/ExtendedJoi';
import { BusinessUserForm } from '../business_user/BusinessUser';
import { getCurrencyByCountry, toCountryLabelText, toCurrencyLabelText, toLanguageLabelText } from '../core/CurrencySelect';
import { getCountryByLocale, getSupportedLang } from '../intl/helpers';
import { localeChanged } from '../api/intlSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import useBusinessUserForm, { preparePayload } from '../business_user/useBusinessUserForm';
import { useCreateBusinessUserMutation } from '../business_user/businessUsersApi';
import { QueryError } from '../core/QueryError';
import { RecoverForm } from '../core/RecoverForm';
import { ResetPasswordForm } from '../core/ResetPasswordForm';
import useGaPageview from '../ga/useGaPageview';
import type { BusinessUserFormValues, OAuthToken, TokenErrorResponse, WeakPasswordResponse } from '../types/api';

interface RedirectIfAuthorizedProps {
  changeLanguage: (locale: string, locale_messages: string) => void;
}

export const RedirectIfAuthorized: React.FC<RedirectIfAuthorizedProps> = ({
  changeLanguage,
}) => {
  const location = useLocation();
  const intl = useIntl();
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const {
    locale: storeLocale,
    locale_messages: storeLocaleMessages,
  } = useAppSelector((store) => store.intl);
  const translations = ['en', 'ru'];
  const closestTranslation = useMemo(
    () => getSupportedLang({ locale: [intl?.locale], languages: translations, fallbackLanguage: 'en' }),
    [],
  );
  const getFormState = useCallback(
    (locale: string, locale_messages: string) => ({
      locale: {
        label: toLanguageLabelText({ isoCode: locale, intl }),
        value: locale,
      },
      locale_messages: {
        label: toLanguageLabelText({ isoCode: locale_messages, intl }),
        value: locale_messages,
      },
    }),
    [intl],
  );
  const dispatch = useAppDispatch();
  const switcherForm = useForm({
    defaultValues: getFormState(storeLocale ?? intl?.locale, storeLocaleMessages ?? closestTranslation),
  });
  const watchLocale = switcherForm.watch('locale');
  const watchLocaleMessages = switcherForm.watch('locale_messages');

  useGaPageview();

  useEffect(() => {
    if (
      watchLocale?.value
      && watchLocaleMessages?.value
      && (
        watchLocale.value !== storeLocale
        || watchLocaleMessages.value !== storeLocaleMessages
      )
    ) {
      dispatch(localeChanged({
        locale: watchLocale.value,
        locale_messages: watchLocaleMessages.value,
      }));
    }
  }, [watchLocale?.value, watchLocaleMessages?.value]);

  useEffect(() => {
    if (storeLocale && storeLocaleMessages) {
      changeLanguage(storeLocale, storeLocaleMessages);
    }
  }, [storeLocale, storeLocaleMessages]);

  if (isAuthorized) {
    return <Navigate to={{ pathname: toTodayPathname() }} state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export const NotFoundScreen: React.FC = () => (
  <div className="h1">
    <FormattedMessage defaultMessage="Not Found" />
  </div>
);

export const SigninScreen: React.FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { state: locationState, search } = useLocation();

  const signinSchema = useMemo(() => Joi.object({
    email: Joi.string().email({ tlds: { allow: false } }).trim().required()
      .label(intl.formatMessage({ defaultMessage: 'email' })),
    password: Joi.string().trim().max(100).required()
      .label(intl.formatMessage({ defaultMessage: 'password' })),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [intl]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const joiMessages = useMemo(() => defineJoiMessages(intl), [intl]);

  const signinForm = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    // TODO: add validation error translations here
    resolver: joiResolver(signinSchema, { abortEarly: false, messages: joiMessages }),
  });

  interface Credentials {
    email: string;
    password: string;
  }

  const onSubmit = async ({ email, password }: Credentials) => {
    const { data, error } = await obtainTokenQuery(email, password);

    if (typeof data === 'object' && 'access_token' in data) {
      dispatch(tokenReceived(data));
      return;
    }

    if (error) {
      const wrongCredentials = error?.data?.error_description === 'The user credentials were incorrect.';
      const feedback = {
        type: 'invalid_credentials',
        message: intl.formatMessage({ defaultMessage: 'Invalid password or email' }),
      } as ErrorOption;
      if (!wrongCredentials) {
        feedback.type = 'manual';
        feedback.message = error?.data?.message
          ?? error?.data?.error_description
          ?? intl.formatMessage({ defaultMessage: 'Unknown Error Occurred' });
      }
      signinForm.setError('email', feedback);
      signinForm.setError('password', feedback);
    }
  };

  const checkDemoQuery = useCallback(() => {
    const query = new URLSearchParams(search ?? '');
    if (query.has('demo')) {
      switch (query.get('demo')) {
        case 'en':
          ReactGA.event({
            category: 'Demo',
            action: 'Entering english demo account',
            label: 'en',
          });
          signinForm.setValue('email', 'msgorbachev@och.onl');
          signinForm.setValue('password', 'q');
          signinForm.handleSubmit(onSubmit)();
          break;
        case 'ru':
          ReactGA.event({
            category: 'Demo',
            action: 'Entering russian demo account',
            label: 'ru',
          });
          signinForm.setValue('email', 'jfkennedy@och.onl');
          signinForm.setValue('password', 'q');
          signinForm.handleSubmit(onSubmit)();
          break;
        default:
          // wrong value
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signinForm, search]);

  useEffect(() => {
    checkDemoQuery();
  }, [checkDemoQuery]);

  return (
    <Fragment>
      <Container className="pt-3" tag="main">
        <Row>
          <Col md={{ size: 4, offset: 4 }}>
            <Card>
              <CardHeader>
                <Nav tabs card>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to={{
                        pathname: '/signin',
                      }}
                    >
                      <FormattedMessage defaultMessage="Sign In" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to={{
                        pathname: '/recover',
                      }}
                    >
                      <FormattedMessage defaultMessage="Forgot Password" />
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <SigninForm
                hookForm={signinForm}
                onSubmit={onSubmit}
                components={{
                  // head: CardHeader,
                  body: CardBody,
                  foot: CardFooter,
                }}
              />
            </Card>
            {locationState?.newPassword !== true && (
              <UncontrolledAlert color="info" className="alert-sm mt-3">
                <FormattedMessage
                  tagName="small"
                  defaultMessage="There is no sign in via mobile number so far. But it might be possible anytime soon."
                />
              </UncontrolledAlert>
            )}
            {locationState?.newPassword === true && (
              <UncontrolledAlert color="success" className="alert-sm mt-3">
                <FormattedMessage
                  tagName="small"
                  defaultMessage="Please, use your just created password to enter."
                />
              </UncontrolledAlert>
            )}
          </Col>
        </Row>
      </Container>
      {/* footer */}
    </Fragment>
  );
};

export const RecoverScreen: React.FC = () => {
  const intl = useIntl();
  const [queryReturn, setQueryReturn] = useState<QueryReturnValue<{ message: string; expires_in: OAuthToken['expires_in']; }, TokenErrorResponse>>({
    data: null,
    error: null,
  });

  const recoverSchema = useMemo(() => Joi.object({
    email: Joi.string().email({ tlds: { allow: false } }).trim().required()
      .label(intl.formatMessage({ defaultMessage: 'email' })),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [intl]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const joiMessages = useMemo(() => defineJoiMessages(intl), [intl]);

  const recoverForm = useForm<Pick<BusinessUserFormValues, 'email'>>({
    defaultValues: { email: '' },
    resolver: joiResolver(recoverSchema, { abortEarly: false, messages: joiMessages }),
  });

  const onSubmit = async ({ email }: Pick<BusinessUserFormValues, 'email'>) => {
    const result = await recoverQuery(email);
    setQueryReturn(result);
  };

  return (
    <Fragment>
      <Container className="pt-3" tag="main">
        <Row>
          <Col md={{ size: 4, offset: 4 }}>
            <Card className="mb-3">
              <CardHeader>
                <Nav tabs card>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to={{
                        pathname: '/signin',
                      }}
                    >
                      <FormattedMessage defaultMessage="Sign In" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to={{
                        pathname: '/recover',
                      }}
                    >
                      <FormattedMessage defaultMessage="Forgot Password" />
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <RecoverForm
                hookForm={recoverForm}
                onSubmit={onSubmit}
                disabled={Boolean(queryReturn?.data?.message)}
                components={{
                  body: CardBody,
                  foot: CardFooter,
                }}
              />
            </Card>
            <QueryError
              error={queryReturn.error}
            />
            {queryReturn?.data?.message && (
              <Alert color="success" className="alert-sm">
                <FormattedMessage defaultMessage="Please, check your inbox. If account with provided email exists then there should be mail with recovery instructions." />
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
      {/* footer */}
    </Fragment>
  );
};

export const ResetPasswordScreen: React.FC = () => {
  const intl = useIntl();
  const [queryReturn, setQueryReturn] = useState<QueryReturnValue<boolean, TokenErrorResponse | WeakPasswordResponse>>({
    data: undefined,
    error: undefined,
  });
  const [forceCheckboxHidden, setForceCheckboxHidden] = useState<boolean>(true);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');

  const passwordSchema = useMemo(() => Joi.object({
    password: Joi.string().trim().required()
      .label(intl.formatMessage({ defaultMessage: 'password' })),
    force_weak_password: Joi.bool().empty('').default(false),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [intl]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const joiMessages = useMemo(() => defineJoiMessages(intl), [intl]);

  const passwordForm = useForm<{ password: string; force_weak_password: string; }>({
    defaultValues: { password: '', force_weak_password: '' },
    resolver: joiResolver(passwordSchema, { abortEarly: false, messages: joiMessages }),
  });

  const recoverLink: React.FC<unknown> = (chunk) => <Link to={{ pathname: '/recover', replace: true }}>{chunk}</Link>;

  const onSubmit = async ({ password, force_weak_password }) => {
    const result = await resetPasswordQuery(password, token, force_weak_password);
    if (result.error?.data?.error_id === 'err_422_weak_pass') {
      const field = result.error.data.invalid_fields.password as WeakPasswordResponse['data']['invalid_fields']['password'];
      const joinedFeedback = field.extra.feedback.suggestions.reduce(
        (carry: string, suggest: string) => `${carry}. ${suggest}`,
        field.extra.feedback.warning ?? '',
      );
      passwordForm.setError('password', { message: joinedFeedback, type: 'weak' });
      setForceCheckboxHidden(false);
    } else {
      setQueryReturn(result);
    }
  };

  if (queryReturn?.data === true) return <Navigate to={{ pathname: '/signin' }} state={{ newPassword: true }} replace />;

  return (
    <Fragment>
      <Container className="pt-3" tag="main">
        <Row>
          <Col md={{ size: 4, offset: 4 }}>
            <Card className="mb-3">
              <ResetPasswordForm
                hookForm={passwordForm}
                onSubmit={onSubmit}
                components={{
                  head: CardHeader,
                  body: CardBody,
                  foot: CardFooter,
                }}
                disabled={!token || queryReturn?.data === true}
                inputs={{
                  force_weak_password: { hidden: forceCheckboxHidden },
                }}
              />
            </Card>
            <QueryError
              error={queryReturn.error}
            />
            {!token && (
              <Alert color="danger" className="mt-3">
                <FormattedMessage
                  defaultMessage="Link is broken or expired, request new <a>recover link</a>."
                  values={{
                    a: recoverLink,
                  }}
                />
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
      {/* footer */}
    </Fragment>
  );
};

export const SignoutScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const onMountOnce = useCallback(() => {
    dispatch(loggedOut());
    dispatch(emptySplitApi.util.resetApiState());
  }, []); // eslint-disable-line

  useEffect(() => {
    onMountOnce();
  }, [onMountOnce]);

  return (
    <Container>
      <div className="h5">
        <FormattedMessage defaultMessage="Signing out, please wait..." />
      </div>
    </Container>
  );
};

export const RegisterScreen: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [createBusinessUser, creationState] = useCreateBusinessUserMutation();
  const { locale, locale_messages } = useAppSelector((store) => store.intl);
  const dispatch = useAppDispatch();
  const {
    country,
    currency,
    systemTz,
  } = useMemo<Record<string, string>>(
    () => {
      const country = getCountryByLocale(locale);
      const currency = getCurrencyByCountry(country);
      const systemTz = (new SystemZone()).name;

      return { country, currency, systemTz };
    },
    [],
  );

  const { hookForm } = useBusinessUserForm({
    defaultValues: {
      country: {
        label: toCountryLabelText({ isoCode: country, intl }),
        value: country,
      },
      timezone: systemTz,
      locale: {
        label: toLanguageLabelText({ isoCode: locale, intl }),
        value: locale,
      },
      locale_messages: {
        label: toLanguageLabelText({ isoCode: locale_messages, intl }),
        value: locale_messages,
      },
      default_currency: {
        label: toCurrencyLabelText({ isoCode: currency, intl }),
        value: currency,
      },
    },
    schema: Joi.object({
      email: Joi.any().required(),
      contact: Joi.any().empty(null),
    }),
  });
  const { locale: watchLocale, locale_messages: watchLocaleMessages, email: watchEmail } = hookForm.watch();
  const onSubmit = useCallback(async (data: BusinessUserFormValues) => {
    const payload = preparePayload(data);
    try {
      await createBusinessUser(payload).unwrap();
    } catch (e) {
      return false;
    }
    return true;
  }, []);

  useEffect(() => {
    if (
      watchLocale?.value
      && watchLocaleMessages?.value
      && (
        watchLocale.value !== locale
        || watchLocaleMessages.value !== locale_messages
      )
    ) {
      dispatch(localeChanged({
        locale: watchLocale.value,
        locale_messages: watchLocaleMessages.value,
        skipLocaleSelect: true,
      }));
    }
  }, [watchLocale?.value, watchLocaleMessages?.value]);

  return (
    <Container className="pt-3" tag="main">
      <Row>
        <Col md={{ size: 4, offset: 4 }}>
          <QueryError
            error={creationState.error}
          />
          {creationState.isSuccess && (
            <Alert color="success" className="alert-sm mt-3">
              <span className="me-2">
                <FontAwesomeIcon icon={solid('handshake')} />
              </span>
              <FormattedMessage
                defaultMessage="Hurray! Account has been successfully created. Please, check your inbox {email} for future instructions."
                values={{ email: watchEmail }}
              />
            </Alert>
          )}

          {!creationState.isSuccess && (
            <Fragment>
              <Card>
                <BusinessUserForm
                  hookForm={hookForm}
                  onSubmit={onSubmit}
                  onCancel={() => {
                    navigate({
                      pathname: '/signin',
                    });
                  }}
                  components={{
                    head: CardHeader,
                    body: CardBody,
                    foot: CardFooter,
                  }}
                  loadingState={{
                    isLoading: creationState.isLoading,
                    isSuccess: creationState.isSuccess,
                  }}
                />
              </Card>
              <UncontrolledAlert color="info" className="alert-sm mt-3">
                <FormattedMessage
                  tagName="small"
                  defaultMessage="You can't register via mobile number so far. But it might be possible anytime soon."
                />
              </UncontrolledAlert>
            </Fragment>
          )}
        </Col>
      </Row>
    </Container>
  );
};

import { useEffect, MutableRefObject, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { toolbarHeightChanged } from '../api/uiSlice';

interface FnReturn {
  headerHeight?: number;
  footerHeight?: number;
}

interface Props {
  headerRef?: MutableRefObject<HTMLElement | null>;
  footerRef?: MutableRefObject<HTMLElement | null>;
}

export default function useHeaderAndFooterHeight({ headerRef, footerRef }: Props): FnReturn {
  const [observer, setObserver] = useState<MutationObserver | null>(null);
  const dispatch = useAppDispatch();
  const { headerHeight, footerHeight } = useAppSelector((store) => store.ui);

  const onResize = () => {
    // console.debug(headerRef?.current?.offsetHeight, headerHeight);
    const changes = {} as FnReturn;
    if (headerRef?.current?.offsetHeight !== undefined && headerRef?.current?.offsetHeight !== headerHeight) {
      changes.headerHeight = headerRef?.current?.offsetHeight;
    }

    if (footerRef?.current?.offsetHeight !== undefined && footerRef?.current?.offsetHeight !== footerHeight) {
      changes.footerHeight = footerRef?.current?.offsetHeight;
    }

    if (Object.keys(changes).length > 0) {
      dispatch(toolbarHeightChanged(changes));
    }
  };

  useEffect(() => {
    if (!observer) {
      /** @link https://blog.logrocket.com/guide-to-custom-react-hooks-with-mutationobserver/ */
      // Create an observer instance linked to the callback function
      const newObserver = new MutationObserver(onResize);
      setObserver(newObserver);
    }

    if (observer && headerRef?.current) {
      // Start observing the target node for configured mutations
      observer.observe(headerRef.current, { attributes: false, childList: true, subtree: true });
    }

    if (observer && footerRef?.current) {
      // Start observing the target node for configured mutations
      observer.observe(footerRef.current, { attributes: false, childList: true, subtree: true });
    }

    onResize();

    return () => {
      // Later, you can stop observing
      if (observer) {
        observer.disconnect();
        setObserver(null);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observer, headerRef?.current, footerRef?.current]);

  return { headerHeight, footerHeight };
}

import { useState, useMemo, useRef, useEffect } from 'react';
import { BaseQueryFn, TypedUseQueryStateResult } from '@reduxjs/toolkit/dist/query/react';
import { Link, NavLink as RouterNavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Col, Collapse, Container, Nav, NavItem, List, Progress, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

import { DateTime } from 'luxon';
import { businessUsersApi, useUpdateCurrentBusinessUserMutation } from '../business_user/businessUsersApi';
import { contactsApi } from '../contacts/contactsApi';
import { BaseToolbar } from '../core/BaseToolbar';
import { LoadingBar } from '../core/LoadingBar';
import { QueryError } from '../core/QueryError';
import { mergeRtkQueryStates } from '../api/fetchBaseQuery';
import { LoadingIndicator } from '../core/LoadingIndicator';
import { AppVersion } from '../core/AppVersion';
import { BusinessUserForm } from '../business_user/BusinessUser';
import Joi from '../core/ExtendedJoi';
import useBusinessUserForm, { preparePayload } from '../business_user/useBusinessUserForm';
import { formSubmitHandler } from '../core/formSubmitHandler';
import { Amount } from '../core/CurrencySelect';
import type { ApiCollection, BusinessUser, BusinessUserFormValues, ContactBasic, PaginationParams, UsageLimit } from '../types/api';
import useHeaderAndFooterHeight from '../core/useHeaderAndFooterHeight';
import { useAppDispatch } from '../hooks';
import { localeChanged } from '../api/intlSlice';

interface SettingsScreenProps {
  userResult: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  limitsResult: TypedUseQueryStateResult<UsageLimit, void, BaseQueryFn>;
  contactsResult: TypedUseQueryStateResult<ApiCollection<ContactBasic>, PaginationParams, BaseQueryFn>;
}

export const SettingsScreen: React.FC<SettingsScreenProps> = ({
  userResult,
  limitsResult,
  contactsResult,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const dispatch = useAppDispatch();
  const [updateBusinessUser, updateState] = useUpdateCurrentBusinessUserMutation();
  const [visibleForm, setVisibleForm] = useState<'' | 'user' | 'plan'>('plan');
  const toggleUser = () => setVisibleForm(visibleForm === 'user' ? '' : 'user');
  const togglePlan = () => setVisibleForm(visibleForm === 'plan' ? '' : 'plan');
  const { isSuccess, error } = mergeRtkQueryStates(userResult, limitsResult, contactsResult);

  const { hookForm } = useBusinessUserForm({
    schema: Joi.object({
      email: Joi.any().required().failover(null), // TODO: fix validation
      mobile: Joi.any().empty(null).failover(null), // TODO: fix validation
      contact: Joi.any().empty(null),
    }),
    userResult,
  });
  const { formState: { isDirty, isSubmitting }, handleSubmit, watch } = hookForm;
  const { locale: watchLocale, locale_messages: watchLocaleMessages } = watch();
  const isSaving = false || isSubmitting || updateState.isLoading;

  const onSubmit = async (data: BusinessUserFormValues) => {
    const userPayload = preparePayload(data);
    try {
      await updateBusinessUser(userPayload).unwrap();
      setVisibleForm('');
    } catch {
      // do nothing
    }
  };
  const formCallback = useMemo(() => formSubmitHandler({ handleSubmit, onSubmit }), [handleSubmit]);
  const usageKeys = ['new_events', 'contacts', 'services', 'templates', 'new_financial_records'];
  const progressMap: Map<string, number> = usageKeys.reduce((map, key: 'new_events' | 'contacts' | 'services' | 'templates' | 'new_financial_records') => {
    const currentKey = `current_${key}` as const;
    const maxKey = `max_${key}` as const;
    if (
      limitsResult.data
      && limitsResult.data?.subscription
      && limitsResult.data?.subscription?.plan
      && currentKey in limitsResult.data
      && typeof limitsResult.data[currentKey] === 'number'
      && maxKey in limitsResult.data.subscription.plan
      && typeof limitsResult.data.subscription.plan[maxKey] === 'number'
    ) {
      const precise = (limitsResult.data[currentKey] / limitsResult.data.subscription.plan[maxKey]) * 100;
      map.set(key, Math.round(precise));
    }

    return map;
  }, new Map<string, number>());

  // refresh intl on locale select
  useEffect(() => {
    if (
      userResult.isSuccess
      && watchLocale?.value
      && watchLocaleMessages?.value
    ) {
      // TODO: move that dispatch directly to RTK Query endpoint
      dispatch(localeChanged({
        locale: watchLocale?.value,
        locale_messages: watchLocaleMessages?.value,
        skipLocaleSelect: true,
      }));
    }
  }, [userResult?.fulfilledTimeStamp, watchLocale?.value, watchLocaleMessages?.value]);

  const onReset = () => {
    hookForm.reset();
    dispatch(localeChanged({
      locale: hookForm.formState.defaultValues?.locale?.value,
      locale_messages: hookForm.formState.defaultValues?.locale_messages?.value,
      skipLocaleSelect: true,
    }));
  };

  // eslint-disable-next-line react/jsx-fragments, react/jsx-one-expression-per-line
  let content = <LoadingIndicator />;
  if (isSuccess) {
    const subscription = limitsResult.data?.subscription;
    const isPremium: boolean = false || subscription?.plan?.level > 0;

    content = (
      <div>
        <section className="mt-4 mb-4 fs-5 d-md-none">
          <Nav justified>
            <NavItem>
              <Button tag={RouterNavLink} to="/services" block color="primary">
                <FormattedMessage defaultMessage="Services" />
              </Button>
            </NavItem>
            <NavItem className="mx-3">
              <Button tag={RouterNavLink} to="/templates" block color="primary">
                <FormattedMessage defaultMessage="Templates" />
              </Button>
            </NavItem>
            <NavItem>
              <Button tag={RouterNavLink} to="/finance" block color="primary">
                <FormattedMessage defaultMessage="Budget" />
              </Button>
            </NavItem>
          </Nav>
        </section>
        <hr />
        <section className="mb-3">
          <div className="h3 d-flex justify-content-between">
            <FormattedMessage defaultMessage="User" />
            <Button onClick={toggleUser} color="link" type="button">
              {visibleForm === 'user' && <FontAwesomeIcon icon={solid('chevron-circle-up')} />}
              {visibleForm !== 'user' && <FontAwesomeIcon icon={solid('chevron-circle-down')} />}
            </Button>
          </div>
          <Collapse isOpen={visibleForm === 'user'}>
            <BusinessUserForm
              hookForm={hookForm}
              contactsResult={contactsResult}
              onSubmit={onSubmit}
              loadingState={updateState}
              disabled={isSaving}
            />
            {isDirty && (
              <ButtonGroup vertical className="w-100">
                <Button block type="button" color="danger" outline onClick={onReset} disabled={isSaving}>
                  <FormattedMessage defaultMessage="Discard User Settings" />
                </Button>
                <Button block type="submit" color="primary" outline onClick={formCallback} disabled={isSaving}>
                  {!isSaving && <FormattedMessage defaultMessage="Save User Settings" />}
                  {isSaving && <FormattedMessage defaultMessage="Saving..." />}
                </Button>
              </ButtonGroup>
            )}
          </Collapse>
        </section>
        <hr />
        <section>
          <div className="h3 d-flex justify-content-between">
            <FormattedMessage defaultMessage="Plan" />
            <Button onClick={togglePlan} color="link" type="button">
              {visibleForm === 'plan' && <FontAwesomeIcon icon={solid('chevron-circle-up')} />}
              {visibleForm !== 'plan' && <FontAwesomeIcon icon={solid('chevron-circle-down')} />}
            </Button>
          </div>
          <Collapse isOpen={visibleForm === 'plan'}>
            <div className="d-flex justify-content-between h6 mb-3">
              <div>
                {subscription?.plan && subscription?.plan?.level > 0 && (
                  <FormattedMessage
                    defaultMessage="{premium_icon} {plan_name} (expires {expires})"
                    values={{
                      plan_name: subscription?.plan?.name,
                      premium_icon: (
                        <span className="me-1">
                          <FontAwesomeIcon icon={solid('crown')} />
                        </span>
                      ),
                      expires: (
                        <time dateTime={subscription?.end} title={DateTime.fromISO(subscription.end).toLocaleString()}>
                          {DateTime.fromISO(subscription.end).toLocaleString(DateTime.DATE_SHORT)}
                        </time>
                      ),
                    }}
                  />
                )}
                {subscription?.plan?.level < 1 && subscription?.plan?.name}
              </div>
              <div>
                {subscription?.plan?.level > 0 && (
                  <FormattedMessage
                    defaultMessage="{amount}/month"
                    values={{
                      amount: (
                        <Amount
                          amount={subscription.plan?.amount}
                          currency={subscription.plan?.currency}
                          colors={false}
                          formatOps={{
                            signDisplay: 'never',
                            minimumFractionDigits: 0,
                          }}
                        />
                      ),
                    }}
                  />
                )}
                {subscription?.plan?.level < 1 && <FormattedMessage defaultMessage="free" />}
              </div>
            </div>
            <div className="h6 text-center">
              <FormattedMessage defaultMessage="Usage" />
            </div>
            <List type="unstyled">
              <li className="mb-2">
                <div className="d-flex justify-content-between">
                  <FormattedMessage defaultMessage="Events" tagName="span" />
                  <FormattedMessage
                    defaultMessage="{count, number} / {max, number}"
                    values={{
                      count: limitsResult.data?.current_new_events,
                      max: subscription?.plan?.max_new_events,
                    }}
                    tagName="span"
                  />
                </div>
                <div>
                  {progressMap.has('new_events') && (
                    <Progress
                      value={progressMap.get('new_events')}
                      color={isPremium ? 'primary' : 'secondary'}
                    />
                  )}
                </div>
              </li>
              <li className="mb-2">
                <div className="d-flex justify-content-between">
                  <FormattedMessage defaultMessage="Contacts" tagName="span" />
                  <FormattedMessage
                    defaultMessage="{count, number} / {max, number}"
                    values={{
                      count: limitsResult.data?.current_contacts,
                      max: subscription?.plan?.max_contacts,
                    }}
                    tagName="span"
                  />
                </div>
                <div>
                  {progressMap.has('contacts') && (
                    <Progress
                      value={progressMap.get('contacts')}
                      color={isPremium ? 'primary' : 'secondary'}
                    />
                  )}
                </div>
              </li>
              <li className="mb-2">
                <div className="d-flex justify-content-between">
                  <FormattedMessage defaultMessage="Services" tagName="span" />
                  <FormattedMessage
                    defaultMessage="{count, number} / {max, number}"
                    values={{
                      count: limitsResult.data?.current_services,
                      max: subscription?.plan?.max_services,
                    }}
                    tagName="span"
                  />
                </div>
                <div>
                  {progressMap.has('services') && (
                    <Progress
                      value={progressMap.get('services')}
                      color={isPremium ? 'primary' : 'secondary'}
                    />
                  )}
                </div>
              </li>
              <li className="mb-2">
                <div className="d-flex justify-content-between">
                  <FormattedMessage defaultMessage="Templates" tagName="span" />
                  <FormattedMessage
                    defaultMessage="{count, number} / {max, number}"
                    values={{
                      count: limitsResult.data?.current_templates,
                      max: subscription?.plan?.max_templates,
                    }}
                    tagName="span"
                  />
                </div>
                <div>
                  {progressMap.has('templates') && (
                    <Progress
                      value={progressMap.get('templates')}
                      color={isPremium ? 'primary' : 'secondary'}
                    />
                  )}
                </div>
              </li>
              <li>
                <div className="d-flex justify-content-between">
                  <FormattedMessage defaultMessage="Budget Records" tagName="span" />
                  <FormattedMessage
                    defaultMessage="{count, number} / {max, number}"
                    values={{
                      count: limitsResult.data?.current_new_financial_records,
                      max: subscription?.plan?.max_new_financial_records,
                    }}
                    tagName="span"
                  />
                </div>
                <div>
                  {progressMap.has('new_financial_records') && (
                    <Progress
                      value={progressMap.get('new_financial_records')}
                      color={isPremium ? 'primary' : 'secondary'}
                    />
                  )}
                </div>
              </li>
            </List>
            <div className="text-center">
              <Button
                color="secondary"
                to={{
                  pathname: '/plans',
                }}
                tag={RouterNavLink}
                outline
              >
                <FormattedMessage defaultMessage="All Available Plans" />
              </Button>
            </div>
          </Collapse>
        </section>
        <hr />
        <section className="text-center">
          <UncontrolledDropdown direction="up">
            <DropdownToggle outline color="danger">
              <FormattedMessage defaultMessage="Sign Out" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem to={{ pathname: '/signout' }} tag={Link}>
                <FormattedMessage defaultMessage="Confirm Sign Out" />
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </section>
        <AppVersion />
      </div>
    );
  }

  return (
    <div id="settings-screen">
      <header ref={headerRef}>
        <BaseToolbar
          title={<FormattedMessage defaultMessage="Settings" />}
        />
        {!isSaving && <LoadingBar loadings={[userResult, limitsResult, contactsResult]} />}
        {/* always 25% while submitting form */}
        {isSaving && <LoadingBar loadings={[{}]} />}
      </header>
      <main style={{ paddingTop: headerHeight, paddingBottom: footerHeight }}>
        <Container className="pt-3" fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            <QueryError error={[error, updateState.error]} />
            {content}
          </Col>
        </Container>
      </main>
    </div>
  );
};

export const SettingsRouteElement: React.FC = () => {
  const userResult = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();
  const limitsResult = businessUsersApi.endpoints.getUsageLimits.useQueryState();
  const contactsResult = contactsApi.endpoints.getContacts.useQueryState({ page_size: 1000 });

  return (
    <SettingsScreen
      userResult={userResult}
      limitsResult={limitsResult}
      contactsResult={contactsResult}
    />
  );
};

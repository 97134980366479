import { ObjectSchema } from 'joi';
import { IntlShape } from 'react-intl';

import Joi from '../core/ExtendedJoi';

/**
 * .empty('') required cause react hook form doesn't work with nulls but ''
 * .default(null) replaces '' with NULL to avoid extra transformation during API requests
 */
export const getTemplateSchema = (intl: IntlShape): ObjectSchema => Joi.object({
  /* eslint-disable newline-per-chained-call */
  id: Joi.string().trim().max(100).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'ID' }),
  ),
  name: Joi.string().trim().max(100).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Name' }),
  ),
  text: Joi.string().trim().max(1000).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Text' }),
  ),
  locale: Joi.object({
    value: Joi.string().max(10).default(null),
  }).unknown(true).label(
    intl.formatMessage({ defaultMessage: 'Locale' }),
  ),
  type: Joi.string().trim().max(20).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Type' }),
  ),
});

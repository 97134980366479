import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface UISliceState {
  headerHeight: number;
  footerHeight: number;
}

const initialState = {
  headerHeight: 0,
  footerHeight: 0,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    toolbarHeightChanged(state, { payload }: PayloadAction<Partial<UISliceState>>) {
      if (payload?.headerHeight !== undefined && payload.headerHeight !== state.headerHeight) {
        state.headerHeight = payload.headerHeight;
      }
      if (payload?.footerHeight !== undefined && payload.footerHeight !== state.footerHeight) {
        state.footerHeight = payload.footerHeight;
      }
    },
    /* eslint-enable */
  },
});

export const { toolbarHeightChanged } = uiSlice.actions;

export default uiSlice.reducer;

import { emptySplitApi } from '../api/fetchBaseQuery';
import type { GetFinancialRecordsParams, FinancialRecord, FinancialRecordsResponse, FinancialRecordPayload } from '../types/api';

export const financeApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({

    getFinancialRecords: build.query<FinancialRecordsResponse, GetFinancialRecordsParams>({
      query: (params) => ({
        url: 'finance',
        params,
      }),
      providesTags: (result) => {
        /** @link https://redux-toolkit.js.org/rtk-query/usage/mutations#revalidation-example */
        if (result && Array.isArray(result?.items)) {
          // successful query
          return [
            ...result.items.map(({ id }) => ({ type: 'Finance' as const, id })),
            { type: 'Finance', id: 'LIST' },
          ];
        }
        // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
        return [{ type: 'Finance', id: 'LIST' }];
      },
    }),

    createFinancialRecord: build.mutation<FinancialRecord, FinancialRecordPayload>({
      query: (payload) => ({
        url: 'finance',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [
        { type: 'Finance', id: 'LIST' },
        { type: 'UsageLimits', id: 'ME' },
      ],
    }),

    deleteFinancialRecord: build.mutation<void, FinancialRecord['id']>({
      query: (id: string) => ({
        url: `finance/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Finance', id },
        { type: 'Finance', id: 'LIST' },
        { type: 'UsageLimits', id: 'ME' },
      ],
    }),

  }),
});

export const {
  useGetFinancialRecordsQuery,
  useCreateFinancialRecordMutation,
  useDeleteFinancialRecordMutation,
} = financeApi;

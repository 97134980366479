import { ObjectSchema } from 'joi';
import { IntlShape } from 'react-intl';

import Joi from '../core/ExtendedJoi';

/**
 * .empty('') required cause react hook form doesn't work with nulls but ''
 * .default(null) replaces '' with NULL to avoid extra transformation during API requests
 */
export const getServiceSchema = (intl: IntlShape): ObjectSchema => Joi.object({
  /* eslint-disable newline-per-chained-call */
  id: Joi.string().trim().max(100).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'ID' }),
  ),
  name: Joi.string().trim().max(100).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Name' }),
  ),
  duration: Joi.number().integer().positive().max(480).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Duration' }),
  ),

  use_range: Joi.boolean().optional().empty(['', null]).default(false).strip(),

  price_equal: Joi.when('use_range', {
    is: false,
    then: Joi.number().positive().empty(['']).default(null),
    otherwise: Joi.any().forbidden().failover(null),
  }).label(intl.formatMessage({ defaultMessage: 'Price' })),

  price_from: Joi.when('use_range', {
    is: true,
    then: Joi.number().positive().empty(['']).default(null),
    otherwise: Joi.any().forbidden().failover(null),
  }).label(intl.formatMessage({ defaultMessage: 'Price' })),

  price_to: Joi.when('use_range', {
    is: true,
    then: Joi.number().positive().empty(['']).default(null),
    otherwise: Joi.any().forbidden().failover(null),
  }).label(intl.formatMessage({ defaultMessage: 'Price' })),

  price_currency: Joi.object({
    value: Joi.string().length(3).default(null),
  }).unknown(true).label(
    intl.formatMessage({ defaultMessage: 'Currency' }),
  ),

  default_expenses: Joi.number().positive().empty(['']).default(null).label(
    intl.formatMessage({ defaultMessage: 'Expenses' }),
  ),
  description: Joi.string().trim().max(500).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Description' }),
  ),
  template_var: Joi.string().trim().max(50).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Placeholder' }),
  ),
  set_name: Joi.string().trim().max(30).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Set name' }),
  ),
});

import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import { Col, Navbar, Nav, NavItem, NavLink } from 'reactstrap';

import { toTodayPathname } from '../calendar/Calendar';

export const NavigationToolbar: React.FC = () => {
  const { pathname } = useLocation();
  const todayPathname = toTodayPathname();
  const inCalendar = pathname.indexOf('/calendar/') === 0;
  const showTodayLabel = inCalendar && pathname !== todayPathname;

  return (
    <Col md={{ size: 8, offset: 2 }}>
      <Navbar expand container="fluid">
        <Nav navbar className="w-100 justify-content-between">
          <NavItem>
            <small>
              <NavLink tag={RouterNavLink} to={{ pathname: todayPathname }} className="d-flex flex-column p-0">
                <FontAwesomeIcon icon={solid('calendar')} size="2x" />
                {!showTodayLabel && <FormattedMessage defaultMessage="Calendar" />}
                {showTodayLabel && <FormattedMessage defaultMessage="Today" />}
              </NavLink>
            </small>
          </NavItem>
          <NavItem>
            <small>
              <NavLink tag={RouterNavLink} to="/contacts" className="d-flex flex-column p-0">
                <FontAwesomeIcon icon={solid('address-book')} size="2x" />
                <FormattedMessage defaultMessage="Contacts" />
              </NavLink>
            </small>
          </NavItem>
          <NavItem className="d-none d-md-inline-block">
            <small>
              <NavLink tag={RouterNavLink} to="/services" className="d-flex flex-column p-0">
                <FontAwesomeIcon icon={solid('scissors')} size="2x" />
                <FormattedMessage defaultMessage="Services" />
              </NavLink>
            </small>
          </NavItem>
          <NavItem className="d-none d-md-inline-block">
            <small>
              <NavLink tag={RouterNavLink} to="/templates" className="d-flex flex-column p-0">
                <FontAwesomeIcon icon={solid('message')} size="2x" />
                <FormattedMessage defaultMessage="Templates" />
              </NavLink>
            </small>
          </NavItem>
          <NavItem className="d-none d-md-inline-block">
            <small>
              <NavLink tag={RouterNavLink} to="/finance" className="d-flex flex-column p-0">
                <FontAwesomeIcon icon={solid('coins')} size="2x" />
                <FormattedMessage defaultMessage="Budget" />
              </NavLink>
            </small>
          </NavItem>
          <NavItem>
            <small>
              <NavLink
                tag={RouterNavLink}
                to="/settings"
                className="d-flex flex-column p-0"
              >
                <FontAwesomeIcon icon={solid('gear')} size="2x" />
                <FormattedMessage defaultMessage="Settings" />
              </NavLink>
            </small>
          </NavItem>
        </Nav>
      </Navbar>
    </Col>
  );
};

import { Fragment, useId } from 'react';
import { FormattedMessage } from 'react-intl';
import { TypedUseQueryStateResult, TypedUseMutationResult, BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, List, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used

import { DateTime, Duration } from 'luxon';
import { Amount } from '../core/CurrencySelect';
import { GetPlansParams, Plan, Subscription, SubscriptionPayload, TagNames } from '../types/api';

interface PlanItemProps extends TagNames<'head' | 'body' | 'foot' | 'footNote'>{
  item?: Plan;
  active?: boolean;
  activeEnd?: string | null;
  activeReason?: Subscription['reason'];
  disabled?: boolean;
  onPayClick?: (id: Plan['id']) => void;
}

export const PlanItem: React.FC<PlanItemProps> = ({
  item,
  active = false,
  activeEnd,
  activeReason,
  disabled = false,
  onPayClick,
  components,
}) => {
  const Head = components?.head ?? CardHeader;
  const Body = components?.body ?? CardBody;
  const Foot = components?.body ?? CardFooter;
  const FootNote = components?.footNote ?? 'div';
  const onClickHandler = (item?.id && typeof onPayClick === 'function') ? () => onPayClick(item.id) : undefined;

  return (
    <Fragment>
      {components?.head !== undefined && (
        <Head className={`${item?.level ? 'text-bg-primary' : ''}`}>
          <div className="d-flex justify-content-between">
            <span className={`${!item ? 'placeholder w-50' : ''}`}>
              {item && (
                <Fragment>
                  <FormattedMessage
                    defaultMessage="{name}"
                    values={{
                      name: <span>{item.name}</span>,
                    }}
                  />
                  {active && <FontAwesomeIcon icon={solid('check-circle')} className="ms-2" />}
                  {active && activeReason && activeReason?.indexOf('gift') >= 0 && (
                    <FontAwesomeIcon
                      icon={solid('gift')}
                      className="ms-2"
                    />
                  )}
                </Fragment>
              )}
            </span>
            <span className={`${!item ? 'placeholder w-25' : ''}`}>
              {item && (
                <Badge pill>
                  {item.currency && item.amount && (
                    <FormattedMessage
                      defaultMessage="{amount}/month"
                      values={{
                        amount: (
                          <Amount
                            amount={item.amount}
                            currency={item.currency}
                            colors={false}
                            formatOps={{
                              signDisplay: 'never',
                              minimumFractionDigits: 0,
                            }}
                          />
                        ),
                      }}
                    />
                  )}
                  {item.currency === null && (
                    <FormattedMessage defaultMessage="free forever" />
                  )}
                </Badge>
              )}
            </span>
          </div>
        </Head>
      )}
      <Body>
        {item && (
          <Fragment>
            <strong>
              <FormattedMessage defaultMessage="Limits:" />
            </strong>
            <List type="unstyled">
              {Object.keys(item).map((prop: keyof Plan) => {
                // hide links and imports for a time being, since these features are not implemented yet
                if (['max_links', 'max_new_imports'].includes(prop)) return null;

                const v = item?.[prop];
                const key = `${item.id}${prop}`;
                switch (prop) {
                  case 'max_new_events':
                    return <FormattedMessage key={key} defaultMessage="{max, number, ::compact-short} {max, plural, one {event} other{events}}/month" values={{ max: v }} tagName="li" />;
                  case 'max_contacts':
                    return <FormattedMessage key={key} defaultMessage="{max, number, ::compact-short} contacts" values={{ max: v }} tagName="li" />;
                  case 'max_services':
                    return <FormattedMessage key={key} defaultMessage="{max, number, ::compact-short} services" values={{ max: v }} tagName="li" />;
                  case 'max_templates':
                    return <FormattedMessage key={key} defaultMessage="{max, number, ::compact-short} {max, plural, one {template} other {templates}}" values={{ max: v }} tagName="li" />;
                  case 'max_new_financial_records':
                    return <FormattedMessage key={key} defaultMessage="{max, number, ::compact-short} budget {max, plural, one {record} other {records}}/month" values={{ max: v }} tagName="li" />;
                  case 'max_new_imports':
                    return <FormattedMessage key={key} defaultMessage="{max, number, ::compact-short} {max, plural, one {import} other {imports}}/month" values={{ max: v }} tagName="li" />;
                  case 'max_links':
                    return <FormattedMessage key={key} defaultMessage="{max, number, ::compact-short} public {max, plural, one {link} other {links}}" values={{ max: v }} tagName="li" />;
                  default:
                    return null;
                }
              })}
            </List>
            {components?.footNote !== undefined && item?.refund_period && (
              <FootNote className="text-muted">
                <small>
                  <FormattedMessage
                    defaultMessage="Refund possible {period} after payment"
                    values={{
                      period: Duration.fromISO(item?.refund_period).toHuman(),
                    }}
                  />
                </small>
              </FootNote>
            )}
          </Fragment>
        )}
        {!item && <div className="placeholder w-100" />}
      </Body>
      {components?.foot !== undefined && item?.currency && item.amount && (
        <Foot className="text-center">
          {active && activeEnd && (
            <FormattedMessage
              defaultMessage="Expires at {date}"
              values={{
                date: (
                  <time dateTime={activeEnd} title={DateTime.fromISO(activeEnd).toLocaleString(DateTime.DATETIME_HUGE)}>
                    {DateTime.fromISO(activeEnd).toLocaleString(DateTime.DATE_SHORT)}
                  </time>
                ),
              }}
            />
          )}
          {!active && (
            <Button
              type="button"
              color="primary"
              disabled={active || disabled}
              className={`${!item ? 'placeholder' : ''}`}
              onClick={onClickHandler}
              block
            >
              <FormattedMessage
                defaultMessage="Upgrade for {amount}"
                values={{
                  amount: (
                    <Amount
                      amount={item.amount}
                      currency={item.currency}
                      colors={false}
                      formatOps={{
                        signDisplay: 'never',
                        minimumFractionDigits: 0,
                      }}
                    />
                  ),
                }}
              />
            </Button>
          )}
        </Foot>
      )}
    </Fragment>
  );
};

interface PlansPanelProps {
  itemsResult: TypedUseQueryStateResult<Plan[], GetPlansParams, BaseQueryFn>;
  onPayClick: (id: Plan['id']) => void;
  activeSubscription?: Subscription | null;
  createResult: TypedUseMutationResult<Subscription, SubscriptionPayload, BaseQueryFn>;
  disabled?: boolean;
}

export const PlansPanel: React.FC<PlansPanelProps> = ({
  itemsResult,
  onPayClick,
  activeSubscription,
  createResult,
  disabled,
}) => {
  const px = useId();

  const items = (!itemsResult.isLoading && Array.isArray(itemsResult.data))
    ? itemsResult.data
    : Array.from({ length: 4 }).fill(undefined);
  const paymentDisabled = disabled || createResult.isLoading;

  return (
    <Row>
      {items.map((plan?: Plan, i?: number) => (
        <Col
          key={`${px}${plan?.id ?? `placeholder ${i}`}`}
          md={{ size: 3 }}
          className="mb-4"
        >
          <Card>
            <PlanItem
              item={plan}
              active={plan?.id === activeSubscription?.plan_id}
              disabled={paymentDisabled}
              activeEnd={(plan?.id === activeSubscription?.plan_id) ? activeSubscription?.end : undefined}
              activeReason={activeSubscription?.reason}
              onPayClick={onPayClick}
              components={{
                head: CardHeader,
                footNote: 'div',
                foot: CardFooter,
              }}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

import { ObjectSchema } from 'joi';
import { IntlShape } from 'react-intl';

import { PhoneValidationOptions } from '../contacts/schema';
import Joi from '../core/ExtendedJoi';

/**
 * .empty('') required cause react hook form doesn't work with nulls but ''
 * .default(null) replaces '' with NULL to avoid extra transformation during API requests
 */
export const getBusinessUserSchema = (intl: IntlShape, phoneOptions: PhoneValidationOptions): ObjectSchema => Joi.object({
  /* eslint-disable newline-per-chained-call */
  id: Joi.string().trim().max(100).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'ID' }),
  ),

  email: Joi.string().email({ tlds: { allow: false } }).empty('').label(
    intl.formatMessage({ defaultMessage: 'Email' }),
  ),

  mobile: Joi.string().phone(phoneOptions).empty('').default(null)
    .label(intl.formatMessage({ defaultMessage: 'Mobile' })),

  country: Joi.object({
    value: Joi.string().length(2).default(null),
  }).unknown(true).label(
    intl.formatMessage({ defaultMessage: 'Country' }),
  ),

  timezone: Joi.string().trim().empty('').default(null)
    .label(intl.formatMessage({ defaultMessage: 'Timezone' })),

  // timezone: Joi.object({
  //   value: Joi.string().length(2).default(null),
  // }).unknown(true).label(
  //   intl.formatMessage({ defaultMessage: 'Timezone' }),
  // ),

  default_currency: Joi.object({
    value: Joi.string().length(3).default(null),
  }).unknown(true).label(
    intl.formatMessage({ defaultMessage: 'Currency' }),
  ),

  locale: Joi.object({
    value: Joi.string().min(2).max(20).default(null),
  }).unknown(true).label(
    intl.formatMessage({ defaultMessage: 'Locale' }),
  ),

  locale_messages: Joi.object({
    value: Joi.any().allow('he').empty('').default(null),
  }).unknown(true).label(
    intl.formatMessage({ defaultMessage: 'Translation' }),
  ),

  contact: Joi.object({
    value: Joi.string().trim().max(100).empty('').default(null),
  }).unknown(true).label(
    intl.formatMessage({ defaultMessage: 'Contact' }),
  ),

  default_calendar: Joi.object({
    value: Joi.string().trim().max(100).empty('').default(null),
  }).empty(null).unknown(true).label(
    intl.formatMessage({ defaultMessage: 'Default Calendar' }),
  ),

  default_contacts_book: Joi.object({
    value: Joi.string().trim().max(100).empty('').default(null),
  }).empty(null).unknown(true).label(
    intl.formatMessage({ defaultMessage: 'Default Contacts Book' }),
  ),
});

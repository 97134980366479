import { useIntl } from 'react-intl';
import { Spinner, SpinnerProps } from 'reactstrap';

export const LoadingIndicator: React.FC<SpinnerProps> = (
  props,
) => {
  const intl = useIntl();
  return (
    <div className="text-center text-primary">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Spinner {...props}>
        {intl.formatMessage({ defaultMessage: 'Loading...' })}
      </Spinner>
    </div>
  );
};

import { FieldErrors, FieldValues, SubmitErrorHandler, SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';

interface FormSubmitHandlerProps<TFieldValues extends FieldValues> {
  handleSubmit: UseFormHandleSubmit<TFieldValues>;
  onSubmit?: SubmitHandler<TFieldValues>;
  onSubmitError?: SubmitErrorHandler<TFieldValues>;
}

/* eslint-disable no-console */
export const formSubmitHandler = <T extends FieldValues>({
  handleSubmit,
  onSubmit,
  onSubmitError,
}: FormSubmitHandlerProps<T>) => {
  if (process.env.NODE_ENV === 'development') {
    const logger = (arg: SubmitHandler<T> | SubmitErrorHandler<T>) => {
      if (typeof arg === 'function') {
        return async (data: T | FieldErrors<T>, e?: React.SyntheticEvent): Promise<any> => {
          console.debug(data, e);
          await arg(data);
          return null;
        };
      }

      console.debug(arg);
    };
    return handleSubmit(
      typeof onSubmit === 'function' ? logger(onSubmit) : logger,
      typeof onSubmitError === 'function' ? logger(onSubmitError) : logger,
    );
  }

  return handleSubmit(
    typeof onSubmit === 'function' ? onSubmit : () => null,
    typeof onSubmitError === 'function' ? onSubmitError : undefined,
  );
};
/* eslint-enable no-console */

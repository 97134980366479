import { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Toast, ToastHeader, ToastBody, UncontrolledAlert } from 'reactstrap';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import type { SlimErrorResponse } from '../types/api';

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 * @link https://redux-toolkit.js.org/rtk-query/usage-with-typescript#inline-error-handling-example
 */
export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 * @link https://redux-toolkit.js.org/rtk-query/usage-with-typescript#inline-error-handling-example
 */
export function isErrorWithMessage(
  error: unknown,
): error is SerializedError {
  return (
    typeof error === 'object'
    && error != null
    && 'message' in error
    && typeof (error as SerializedError).message === 'string'
  );
}

export function isJsError(
  error: unknown,
): error is Error {
  return (
    typeof error === 'object'
    && error != null
    && 'name' in error
    && 'message' in error
    && 'stack' in error
  );
}

interface ReducedError {
  status: FetchBaseQueryError['status'] | SerializedError['name'] | null;
  message: string | null;
  clipboard: string | null;
  errorData?: FetchBaseQueryError['data'] | Omit<SerializedError, 'code' | 'stack'> | { error_id?: null };
  isJSError?: boolean;
  isServerError?: boolean;
}

const reduceErrorList = (prev: ReducedError, err: FetchBaseQueryError | SerializedError | Error): ReducedError => {
  if (prev?.status && prev?.message) return prev;

  if (isJsError(err)) {
    return { status: err.name, message: `${err.message} ${err.stack}`, clipboard: err.toString(), isJSError: true };
  }

  if (isFetchBaseQueryError(err)) {
    // you can access all properties of `FetchBaseQueryError` here
    const errMsg = 'error' in err ? err.error : JSON.stringify(err.data, null, '  ');
    const errorData = 'error' in err ? { error_id: null } : err.data;
    return { status: err?.status, message: errMsg, clipboard: JSON.stringify(err, null, '  '), errorData, isServerError: true };
  }

  if (isErrorWithMessage(err)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { code, stack, ...rest } = err as SerializedError;
    return { status: rest?.name, message: rest.message ?? null, clipboard: JSON.stringify(rest, null, '  '), errorData: rest, isServerError: true };
  }

  return prev;
};

const errorIsSlimError = (
  error: unknown,
): error is SlimErrorResponse => typeof error === 'object' && error != null && 'error_id' in error;

interface QueryErrorProps {
  error?:
    FetchBaseQueryError
    | SerializedError
    | Array<FetchBaseQueryError | SerializedError>
    | unknown
    | null;
}

export const QueryError: React.FC<QueryErrorProps> = ({
  error,
}) => {
  const errorList = (Array.isArray(error)) ? error : [error];
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const toastRef = useRef<HTMLElement>(null);
  const { status, message, clipboard, errorData, isJSError, isServerError } = errorList.reduce(reduceErrorList, { status: null, message: null, clipboard: null, errorData: { error_id: null }, isJSError: false, isServerError: false });

  useEffect(() => {
    if (toastRef.current) {
      setTimeout(() => {
        toastRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 200); // doesn't work without small delay, maybe because of transition
    }
  }, [message]);

  const onCopy = (text: string, result: boolean) => setIsCopied(result);

  // foreign key violation
  // not actually an error but business logic
  if (errorIsSlimError(errorData) && errorData?.error_id === 'err_400_fk_violation') {
    return (
      <UncontrolledAlert
        color="danger"
        innerRef={toastRef}
      >
        <FormattedMessage
          defaultMessage="Cannot delete or update because there are references to this item somewhere else in the application. For instance, you can't delete contact or service when it's already mapped to some event in the calendar."
        />
      </UncontrolledAlert>
    );
  }

  return (
    <Toast
      isOpen={Boolean(message)}
      className="mb-3 mx-auto"
      innerRef={toastRef}
    >
      <ToastHeader icon="danger">
        {isServerError && (
          <Fragment>
            {status === 'FETCH_ERROR' && <FormattedMessage defaultMessage="Internet Connection Lost" />}
            {status !== 'FETCH_ERROR' && <FormattedMessage defaultMessage="Server Error" />}
          </Fragment>
        )}
        {isJSError && <FormattedMessage defaultMessage="JavaScript Error" />}
      </ToastHeader>
      <ToastBody>
        {/* not stable internet connection */}
        {status === 'FETCH_ERROR' && (
          <FormattedMessage defaultMessage="Could not connect to server, please check your internet connection." />
        )}
        {/* server api error */}
        {status !== 'FETCH_ERROR' && (
          <Fragment>
            <strong>
              {status}
            </strong>
            <pre>
              <code>
                {message}
              </code>
            </pre>
            {clipboard && (
              <CopyToClipboard text={clipboard} options={{ format: 'text/plain' }} onCopy={onCopy}>
                <Button
                  block
                  outline
                  color="secondary"
                  type="button"
                  active={isCopied}
                >
                  {!isCopied && <FormattedMessage defaultMessage="Copy Error Text" />}
                  {isCopied && <FormattedMessage defaultMessage="Copied" />}
                </Button>
              </CopyToClipboard>
            )}
          </Fragment>
        )}
      </ToastBody>
    </Toast>
  );
};

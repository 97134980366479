import CldrTerritoryInfo from 'cldr-core/supplemental/territoryInfo.json';

import { CountryList } from '../core/CurrencySelect';

const { supplemental: { territoryInfo } } = CldrTerritoryInfo;

const CountriesData = Object.keys(territoryInfo)
  .map((isoCode) => ({ ...territoryInfo[isoCode], countryCode: isoCode }));

interface GetBrowserLocalesProps {
  language?: string | string[];
  languages?: string | string[];
}

export const getBrowserLocales = (
  arg?: GetBrowserLocalesProps,
): Set<string> => {
  const { language, languages } = arg ?? {};
  const navigator = typeof window !== 'undefined' && window.hasOwnProperty('navigator') ? window.navigator : undefined;
  const firstProp = (language === undefined && navigator && navigator?.language) ? navigator.language : language;
  const secondProp = (languages === undefined && navigator && navigator?.languages) ? navigator.languages : languages;
  return [firstProp, secondProp].reduce((set, prop) => {
    const iterable = (Array.isArray(prop)) ? prop : [prop];
    iterable.forEach((str) => {
      if (typeof str === 'string' && str) {
        try {
          const locale = (Intl.hasOwnProperty('getCanonicalLocales') && typeof Intl.getCanonicalLocales === 'function') ? Intl.getCanonicalLocales(str)[0] : str;
          set.add(locale);
        } catch (e) {
          // too bad, we can't normalize locale
          set.add(str);
        }
      }
    });

    return set;
  }, new Set<string>());
};

interface GetSupportedLangProps {
  locale: string | string[];
  languages: string | string[];
  fallbackLanguage?: string;
}

export const getSupportedLang = ({
  locale,
  languages,
  fallbackLanguage,
}: GetSupportedLangProps): string => {
  const localeSet = (Array.isArray(locale)) ? new Set(locale) : new Set([locale]);
  const languagesSet = (Array.isArray(languages)) ? new Set(languages) : new Set([languages]);
  const [primary] = Array.from(localeSet);
  localeSet.delete(primary);

  // try to find primary locale first
  if (languagesSet.has(primary)) return primary;
  const closePrimary = Array.from(languagesSet).find((lang) => lang.split('-')[0] === primary.split('-')[0]);

  if (closePrimary) return closePrimary;

  // try rest locales
  const exact = Array.from(languagesSet).find((str) => localeSet.has(str));
  if (exact) return exact;

  // try to find closest one
  const closest = Array.from(languagesSet)
    .find((lang) => Array.from(localeSet).find((str) => lang.split('-')[0] === str.split('-')[0]));
  if (closest) return closest;

  return (typeof fallbackLanguage === 'string' && fallbackLanguage) ? fallbackLanguage : 'en';
};

export const getCountryByLang = (
  lang: string,
): string => CountriesData.reduce(
  (carry, data) => {
    const { population, isOfficial } = carry;

    if (data.languagePopulation && Object.keys(data.languagePopulation).includes(lang)) {
      // count population
      const { countryCode } = data;
      const countryPopulation = parseInt(data._population, 10);
      const langPercent = parseFloat(data.languagePopulation[lang]._populationPercent) * 0.01;
      const total = countryPopulation * langPercent;
      const officialStatus = ['official', 'de_facto_official'].includes(data.languagePopulation[lang]?._officialStatus);
      if (
        (
          isOfficial === false
          && officialStatus === true
        ) || (
          isOfficial === true
          && officialStatus === true
          && total > population
        )
      ) {
        return { countryCode, population: total, isOfficial: officialStatus };
      }
    }
    return carry;
  },
  { population: 0, countryCode: 'US', isOfficial: false },
).countryCode;

export const getCountryByLocale = (
  locale: string,
): string => {
  if (typeof locale !== 'string') return 'US';
  if (CountryList.has(locale)) return locale;

  const langPart = locale?.split('-').shift() ?? 'en';
  const countryPart = locale?.split('-').pop();

  if (countryPart && CountryList.has(countryPart)) return countryPart;

  // find territory where lang is official and most population
  return getCountryByLang(langPart);
};

export const getUnauthorizedSetting = () => {

};

import { Fragment, ReactNode, useMemo, useEffect, useRef } from 'react';
import { TypedUseQueryStateResult, BaseQueryFn, FetchBaseQueryError, skipToken, TypedUseQueryHookResult } from '@reduxjs/toolkit/dist/query/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, Navigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import { Button, Container, Col, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledAlert, UncontrolledDropdown } from 'reactstrap';

import { BaseToolbar } from '../core/BaseToolbar';
import { QueryError } from '../core/QueryError';
import { LoadingIndicator } from '../core/LoadingIndicator';
import { LoadingBar } from '../core/LoadingBar';
import { businessUsersApi } from '../business_user/businessUsersApi';
import { useCreateTemplateMutation, useDeleteTemplateMutation, useGetTemplateDetailsQuery, useGetTemplatesQuery, useUpdateTemplateMutation } from '../templates/templatesApi';
import { TemplateForm, TemplatesPanels } from '../templates/Template';
import { toLocaleSelectOption } from '../core/CurrencySelect';
import { getTemplateSchema } from '../templates/schema';
import Joi, { defineJoiMessages } from '../core/ExtendedJoi';
import useHeaderAndFooterHeight from '../core/useHeaderAndFooterHeight';
import { DemoUserDisclaimer } from '../core/DemoUserDisclaimer';
import type { ApiCollection, ApiErrorResponse, BusinessUser, Event, PaginationParams, Template, UsageLimit, TemplateFormValues } from '../types/api';

interface TemplatesScreenProps {
  userResult: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  templates: TypedUseQueryStateResult<ApiCollection<Template>, PaginationParams, BaseQueryFn>;
}

export const TemplatesScreen: React.FC<TemplatesScreenProps> = ({
  userResult,
  templates,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);
  const noTemplates = false || (templates.isSuccess && templates.data.items_count < 1);

  return (
    <div id="templates-screen">
      <header ref={headerRef}>
        <BaseToolbar
          title={<FormattedMessage defaultMessage="Templates" />}
          forward={(
            <Button tag={Link} to="/templates/new" color="link">
              <FontAwesomeIcon icon={solid('plus')} />
              <span className="visually-hidden">
                <FormattedMessage defaultMessage="Add new message template" />
              </span>
            </Button>
          )}
        />
        <LoadingBar loadings={[userResult, templates]} />
      </header>
      <main style={mainPaddings}>
        <Container className="pt-3" fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            {userResult.data?.is_demo && <DemoUserDisclaimer />}
            {noTemplates && (
              <div className="h2 text-center">
                <FormattedMessage defaultMessage="No Templates" />
              </div>
            )}
            {!noTemplates && <TemplatesPanels items={templates} />}
          </Col>
        </Container>
      </main>
    </div>
  );
};

export const TemplatesRouteElement: React.FC = () => {
  const userResult = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();
  const templatesResult = useGetTemplatesQuery({ page: 1, page_size: 100, sort_order: 'DESC' });

  return (
    <TemplatesScreen
      userResult={userResult}
      templates={templatesResult}
    />
  );
};

interface NewTemplateScreenProps {
  userResult: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  limitsResult: TypedUseQueryStateResult<UsageLimit, void, BaseQueryFn>;
}

const NewTemplateScreen: React.FC<NewTemplateScreenProps> = ({
  userResult,
  limitsResult,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);
  const intl = useIntl();
  const navigate = useNavigate();
  const [createTemplate, creationState] = useCreateTemplateMutation();
  const serverErrors = [userResult?.error, creationState?.error]
    .filter((e) => Boolean(e)) as Array<FetchBaseQueryError | ApiErrorResponse>;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const schemaMemoized = useMemo(() => getTemplateSchema(intl), [intl]);
  const schema = schemaMemoized.concat(Joi.object({
    name: Joi.string().required(),
    text: Joi.string().required(),
    locale: Joi.object().required(),
  }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const joiMessages = useMemo(() => defineJoiMessages(intl), [intl]);

  const defaultFormValues = {
    name: '',
    text: '',
    locale: toLocaleSelectOption('en-US'),
    type: 'manual',
  } as TemplateFormValues;
  const form = useForm<TemplateFormValues>({
    defaultValues: defaultFormValues,
    resolver: joiResolver(schema, {
      messages: joiMessages,
      abortEarly: false,
    }),
  });

  const tomorrow = useMemo(() => DateTime.now().plus({ days: 1 }), []);
  const eventExample = useMemo(() => ({
    start: tomorrow.toISO(),
    organizer: [{
      first_name: userResult.data?.contact?.first_name
        ?? intl.formatMessage({ defaultMessage: 'Jennifer' }),
    }],
    attendee: [{
      first_name: intl.formatMessage({ defaultMessage: 'Mary' }),
    }],
    service: [{
      template_var: intl.formatMessage({ defaultMessage: 'massage' }),
    }],
  } as Partial<Event>), [tomorrow, userResult.isSuccess, intl]);

  useEffect(() => {
    // predefine currency
    if (userResult.data?.locale) {
      form.resetField(
        'locale',
        {
          defaultValue: toLocaleSelectOption(userResult.data?.locale),
          keepDirty: true,
          keepTouched: true,
        },
      );
    }
  }, [userResult.data?.locale]);

  const onSubmit = async (formData: TemplateFormValues) => {
    const { locale, ...data } = formData;
    let templateId: string | null = null;
    try {
      const payload = { ...data, locale: locale.value };
      const response = await createTemplate(payload).unwrap();
      templateId = response?.id;
    } catch (e) {
      return false;
    }

    if (templateId) {
      navigate('/templates', { replace: true });
      return true;
    }
    return false;
  };

  const onReset = () => form.reset();
  const isSaving = form.formState.isSubmitting;
  const limitReached = false || limitsResult.data?.max_templates <= limitsResult.data?.current_templates;
  const upgradeLink: React.FC<ReactNode> = (chunk) => <Link to={{ pathname: '/plans' }}>{chunk}</Link>;

  return (
    <div id="new-template-screen">
      <header ref={headerRef}>
        <BaseToolbar
          title={<FormattedMessage defaultMessage="New Template" tagName="strong" />}
          back={(
            <Button
              className="back-link"
              tag={Link}
              to="/templates"
              color="link"
              disabled={isSaving}
            >
              <FontAwesomeIcon icon={solid('chevron-left')} />
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              {' '}
              {!form.formState.isDirty && <FormattedMessage defaultMessage="Templates" />}
              {form.formState.isDirty && <FormattedMessage defaultMessage="Cancel" />}
            </Button>
          )}
          forward={(
            <Button
              type="button"
              color="link"
              onClick={form.handleSubmit(onSubmit)}
              disabled={limitReached || isSaving}
            >
              {!isSaving && <FormattedMessage defaultMessage="Add" />}
              {isSaving && <FormattedMessage defaultMessage="Adding..." />}
            </Button>
          )}
        />
        {!isSaving && <LoadingBar loadings={[userResult, limitsResult]} />}
        {/* always 25% while submitting form */}
        {isSaving && <LoadingBar loadings={[{}]} />}
      </header>
      <main style={mainPaddings}>
        <Container className="pt-3" fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            <QueryError error={serverErrors} />
            {limitReached && (
              <UncontrolledAlert color="warning" className="alert-sm">
                <FormattedMessage
                  defaultMessage="Ouch, looks like ''{name}'' plan limit of {max, plural, one {# template} other {{max, number, ::compact-short} templates}} reached."
                  values={{
                    name: limitsResult.data?.subscription?.plan?.name,
                    max: limitsResult.data?.max_templates,
                  }}
                />
                {limitsResult.data?.subscription?.plan?.level < 3 && (
                  <Fragment>
                    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                    {' '}
                    <FormattedMessage
                      defaultMessage="It's possible to <a>upgrade plan</a> if you need more templates."
                      values={{
                        a: upgradeLink,
                      }}
                    />
                  </Fragment>
                )}
              </UncontrolledAlert>
            )}
            <TemplateForm
              hookForm={form}
              onSubmit={onSubmit}
              eventExample={eventExample}
              disabled={limitReached || isSaving}
            />
            {form.formState.isDirty && (
              <div className="mt-3">
                <Button
                  color="danger"
                  block
                  type="button"
                  onClick={onReset}
                >
                  <FormattedMessage defaultMessage="Reset form" />
                </Button>
              </div>
            )}
          </Col>
        </Container>
      </main>
    </div>
  );
};

export const NewTemplateRouteElement: React.FC = () => {
  const userResult = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();
  const limitsResult = businessUsersApi.endpoints.getUsageLimits.useQueryState();

  return (
    <NewTemplateScreen
      userResult={userResult}
      limitsResult={limitsResult}
    />
  );
};

interface EditTemplateScreenProps {
  templateId: Template['id'];
  userResult: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  templateResult: TypedUseQueryHookResult<Template, Template['id'], BaseQueryFn>;
}

export const EditTemplateScreen: React.FC<EditTemplateScreenProps> = ({
  templateId,
  userResult,
  templateResult,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);
  const intl = useIntl();
  const navigate = useNavigate();
  const [updateTemplate, updatingState] = useUpdateTemplateMutation();
  const [deleteTemplate, deletionState] = useDeleteTemplateMutation();
  const isLoading = userResult.isLoading || templateResult.isLoading;
  const serverErrors = [userResult?.error, updatingState?.error, deletionState?.error]
    .filter((e) => Boolean(e)) as Array<FetchBaseQueryError | ApiErrorResponse>;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const schemaMemoized = useMemo(() => getTemplateSchema(intl), [intl]);
  const schema = schemaMemoized.concat(Joi.object({
    name: Joi.string().required(),
    text: Joi.string().required(),
    locale: Joi.object().required(),
  }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const joiMessages = useMemo(() => defineJoiMessages(intl), [intl]);

  const defaultFormValues = {
    id: templateId,
    name: '',
    text: '',
    locale: toLocaleSelectOption('en-US'),
    type: 'manual',
  } as TemplateFormValues;
  const form = useForm<TemplateFormValues>({
    defaultValues: defaultFormValues,
    resolver: joiResolver(schema, {
      messages: joiMessages,
      abortEarly: false,
    }),
  });
  const { formState: { dirtyFields, isSubmitting } } = form;
  const isSaving = isSubmitting || deletionState.isLoading;
  const hasDirtyFields = Object.keys(dirtyFields).length > 0;
  const tomorrow = useMemo(() => DateTime.now().plus({ days: 1 }), []);
  const eventExample = useMemo(() => ({
    start: tomorrow.toISO(),
    organizer: [{
      first_name: userResult.data?.contact?.first_name
        ?? intl.formatMessage({ defaultMessage: 'Jennifer' }),
    }],
    attendee: [{
      first_name: intl.formatMessage({ defaultMessage: 'Mary' }),
    }],
    service: [{
      template_var: intl.formatMessage({ defaultMessage: 'massage' }),
    }],
  } as Partial<Event>), [tomorrow, userResult.isSuccess, intl]);

  // on data load
  useEffect(() => {
    if (templateResult.isSuccess) {
      const { data } = templateResult;
      form.reset({
        id: data.id,
        name: data.name ?? '',
        text: data.text ?? '',
        locale: toLocaleSelectOption(data?.locale),
        type: data.type ?? 'manual',
      });
    }
  }, [templateResult.isSuccess]);

  const onSubmit = async (formData: TemplateFormValues) => {
    const { locale, ...data } = formData;
    try {
      const payload = { ...data, locale: locale.value };
      await updateTemplate(payload).unwrap();
    } catch (e) {
      return;
    }

    navigate('/templates', { replace: true });
  };

  const onReset = () => form.reset();

  const onDelete = async () => {
    try {
      await deleteTemplate(templateId).unwrap();
      // make redirect to wipe off old template link
      navigate('/templates', { replace: true });
    } catch (e) {
      // do nothing
    }
  };

  return (
    <div id="edit-template-screen">
      <header ref={headerRef}>
        <BaseToolbar
          back={(
            <Button
              className="back-link"
              tag={Link}
              to={{
                pathname: '/templates',
              }}
              color="link"
              disabled={isLoading || isSaving}
            >
              <FontAwesomeIcon icon={solid('chevron-left')} />
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              {' '}
              {hasDirtyFields && <FormattedMessage defaultMessage="Discard" />}
              {!hasDirtyFields && <FormattedMessage defaultMessage="Back to templates" />}
            </Button>
          )}
          forward={(
            <Button
              type="button"
              color="link"
              disabled={hasDirtyFields === false || isSaving}
              onClick={form.handleSubmit(onSubmit)}
            >
              {!isSaving && <FormattedMessage defaultMessage="Save" />}
              {isSaving && <FormattedMessage defaultMessage="Saving..." />}
            </Button>
          )}
        />
        {!isSaving && <LoadingBar loadings={[userResult, templateResult]} />}
        {/* always 25% while submitting form */}
        {isSaving && <LoadingBar loadings={[{}]} />}
      </header>
      <main style={mainPaddings}>
        <Container className="pt-3" fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            <QueryError error={serverErrors} />
            {isLoading && <LoadingIndicator />}
            {!isLoading && (
              <Fragment>
                <TemplateForm
                  hookForm={form}
                  onSubmit={onSubmit}
                  eventExample={eventExample}
                  disabled={isSaving}
                />
                {hasDirtyFields && (
                  <div className="mt-3">
                    <Button
                      color="danger"
                      block
                      type="button"
                      onClick={onReset}
                    >
                      <FormattedMessage defaultMessage="Discard All Changes" />
                    </Button>
                  </div>
                )}
                <div className="mt-3">
                  <UncontrolledDropdown className="w-100" direction="up" disabled={isSaving}>
                    <DropdownToggle color="danger" block disabled={isSaving}>
                      <FormattedMessage defaultMessage="Delete Template" />
                    </DropdownToggle>
                    <DropdownMenu className="w-100 text-center">
                      <DropdownItem onClick={onDelete}>
                        <FormattedMessage defaultMessage="Confirm Deletion" />
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>
                        <FormattedMessage defaultMessage="Cancel Deletion" />
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Fragment>
            )}
          </Col>
        </Container>
      </main>
    </div>
  );
};

export const EditTemplateRouteElement: React.FC = () => {
  const { templateId } = useParams();
  const userResult = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();
  const queryParams = templateId?.toString() ?? skipToken;
  const templateResult = useGetTemplateDetailsQuery((userResult.isSuccess) ? queryParams : skipToken);

  // can't use redirect before second hook
  if (typeof templateId !== 'string') return <Navigate to={{ pathname: '/templates' }} />;

  return (
    <EditTemplateScreen
      templateId={templateId}
      userResult={userResult}
      templateResult={templateResult}
    />
  );
};

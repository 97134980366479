import { FormattedMessage } from 'react-intl';
import { Progress, ProgressProps } from 'reactstrap';

import type { LoadingState } from '../types/api';

export const countProgress = (
  count: number,
  total: number,
  startPercent: number | undefined = 0,
): number => {
  if (total <= 0) return 100;

  const progress = (1 - startPercent * 0.01) * (count / total) * 100;

  return Math.min(
    100,
    Math.round(startPercent + progress),
  );
};

interface LoadingBarProps extends Omit<ProgressProps, 'value'> {
  loadings: Array<LoadingState | null | undefined>;
  startPercent?: number;
  visibleFinished?: boolean;
}

export const LoadingBar: React.FC<LoadingBarProps> = ({
  loadings,
  startPercent = 25,
  color = 'primary',
  animated = true,
  visibleFinished = false,
  ...progressProps
}) => {
  const {
    total,
    finished,
  } = loadings
    .filter((element) => ![undefined, null].includes(element))
    .reduce((carry, element: LoadingState) => {
      const { isUninitialized, isLoading, isFetching } = element ?? {};
      if (isUninitialized) return carry;
      /* eslint-disable no-param-reassign */
      const isFinished = isLoading !== true && isFetching !== true;
      carry.total += 1;
      carry.finished += (isFinished) ? 1 : 0;
      return carry;
      /* eslint-enable no-param-reassign */
    }, { total: 0, finished: 0 });
  const progressValue = countProgress(finished, total, startPercent);

  if (progressValue >= 100 && !visibleFinished) return null;

  return (
    <Progress
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...progressProps}
      value={progressValue}
      color={color}
      animated={animated}
      style={{
        height: '4px',
      }}
    >
      <span className="visually-hidden">
        <FormattedMessage
          defaultMessage="Loading {num, number, percent}"
          values={{ num: progressValue * 0.01 }}
        />
      </span>
    </Progress>
  );
};

import { useId, ChangeEvent, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import { Input, Form, FormFeedback, FormGroup, Label, FormText } from 'reactstrap';
import type { FormMethods, TagNames } from '../types/api';

const AndroidExportVcfLink = (locale?: string) => {
  let guideLink = '#';
  switch (locale?.toLowerCase()) {
    case 'ru':
    case 'ru_ru':
    case 'ru-ru':
      guideLink = 'https://support.google.com/contacts/answer/7199294?hl=ru&co=GENIE.Platform%3DAndroid';
      break;
    case 'en':
    default:
      guideLink = 'https://support.google.com/contacts/answer/7199294?hl=en&co=GENIE.Platform%3DAndroid';
  }

  return function Link(chunk: ReactNode): ReactNode { return <a href={guideLink} target="_blank" rel="noreferrer">{chunk}</a>; };
};

const IOSExportVcfLink = (locale?: string) => {
  let guideLink = '#';
  switch (locale?.toLowerCase()) {
    case 'ru':
    case 'ru_ru':
    case 'ru-ru':
      guideLink = 'https://support.apple.com/ru-ru/guide/icloud/mmfba748b2/icloud#mmbfb255a4a7';
      break;
    case 'en':
    default:
      guideLink = 'https://support.apple.com/guide/icloud/import-or-export-vcards-mmfba748b2/icloud#mmbfb255a4a7';
  }

  return function Link(chunk: ReactNode): ReactNode { return <a href={guideLink} target="_blank" rel="noreferrer">{chunk}</a>; };
};

interface FormValues {
  file: FileList | '';
}
interface ImportVcfFormProps extends FormMethods<FormValues>, TagNames<'body'> {}

export const ImportVcfForm: React.FC<ImportVcfFormProps> = ({
  hookForm,
  onSubmit,
  components,
  disabled,
}) => {
  const { control, handleSubmit, formState: { errors }, setValue } = hookForm;
  // form prefix
  const px = useId();
  const intl = useIntl(); // handy for plain text values like input placeholders
  const BodyWrapper = components?.body ?? 'div';
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => setValue('file', e.target.files ?? '');
  const androidLink = AndroidExportVcfLink(intl?.locale);
  const iosLink = IOSExportVcfLink(intl?.locale);
  const formCallback = (onSubmit) ? handleSubmit(onSubmit) : undefined;

  return (
    <Form
      autoComplete="off"
      onSubmit={formCallback}
      noValidate
    >
      <BodyWrapper>
        <fieldset>
          <FormGroup>
            <Label htmlFor={`${px}file`} hidden>
              <FormattedMessage defaultMessage="File" />
            </Label>
            <Controller
              name="file"
              control={control}
              render={({ field: { name, ref } }) => (
                <Input
                  accept=".vcf,.VCF"
                  bsSize="sm"
                  id={`${px}file`}
                  innerRef={ref}
                  invalid={Boolean(errors?.file)}
                  name={name}
                  onChange={handleFileChange}
                  type="file"
                  disabled={disabled || hookForm.formState.isSubmitting}
                />
              )}
            />
            <FormFeedback>
              {errors?.file?.message}
            </FormFeedback>
          </FormGroup>
          <FormText>
            <FormattedMessage
              defaultMessage="If you don't know how to export contacts into VCF file check these guides for <link1>iOS</link1> and <link2>Android</link2>."
              values={{
                link1: iosLink,
                link2: androidLink,
              }}
            />
          </FormText>
        </fieldset>
      </BodyWrapper>
    </Form>
  );
};

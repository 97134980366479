import Joi from 'joi';
import parsePhoneNumber, { isSupportedCountry, getCountries } from 'libphonenumber-js/max';
import type { IntlShape } from 'react-intl';

const phoneExtension = (joi: Joi.Root): Joi.Extension => ({
  type: 'string',
  base: joi.string(),
  messages: {
    'phone.base': '{{#label}} must be valid phone number',
    'phone.allowedCountry': '{{#label}} from country "{{#q}}" not allowed, use one of: {{#a}}',
    'phone.allowedType': '{{#label}} type "{{#q}}" not allowed, use one of: {{#a}}',
  },
  rules: {
    phone: {
      method(options = {}) {
        return this.$_addRule({ name: 'phone', args: { allowedType: [], allowedCountry: [], ...options } });
      },
      args: [
        {
          name: 'country',
          ref: true,
          assert: (value) => isSupportedCountry(value),
          message: 'A "country code" is a two-letter ISO country code (like US).',
        },
        {
          name: 'allowedCountry',
          ref: true,
          assert: joi.array().unique().min(0).items(joi.string().equal(...getCountries())),
          message: 'Allowed list of countries should be array of ISO country codes (like US).',
        },
        {
          name: 'allowedType',
          ref: true,
          assert: joi.array().unique().min(0).items(
            joi.string().equal('PREMIUM_RATE', 'TOLL_FREE', 'SHARED_COST', 'VOIP', 'PERSONAL_NUMBER', 'PAGER', 'UAN', 'VOICEMAIL', 'FIXED_LINE_OR_MOBILE', 'FIXED_LINE', 'MOBILE'),
          ),
          message: 'Invalid allowed type',
        },
      ],
      validate(value, helpers, { country, allowedCountry, allowedType }) {
        const phone = parsePhoneNumber(value, country);
        if (!phone?.isValid()) {
          return helpers.error('phone.base');
        }

        if (Array.isArray(allowedCountry) && allowedCountry?.length > 0 && !allowedCountry.includes(phone?.country)) {
          return helpers.error('phone.allowedCountry', { q: phone?.country, a: allowedCountry.join(', ') });
        }
        if (Array.isArray(allowedType) && allowedType?.length > 0 && !allowedType.includes(phone?.getType())) {
          return helpers.error('phone.allowedType', { q: phone?.getType(), a: allowedType.join(', ') });
        }

        return phone.format('E.164');
      },
    },
  },
} as Joi.Extension);

const fileListExtension = (joi: Joi.Root): Joi.Extension => ({
  type: 'any',
  base: joi.any(),
  messages: {
    'fileList.base': '{{#label}} must be FileList instance',
    'fileList.length': '{{#label}} {{#limit}} files must be selected',
    'fileList.min': '{{#label}} at least {{#limit}} file must be selected',
    'fileList.max': '{{#label}} no more than {{#limit}} files must be selected',
    'fileList.fileSize': '{{#label}} {{#name}} exceeds size limit {{#limit}}({{#size}}) bytes',
    'fileList.fileType': '{{#label}} {{#name}} type "{{#type}}" is not in allowed {{#types}}',
  },
  rules: {
    fileList: {
      method() {
        return this.$_addRule({ name: 'fileList' });
      },
      validate(value, { error }) {
        if (value instanceof FileList !== true) {
          return error('fileList.base');
        }

        return value;
      },
    },
    length: {
      method(limit) {
        return this.$_addRule({ name: 'length', args: { limit }, operator: '=' });
      },
      validate(value, helpers, { limit }, { name, operator = '=', args }) {
        switch (operator) {
          case '<=':
            if (value?.length <= limit) return value;
            break;
          case '>=':
            if (value?.length >= limit) return value;
            break;
          case '=':
            if (value?.length === limit) return value;
            break;
          default:
            // validation failed
        }

        return helpers.error(`fileList.${name}`, { limit: args.limit, value });
      },
      args: [
        {
          name: 'limit',
          ref: true,
          assert: joi.number().integer().min(0),
          message: 'must be a positive integer',
        },
      ],
    },

    max: {
      method(limit) {
        return this.$_addRule({ name: 'max', method: 'length', args: { limit }, operator: '<=' });
      },
    },

    min: {
      method(limit) {
        return this.$_addRule({ name: 'min', method: 'length', args: { limit }, operator: '>=' });
      },
    },

    fileSize: {
      method(limit) {
        return this.$_addRule({ name: 'fileSize', args: { limit } });
      },
      args: [
        {
          name: 'limit',
          ref: true,
          assert: joi.number().integer().min(0),
          message: 'must be a positive integer',
        },
      ],
      validate(value, helpers, { limit }) {
        for (let f = 0; f < value.length; f++) {
          if (value?.[f] instanceof File) {
            if (value?.[f]?.size > limit) {
              return helpers.error('fileList.fileSize', {
                name: value?.[f]?.name,
                size: value?.[f]?.size,
                limit,
              });
            }
          }
        }

        return value;
      },
    },
    fileType: {
      method(...types) {
        return this.$_addRule({ name: 'fileType', args: { types } });
      },
      validate(value, helpers, { types }) {
        const loweredTypes = types?.map((t) => t.toLowerCase());

        for (let f = 0; f < value.length; f++) {
          if (value?.[f] instanceof File) {
            if (!loweredTypes.includes(value?.[f]?.type?.toLowerCase())) {
              return helpers.error('fileList.fileType', {
                name: value?.[f]?.name,
                type: value?.[f]?.type,
                types: loweredTypes.join(', '),
              });
            }
          }
        }

        return value;
      },
    },
  },
} as Joi.Extension);

export default Joi.extend(phoneExtension, fileListExtension);

export function defineJoiMessages(intl: IntlShape): Joi.LanguageMessages {
  return {
    'alternatives.all': intl.formatMessage({ defaultMessage:
      "'{{#label}}' does not match all of the required types",
    }),
    'alternatives.any': intl.formatMessage({ defaultMessage:
      "'{{#label}}' does not match any of the allowed types",
    }),
    'alternatives.match': intl.formatMessage({ defaultMessage:
      "'{{#label}}' does not match any of the allowed types",
    }),
    'alternatives.one': intl.formatMessage({ defaultMessage:
      "'{{#label}}' matches more than one allowed type",
    }),
    'alternatives.types': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be one of '{{#types}}",
    }),

    'any.custom': intl.formatMessage({ defaultMessage:
      "'{{#label}}' failed custom validation because '{{#error.message}}",
    }),
    'any.default': intl.formatMessage({ defaultMessage:
      "'{{#label}}' threw an error when running default method",
    }),
    'any.failover': intl.formatMessage({ defaultMessage:
      "'{{#label}}' threw an error when running failover method",
    }),
    'any.invalid': intl.formatMessage({ defaultMessage:
      "'{{#label}}' contains an invalid value",
    }),
    'any.only': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be '{if(#valids.length == 1, \"\", \"one of \")}{{#valids}}'",
    }),
    'any.ref': intl.formatMessage({ defaultMessage:
      "'{{#label}}' '{{#arg}}' references '{{:#ref}}' which '{{#reason}}",
    }),

    'any.required': intl.formatMessage({ defaultMessage:
      "'{{#label}}' is required",
    }),
    'any.unknown': intl.formatMessage({ defaultMessage:
      "'{{#label}}' is not allowed",
    }),

    'array.base': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be an array",
    }),
    'array.excludes': intl.formatMessage({ defaultMessage:
      "'{{#label}}' contains an excluded value",
    }),
    'array.hasKnown': intl.formatMessage({ defaultMessage:
      "'{{#label}}' does not contain at least one required match for type '{:#patternLabel}'",
    }),
    'array.hasUnknown': intl.formatMessage({ defaultMessage:
      "'{{#label}}' does not contain at least one required match",
    }),
    'array.includes': intl.formatMessage({ defaultMessage:
      "'{{#label}}' does not match any of the allowed types",
    }),
    'array.includesRequiredBoth': intl.formatMessage({ defaultMessage:
      "'{{#label}}' does not contain '{{#knownMisses}}' and '{{#unknownMisses}}' other required value(s)",
    }),
    'array.includesRequiredKnowns': intl.formatMessage({ defaultMessage:
      "'{{#label}}' does not contain '{{#knownMisses}}",
    }),
    'array.includesRequiredUnknowns': intl.formatMessage({ defaultMessage:
      "'{{#label}}' does not contain '{{#unknownMisses}}' required value(s)",
    }),
    'array.length': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must contain '{{#limit}}' items",
    }),
    'array.max': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must contain less than or equal to '{{#limit}}' items",
    }),
    'array.min': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must contain at least '{{#limit}}' items",
    }),
    'array.orderedLength': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must contain at most '{{#limit}}' items",
    }),
    'array.sort': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be sorted in '{#order}' order by '{{#by}}'",
    }),
    'array.sort.mismatching': intl.formatMessage({ defaultMessage:
      "'{{#label}}' cannot be sorted due to mismatching types",
    }),
    'array.sort.unsupported': intl.formatMessage({ defaultMessage:
      "'{{#label}}' cannot be sorted due to unsupported type '{#type}'",
    }),
    'array.sparse': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must not be a sparse array item",
    }),
    'array.unique': intl.formatMessage({ defaultMessage:
      "'{{#label}}' contains a duplicate value",
    }),

    'binary.base': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a buffer or a string",
    }),
    'binary.length': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be '{{#limit}}' bytes",
    }),
    'binary.max': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be less than or equal to '{{#limit}}' bytes",
    }),
    'binary.min': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be at least '{{#limit}}' bytes",
    }),

    'boolean.base': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a boolean",
    }),

    'date.base': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a valid date",
    }),
    'date.format': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be in '{msg(\"date.format.\" + #format) || #format}' format",
    }),
    'date.greater': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be greater than '{{:#limit}}",
    }),
    'date.less': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be less than '{{:#limit}}",
    }),
    'date.max': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be less than or equal to '{{:#limit}}",
    }),
    'date.min': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be greater than or equal to '{{:#limit}}",
    }),

    // Messages used in date.format
    'date.format.iso': intl.formatMessage({ defaultMessage: 'ISO 8601 date' }),
    'date.format.javascript': intl.formatMessage({ defaultMessage: 'timestamp or number of milliseconds' }),
    'date.format.unix': intl.formatMessage({ defaultMessage: 'timestamp or number of seconds' }),

    'function.arity': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must have an arity of '{{#n}}",
    }),
    'function.class': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a class",
    }),
    'function.maxArity': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must have an arity lesser or equal to '{{#n}}",
    }),
    'function.minArity': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must have an arity greater or equal to '{{#n}}",
    }),

    'object.and': intl.formatMessage({ defaultMessage:
      "'{{#label}}' contains '{{#presentWithLabels}}' without its required peers '{{#missingWithLabels}}",
    }),
    'object.assert': intl.formatMessage({ defaultMessage:
      "'{{#label}}' is invalid because '{if(#subject.key, `\"` + #subject.key + `\" failed to ` + (#message || \"pass the assertion test\"), #message || \"the assertion failed\")}'",
    }),
    'object.base': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be of type '{{#type}}",
    }),
    'object.instance': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be an instance of '{{:#type}}",
    }),
    'object.length': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must have '{{#limit}}' key'{if(#limit == 1, \"\", \"s\")}'",
    }),
    'object.max': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must have less than or equal to '{{#limit}}' key'{if(#limit == 1, \"\", \"s\")}'",
    }),
    'object.min': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must have at least '{{#limit}}' key'{if(#limit == 1, \"\", \"s\")}'",
    }),
    'object.missing': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must contain at least one of '{{#peersWithLabels}}",
    }),
    'object.nand': intl.formatMessage({ defaultMessage:
      "'{{:#mainWithLabel}}' must not exist simultaneously with '{{#peersWithLabels}}",
    }),
    'object.oxor': intl.formatMessage({ defaultMessage:
      "'{{#label}}' contains a conflict between optional exclusive peers '{{#peersWithLabels}}",
    }),
    'object.pattern.match': intl.formatMessage({ defaultMessage:
      "'{{#label}}' keys failed to match pattern requirements",
    }),
    'object.refType': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a Joi reference",
    }),
    'object.regex': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a RegExp object",
    }),
    'object.rename.multiple': intl.formatMessage({ defaultMessage:
      "'{{#label}}' cannot rename '{{:#from}}' because multiple renames are disabled and another key was already renamed to '{{:#to}}",
    }),
    'object.rename.override': intl.formatMessage({ defaultMessage:
      "'{{#label}}' cannot rename '{{:#from}}' because override is disabled and target '{{:#to}}' exists",
    }),
    'object.schema': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a Joi schema of '{{#type}}' type",
    }),
    'object.unknown': intl.formatMessage({ defaultMessage:
      "'{{#label}}' is not allowed",
    }),
    'object.with': intl.formatMessage({ defaultMessage:
      "'{{:#mainWithLabel}}' missing required peer '{{:#peerWithLabel}}",
    }),
    'object.without': intl.formatMessage({ defaultMessage:
      "'{{:#mainWithLabel}}' conflict with forbidden peer '{{:#peerWithLabel}}",
    }),
    'object.xor': intl.formatMessage({ defaultMessage:
      "'{{#label}}' contains a conflict between exclusive peers '{{#peersWithLabels}}",
    }),

    'number.base': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a number",
    }),
    'number.greater': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be greater than '{{#limit}}",
    }),
    'number.infinity': intl.formatMessage({ defaultMessage:
      "'{{#label}}' cannot be infinity",
    }),
    'number.integer': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be an integer",
    }),
    'number.less': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be less than '{{#limit}}",
    }),
    'number.max': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be less than or equal to '{{#limit}}",
    }),
    'number.min': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be greater than or equal to '{{#limit}}",
    }),
    'number.multiple': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a multiple of '{{#multiple}}",
    }),
    'number.negative': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a negative number",
    }),
    'number.port': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a valid port",
    }),
    'number.positive': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a positive number",
    }),
    'number.precision': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must have no more than '{{#limit}}' decimal places",
    }),
    'number.unsafe': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a safe number",
    }),

    'string.alphanum': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must only contain alpha-numeric characters",
    }),
    'string.base': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a string",
    }),
    'string.base64': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a valid base64 string",
    }),
    'string.creditCard': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a credit card",
    }),
    'string.dataUri': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a valid dataUri string",
    }),
    'string.domain': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must contain a valid domain name",
    }),
    'string.email': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a valid email",
    }),
    'string.empty': intl.formatMessage({ defaultMessage:
      "'{{#label}}' не может быть пустым",
    }),
    'string.guid': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a valid GUID",
    }),
    'string.hex': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must only contain hexadecimal characters",
    }),
    'string.hexAlign': intl.formatMessage({ defaultMessage:
      "'{{#label}}' hex decoded representation must be byte aligned",
    }),
    'string.hostname': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a valid hostname",
    }),
    'string.ip': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a valid ip address with a '{{#cidr}}' CIDR",
    }),
    'string.ipVersion': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a valid ip address of one of the following versions '{{#version}}' with a '{{#cidr}}' CIDR",
    }),
    'string.isoDate': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be in iso format",
    }),
    'string.isoDuration': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a valid ISO 8601 duration",
    }),
    'string.length': intl.formatMessage({ defaultMessage:
      "'{{#label}}' length must be '{{#limit}}' characters long",
    }),
    'string.lowercase': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must only contain lowercase characters",
    }),
    'string.max': intl.formatMessage({ defaultMessage:
      "'{{#label}}' length must be less than or equal to '{{#limit}}' characters long",
    }),
    'string.min': intl.formatMessage({ defaultMessage:
      "'{{#label}}' length must be at least '{{#limit}}' characters long",
    }),
    'string.normalize': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be unicode normalized in the '{{#form}}' form",
    }),
    'string.token': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must only contain alpha-numeric and underscore characters",
    }),
    'string.pattern.base': intl.formatMessage({ defaultMessage:
      "'{{#label}}' with value '{:[.]}' fails to match the required pattern: '{{#regex}}",
    }),
    'string.pattern.name': intl.formatMessage({ defaultMessage:
      "'{{#label}}' with value '{:[.]}' fails to match the '{{#name}}' pattern",
    }),
    'string.pattern.invert.base': intl.formatMessage({ defaultMessage:
      "'{{#label}}' with value '{:[.]}' matches the inverted pattern: '{{#regex}}",
    }),
    'string.pattern.invert.name': intl.formatMessage({ defaultMessage:
      "'{{#label}}' with value '{:[.]}' matches the inverted '{{#name}}' pattern",
    }),
    'string.trim': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must not have leading or trailing whitespace",
    }),
    'string.uri': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a valid uri",
    }),
    'string.uriCustomScheme': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a valid uri with a scheme matching the '{{#scheme}}' pattern",
    }),
    'string.uriRelativeOnly': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a valid relative uri",
    }),
    'string.uppercase': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must only contain uppercase characters",
    }),

    'symbol.base': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be a symbol",
    }),
    'symbol.map': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be one of '{{#map}}",
    }),

    // custom types
    'phone.base': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be valid phone number",
    }),
    'phone.allowedCountry': intl.formatMessage({ defaultMessage:
      "'{{#label}}' from country \"'{{#q}}'\" not allowed, use one of: '{{#a}}'",
    }),
    'phone.allowedType': intl.formatMessage({ defaultMessage:
      "'{{#label}}' type \"'{{#q}}'\" not allowed, use one of: '{{#a}}'",
    }),

    'fileList.base': intl.formatMessage({ defaultMessage:
      "'{{#label}}' must be FileList instance",
    }),
    'fileList.length': intl.formatMessage({ defaultMessage:
      "'{{#label}}' '{{#limit}}' files must be selected",
    }),
    'fileList.min': intl.formatMessage({ defaultMessage:
      "'{{#label}}' at least '{{#limit}}' file must be selected",
    }),
    'fileList.max': intl.formatMessage({ defaultMessage:
      "'{{#label}}' no more than '{{#limit}}' files must be selected",
    }),
    'fileList.fileSize': intl.formatMessage({ defaultMessage:
      "'{{#label}}' '{{#name}}' exceeds size limit '{{#limit}}({{#size}}) bytes",
    }),
    'fileList.fileType': intl.formatMessage({ defaultMessage:
      "'{{#label}}' '{{#name}}' type \"'{{#type}}'\" is not in allowed '{{#types}}",
    }),
  };
}

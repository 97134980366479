import { MouseEventHandler, useId, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller } from 'react-hook-form';
import { Button, Form, FormGroup, Input, FormFeedback, Label } from 'reactstrap';

import { formSubmitHandler } from './formSubmitHandler';
import type { FormMethods, TagNames } from '../types/api';

interface FormValues {
  email: string
}

interface RecoverFormProps extends FormMethods<FormValues>, TagNames {
  onCancel?: MouseEventHandler;
}

export const RecoverForm: React.FC<RecoverFormProps> = ({
  hookForm: {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  },
  disabled,
  onSubmit,
  onSubmitError,
  onCancel,
  components,
  loadingState,
}) => {
  const px = useId();
  const HeaderWrapper = components?.head ?? 'legend';
  const BodyWrapper = components?.body ?? 'div';
  const FooterWrapper = components?.foot ?? 'fieldset';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formCallback = useMemo(() => formSubmitHandler<FormValues>({ handleSubmit, onSubmit, onSubmitError }), [handleSubmit]);
  const isUpdating = loadingState?.isFetching || loadingState?.isLoading || isSubmitting;

  return (
    <Form
      autoComplete="off"
      onSubmit={formCallback}
      noValidate
    >
      <fieldset>
        {components?.head !== undefined && (
          <HeaderWrapper>
            <FormattedMessage defaultMessage="Account Recovery" />
          </HeaderWrapper>
        )}
        <BodyWrapper>
          <FormGroup>
            <Label htmlFor={`${px}email`}>
              <FormattedMessage defaultMessage="Email" />
            </Label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  bsSize="sm"
                  id={`${px}email`}
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.email)}
                  disabled={disabled}
                />
              )}
            />
            <FormFeedback>
              {errors?.email?.message}
            </FormFeedback>
          </FormGroup>
        </BodyWrapper>
      </fieldset>
      {components?.foot !== undefined && (
        <FooterWrapper>
          <div className="d-flex justify-content-end">
            {typeof onCancel === 'function' && (
              <Button
                disabled={isUpdating}
                type="button"
                onClick={onCancel}
              >
                <FormattedMessage defaultMessage="Cancel" />
              </Button>
            )}
            <Button
              type="submit"
              color="primary"
              className="ms-3"
              disabled={disabled || isUpdating || isSubmitting}
            >
              {isSubmitting && <FormattedMessage defaultMessage="Submitting..." />}
              {!isSubmitting && <FormattedMessage defaultMessage="Submit" />}
            </Button>
          </div>
        </FooterWrapper>
      )}
    </Form>
  );
};

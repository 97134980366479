import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { IntlState } from '../types/api';

const initialState = {
  locale: null,
  locale_messages: null,
  skipLocaleSelect: true,
} as Partial<IntlState>;

const intlSlice = createSlice({
  name: 'intl',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    localeChanged(state, { payload }: PayloadAction<IntlState>) {
      Object.assign(state, payload);
      localStorage.setItem('intl', JSON.stringify(state));
    },
    /* eslint-enable */
  },
});

export const { localeChanged } = intlSlice.actions;

export default intlSlice.reducer;

import { emptySplitApi } from '../api/fetchBaseQuery';
import type { ApiCollection, Subscription, GetSubscriptionsParams, SubscriptionPayload } from '../types/api';

export const subscriptionsApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({

    getSubscriptions: build.query<ApiCollection<Subscription>, GetSubscriptionsParams>({
      query: (params) => ({
        url: 'subscriptions',
        params,
      }),
      providesTags: (result) => {
        /** @link https://redux-toolkit.js.org/rtk-query/usage/mutations#revalidation-example */
        if (result && Array.isArray(result?.items)) {
          // successful query
          return [
            ...result.items.map(({ id }) => ({ type: 'Subscriptions' as const, id })),
            { type: 'Subscriptions', id: 'LIST' },
          ];
        }
        // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
        return [{ type: 'Subscriptions', id: 'LIST' }];
      },
    }),

    createSubscription: build.mutation<Subscription, SubscriptionPayload>({
      query: (payload) => ({
        url: 'subscriptions',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'Subscriptions', id: 'LIST' }, { type: 'UsageLimits', id: 'ME' }],
    }),

  }),
  overrideExisting: false,
});

export const {
  useGetSubscriptionsQuery,
  useCreateSubscriptionMutation,
} = subscriptionsApi;

import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

const { GIT_VERSION } = process.env;
const { GIT_AUTHOR_DATE } = process.env;

export const AppVersion = () => {
  const m = (chunk: ReactNode) => <strong>{chunk}</strong>;

  return (
    <div className="text-center mt-5">
      <small className="text-muted font-monospace lh-1">
        <FormattedMessage
          defaultMessage="ver. <m>{version}</m>, from <m>{date}</m>"
          values={{
            version: GIT_VERSION,
            date: GIT_AUTHOR_DATE,
            m,
          }}
        />
      </small>
    </div>
  );
};

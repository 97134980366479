import { useId } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import { Button, Form, FormGroup, InputGroup, InputGroupText, Input, FormFeedback, Label } from 'reactstrap';

import type { FormMethods } from '../types/api';

interface FormValues {
  filterSearch: string;
}

export const FilterInput: React.FC<FormMethods<FormValues>> = ({
  hookForm,
  onSubmit,
  disabled = false,
}) => {
  const { control, handleSubmit, formState: { errors }, watch, reset } = hookForm;
  const px = useId();
  const intl = useIntl();
  const watchFilterSearch = watch('filterSearch');
  const formCallback = (onSubmit) ? handleSubmit(onSubmit) : undefined;
  const onResetClick = () => reset({ filterSearch: '' });

  return (
    <Form
      onSubmit={formCallback}
      noValidate
    >
      <FormGroup>
        <InputGroup>
          <InputGroupText>
            <FontAwesomeIcon icon={solid('magnifying-glass')} />
            <Label htmlFor={`${px}search`} hidden>
              <FormattedMessage defaultMessage="Search" />
            </Label>
          </InputGroupText>
          <Controller
            name="filterSearch"
            control={control}
            render={({ field }) => (
              <Input
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                disabled={Boolean(disabled)}
                id={`${px}search`}
                innerRef={(e) => field.ref(e)}
                invalid={Boolean(errors?.filterSearch)}
                placeholder={intl.formatMessage({ defaultMessage: 'start typing...' })}
              />
            )}
          />
          {watchFilterSearch?.length > 0 && (
            <Button onClick={onResetClick} type="button">
              <FontAwesomeIcon icon={solid('close')} />
              <span className="visually-hidden">
                <FormattedMessage defaultMessage="Reset" />
              </span>
            </Button>
          )}
        </InputGroup>

        {/* doesn't work at the moment, keep it for screen readers */}
        <FormFeedback>
          {errors?.filterSearch?.message}
        </FormFeedback>
      </FormGroup>
    </Form>
  );
};

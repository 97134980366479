import { emptySplitApi } from '../api/fetchBaseQuery';
import type { Service, ApiCollection, PaginationParams, ServicePayload } from '../types/api';

export const servicesApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({

    getServices: build.query<ApiCollection<Service>, PaginationParams>({
      query: (params) => ({
        url: 'services',
        params,
      }),
      providesTags: (result) => {
        /** @link https://redux-toolkit.js.org/rtk-query/usage/mutations#revalidation-example */
        if (result && Array.isArray(result?.items)) {
          // successful query
          return [
            ...result.items.map(({ id }) => ({ type: 'Services' as const, id })),
            { type: 'Services', id: 'LIST' },
          ];
        }
        // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
        return [{ type: 'Services', id: 'LIST' }];
      },
    }),

    getServiceDetails: build.query<Service, Service['id']>({
      query: (id) => `services/${id}`,
      providesTags: (result, error, id) => [{ type: 'Services', id }],
    }),

    createService: build.mutation<Service, Omit<ServicePayload, 'id'>>({
      query: (payload) => ({
        url: 'services',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [
        { type: 'Services', id: 'LIST' },
        { type: 'UsageLimits', id: 'ME' },
      ],
    }),

    updateService: build.mutation<Service, ServicePayload>({
      query: ({ id, ...patch }) => ({
        url: `services/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Events', id: 'LIST' },
        { type: 'Services', id: 'LIST' },
        { type: 'Services', id },
      ],
    }),

    deleteService: build.mutation<void, Service['id']>({
      query: (id: string) => ({
        url: `services/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Services', id: 'LIST' },
        { type: 'Services', id },
        { type: 'UsageLimits', id: 'ME' },
      ],
    }),

  }),
});

export const {
  useGetServicesQuery,
  useGetServiceDetailsQuery,
  useCreateServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
} = servicesApi;

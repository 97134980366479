import { emptySplitApi } from '../api/fetchBaseQuery';
import { GetEventsParams, GetEventsMapParams, EventsMapResponse, Event, EventPayload, ApiCollection, ParticipantsListPayload, ServicesListPayload } from '../types/api';

export const calendarApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({

    getEventsMap: build.query<EventsMapResponse & { map?: Record<string, number> }, GetEventsMapParams>({
      query: (params) => ({
        url: 'calendar/events_map',
        params,
      }),
      transformResponse: (rawResult: EventsMapResponse) => {
        // TODO: switch to Map instead of object
        if (Array.isArray(rawResult?.items)) {
          return {
            ...rawResult,
            map: rawResult?.items.reduce((prev: Record<string, number>, item) => ({
              ...prev,
              [item.date]: item.total_counter,
            }), {}),
          };
        }

        return rawResult;
      },
      providesTags: () => [{ type: 'Events', id: 'MAP' }],
    }),

    getEvents: build.query<ApiCollection<Event>, GetEventsParams>({
      query: (params) => ({
        url: 'calendar',
        params,
      }),
      providesTags: (result) => {
        /** @link https://redux-toolkit.js.org/rtk-query/usage/mutations#revalidation-example */
        if (result && Array.isArray(result?.items)) {
          // successful query
          return [
            ...result.items.map(({ id }) => ({ type: 'Events' as const, id })),
            { type: 'Events', id: 'LIST' },
          ];
        }
        // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
        return [{ type: 'Events', id: 'LIST' }];
      },
    }),

    getEvent: build.query<Event, Event['id']>({
      query: (id) => `calendar/${id}`,
      providesTags: (result, error, id) => [{ type: 'Events', id }],
    }),

    createEvent: build.mutation<Event, Omit<EventPayload, 'id'>>({
      query: (payload) => ({
        url: 'calendar',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'Events', id: 'LIST' }, { type: 'Events', id: 'MAP' }, { type: 'UsageLimits', id: 'ME' }],
    }),

    updateEvent: build.mutation<Event, EventPayload>({
      query: ({ id, ...patch }) => ({
        url: `calendar/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Events', id: 'LIST' }, { type: 'Events', id }],
    }),

    updateOrganizer: build.mutation<Event, ParticipantsListPayload>({
      query: (payload) => ({
        url: 'calendar/organizer',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, { event_id }) => [{ type: 'Events', id: 'LIST' }, { type: 'Events', id: event_id }],
    }),

    updateAttendeeList: build.mutation<Event, ParticipantsListPayload>({
      query: (payload) => ({
        url: 'calendar/attendee',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, { event_id }) => [{ type: 'Events', id: 'LIST' }, { type: 'Events', id: event_id }],
    }),

    updateServiceList: build.mutation<Event, ServicesListPayload>({
      query: (payload) => ({
        url: 'calendar/service',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, { event_id }) => [{ type: 'Events', id: 'LIST' }, { type: 'Events', id: event_id }],
    }),

    deleteEvent: build.mutation<void, Event['id']>({
      query: (id: string) => ({
        url: `calendar/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Events', id: 'LIST' }, { type: 'Events', id }, { type: 'Events', id: 'MAP' }, { type: 'UsageLimits', id: 'ME' }],
    }),

  }),
  overrideExisting: false,
});

export const {
  useGetEventsMapQuery,
  useGetEventsQuery,
  useGetEventQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useUpdateOrganizerMutation,
  useUpdateAttendeeListMutation,
  useUpdateServiceListMutation,
  useDeleteEventMutation,
} = calendarApi;

import { ReactNode } from 'react';
import { Container, Col, Row } from 'reactstrap';

type BaseToolbarProps = {
  back?: ReactNode | null;
  forward?: ReactNode | null;
  title?: ReactNode | null;
}

export const BaseToolbar: React.FC<BaseToolbarProps> = ({
  back = null,
  forward = null,
  title = null,
}) => (
  <div className="vcd-toolbar">
    <Container fluid className="px-0">
      <Row className="align-items-center g-0">
        <Col>
          {back}
        </Col>
        {title && (
          <Col className="text-center fs-6 mb-0 py-2">
            {title}
          </Col>
        )}
        <Col className="text-end">
          {forward}
        </Col>
      </Row>
    </Container>
  </div>
);

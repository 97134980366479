import { IntlShape } from 'react-intl';

import Joi from '../core/ExtendedJoi';

const megabyte = 1048576;

export const getVcfSchema = (intl: IntlShape) => Joi.object({
  file: Joi.any().fileList().length(1).fileSize(megabyte)
    .label(intl.formatMessage({ defaultMessage: 'File' }))
    .messages({
      'fileList.base': intl.formatMessage({ defaultMessage: 'Select .vcf file from your device' }),
      'fileList.fileSize': intl.formatMessage({
        defaultMessage: "\"'{{#name}}'\" is too big('{{#size}}' bytes) must be less than {limit, number, :: unit/megabyte}",
      }, { limit: 1 }),
    }),
});

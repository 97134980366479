import { ObjectSchema } from 'joi';
import { IntlShape } from 'react-intl';

import Joi from '../core/ExtendedJoi';

/**
 * .empty('') required cause react hook form doesn't work with nulls but ''
 * .default(null) replaces '' with NULL to avoid extra transformation during API requests
 */
export const getFinancialRecordSchema = (intl: IntlShape): ObjectSchema => Joi.object({
  /* eslint-disable newline-per-chained-call */
  id: Joi.string().trim().max(100).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'ID' }),
  ),
  date: Joi.date().raw().empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Date' }),
  ),
  time: Joi.string().trim().empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Time' }),
  ),
  user_amount: Joi.number().empty(['', 0, '0']).default(null).label(
    intl.formatMessage({ defaultMessage: 'Amount' }),
  ),
  contact_amount: Joi.number().empty(['', 0, '0']).default(null).label(
    intl.formatMessage({ defaultMessage: 'Amount' }),
  ),
  discount: Joi.number().positive().empty(['', 0, '0']).default(null).label(
    intl.formatMessage({ defaultMessage: 'Discount' }),
  ),

  description: Joi.string().max(400).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Description' }),
  ),

  currency: Joi.object({
    value: Joi.string().length(3).default(null),
  }).unknown(true).label(
    intl.formatMessage({ defaultMessage: 'Currency' }),
  ),

  business_user: Joi.object({
    value: Joi.string().default(null),
  }).unknown(true).label(
    intl.formatMessage({ defaultMessage: 'User' }),
  ),
  contact: Joi.object({
    value: Joi.string().default(null),
  }).unknown(true).label(
    intl.formatMessage({ defaultMessage: 'Contact' }),
  ),
  event: Joi.object({
    value: Joi.string().default(null),
  }).unknown(true).label(
    intl.formatMessage({ defaultMessage: 'Event' }),
  ),
  service: Joi.object({
    value: Joi.string().default(null),
  }).unknown(true).label(
    intl.formatMessage({ defaultMessage: 'Service' }),
  ),
});

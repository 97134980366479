import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { tokenReceived, loggedOut } from './authSlice';
import type { RootState } from '../store';
import type { OAuthPasswordGrant, OAuthRefreshTokenGrant, OAuthCustomEmailGrant, OAuthToken, TokenErrorResponse, LoadingState, WeakPasswordResponse } from '../types/api';

const { API_HOST } = process.env;
const { OAUTH_CLIENT } = process.env;
const { OAUTH_SECRET } = process.env;

const tokenRequestPayload = {
  grant_type: 'password',
  client_id: OAUTH_CLIENT,
  client_secret: OAUTH_SECRET,
  scope: 'calendar:read calendar:write',
} as Partial<OAuthPasswordGrant>;

/** @link https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery */
export const baseQuery = fetchBaseQuery({
  baseUrl: `${API_HOST}/`,
  prepareHeaders: (headers, { getState }) => {
    const { access_token } = (getState() as RootState).auth;
    // If we have a token set in state, let's assume that we should be passing it.
    if (access_token) {
      headers.set('authorization', `Bearer ${access_token}`);
    }
    return headers;
  },
});

export const loadBinary = async (url: string, api: BaseQueryApi, accept = 'image/*') => baseQuery({
  url,
  headers: { Accept: accept },
  responseHandler: (response) => response.blob(),
}, api, {});

export const obtainTokenQuery = async (
  username: OAuthPasswordGrant['username'],
  password: OAuthPasswordGrant['password'],
): Promise<QueryReturnValue<OAuthToken, TokenErrorResponse>> => {
  let response;
  let data;
  try {
    response = await fetch(`${API_HOST}/access_token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...tokenRequestPayload,
        username,
        password,
      } as OAuthPasswordGrant),
    });

    data = await response.json();
  } catch (e) {
    return { error: { status: 'FETCH_ERROR', data: { message: String(e) } } };
  }

  if (response.ok) {
    return { data };
  }

  return { error: { status: 'SLIM_ERROR', data } };
};

export const recoverQuery = async (
  email: OAuthCustomEmailGrant['email'],
): Promise<QueryReturnValue<{ message: string; expires_in: OAuthToken['expires_in']; }, TokenErrorResponse>> => {
  let response;
  let data;
  const { client_id, client_secret } = tokenRequestPayload;
  try {
    response = await fetch(`${API_HOST}/access_token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        grant_type: 'email_custom',
        client_id,
        client_secret,
        email,
        scope: 'password:write_once',
      } as OAuthCustomEmailGrant),
    });

    data = await response.json();
  } catch (e) {
    return { error: { status: 'FETCH_ERROR', data: { message: String(e) } } };
  }

  if (response.ok) {
    return { data };
  }

  return { error: { status: 'SLIM_ERROR', data } };
};

interface NewPasswordPayload {
  new_password: string;
}

export const resetPasswordQuery = async (
  password: string,
  token: string,
  force_weak_password?: boolean,
): Promise<QueryReturnValue<boolean, TokenErrorResponse | WeakPasswordResponse>> => {
  let response;
  let data;
  try {
    response = await fetch(`${API_HOST}/business_users/password`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        new_password: password,
        force_weak_password: force_weak_password ?? false,
      } as NewPasswordPayload),
    });

    if (!response.ok) {
      data = await response.json();
    }
  } catch (e) {
    return { error: { status: 'FETCH_ERROR', data: { message: String(e) } } };
  }

  if (response.ok) {
    return { data: true };
  }

  return { error: { status: 'SLIM_ERROR', data } };
};

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const { refresh_token } = (api.getState() as RootState).auth;
    const tokenResult = await baseQuery({
      url: 'access_token',
      method: 'POST',
      body: {
        ...tokenRequestPayload,
        grant_type: 'refresh_token',
        refresh_token,
      } as OAuthRefreshTokenGrant,
    }, api, extraOptions) as QueryReturnValue<OAuthToken, FetchBaseQueryError>;
    if (tokenResult?.data) {
      // store the new token
      api.dispatch(tokenReceived(tokenResult.data));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(loggedOut());
    }
  }
  return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'BusinessUsers',
    'Contacts',
    'Events',
    'Finance',
    'ImportsContacts',
    'Links',
    'Plans',
    'Services',
    'Subscriptions',
    'Templates',
    'UsageLimits',
  ],
  endpoints: () => ({}),
});

const initRTKQueryLoadingState = {
  isUninitialized: false,
  isLoading: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
  error: undefined,
} as LoadingState;

export const mergeRtkQueryStates = (
  state: Partial<LoadingState>,
  ...states: Partial<LoadingState>[]
): LoadingState => [state, ...states].reduce((result, element, index: number) => {
  if (index === 0) return { ...result, ...element };

  return {
    isUninitialized: result.isUninitialized || element?.isUninitialized,
    // any loading
    isLoading: result.isLoading || element?.isLoading,
    // any fetching
    isFetching: result.isFetching || element?.isFetching,
    // all success
    isSuccess: result.isSuccess && element?.isSuccess,
    // any error
    isError: result.isError || element?.isError,
    error: result.error || element?.error,
  };
}, initRTKQueryLoadingState);

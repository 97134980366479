import { emptySplitApi } from '../api/fetchBaseQuery';
import type { Plan, GetPlansParams } from '../types/api';

export const plansApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({

    getPlans: build.query<Plan[], GetPlansParams>({
      query: (params) => ({
        url: 'plans',
        params,
      }),
      providesTags: (result) => {
        /** @link https://redux-toolkit.js.org/rtk-query/usage/mutations#revalidation-example */
        if (result && Array.isArray(result)) {
          // successful query
          return [
            ...result.map(({ id }) => ({ type: 'Plans' as const, id })),
            { type: 'Plans', id: 'LIST' },
          ];
        }
        // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
        return [{ type: 'Plans', id: 'LIST' }];
      },
    }),

  }),
  overrideExisting: false,
});

export const {
  useGetPlansQuery,
} = plansApi;

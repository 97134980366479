import { Fragment } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';
import { Input, InputProps } from 'reactstrap';

interface YearSelectOptionsProps {
  min: number;
  max: number;
  sortDir?: 'desc' | 'asc';
  px?: string;
}

export const YearSelectOptions: React.FC<YearSelectOptionsProps> = ({ min, max, sortDir = 'desc', px = 'yso' }) => (
  <Fragment>
    {Array.from({ length: Math.abs(max - min) + 1 }, (v, i) => {
      const year = (sortDir === 'desc') ? max - i : min + i;

      return <option value={year} key={`${px}${i}`}>{year}</option>;
    })}
  </Fragment>
);

export const MonthSelectOptions: React.FC<Pick<YearSelectOptionsProps, 'px'>> = ({ px = 'mso' }) => (
  <Fragment>
    {Array.from({ length: 12 }, (v, i) => (
      <option value={i + 1} key={`${px}${i}`}>
        {DateTime.fromObject({ month: i + 1 }).toLocaleString({ month: 'long' })}
      </option>
    ))}
  </Fragment>
);

interface DaySelectOptionsProps {
  year?: number | string | null;
  month?: number | string | null;
  px?: string;
}

export const DaySelectOptions: React.FC<DaySelectOptionsProps> = ({ year = null, month = null, px = 'dso' }) => {
  const { year: nowYear, month: nowMonth } = DateTime.now();
  const yearPart = (typeof year === 'string') ? parseInt(year, 10) : year;
  const monthPart = (typeof month === 'string') ? parseInt(month, 10) : month;
  const date = DateTime.fromObject({
    year: (typeof yearPart === 'number' && yearPart > 0) ? yearPart : nowYear,
    month: (typeof monthPart === 'number' && monthPart > 0) ? monthPart : nowMonth,
  });
  const days = (date.isValid) ? date.daysInMonth : 31;

  return (
    <Fragment>
      {Array.from({ length: days }, (v, i) => (
        <option value={i + 1} key={`${px}${i}`}>
          {i + 1}
        </option>
      ))}
    </Fragment>
  );
};

type YearSelectProps = {
  field: ControllerRenderProps & Omit<InputProps, 'type'>,
} & YearSelectOptionsProps;

export const YearSelect: React.FC<YearSelectProps> = ({ field, min, max, sortDir = 'desc', px = 'ys' }) => (
  <Input
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...field}
    innerRef={(e) => field.ref(e)}
    type="select"
  >
    <option value="">
      <FormattedMessage defaultMessage="Year" />
    </option>
    <YearSelectOptions
      min={min}
      max={max}
      sortDir={sortDir}
      px={px}
    />
  </Input>
);

type MonthSelectProps = {
  field: ControllerRenderProps & Omit<InputProps, 'type'>,
} & Pick<YearSelectOptionsProps, 'px'>;

export const MonthSelect: React.FC<MonthSelectProps> = ({ field, px = 'ms' }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Input {...field} type="select">
    <option value="">
      <FormattedMessage defaultMessage="Month" />
    </option>
    <MonthSelectOptions px={px} />
  </Input>
);

type DaySelectProps = {
  field: ControllerRenderProps & Omit<InputProps, 'type'>,
} & DaySelectOptionsProps;

export const DaySelect: React.FC<DaySelectProps> = ({ field, year = null, month = null, px = 'ds' }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Input {...field} type="select">
    <option value="">
      <FormattedMessage defaultMessage="Day" />
    </option>
    <DaySelectOptions year={year} month={month} px={px} />
  </Input>
);

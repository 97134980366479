import { emptySplitApi } from '../api/fetchBaseQuery';
import type { Contact, ContactBasic, ContactPayload, ContactProperty, ApiCollection, PaginationParams } from '../types/api';

export const contactsApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({

    getContacts: build.query<ApiCollection<ContactBasic>, PaginationParams>({
      query: (params) => ({
        url: 'contacts',
        params,
      }),
      providesTags: (result) => {
        /** @link https://redux-toolkit.js.org/rtk-query/usage/mutations#revalidation-example */
        if (result && Array.isArray(result?.items)) {
          // successful query
          return [
            ...result.items.map(({ id }) => ({ type: 'Contacts' as const, id })),
            { type: 'Contacts', id: 'LIST' },
          ];
        }
        // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
        return [{ type: 'Contacts', id: 'LIST' }];
      },
    }),

    getContactDetails: build.query<Contact, Contact['id']>({
      query: (id) => `contacts/${id}`,
      providesTags: (result, error, id) => [{ type: 'Contacts', id }],
    }),

    createContact: build.mutation<Contact, Omit<ContactPayload, 'id' | 'properties'>>({
      query: (payload) => ({
        url: 'contacts',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'Contacts', id: 'LIST' }, { type: 'UsageLimits', id: 'ME' }],
    }),

    updateContact: build.mutation<Contact, Omit<ContactPayload, 'properties'>>({
      query: ({ id, ...patch }) => ({
        url: `contacts/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Contacts', id: 'LIST' }, { type: 'Events', id: 'LIST' }, { type: 'Contacts', id }],
    }),

    deleteContact: build.mutation<void, Contact['id']>({
      query: (id: string) => ({
        url: `contacts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Contacts', id: 'LIST' }, { type: 'Contacts', id }, { type: 'UsageLimits', id: 'ME' }],
    }),

    createContactProperty: build.mutation<ContactProperty, Omit<ContactProperty, 'id'>>({
      query: (patch) => ({
        url: 'contacts/properties',
        method: 'POST',
        body: patch,
      }),
      invalidatesTags: (result, error, { contact_id }) => [{ type: 'Contacts', id: contact_id }],
    }),

    updateContactProperty: build.mutation<ContactProperty, ContactProperty>({
      query: ({ id, ...patch }) => ({
        url: `contacts/properties/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { contact_id }) => [{ type: 'Contacts', id: contact_id }],
    }),

    deleteContactProperty: build.mutation<void, Pick<ContactProperty, 'id' | 'contact_id'>>({
      query: ({ id }) => ({
        url: `contacts/properties/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { contact_id }) => [{ type: 'Contacts', id: contact_id }],
    }),

  }),
  overrideExisting: false,
});

export const {
  useGetContactsQuery,
  useGetContactDetailsQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useCreateContactPropertyMutation,
  useUpdateContactPropertyMutation,
  useDeleteContactPropertyMutation,
} = contactsApi;

import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Alert, AlertProps } from 'reactstrap';

export const DemoUserDisclaimer: React.FC<AlertProps> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Alert {...props} color="warning" className="alert-sm">
    <span className="me-2">
      <FontAwesomeIcon icon={solid('warning')} />
    </span>
    <FormattedMessage defaultMessage="You're using demo account. All data is fake so don't be shy check any button and form, you can't break anything." />
  </Alert>
);

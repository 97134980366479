import { useState, useId, useMemo, ReactNode } from 'react';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { BaseQueryFn, TypedUseQueryStateResult } from '@reduxjs/toolkit/dist/query/react';
import { generatePath, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import { Controller } from 'react-hook-form';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Col, Form, FormGroup, FormFeedback, Input, Label, Row, FormText, Card, CardBody, List, ListInlineItem, Collapse } from 'reactstrap';
import Mustache from 'mustache';

import { ApiCollection, Event, TagNames, PaginationParams, Template, FormMethods, TemplateFormValues, ContactBasic, Service, MustacheVars } from '../types/api';
import { ItemsList } from '../core/ItemsList';
import { LocaleSelect } from '../core/CurrencySelect';

interface TemplateListItemProps {
  item: Template,
}

const TemplateListItem: React.FC<TemplateListItemProps> = ({
  item,
}) => (
  <span className="d-flex align-items-center">
    <span className="flex-grow-1">
      {item.name}
    </span>
    <Button
      tag={Link}
      to={{
        pathname: generatePath('/templates/:id/edit', { id: item.id }),
      }}
      className="ms-2 p-0"
      color="link"
    >
      <span className="visually-hidden">
        <FormattedMessage
          defaultMessage="Edit Service"
        />
      </span>
      <FontAwesomeIcon icon={solid('pen-to-square')} />
    </Button>
  </span>
);

interface ToMustacheVarsProps {
  date?: DateTime;
  organizer?: ContactBasic;
  attendee?: ContactBasic;
  service?: Service;
  locale?: Template['locale'];
}

export const toMustacheVars = ({
  date,
  organizer,
  attendee,
  service,
  locale,
}: ToMustacheVarsProps): MustacheVars => {
  const dateFormat: DateTimeFormatOptions = DateTime.DATE_MED;
  const timeFormat: DateTimeFormatOptions = DateTime.TIME_SIMPLE;

  const vars: MustacheVars = {
    organizerName: organizer?.first_name ?? undefined,
    attendeeName: attendee?.first_name ?? undefined,
    serviceName: service?.template_var ?? service?.name ?? undefined,
  };

  if (DateTime.isDateTime(date) && date.isValid) {
    const localDate = date.setLocale(locale ?? date.locale);
    vars.eventDate = localDate.toLocaleString(dateFormat);
    vars.eventTime = localDate.toLocaleString(timeFormat);
    vars.eventDateRelative = localDate.toRelative() ?? undefined;
  }

  return vars;
};

interface RenderTemplateProps {
  text: string;
  vars?: MustacheVars;
}

const renderTemplate = ({
  text,
  vars,
}: RenderTemplateProps): string | null => {
  try {
    return (text) ? Mustache.render(text.replace(/(\\r\\n|\\n|\\r)/g, '\n'), vars) : null;
  } catch (e) {
    return text ?? null;
  }
};

interface TemplatePreviewProps extends TagNames<'container'> {
  template?: Pick<Template, 'text' | 'locale'>;
  vars?: MustacheVars;
  isLoading?: boolean;
  isCopied?: boolean;
  onCopy?: (text: string, result: boolean) => void;
}

export const TemplatePreview: React.FC<TemplatePreviewProps> = ({
  template,
  vars,
  isLoading = true,
  isCopied,
  onCopy,
  components,
}) => {
  const ContainerTag = components?.container ?? 'div';
  const msg = renderTemplate({
    text: template?.text ?? '',
    vars,
  });

  return (
    <ContainerTag>
      {isLoading && (
        <div className="placeholder-glow">
          <span className="placeholder col-12" />
          <span className="placeholder col-12" />
          <span className="placeholder col-3 mb-3" />
          <Button className="placeholder" block outline size="sm" />
        </div>
      )}
      {!isLoading && (
        <div className="d-flex justify-content-between align-items-end">
          <div
            style={{ whiteSpace: 'pre-line', verticalAlign: 'bottom' }}
            className="lh-sm"
          >
            <small>{msg}</small>
          </div>
          <div className="ms-2">
            <CopyToClipboard text={msg} options={{ format: 'text/plain' }} onCopy={onCopy}>
              <Button
                className="py-0 pe-0"
                color="link"
                active={isCopied}
                disabled={!msg}
              >
                <span className="visually-hidden">
                  <FormattedMessage defaultMessage="Copy" />
                </span>
                <FontAwesomeIcon icon={solid('copy')} size="1x" />
              </Button>
            </CopyToClipboard>
          </div>
        </div>
      )}
    </ContainerTag>
  );
};

interface TemplateFormProps extends FormMethods<TemplateFormValues>, TagNames<'head' | 'body' | 'foot'> {
  eventExample: Partial<Event>;
}

export const TemplateForm: React.FC<TemplateFormProps> = ({
  hookForm,
  onSubmit,
  components,
  eventExample,
  disabled: disabledInput,
}) => {
  const { control, handleSubmit, formState: { errors, isSubmitting }, watch } = hookForm;
  // form prefix
  const px = useId();
  const [placeholderVisible, setPlaceholderVisible] = useState(false);
  const onToggle = () => setPlaceholderVisible(!placeholderVisible);
  const HeaderWrapper = components?.head ?? 'legend';
  const BodyWrapper = components?.body ?? 'div';
  const FooterWrapper = components?.foot ?? 'fieldset';
  const formCallback = (onSubmit) ? handleSubmit(onSubmit) : undefined;
  const tomorrow = useMemo(() => DateTime.now().plus({ days: 1 }), []);
  const { locale: watchLocale, text: watchText } = watch();
  const mustacheVars = useMemo(() => toMustacheVars({
    date: (eventExample.start) ? DateTime.fromISO(eventExample.start) : undefined,
    organizer: eventExample.organizer?.[0],
    attendee: eventExample.attendee?.[0],
    service: eventExample.service?.[0],
    locale: watchLocale.value,
  }), [eventExample, watchLocale]);
  const msg = renderTemplate({
    text: watchText,
    vars: mustacheVars,
  });
  /* eslint-disable formatjs/no-literal-string-in-jsx */
  const mustacheVar: React.FC<ReactNode> = (chunk) => (
    <code>
      &#123;&#123;
      {chunk}
      &#125;&#125;
    </code>
  );
  /* eslint-enable formatjs/no-literal-string-in-jsx */
  const mustacheDoc: React.FC<ReactNode> = (chunk) => <a href="https://mustache.github.io/mustache.5.html" target="_blank" rel="noreferrer">{chunk}</a>;
  const disabled = disabledInput || isSubmitting;

  return (
    <Form
      autoComplete="off"
      onSubmit={formCallback}
      noValidate
    >
      {components?.head !== undefined && (
        <HeaderWrapper>
          <div className="h5">
            <FormattedMessage defaultMessage="New template" />
          </div>
        </HeaderWrapper>
      )}

      <BodyWrapper>
        <fieldset>
          <FormGroup>
            <Label htmlFor={`${px}name`}>
              <FormattedMessage defaultMessage="Name" />
            </Label>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  id={`${px}name`}
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.name)}
                  disabled={disabled}
                />
              )}
            />
            <FormFeedback>
              {errors?.name?.message}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <div className="d-flex justify-content-between align-items-center">
              <Label htmlFor={`${px}text`}>
                <FormattedMessage defaultMessage="Text" />
              </Label>
              <Button color="link" onClick={onToggle} className="p-0">
                <span className="visually-hidden">
                  <FormattedMessage defaultMessage="Show placeholders" />
                </span>
                {placeholderVisible && <FontAwesomeIcon icon={solid('chevron-circle-up')} />}
                {!placeholderVisible && <FontAwesomeIcon icon={solid('chevron-circle-down')} />}
              </Button>
            </div>
            <Collapse isOpen={placeholderVisible}>
              <div>
                <List type="inline">
                  <ListInlineItem>
                    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                    <Button size="sm" type="button">
                      &#123;&#123;organizerName&#125;&#125;
                    </Button>
                  </ListInlineItem>
                  <ListInlineItem>
                    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                    <Button size="sm" type="button">
                      &#123;&#123;attendeeName&#125;&#125;
                    </Button>
                  </ListInlineItem>
                  <ListInlineItem>
                    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                    <Button size="sm" type="button">
                      &#123;&#123;serviceName&#125;&#125;
                    </Button>
                  </ListInlineItem>
                  <ListInlineItem>
                    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                    <Button size="sm" type="button">
                      &#123;&#123;eventDate&#125;&#125;
                    </Button>
                  </ListInlineItem>
                  <ListInlineItem>
                    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                    <Button size="sm" type="button">
                      &#123;&#123;eventTime&#125;&#125;
                    </Button>
                  </ListInlineItem>
                  <ListInlineItem>
                    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                    <Button size="sm" type="button">
                      &#123;&#123;eventDateRelative&#125;&#125;
                    </Button>
                  </ListInlineItem>
                </List>
              </div>
            </Collapse>
            <Controller
              name="text"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  id={`${px}text`}
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.text)}
                  rows={4}
                  type="textarea"
                  disabled={disabled}
                />
              )}
            />
            <FormFeedback>
              {errors?.text?.message}
            </FormFeedback>
            <FormText>
              <FormattedMessage
                defaultMessage="Supports <code>mustache</code> placeholders. For advanced usage check <a>mustache documentation</a>."
                values={{
                  code: mustacheVar,
                  a: mustacheDoc,
                }}
              />
            </FormText>
          </FormGroup>
          <FormGroup>
            <div className="fs-6 mb-2">
              <FormattedMessage defaultMessage="Preview" />
            </div>
            <Card className="bg-light border-0">
              <CardBody
                style={{
                  whiteSpace: 'pre-line',
                  verticalAlign: 'bottom',
                  padding: '0.375rem 0.75rem',
                  minHeight: '2.375rem',
                }}
              >
                {msg}
              </CardBody>
            </Card>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={`${px}locale`}>
              <FormattedMessage defaultMessage="Locale" />
            </Label>
            <LocaleSelect
              hookForm={hookForm}
              fieldName="locale"
              isClearable={false}
              disabled={disabled}
            />
            <FormFeedback>
              {errors?.locale?.message}
            </FormFeedback>
            <FormText>
              <FormattedMessage
                defaultMessage="Changes how dates looks like. Example {date}"
                values={{
                  date: <mark>{tomorrow.toLocaleString(DateTime.DATETIME_SHORT, { locale: watchLocale.value })}</mark>,
                }}
              />
            </FormText>
          </FormGroup>
        </fieldset>
      </BodyWrapper>

      {components?.foot !== undefined && (
        <FooterWrapper>
          <fieldset className="d-flex justify-content-end">
            <Row>
              <Col>
                <Button>
                  <FormattedMessage defaultMessage="Cancel" />
                </Button>
              </Col>
              <Col>
                <Button type="submit" color="primary" disabled={disabled}>
                  <FormattedMessage defaultMessage="Save" />
                </Button>
              </Col>
            </Row>
          </fieldset>
        </FooterWrapper>
      )}
    </Form>
  );
};

interface TemplatesPanelsProps {
  items: TypedUseQueryStateResult<ApiCollection<Template>, PaginationParams, BaseQueryFn>;
}

export const TemplatesPanels: React.FC<TemplatesPanelsProps> = ({
  items,
}) => {
  const { isError, isLoading, isFetching, isSuccess } = items;
  const itemRender = (e: Template) => <TemplateListItem item={e} />;
  const countRender = (count: number, total: number) => (
    <div className="text-muted text-center">
      <FormattedMessage
        defaultMessage="Showed {num} of { total, plural, one {# template} other {# templates} }"
        // tagName="small"
        values={{
          num: count,
          total,
        }}
      />
    </div>
  );

  return (
    <ItemsList
      items={items?.data?.items ?? []}
      itemsTotal={items?.data?.items_total}
      itemRender={itemRender}
      countRender={countRender}
      loadingState={{
        isError,
        isFetching,
        isLoading,
        isSuccess,
      }}
    />
  );
};

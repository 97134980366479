import { ObjectSchema } from 'joi';
import { IntlShape } from 'react-intl';
import type { CountryCode, NumberType } from 'libphonenumber-js';

import Joi from '../core/ExtendedJoi';

export type PhoneValidationOptions = {
  country: CountryCode;
  allowedCountry?: CountryCode[];
  allowedType?: NumberType[];
}

/**
 * .empty('') required cause react hook form doesn't work with nulls but ''
 * .default(null) replaces '' with NULL to avoid extra transformation during API requests
 */
export const getContactSchema = (intl: IntlShape, phoneOptions: PhoneValidationOptions): ObjectSchema => Joi.object({
  /* eslint-disable newline-per-chained-call */
  id: Joi.string().trim().max(100).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'ID' }),
  ),
  kind: Joi.string().trim().empty('').default(null),
  first_name: Joi.string().trim().max(100).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'First Name' }),
  ),
  last_name: Joi.string().trim().max(100).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Last Name' }),
  ),
  middle_name: Joi.string().trim().max(100).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Middle Name' }),
  ),
  // gender: Joi.any().equal('M', 'F', 'O', 'N', 'U', '', null).empty('').default(null).label(
  gender: Joi.array().max(2).items(Joi.string().trim().empty('').default('')).label(
    intl.formatMessage({ defaultMessage: 'Gender' }),
  ),
  birth_year: Joi.number().integer().min(1604).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Birth Year' }),
  ),
  birth_month: Joi.number().integer().min(1).max(12).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Birth Month' }),
  ),
  birth_day: Joi.number().integer().min(1).max(31).empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Birth Day' }),
  ),
  properties: Joi.array().items(getContactPropertySchema(intl, phoneOptions)).sparse().label(
    intl.formatMessage({ defaultMessage: 'Properties' }),
  ),
});

export const getContactPhonePropertySchema = (intl: IntlShape, phoneOptions: PhoneValidationOptions): ObjectSchema => Joi.string().phone(phoneOptions).empty('').default(null).label(
  intl.formatMessage({ defaultMessage: 'Phone' }),
);

export const getContactEmailPropertySchema = (intl: IntlShape): ObjectSchema => Joi.string().email({ tlds: { allow: false } }).empty('').label(
  intl.formatMessage({ defaultMessage: 'Email' }),
);

export const getContactUriPropertySchema = (intl: IntlShape): ObjectSchema => Joi.string().uri({ allowRelative: false, allowQuerySquareBrackets: true }).default(null).label(
  intl.formatMessage({ defaultMessage: 'Website' }),
);

export const getContactAdrPropertySchema = (intl: IntlShape): ObjectSchema => Joi.array().items(Joi.string().max(100).empty('')).label(
  intl.formatMessage({ defaultMessage: 'Address' }),
);

export const getContactNotePropertySchema = (intl: IntlShape): ObjectSchema => Joi.string().trim().max(300).empty('').label(
  intl.formatMessage({ defaultMessage: 'Note' }),
);

export const getContactPropertySchema = (intl: IntlShape, phoneOptions: PhoneValidationOptions): ObjectSchema => Joi.object({
  id: Joi.string().empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Property ID' }),
  ),
  contact_id: Joi.string().empty('').default(null).label(
    intl.formatMessage({ defaultMessage: 'Contact ID' }),
  ),
  name: Joi.string().lowercase().required().label(
    intl.formatMessage({ defaultMessage: 'Property name' }),
  ),
  type: Joi.string().lowercase().required().label(
    intl.formatMessage({ defaultMessage: 'Property type' }),
  ),
  params: Joi.object().empty({}).default(null).label(
    intl.formatMessage({ defaultMessage: 'Property params' }),
  ),
  value: Joi.when('skip_validation', {
    not: true,
    then: Joi.alternatives().conditional('name', [
      {
        is: 'tel',
        then: getContactPhonePropertySchema(intl, phoneOptions).required(),
      },
      {
        is: 'email',
        then: getContactEmailPropertySchema(intl).required(),
      },
      {
        is: 'adr',
        then: getContactAdrPropertySchema(intl).required(),
      },
      {
        is: 'url',
        then: getContactUriPropertySchema(intl).required(),
      },
      {
        is: 'note',
        then: getContactNotePropertySchema(intl).required(),
        otherwise: Joi.string().trim().max(300).empty('').default(null),
      },
    ]),
  }),
  skip_validation: Joi.bool().optional(),
  read_only: Joi.bool().optional(),
  deleted: Joi.bool().optional(),
});

export const getJoiMessages = (intl: IntlShape) => ({
  'any.required': intl.formatMessage({
    defaultMessage: '\'{{#label}}\' is required',
  }),
});

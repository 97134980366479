import { useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { DateTime } from 'luxon';
import { BaseQueryFn, TypedUseQueryHookResult, TypedUseQueryStateResult } from '@reduxjs/toolkit/dist/query/react';
import { useIntl } from 'react-intl';
import Joi from 'joi';

import { BusinessUser, Event, FinancialRecordFormValues, FinancialRecordPayload } from '../types/api';
import { getFinancialRecordSchema } from './schema';
import { toReactSelectOption } from '../core/CurrencySelect';
import { servicesToReactSelectOptions } from '../services/Service';
import { toContactName } from '../contacts/Contact';

export const preparePayload = (data: FinancialRecordFormValues): FinancialRecordPayload => {
  const { id, date, time, business_user, contact, event, service, user_amount, contact_amount, discount, currency, description } = data;

  return {
    id,
    date: DateTime.fromISO(`${date}T${time}`).toISO(),
    currency: currency?.value,
    user_amount: (typeof user_amount === 'number') ? user_amount : null,
    contact_amount: (typeof contact_amount === 'number') ? contact_amount : null,
    discount: (typeof discount === 'number') ? discount : null,
    description,
    business_user_id: business_user?.value ?? null,
    contact_id: contact?.value ?? null,
    event_id: event?.value ?? null,
    service_id: service?.value ?? null,
  };
};

interface Props {
  defaultValues?: FinancialRecordFormValues;
  messages?: Joi.LanguageMessages;
  schema?: Joi.ObjectSchema;
  userResult?: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  eventResult?: TypedUseQueryHookResult<Event, Event['id'], BaseQueryFn>;
}

interface FnReturn {
  hookForm: UseFormReturn<FinancialRecordFormValues>;
  defaultValues: FinancialRecordFormValues;
}

export default function useFinanceForm({
  defaultValues,
  messages,
  schema,
  userResult,
  eventResult,
}: Props = {}): FnReturn {
  const intl = useIntl();
  const [defaults, setDefaults] = useState<FinancialRecordFormValues>({
    date: DateTime.now().toISODate(),
    time: DateTime.now().startOf('minute').toISOTime({
      suppressMilliseconds: true,
      includeOffset: false,
    }),
    currency: toReactSelectOption('USD'),
    user_amount: '0',
    discount: '',
    contact_amount: '',
    description: '',
    business_user: null,
    contact: null,
    event: null,
    service: null,
  });

  const defaultSchema = getFinancialRecordSchema(intl);
  const mergedSchema = (schema) ? defaultSchema.concat(schema) : defaultSchema;

  const hookForm = useForm<FinancialRecordFormValues>({
    defaultValues: Object.assign(
      defaults,
      defaultValues,
    ),
    resolver: joiResolver(mergedSchema, {
      messages,
      abortEarly: false,
    }),
  });

  const onDataLoaded = () => {
    let newDefaults = {};
    if (userResult?.isSuccess) {
      const user = userResult.data;
      const currency = user.default_currency ?? 'USD';
      const currencyOption = toReactSelectOption(currency);
      const userOption = {
        ...user,
        label: (user.contact)
          ? toContactName(intl, user.contact)
          : intl.formatMessage({ defaultMessage: 'User without mapped contact' }),
        value: userResult.data.id,
      };

      newDefaults = {
        ...newDefaults,
        currency: currencyOption,
        business_user: userOption,
      };
    }

    if (eventResult?.isSuccess) {
      const event = eventResult.data;
      const eventEnd = DateTime.fromISO(event.end);
      const eventOption = {
        attendee: event.attendee,
        label: event.summary ?? intl.formatMessage({ defaultMessage: 'Event without title' }),
        value: event.id,
      };
      const serviceOption = event?.service?.[0]
        ? servicesToReactSelectOptions(intl, [event?.service?.[0]])[0]
        : defaults.service;
      const contactOption = event.attendee?.[0]
        ? { label: toContactName(intl, event.attendee?.[0]), value: event.attendee?.[0]?.id }
        : null;

      newDefaults = {
        ...newDefaults,
        date: eventEnd.toISODate(),
        time: eventEnd.toISOTime({
          includeOffset: false,
          suppressMilliseconds: true,
        }),
        event: eventOption,
        contact: contactOption,
        service: serviceOption,
      };
    }

    if (Object.keys(newDefaults).length > 0) {
      setDefaults({ ...defaults, ...newDefaults });
      hookForm.reset({ ...defaults, ...newDefaults });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onDataLoaded, [eventResult?.fulfilledTimeStamp, userResult?.fulfilledTimeStamp]);

  return {
    hookForm,
    defaultValues: defaults,
  };
}

import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedDisplayName, FormattedMessage, useIntl } from 'react-intl';
import { Link, NavLink as RouterNavLink, useLocation } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Container, Navbar, Nav, NavItem, NavLink, Row, NavbarBrand, NavbarToggler, Collapse, Button, Badge } from 'reactstrap';

import { useAppDispatch, useAppSelector } from '../hooks';
import { localeChanged } from '../api/intlSlice';
import CalendarImg from '../assets/IMG_2575.jpg';
import ContactsImg from '../assets/IMG_2576.jpg';
import ServicesImg from '../assets/IMG_2577.jpg';
import TemplatesImg from '../assets/IMG_2578.jpg';
import BudgetImg from '../assets/IMG_2579.jpg';
import CalendarImgRu from '../assets/IMG_2580.jpg';
import ContactsImgRu from '../assets/IMG_2581.jpg';
import ServicesImgRu from '../assets/IMG_2582.jpg';
import TemplatesImgRu from '../assets/IMG_2583.jpg';
import BudgetImgRu from '../assets/IMG_2584.jpg';

export const HomeScreen: React.FC = ({
}) => {
  const intl = useIntl();
  const location = useLocation();
  const { locale_messages } = useAppSelector((state) => state.intl);
  const dispatch = useAppDispatch();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const aboutRef = useRef<HTMLElement>(null);
  const adoptersRef = useRef<HTMLElement>(null);
  const calendarRef = useRef<HTMLElement>(null);
  const contactsRef = useRef<HTMLElement>(null);
  const servicesRef = useRef<HTMLElement>(null);
  const templatesRef = useRef<HTMLElement>(null);
  const budgetRef = useRef<HTMLElement>(null);
  const pricesRef = useRef<HTMLElement>(null);
  const isRussian = locale_messages === 'ru';
  const calendarImgSrc = isRussian ? CalendarImgRu : CalendarImg;
  const contactsImgSrc = isRussian ? ContactsImgRu : ContactsImg;
  const servicesImgSrc = isRussian ? ServicesImgRu : ServicesImg;
  const templatesImgSrc = isRussian ? TemplatesImgRu : TemplatesImg;
  const budgetImgSrc = isRussian ? BudgetImgRu : BudgetImg;
  const calendarImgAlt = intl.formatMessage({ defaultMessage: 'Och.Onl App - Calendar page screenshot' });
  const contactsImgAlt = intl.formatMessage({ defaultMessage: 'Och.Onl App - Contacts page screenshot' });
  const servicesImgAlt = intl.formatMessage({ defaultMessage: 'Och.Onl App - Services page screenshot' });
  const templatesImgAlt = intl.formatMessage({ defaultMessage: 'Och.Onl App - Templates page screenshot' });
  const budgetImgAlt = intl.formatMessage({ defaultMessage: 'Och.Onl App - Budget page screenshot' });
  const earlyAdoptersLink: React.FC<ReactNode> = (chunk) => <Link to={{ hash: 'early-adopters' }}>{chunk}</Link>;
  const crmTerm: React.FC<ReactNode> = (chunk) => <abbr title={intl.formatMessage({ defaultMessage: 'Customer Relationship Management' })}>{chunk}</abbr>;
  const ast: React.FC<ReactNode> = (chunk) => <sup>{chunk}</sup>;
  const jumpDemo: React.FC<ReactNode> = (chunk) => <Link to={{ pathname: '/signin', search: isRussian ? 'demo=ru' : 'demo=en' }} rel="noindex nofollow">{chunk}</Link>;
  const registerLink: React.FC<ReactNode> = (chunk) => <Link to={{ pathname: '/register' }}>{chunk}</Link>;
  const toggleNav = () => setIsNavOpen(!isNavOpen);
  const onChangeLanguageClick = () => dispatch(localeChanged({
    locale_messages: locale_messages === 'en' ? 'ru' : 'en',
  }));

  /** on location change callback @link https://reactrouter.com/en/6.8.1/hooks/use-location */
  const onAnchorChange = useCallback(() => {
    switch (location.hash) {
      case '#early-adopters':
        adoptersRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case '#calendar':
        calendarRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case '#contacts':
        contactsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case '#services':
        servicesRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case '#templates':
        templatesRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case '#budget':
        budgetRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case '#prices':
        pricesRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case '#about':
      case '':
        aboutRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      default:
        // do nothing, empty or unknown anchor
    }
  }, [location]);

  useEffect(() => {
    onAnchorChange();
  }, [onAnchorChange]);

  return (
    <div className="home-screen">
      <header>
        <Container>
          <Navbar expand="md" light>
            <NavbarBrand to={{ pathname: '/' }} tag={RouterNavLink}>
              <span style={{ maxWidth: '130px' }} className="d-md-none text-truncate d-inline-block">
                <FormattedMessage defaultMessage="Och.Onl" description="Project localized name in headers" />
              </span>
              <span className="d-none d-md-inline-block">
                <FormattedMessage defaultMessage="Och.Onl" description="Project localized name in headers" />
              </span>
            </NavbarBrand>
            <Nav navbar className="ms-auto d-md-none">
              <NavItem>
                <Button outline type="button" onClick={onChangeLanguageClick} className="border-0">
                  <span className="visually-hidden">
                    {!isRussian && <FormattedDisplayName type="language" value="ru" />}
                    {isRussian && <FormattedDisplayName type="language" value="en" />}
                  </span>
                  <span className="fs-5">
                    {!isRussian && '🇷🇺'}
                    {isRussian && '🇬🇧'}
                  </span>
                </Button>
              </NavItem>
            </Nav>
            <Nav navbar className="mx-3 d-md-none">
              <NavItem>
                <NavLink to={{ pathname: '/signin' }} tag={RouterNavLink}>
                  <FormattedMessage defaultMessage="Sign In" />
                </NavLink>
              </NavItem>
            </Nav>
            <NavbarToggler onClick={toggleNav} />
            <Collapse navbar isOpen={isNavOpen}>
              <Nav navbar className="ms-auto">
                <NavItem>
                  <NavLink to={{ hash: 'early-adopters' }} tag={RouterNavLink}>
                    <FormattedMessage defaultMessage="Early Adopters" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={{ hash: 'calendar' }} tag={RouterNavLink}>
                    <FormattedMessage defaultMessage="Calendar" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={{ hash: 'contacts' }} tag={RouterNavLink}>
                    <FormattedMessage defaultMessage="Contacts" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={{ hash: 'services' }} tag={RouterNavLink}>
                    <FormattedMessage defaultMessage="Services" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={{ hash: 'templates' }} tag={RouterNavLink}>
                    <FormattedMessage defaultMessage="Templates" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={{ hash: 'budget' }} tag={RouterNavLink}>
                    <FormattedMessage defaultMessage="Budget" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={{ hash: 'prices' }} tag={RouterNavLink}>
                    <FormattedMessage defaultMessage="Prices" />
                  </NavLink>
                </NavItem>
                <NavItem className="d-none d-md-inline-block" active>
                  <NavLink to={{ pathname: '/signin' }} tag={RouterNavLink}>
                    <FormattedMessage defaultMessage="Sign In" />
                  </NavLink>
                </NavItem>
                <NavItem className="ms-md-5 d-none d-md-inline-block">
                  <Button outline type="button" onClick={onChangeLanguageClick} className="border-0">
                    <span className="visually-hidden">
                      {!isRussian && <FormattedDisplayName type="language" value="ru" />}
                      {isRussian && <FormattedDisplayName type="language" value="en" />}
                    </span>
                    <span className="fs-5">
                      {!isRussian && '🇷🇺'}
                      {isRussian && '🇬🇧'}
                    </span>
                  </Button>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </Container>
      </header>
      <main className="pt-5">
        <Container>
          <section
            id="about"
            ref={aboutRef}
            className="pt-5"
          >
            <h1 className="visually-hidden">
              <FormattedMessage
                defaultMessage="och.onl"
                description="Project localized name"
              />
            </h1>
            <h2 className="display-3 mb-5 text-center">
              <FormattedMessage
                defaultMessage="Scheduling app with <abr>CRM</abr> features for self-employed service providers"
                values={{
                  abr: crmTerm,
                }}
              />
            </h2>
            <div className="lead text-center text-md-start">
              <div>
                <FormattedMessage
                  defaultMessage="No fancy buttons so far, but it works great especially on mobile devices. Developed specifically for professionals in beauty sector(hair stylists, massage, barbers), but it also can be used by all kind of self-employed people who needs to schedule appointments."
                  values={{
                    link: earlyAdoptersLink,
                    ast,
                  }}
                />
              </div>
              <div>
                <FormattedMessage
                  defaultMessage="Tired of reading boring descriptions and don't want to sign up yet? Let's jump into the <link>demo account</link>. By the way, we give premiums to <adp>early adopters</adp> in your country! "
                  values={{
                    link: jumpDemo,
                    adp: earlyAdoptersLink,
                  }}
                />
              </div>
            </div>
          </section>
          <section
            id="early-adopters"
            ref={adoptersRef}
            className="pt-5"
          >
            <div className="d-md-inline-block position-relative text-center text-md-start">
              <h2 className="display-6 ">
                <FormattedMessage defaultMessage="Early Adopters Required" />
              </h2>
              <Badge color="primary" className="d-none d-md-inline position-absolute top-0 start-100" pill tag="small">
                <FormattedMessage defaultMessage="Free Premium Forever" />
              </Badge>
            </div>
            <div className="lead text-center text-md-start">
              <FormattedMessage
                defaultMessage="We would like to give away free premiums to early adopters since we need real users feedback as soon as possible. Become an early adopter is quite easy, all you need is to send short mail from address used during <lnk>registration</lnk>. In that mail please write where are you from and how you're gonna use our app. It's that simple."
                values={{
                  lnk: registerLink,
                }}
              />
            </div>
          </section>
          <section
            id="calendar"
            ref={calendarRef}
            className="pt-5"
          >
            <Card className="shadow">
              <Row>
                <Col md={{ size: 4, order: 0 }} xs={{ order: 1 }}>
                  <img
                    src={calendarImgSrc}
                    className="img-fluid d-md-none rounded-bottom"
                    alt={calendarImgAlt}
                  />
                  <img
                    src={calendarImgSrc}
                    className="img-fluid d-none d-md-block rounded-start"
                    alt={calendarImgAlt}
                  />
                </Col>
                <Col md={{ size: 8 }}>
                  <CardBody className="d-flex flex-column justify-content-center align-items-center h-100">
                    <CardTitle tag="h2" className="display-4">
                      <FormattedMessage defaultMessage="Calendar" />
                    </CardTitle>
                    <h3 className="visually-hidden">
                      <FormattedMessage defaultMessage="Key Features" />
                    </h3>
                    <ul className="lead">
                      <li>
                        <FormattedMessage defaultMessage="Feels like usual calendar app from the mobile device you use every day." />
                      </li>
                      <li>
                        <FormattedMessage defaultMessage="Generated messages for later use in conversations with your clients." />
                      </li>
                      <li>
                        <FormattedMessage defaultMessage="Mapped earnings and expenses." />
                      </li>
                    </ul>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </section>
          <section
            id="contacts"
            ref={contactsRef}
            className="pt-5"
          >
            <Card className="shadow">
              <Row>
                <Col md={{ size: 4, order: 1 }} xs={{ order: 1 }}>
                  <img
                    src={contactsImgSrc}
                    className="img-fluid d-md-none rounded-bottom"
                    alt={contactsImgAlt}
                  />
                  <img
                    src={contactsImgSrc}
                    className="img-fluid d-none d-md-block rounded-end"
                    alt={contactsImgAlt}
                  />
                </Col>
                <Col md={{ size: 8 }}>
                  <CardBody className="d-flex flex-column justify-content-center align-items-center h-100">
                    <CardTitle tag="h2" className="display-4">
                      <FormattedMessage defaultMessage="Contacts" />
                    </CardTitle>
                    <h3 className="visually-hidden">
                      <FormattedMessage defaultMessage="Key Features" />
                    </h3>
                    <ul className="lead">
                      <li>
                        <FormattedMessage defaultMessage="Guess what? Correct, it looks similar to contacts app from the typical mobile device." />
                      </li>
                      <li>
                        <FormattedMessage defaultMessage="It's possible to import all your contacts via single VCF file(iPhones and Androids can save contacts these way)." />
                      </li>
                      <li>
                        <FormattedMessage defaultMessage="Each client contains related events." />
                      </li>
                      <li>
                        <FormattedMessage defaultMessage="Each client contains earnings and expenses." />
                      </li>
                      <li>
                        <FormattedMessage defaultMessage="All sensitive data is hidden(phones, emails, addresses), so there is no reason to hack your account. Nobody can steal your clients." />
                      </li>
                    </ul>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </section>
          <section
            id="services"
            ref={servicesRef}
            className="pt-5"
          >
            <Card className="shadow">
              <Row>
                <Col md={{ size: 4, order: 0 }} xs={{ order: 1 }}>
                  <img
                    src={servicesImgSrc}
                    className="img-fluid d-md-none rounded-bottom"
                    alt={servicesImgAlt}
                  />
                  <img
                    src={servicesImgSrc}
                    className="img-fluid d-none d-md-block rounded-start"
                    alt={servicesImgAlt}
                  />
                </Col>
                <Col md={{ size: 8 }}>
                  <CardBody className="d-flex flex-column justify-content-center align-items-center h-100">
                    <CardTitle tag="h2" className="display-4">
                      <FormattedMessage defaultMessage="Services" />
                    </CardTitle>
                    <h3 className="visually-hidden">
                      <FormattedMessage defaultMessage="Key Features" />
                    </h3>
                    <ul className="lead">
                      <li>
                        <FormattedMessage defaultMessage="All services described in one place." />
                      </li>
                      <li>
                        <FormattedMessage defaultMessage="Beside name and description you can specify prices in any currency." />
                      </li>
                      <li>
                        <FormattedMessage defaultMessage="You can specify duration of the service in human readable format." />
                      </li>
                      <li>
                        <FormattedMessage defaultMessage="Services can be grouped." />
                      </li>
                      <li>
                        <FormattedMessage defaultMessage="Price list can be saved in PDF file." />
                      </li>
                    </ul>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </section>
          <section
            id="templates"
            ref={templatesRef}
            className="pt-5"
          >
            <Card className="shadow">
              <Row>
                <Col md={{ size: 4, order: 1 }} xs={{ order: 1 }}>
                  <img
                    src={templatesImgSrc}
                    className="img-fluid d-md-none rounded-bottom"
                    alt={templatesImgAlt}
                  />
                  <img
                    src={templatesImgSrc}
                    className="img-fluid d-none d-md-block rounded-end"
                    alt={templatesImgAlt}
                  />
                </Col>
                <Col md={{ size: 8 }}>
                  <CardBody className="d-flex flex-column justify-content-center align-items-center h-100">
                    <CardTitle tag="h2" className="display-4">
                      <FormattedMessage defaultMessage="Templates" />
                    </CardTitle>
                    <h3 className="visually-hidden">
                      <FormattedMessage defaultMessage="Key Features" />
                    </h3>
                    <ul className="lead">
                      <li>
                        <FormattedMessage defaultMessage="It's possible to generate notifications texts from templates." />
                      </li>
                      <li>
                        <FormattedMessage defaultMessage="Mustache language helps to substitute event date, time, client name, service name and even more into the message." />
                      </li>
                      <li>
                        <FormattedMessage defaultMessage="Each event in calendar contains generated text. Just copy it with one click and send the way you want(WhatsApp, Facebook, SMS)." />
                      </li>
                      <li>
                        <FormattedMessage defaultMessage="Adjust date formats by selecting appropriate locale." />
                      </li>
                    </ul>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </section>
          <section
            id="budget"
            ref={budgetRef}
            className="pt-5"
          >
            <Card className="shadow">
              <Row>
                <Col md={{ size: 4, order: 0 }} xs={{ order: 1 }}>
                  <img
                    src={budgetImgSrc}
                    className="img-fluid d-md-none rounded-bottom"
                    alt={budgetImgAlt}
                  />
                  <img
                    src={budgetImgSrc}
                    className="img-fluid d-none d-md-block rounded-start"
                    alt={budgetImgAlt}
                  />
                </Col>
                <Col md={{ size: 8 }}>
                  <CardBody className="d-flex flex-column justify-content-center align-items-center h-100">
                    <CardTitle tag="h2" className="display-4">
                      <FormattedMessage defaultMessage="Budget" />
                    </CardTitle>
                    <h3 className="visually-hidden">
                      <FormattedMessage defaultMessage="Key Features" />
                    </h3>
                    <ul className="lead">
                      <li>
                        <FormattedMessage defaultMessage="Shows all incomes and expenses in historical order." />
                      </li>
                      <li>
                        <FormattedMessage defaultMessage="All numbers are in accounting format." />
                      </li>
                      <li>
                        <FormattedMessage defaultMessage="Totals for selected period." />
                      </li>
                    </ul>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </section>
          <section
            id="prices"
            ref={pricesRef}
            className="py-5"
          >
            <h3 className="display-3 text-center text-md-start">
              <FormattedMessage defaultMessage="Prices" />
            </h3>
            <div className="lead text-center text-md-start">
              <FormattedMessage
                defaultMessage="Become an <adp>early adopter</adp> and get premium absolutely free forever. All you need is to write email about your experience once in a while."
                values={{
                  adp: earlyAdoptersLink,
                }}
              />
            </div>
          </section>
        </Container>
      </main>
      <footer className="position-static bg-dark text-white">
        <Container className="py-5 text-center text-muted" fluid>
          <div>
            <FormattedMessage defaultMessage="Here should be some legal info, doesn't matter so far." />
          </div>
          <div>
            {/* eslint-disable-next-line formatjs/no-emoji */}
            <FormattedMessage defaultMessage="&copy; 2022 &#8212; 2023" />
          </div>
        </Container>
      </footer>
    </div>
  );
};

export const HomeRouteElement: React.FC = ({
}) => {
  return (
    <HomeScreen
      // eslint-disable-next-line react/jsx-closing-bracket-location
    />
  );
};

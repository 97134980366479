import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

/** @link https://github.com/react-ga/react-ga */
export default function useGaPageview(): void {
  const location = useLocation();
  /** @link https://reactrouter.com/en/6.7.0/hooks/use-location */
  useEffect(() => {
    const path = `${location.pathname}${location.search}${location.hash}`;
    ReactGA.send({ hitType: 'pageview', page: path });
  }, [location]);
}

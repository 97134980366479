import { ElementType, ReactNode, useId, Fragment, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { TypedUseQueryStateResult, BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { MonthNumbers, DayNumbers, DateTime } from 'luxon';
import { Link, generatePath } from 'react-router-dom';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { useStore } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import { Button, Form, FormGroup, FormFeedback, Label, Input, Row, Col, List, ListGroup, ListGroupItem, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, FormText, InputProps, UncontrolledAlert } from 'reactstrap';

import { LoadingIndicator } from '../core/LoadingIndicator';
import { FilterInput } from '../core/FilterInput';
import { DobControl } from './DobControl';
import { loadBinary } from '../api/fetchBaseQuery';
import type { Contact, ContactBasic, ContactFormValues, ContactProperty, ContactPropertyFlags, FormMethods, TagNames, ApiCollection, OptionReactSelect, PaginationParams } from '../types/api';

interface ContactNameProps extends ContactBasic, TagNames<'first' | 'rest'> {}

export const ContactName: React.FC<ContactNameProps> = ({ id, last_name, first_name, middle_name, fn, components }) => {
  const FirstWrapper = components?.first ?? Fragment;
  const RestWrapper = components?.rest ?? Fragment;
  const px = useId();
  const keys = [`${px}-${id}-ln`, `${px}-${id}-gn`, `${px}-${id}-mn`];
  const name = [last_name, first_name, middle_name]
    .filter((v) => Boolean(v?.trim()))
    .map((v, i) => {
      const W = (i === 0) ? FirstWrapper : RestWrapper;
      const str = (i === 0) ? v?.trim() : ` ${v?.trim()}`;
      return <W key={keys[i]}>{str}</W>;
    });

  if (name?.length > 0) return <span>{name}</span>;

  return <FirstWrapper key={`${px}-${id}-fn`}>{fn}</FirstWrapper>;
};

const reduceFirstCharMap = (carry: Record<string, ContactBasic[]>, item: ContactBasic): Record<string, ContactBasic[]> => {
  const { last_name, first_name, middle_name, fn } = item;
  const char = [last_name, first_name, middle_name, fn]
    .find((str) => typeof str === 'string' && str?.trim()?.length > 0)
    ?.charAt(0)
    ?.toLocaleUpperCase()
    ?? '#';

  if (Array.isArray(carry?.[char])) {
    carry[char].push(item);
  } else {
    return Object.assign(carry, { [char]: [item] });
  }

  return carry;
};

export interface toContactNameOptions {
  maxElements?: number;
  maxChars?: Array<null | number>;
}

interface ContactNameShortProps {
  contact: Pick<ContactBasic, 'first_name' | 'last_name' | 'middle_name' | 'fn'>,
  options?: toContactNameOptions,
}

export const ContactNameShort: React.FC<ContactNameShortProps> = ({
  contact,
  options,
}) => {
  const {
    maxElements = 2,
    maxChars = [null, 1],
  }: toContactNameOptions = options ?? {};
  const { last_name, first_name, middle_name, fn } = contact;
  // consider formatted name(fn) as fallback value
  type reduceResult = { added: number; result: string; }
  const { result } = [last_name, first_name, middle_name].reduce((prev: reduceResult, name: string) => {
    if (prev.added >= maxElements || typeof name !== 'string' || name.trim() === '') return prev;

    const trimmed = name.trim();
    const substrLength = (Array.isArray(maxChars) && typeof maxChars?.[prev.added] === 'number') ? maxChars?.[prev.added] : null;
    const str = (substrLength !== null && substrLength < trimmed.length) ? `${trimmed.substring(0, substrLength)}.` : trimmed;

    return Object.assign(prev, {
      added: prev.added + 1,
      result: (prev.added > 0) ? `${prev.result} ${str}` : str,
    });
  }, { added: 0, result: '' });

  if (result) return result;
  if (fn?.trim()) return fn.trim();

  return <FormattedMessage defaultMessage="Unnamed Contact" />;
};

export const toContactName = (
  intl: IntlShape,
  contact: Pick<ContactBasic, 'first_name' | 'last_name' | 'middle_name' | 'fn'>,
  options?: toContactNameOptions,
): string => {
  const {
    maxElements = 2,
    maxChars = [null, 1],
  }: toContactNameOptions = options ?? {};
  const { last_name, first_name, middle_name, fn } = contact;
  // consider formatted name(fn) as fallback value
  type reduceResult = { added: number; result: string; }
  const { result } = [last_name, first_name, middle_name].reduce((prev: reduceResult, name: string) => {
    if (prev.added >= maxElements || typeof name !== 'string' || name.trim() === '') return prev;

    const trimmed = name.trim();
    const substrLength = (Array.isArray(maxChars) && typeof maxChars?.[prev.added] === 'number') ? maxChars?.[prev.added] : null;
    const str = (substrLength !== null && substrLength < trimmed.length) ? `${trimmed.substring(0, substrLength)}.` : trimmed;

    return Object.assign(prev, {
      added: prev.added + 1,
      result: (prev.added > 0) ? `${prev.result} ${str}` : str,
    });
  }, { added: 0, result: '' });

  return result || fn?.trim() || intl.formatMessage({ defaultMessage: 'Unnamed Contact' });
};

export interface toContactInlineListOptions {
  join?: string;
  maxLength?: number;
}

export const toContactInlineList = (
  intl: IntlShape,
  contacts: ContactBasic[],
  options?: toContactInlineListOptions,
): string => {
  const {
    join = ', ',
    maxLength = 3,
  } = options ?? {};

  if (contacts.length > maxLength) {
    const contactsList = contacts
      .slice(0, maxLength)
      .map((contact) => toContactName(intl, contact))
      .join(join);
    return intl.formatMessage(
      { defaultMessage: '{contacts} and {rest, plural, one {# other contact} other {# other contacts}}' },
      { contacts: contactsList, rest: contacts.length - maxLength },
    );
  }

  return contacts
    .map((contact) => toContactName(intl, contact))
    .join(join);
};

export const contactsToReactSelectOptions = (
  intl: IntlShape,
  contacts?: ContactBasic[],
  me?: ContactBasic['id'] | null,
  filter?: (e: ContactBasic) => boolean,
  filterDisabled?: (e: ContactBasic) => boolean,
): OptionReactSelect[] | null => {
  if (!Array.isArray(contacts)) return null;

  const shortNameOptions = {
    maxElements: 3,
    maxChars: [null, 1, 1],
  };
  const filtered = (typeof filter === 'function') ? contacts.filter(filter) : contacts;

  return filtered.map((e: ContactBasic) => {
    const { id, first_name, last_name, middle_name, fn } = e;
    const disabled = (typeof filterDisabled === 'function') ? filterDisabled(e) : false;
    const label = (id === me)
      ? intl.formatMessage(
        {
          defaultMessage: '{contact_name} (me)',
        },
        { contact_name: toContactName(intl, { first_name, last_name, middle_name, fn }, shortNameOptions) },
      )
      : toContactName(intl, { first_name, last_name, middle_name, fn }, shortNameOptions);

    return {
      ...e,
      label,
      value: id,
      disabled,
    };
  });
};

const emptyProperty = {
  id: '',
  contact_id: '',
  name: 'tel',
  type: 'text',
  params: {},
  value: '',
  skip_validation: false,
  read_only: false,
  deleted: false,
} as ContactProperty & ContactPropertyFlags;

interface ContactFormPropListProps extends FormMethods<ContactFormValues> {
  propName: ContactProperty['name'];
  legend?: ReactNode | null;
  inputLabel?: ReactNode;
  inputProps?: InputProps;
  formText?: ReactNode;
  addButton?: ReactNode;
}

const ContactFormPropsList: React.FC<ContactFormPropListProps> = ({
  hookForm,
  propName,
  legend = null,
  inputLabel = null,
  inputProps = {},
  formText = null,
  addButton = null,
  disabled,
}) => {
  const { control, setValue, formState: { errors }, resetField, watch, unregister } = hookForm;
  const { id: contactId, properties } = watch();
  const px = useId();
  let renderCounter = 0;

  return (
    <fieldset className="mb-5">
      <legend className="fs-6 fw-normal lh-base">
        {legend}
      </legend>
      {properties?.map((v, i) => {
        if (v?.name?.toLowerCase() !== propName || v?.deleted) return null;

        const identifier = (v?.id) ? `${px}prop-${v.id}` : `${px}prop-${i}`;
        const fieldName = `properties.${i}` as `properties.${number}`;
        const fieldValueName = `${fieldName}.value` as `properties.${number}.value`;
        const hasErrors = Boolean(errors?.properties?.[i]);
        const errorMessage = errors?.properties?.[i]?.value?.message ?? null;
        renderCounter += 1;

        return (
          <div className="d-flex align-items-baseline" key={identifier}>
            <div className="flex-shrink-1">
              <Button
                type="button"
                color="link"
                className="text-danger"
                size="sm"
                onClick={() => {
                  if (v?.id) {
                    // requires API request
                    setValue(fieldName, { ...v, skip_validation: true, deleted: true }, { shouldDirty: true });
                  } else {
                    unregister(fieldName);
                  }
                  return false;
                }}
              >
                <FontAwesomeIcon icon={solid('circle-minus')} />
                <span className="visually-hidden">
                  <FormattedMessage defaultMessage="Delete" />
                </span>
              </Button>
            </div>
            <FormGroup className="w-100 px-1">
              <Label htmlFor={identifier} hidden>
                {inputLabel}
              </Label>
              <Controller
                name={fieldValueName}
                control={control}
                render={({ field }) => (
                  <Input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...inputProps}
                    id={identifier}
                    innerRef={(e) => field.ref(e)}
                    invalid={hasErrors}
                    readOnly={v?.read_only}
                  />
                )}
              />
              {typeof errorMessage === 'string' && (
                <FormFeedback>
                  {errorMessage}
                </FormFeedback>
              )}
              {/* show tip only for the first input of the same type */}
              {renderCounter === 1 && formText}
            </FormGroup>
            <div className="flex-shrink-1">
              <UncontrolledDropdown>
                <DropdownToggle color="link" size="sm">
                  <FontAwesomeIcon icon={solid('ellipsis-vertical')} />
                  <span className="visually-hidden">
                    <FormattedMessage defaultMessage="Actions" />
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      resetField(fieldName);
                    }}
                  >
                    <FormattedMessage defaultMessage="Revert" />
                  </DropdownItem>
                  {v?.read_only === true && (
                    <DropdownItem
                      onClick={() => {
                        setValue(fieldName, { ...v, value: '', skip_validation: false, read_only: false }, { shouldDirty: true });
                        // setFocus(fieldName); // cannot set focus because doesn't have ref yet
                      }}
                    >
                      <FormattedMessage defaultMessage="Edit" />
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        );
      })}
      <Button
        type="button"
        color="link"
        size="sm"
        disabled={disabled}
        onClick={() => {
          const fieldName = `properties.${properties?.length ?? 0}` as `properties.${number}`;
          // const fieldName = `properties.1`;
          const fieldValue = {
            ...emptyProperty,
            contact_id: contactId,
            name: propName,
          };
          // doesn't make state dirty
          setValue(fieldName, fieldValue, { shouldDirty: true });
        }}
      >
        <FontAwesomeIcon icon={solid('circle-plus')} />
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
        {' '}
        {addButton}
      </Button>
    </fieldset>
  );
};

interface ContactFormProps extends FormMethods<ContactFormValues>, TagNames {}

export const ContactForm: React.FC<ContactFormProps> = ({
  hookForm,
  onSubmit,
  components,
  disabled,
}) => {
  const { control, handleSubmit, formState: { errors } } = hookForm;
  // form prefix
  const px = useId();
  const intl = useIntl(); // handy for plain text values like input placeholders
  const HeaderWrapper = components?.head ?? 'div';
  const BodyWrapper = components?.body ?? 'div';
  const FooterWrapper = components?.foot ?? 'div';
  const formCallback = (onSubmit) ? handleSubmit(onSubmit) : undefined;

  return (
    <Form
      autoComplete="off"
      onSubmit={formCallback}
      noValidate
    >
      {components?.head !== undefined && (
        <HeaderWrapper>
          <div className="h5">
            <FormattedMessage defaultMessage="New contact" />
          </div>
        </HeaderWrapper>
      )}
      <BodyWrapper>
        {/* name related fields */}
        <fieldset>
          <FormGroup>
            <Label htmlFor={`${px}ln`} hidden>
              <FormattedMessage defaultMessage="Last Name" />
            </Label>
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  innerRef={(e) => field.ref(e)}
                  placeholder={intl.formatMessage({ defaultMessage: 'Last Name' })}
                  invalid={Boolean(errors?.last_name)}
                  id={`${px}ln`}
                  disabled={disabled}
                />
              )}
            />
            <FormFeedback>
              {errors?.last_name?.message}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={`${px}fn`} hidden>
              <FormattedMessage defaultMessage="First Name" />
            </Label>
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  innerRef={(e) => field.ref(e)}
                  placeholder={intl.formatMessage({ defaultMessage: 'First Name' })}
                  invalid={Boolean(errors?.first_name)}
                  id={`${px}fn`}
                  disabled={disabled}
                />
              )}
            />
            <FormFeedback>
              {errors?.first_name?.message}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={`${px}mn`} hidden>
              <FormattedMessage defaultMessage="Middle Name" />
            </Label>
            <Controller
              name="middle_name"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  innerRef={(e) => field.ref(e)}
                  placeholder={intl.formatMessage({ defaultMessage: 'Middle Name' })}
                  invalid={Boolean(errors?.middle_name)}
                  id={`${px}mn`}
                  disabled={disabled}
                />
              )}
            />
            <FormFeedback>
              {errors?.middle_name?.message}
            </FormFeedback>
          </FormGroup>
        </fieldset>
        {/* gender */}
        <fieldset>
          <FormGroup className="d-flex justify-content-between align-items-baseline">
            <Label htmlFor={`${px}g`} className="me-5">
              <FormattedMessage defaultMessage="Gender" />
            </Label>
            <Controller
              name="gender.0"
              control={control}
              render={({ field: { value, ref, ...rest } }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...rest}
                  id={`${px}g`}
                  innerRef={(e) => ref(e)}
                  invalid={Boolean(errors?.gender)}
                  type="select"
                  value={value}
                  disabled={disabled}
                >
                  <option
                    value=""
                    aria-label={intl.formatMessage({ defaultMessage: 'Has not been specified' })}
                  />
                  <option value="M">
                    <FormattedMessage defaultMessage="male" />
                  </option>
                  <option value="F">
                    <FormattedMessage defaultMessage="female" />
                  </option>
                  <option value="O">
                    <FormattedMessage defaultMessage="other" />
                  </option>
                  <option value="N">
                    <FormattedMessage defaultMessage="none or not applicable" />
                  </option>
                  <option value="U">
                    <FormattedMessage defaultMessage="unknown" />
                  </option>
                </Input>
              )}
            />
            <FormFeedback>
              {errors?.gender?.message}
            </FormFeedback>
          </FormGroup>
        </fieldset>
        <fieldset className="mb-5">
          <legend className="fs-6 fw-normal lh-base">
            <FormattedMessage defaultMessage="Date of birth" />
          </legend>
          <DobControl
            hookForm={hookForm}
            disabled={disabled}
          />
        </fieldset>
        {/* phone dynamic fields */}
        <ContactFormPropsList
          hookForm={hookForm}
          propName="tel"
          legend={<FormattedMessage defaultMessage="Phones" />}
          inputLabel={<FormattedMessage defaultMessage="Phone number" />}
          inputProps={{
            placeholder: intl.formatMessage({ defaultMessage: 'Phone' }),
            type: 'tel',
          }}
          addButton={<FormattedMessage defaultMessage="add phone" />}
          disabled={disabled}
        />

        {/* email dynamic fields */}
        <ContactFormPropsList
          hookForm={hookForm}
          propName="email"
          legend={<FormattedMessage defaultMessage="Emails" />}
          inputLabel={<FormattedMessage defaultMessage="Email address" />}
          inputProps={{
            placeholder: intl.formatMessage({ defaultMessage: 'Email' }),
            type: 'email',
          }}
          addButton={<FormattedMessage defaultMessage="add email" />}
          disabled={disabled}
        />

        {/* url dynamic fields */}
        <ContactFormPropsList
          hookForm={hookForm}
          propName="url"
          legend={<FormattedMessage defaultMessage="Websites" />}
          inputLabel={<FormattedMessage defaultMessage="Website" />}
          inputProps={{
            placeholder: intl.formatMessage({ defaultMessage: 'Website' }),
          }}
          addButton={<FormattedMessage defaultMessage="add website" />}
          disabled={disabled}
        />

        {/* note fields */}
        <ContactFormPropsList
          hookForm={hookForm}
          propName="note"
          legend={<FormattedMessage defaultMessage="Notes" />}
          inputLabel={<FormattedMessage defaultMessage="Note" />}
          inputProps={{
            placeholder: intl.formatMessage({ defaultMessage: 'Note' }),
            rows: 5,
            type: 'textarea',
          }}
          formText={(
            <FormText>
              <small className="lh-1">
                <FormattedMessage defaultMessage="Please don't write any sensitive data(phones, emails, credit cards etc.) here. This note won't be hidden or masked after saving. Beside you might want to share your notes afterwards." />
              </small>
            </FormText>
          )}
          addButton={<FormattedMessage defaultMessage="add note" />}
          disabled={disabled}
        />
      </BodyWrapper>
      {components?.foot !== undefined && (
        <FooterWrapper>
          <fieldset className="d-flex justify-content-end">
            <Row>
              <Col>
                <Button>
                  <FormattedMessage defaultMessage="Cancel" />
                </Button>
              </Col>
              <Col>
                <Button type="submit" color="primary">
                  <FormattedMessage defaultMessage="Save" />
                </Button>
              </Col>
            </Row>
          </fieldset>
        </FooterWrapper>
      )}
    </Form>
  );
};

interface ContactListProps {
  contacts: ContactBasic[];
  selected?: string[] | null;
  groupByFirstChar?: boolean | null;
  onSelect?: (id: string) => void | null;
  me?: ContactBasic['id'] | null;
}

/**
 * TODO: add onselect?: (id: string) => void property
 */
export const ContactList: React.FC<ContactListProps> = ({
  contacts,
  selected = null,
  groupByFirstChar = true,
  me,
}) => {
  const hasCheck = Array.isArray(selected);
  const ListItemTag = hasCheck ? 'li' : Link;
  const NameWrapper = hasCheck ? Label : Fragment;
  const getHref = hasCheck ? () => null : (id: string) => generatePath('/contacts/:id', { id });
  const px = useId();

  const renderContactItem = (contact: Contact) => (
    <ListGroupItem
      key={`${px}-contact-${contact.id}`}
      to={getHref(contact.id)}
      tag={ListItemTag}
    >
      {/* render checkbox when need to select */}
      {hasCheck && <Input type="checkbox" className="me-2" />}
      <NameWrapper>
        <div className="d-flex justify-content-between">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ContactName {...contact} components={{ first: 'strong' }} />
          {contact.id === me && (
            <mark className="contact-me text-muted p-0 bg-transparent">

              <FormattedMessage defaultMessage="me" />
            </mark>
          )}
        </div>
      </NameWrapper>
    </ListGroupItem>
  );

  if (groupByFirstChar) {
    const contactsMap = contacts?.reduce(reduceFirstCharMap, {});

    return (
      <Fragment>
        {Object.keys(contactsMap).sort().map((char) => (
          <ListGroup
            flush
            className="mb-3"
            key={`${px}-group-${char}`}
          >
            <ListGroupItem
              href={`#char-${char}`}
              id={`char-${char}`}
              tag="a"
            >
              <small className="text-muted">
                {char}
              </small>
            </ListGroupItem>
            {contactsMap[char].map(renderContactItem)}
          </ListGroup>
        ))}
      </Fragment>
    );
  }

  return (
    <ListGroup flush>
      {contacts.map(renderContactItem)}
    </ListGroup>
  );
};

interface ContactPanelProps extends FormMethods<{ filterSearch: string; }, 'searchForm'> {
  contacts: TypedUseQueryStateResult<ApiCollection<ContactBasic>, PaginationParams, BaseQueryFn>;
  groupByFirstChar?: boolean | null;
  me?: ContactBasic['id'] | null;
  searchVisible?: boolean;
}

export const ContactPanel: React.FC<ContactPanelProps> = ({
  contacts,
  searchForm,
  groupByFirstChar = true,
  me,
  searchVisible = true,
}) => {
  const intl = useIntl();
  const { watch } = searchForm;
  const watchFilterSearch = watch('filterSearch');
  const filterFn = ({ last_name, first_name, middle_name, fn }: ContactBasic) => [last_name, first_name, middle_name, fn]
    .some((str: string) => str?.toLocaleLowerCase()?.includes(watchFilterSearch?.toLocaleLowerCase()));
  const hasFilter = watchFilterSearch?.length > 1;

  const items = (hasFilter) ? contacts?.data?.items?.filter(filterFn) : contacts?.data?.items;
  const myself: ContactBasic | null | undefined = (me) ? contacts?.data?.items?.find(({ id }) => id === me) : null;

  return (
    <div>
      {searchVisible && (
        <FilterInput
          hookForm={searchForm}
          disabled={contacts.isLoading || contacts.isFetching}
        />
      )}
      {contacts?.isLoading && (
        <div className="text-center">
          <LoadingIndicator />
        </div>
      )}

      {myself && (
        <Button
          className="d-block link-dark"
          to={{
            pathname: `/contacts/${myself.id}`,
          }}
          tag={Link}
          color="link"
        >
          <div className="d-flex mb-4 align-items-center text-start">
            <div className="flex-shrink-1 me-3 text-muted">
              <FontAwesomeIcon icon={solid('user-circle')} size="4x" />
            </div>
            <div className="align-content-bottom" style={{ minWidth: 0 }}>
              <div className="h4 mb-0 text-truncate">
                <strong>
                  {toContactName(intl, myself, { maxElements: 3, maxChars: [null, null, null] })}
                </strong>
              </div>
              <div className="text-muted lh-sm">
                <FormattedMessage defaultMessage="My Card" />
              </div>
            </div>
          </div>
        </Button>
      )}

      {contacts?.isSuccess && (
        <div>
          {Array.isArray(items) && items.length > 0 && (
            <ContactList
              contacts={items}
              groupByFirstChar={groupByFirstChar}
              selected={null}
              me={me}
            />
          )}
          <div className="text-muted text-center">
            {!hasFilter && (
              <FormattedMessage
                defaultMessage="{ num, plural, =0 {no contacts} one {# contact} other {{num, number, ::compact-short} contacts} }"
                values={{ num: items?.length ?? 0 }}
              />
            )}
            {hasFilter && (
              <FormattedMessage
                defaultMessage="Showed {num, number, ::compact-short} of { total, plural, one {# contact} other {{num, number, ::compact-short} contacts} }"
                values={{
                  num: items?.length ?? 0,
                  total: contacts?.data?.items?.length ?? 0,
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

interface ContactPhotoProps {
  contact_id: Contact['id'];
}

const ContactPhoto: React.FC<ContactPhotoProps> = ({
  contact_id,
}) => {
  const store = useStore();
  const intl = useIntl();
  const [imgSrc, setSrc] = useState<string | null>(null);

  useEffect(() => {
    async function fetchData() {
      if (contact_id) {
        const { data, meta } = await loadBinary(`contacts/${contact_id}/photo`, store);

        if (meta?.response?.ok && data instanceof Blob) {
          setSrc(URL.createObjectURL(data));
        }
      }
    }

    fetchData();
  }, [contact_id, store]);

  return (
    <Fragment>
      {imgSrc && (
        <img
          className="img-fluid"
          src={imgSrc}
          alt={intl.formatMessage({ defaultMessage: 'Contact Image' })}
          onLoad={() => {
            URL.revokeObjectURL(imgSrc);
          }}
        />
      )}
      {!imgSrc && (
        <Fragment>
          <FontAwesomeIcon icon={solid('user-circle')} size="5x" />
          <span className="visually-hidden">
            <FormattedMessage defaultMessage="No contact picture so far" />
          </span>
        </Fragment>
      )}
    </Fragment>
  );
};

const ContactGender: React.FC<{ gender: Contact['gender'] }> = ({
  gender,
}) => (
  <Fragment>
    {gender?.[0]?.toUpperCase() === 'M' && <FormattedMessage defaultMessage="Male" />}
    {gender?.[0]?.toUpperCase() === 'F' && <FormattedMessage defaultMessage="Female" />}
    {gender?.[0]?.toUpperCase() === 'O' && <FormattedMessage defaultMessage="Other" />}
    {gender?.[0]?.toUpperCase() === 'N' && <FormattedMessage defaultMessage="None or not applicable" />}
    {gender?.[0]?.toUpperCase() === 'U' && <FormattedMessage defaultMessage="Unknown" />}
  </Fragment>
);

interface ContactBirthDateProps {
  year?: number | null;
  month?: MonthNumbers | number | null;
  day?: DayNumbers | number | null;
  showAge?: boolean | null;
}

const ContactBirthDate: React.FC<ContactBirthDateProps> = ({
  year,
  month,
  day,
  showAge,
}) => {
  let date = null;
  let localDate = null;
  let iso = null;
  let ageMsg = null;
  const hasYear = year && year !== null && DateTime.fromObject({ year }).isValid;
  const hasMonth = month && month !== null && DateTime.fromObject({ month }).isValid;
  const hasDay = day && day !== null && DateTime.fromObject({ day, month: 12 }).isValid;
  const intl = useIntl();

  if (hasYear && hasMonth && hasDay) {
    date = DateTime.fromObject({ year, month, day });
    localDate = date.toLocaleString(DateTime.DATE_FULL);
    iso = date.toISODate();
  } else if (hasYear && hasMonth) {
    // 2. year and month
    date = DateTime.fromObject({ year, month, day: 1 });
    localDate = date.toLocaleString({ year: 'numeric', month: 'long' });
  } else if (hasYear && hasDay) {
    // 3. year and day, weird I agree
    date = DateTime.fromObject({ year, month: 1, day });
    localDate = date.toLocaleString({ year: 'numeric', day: 'numeric' });
  } else if (hasYear) {
    // 4. year only
    date = DateTime.fromObject({ year });
    localDate = date.toLocaleString({ year: 'numeric' });
  } else if (hasMonth && hasDay) {
    // 5. month and day
    date = DateTime.fromObject({ month, day });
    localDate = date.toLocaleString({ month: 'long', day: 'numeric' });
  } else if (hasMonth) {
    // 6. month only
    date = DateTime.fromObject({ month });
    localDate = date.toLocaleString({ month: 'long' });
  } else if (hasDay) {
    // 7. day only
    date = DateTime.fromObject({ day });
    localDate = date.toLocaleString({ day: 'numeric' });
  } else {
    // 8 all empties
    return null;
  }

  if (showAge && hasYear && date instanceof DateTime) {
    const age = Math.floor(Math.abs(date.diffNow('years').years));
    if (hasYear && hasMonth && hasDay) {
      ageMsg = intl.formatMessage({
        defaultMessage: '({ age, plural, one {# year old} two {# years old} other {# years old} })',
      }, { age });
    } else {
      ageMsg = intl.formatMessage({
        defaultMessage: '(about { age, plural, one {# year old} two {# years old} other {# years old} })',
      }, { age });
    }
  }

  return (
    <Fragment>
      {/* full dob specified */}
      {iso !== null && (
        <time dateTime={iso}>
          {localDate}
        </time>
      )}
      {/* partial dob */}
      {!iso && localDate}
      {/* age */}
      {ageMsg !== null && (
        <Fragment>
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          {' '}
          {ageMsg}
        </Fragment>
      )}
    </Fragment>
  );
};

interface ContactAddressProps {
  address?: string | string[] | null;
  showLabels?: boolean;
  blockElement?: ElementType;
}

const ContactAddress: React.FC<ContactAddressProps> = ({
  address = null,
  showLabels = true,
  blockElement = 'div',
}) => {
  const px = useId();
  const Wrapper = blockElement;
  const keys = [
    'pobox', // post office box
    'ext', // extended address (e.g., apartment or suite number)
    'street', // street address
    'locality', // locality (e.g., city)
    'region', // region (e.g., state or province)
    'code', // postal code
    'country', // country name
  ];

  if (!Array.isArray(address) || address.length !== 7) return null;

  return (
    <Fragment>
      {address.map((v, i) => {
        if (!v) return null; // empty

        return (
          <Wrapper
            key={`${px}_${keys[i]}`}
          >
            <Wrapper className={`fw-bold ${!showLabels ? 'visually-hidden' : ''}`}>
              {i === 0 && <FormattedMessage defaultMessage="Post office box" />}
              {i === 1 && <FormattedMessage defaultMessage="Extended address" />}
              {i === 2 && <FormattedMessage defaultMessage="Street" />}
              {i === 3 && <FormattedMessage defaultMessage="City" />}
              {i === 4 && <FormattedMessage defaultMessage="Region" />}
              {i === 5 && <FormattedMessage defaultMessage="Postal code" />}
              {i === 6 && <FormattedMessage defaultMessage="Country" />}
            </Wrapper>
            <Wrapper>
              {v}
            </Wrapper>
          </Wrapper>
        );
      })}
    </Fragment>
  );
};

interface ContactDetailsPanelProps {
  contactDetails: TypedUseQueryStateResult<Contact, string, BaseQueryFn>;
}

export const ContactDetailsPanel: React.FC<ContactDetailsPanelProps> = ({
  contactDetails,
}) => {
  const { data, isLoading, isSuccess } = contactDetails;
  const px = useId();

  if (isSuccess) {
    const { id: contact_id, gender, birth_year, birth_month, birth_day, properties } = data;
    type ReduceResult = {
      map: {
        [Name in ContactProperty['name']]: ContactProperty[];
      },
      unsupported: number;
    }
    const { map: propsMap, unsupported }: ReduceResult = properties.reduce((prev: ReduceResult, prop: ContactProperty) => {
      const { name } = prop;
      const isSupported = ['tel', 'email', 'url', 'adr', 'note', 'photo'].includes(name);
      return {
        map: {
          ...prev.map,
          [name]: (Array.isArray(prev?.map?.[name])) ? [...prev?.map?.[name], prop] : [prop],
        },
        unsupported: (!isSupported) ? prev.unsupported + 1 : prev.unsupported,
      };
    }, { map: {}, unsupported: 0 } as ReduceResult);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { tel, email, url, adr, note, photo, ...rest } = propsMap;
    const restPropKeys = Object.keys(rest);
    const knownProps = ['nickname', 'anniversary', 'impp', 'lang', 'tz', 'geo', 'title', 'role', 'logo', 'org', 'member', 'related', 'categories', 'sound', 'clientpidmap', 'key', 'fburl', 'caladruri', 'caluri'];

    return (
      <div className="lh-sm">
        <div className="text-center mb-2 text-muted">
          <ContactPhoto contact_id={contact_id} />
        </div>
        <div className="h2 text-center mb-5">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ContactName {...data} components={{ first: 'strong' }} />
        </div>
        {/* person age */}
        {(birth_year || birth_month || birth_day) && (
          <ListGroup className="mb-3">
            <ListGroupItem>
              <div className="fw-bold">
                <FormattedMessage defaultMessage="Date of birth" tagName="small" />
              </div>
              <ContactBirthDate
                year={birth_year}
                month={birth_month}
                day={birth_day}
                showAge
              />
            </ListGroupItem>
          </ListGroup>
        )}
        {/* gender */}
        {gender?.[0] && (
          <ListGroup className="mb-3">
            <ListGroupItem>
              <div className="fw-bold">
                <FormattedMessage defaultMessage="Gender" tagName="small" />
              </div>
              <ContactGender gender={gender} />
            </ListGroupItem>
          </ListGroup>
        )}
        {/* phones */}
        {Array.isArray(tel) && (
          <ListGroup className="mb-3">
            {tel?.map(({ id, value }: Pick<ContactProperty, 'id' | 'value'>) => (
              <ListGroupItem key={`tel_prop_${id}`} className="font-monospace">
                <FontAwesomeIcon icon={solid('square-phone')} className="text-muted" size="lg" />
                {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                {' '}
                {value}
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
        {/* emails */}
        {Array.isArray(email) && (
          <ListGroup className="mb-3">
            {email?.map(({ id, value }) => (
              <ListGroupItem key={`email_prop_${id}`} className="font-monospace">
                <FontAwesomeIcon icon={solid('square-envelope')} className="text-muted" size="lg" />
                {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                {' '}
                {value}
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
        {/* links */}
        {Array.isArray(url) && (
          <ListGroup className="mb-3">
            {url?.map(({ id, value }) => (
              <ListGroupItem key={`url_prop_${id}`}>
                <div className="fw-bold">
                  <FormattedMessage defaultMessage="Website" tagName="small" />
                </div>
                {value}
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
        {/* address */}
        {Array.isArray(adr) && (
          <ListGroup className="mb-3">
            {adr?.map(({ id, value }: Pick<ContactProperty, 'id' | 'value'>) => (
              <ListGroupItem key={`adr_prop_${id}`}>
                <div className="fw-bold">
                  <FormattedMessage defaultMessage="Address" tagName="small" />
                </div>
                <ContactAddress address={value} showLabels={false} />
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
        {/* notes */}
        {Array.isArray(note) && (
          <ListGroup className="mb-3">
            {note?.map(({ id, value }, index) => (
              <ListGroupItem
                key={`note_prop_${id}`}
                style={{ whiteSpace: 'pre-line', verticalAlign: 'bottom' }}
              >
                {index === 0 && (
                  <div className="fw-bold">
                    <FormattedMessage defaultMessage="Notes" tagName="small" />
                  </div>
                )}
                {value}
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
        {/* rest properties not really important */}
        {restPropKeys?.length > 0 && (
          <UncontrolledAlert color="info" className="alert-sm">
            <div>
              <FormattedMessage
                defaultMessage="There {num, plural, one {is # hidden property} other {are # hidden properties} } in case you don't see info uploaded recently."
                values={{
                  num: unsupported,
                }}
              />
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              {' '}
              <FormattedMessage
                defaultMessage="Most likely we received {num, plural, one {it} other {them}} during vcf file import:"
                values={{
                  num: unsupported,
                }}
              />
            </div>
            <List>
              {restPropKeys.map((key) => (
                /* TODO: add description to all vCard props */
                <li key={`${px}-hidden-${key}`}>
                  {key === 'nickname' && <FormattedMessage defaultMessage="nickname(text corresponding to the nickname of the object the vCard represents)" />}
                  {key === 'anniversary' && <FormattedMessage defaultMessage="anniversary(date of marriage, or equivalent, of the object the vCard represents)" />}
                  {key === 'impp' && <FormattedMessage defaultMessage="impp(URI for instant messaging and presence protocol communications with the object the vCard represents)" />}
                  {key === 'lang' && <FormattedMessage defaultMessage="lang(language(s) that may be used for contacting the entity associated with the vCard)" />}
                  {key === 'tz' && <FormattedMessage defaultMessage="tz(time zone of the object the vCard represents)" />}
                  {key === 'geo' && <FormattedMessage defaultMessage="geo(information related to the global positioning of the object the vCard represents)" />}
                  {key === 'title' && <FormattedMessage defaultMessage="title(position or job of the object the vCard represents)" />}
                  {key === 'role' && <FormattedMessage defaultMessage="role(function or part played in a particular situation by the object the vCard represents)" />}
                  {key === 'logo' && <FormattedMessage defaultMessage="logo(graphic image of a logo associated with the object the vCard represents)" />}
                  {key === 'org' && <FormattedMessage defaultMessage="org(organizational name and units associated with the vCard)" />}
                  {key === 'member' && <FormattedMessage defaultMessage="member(member in the group this vCard represents)" />}
                  {key === 'related' && <FormattedMessage defaultMessage="related(relationship between another entity and the entity represented by this vCard)" />}
                  {key === 'categories' && <FormattedMessage defaultMessage='categories(application category information about the vCard, also known as "tags")' />}
                  {key === 'sound' && <FormattedMessage defaultMessage="sound(digital sound content information that annotates some aspect of the vCard)" />}
                  {key === 'clientpidmap' && <FormattedMessage defaultMessage="clientpidmap(global meaning to a local PID source identifier)" />}
                  {key === 'key' && <FormattedMessage defaultMessage="key(public key or authentication certificate associated with the object that the vCard represents)" />}
                  {key === 'fburl' && <FormattedMessage defaultMessage="fburl(URI for the busy time associated with the object that the vCard represents)" />}
                  {key === 'caladruri' && <FormattedMessage defaultMessage="caladruri(calendar user address to which a scheduling request should be sent for the object represented by the vCard)" />}
                  {key === 'caluri' && <FormattedMessage defaultMessage="caluri(URI for a calendar associated with the object represented by the vCard)" />}
                  {!knownProps.includes(key) && <FormattedMessage defaultMessage="unknown({key})" values={{ key }} />}
                </li>
              ))}
            </List>
            <FormattedMessage
              defaultMessage="Unfortunately, {num, plural, one {this vcard property is} other {these vcard properties are} } not supported so far. Don't worry, we store all imported data anyway."
              tagName="div"
              values={{ num: unsupported }}
            />
          </UncontrolledAlert>
        )}
      </div>
    );
  }

  return (
    <div>
      {isLoading && (
        <div className="text-center">
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
};

export const contactSelectFilterOption = ({ data }: FilterOptionOption<ContactBasic>, inputValue: string): boolean => {
  // empty string by default
  if (!inputValue?.trim()) return true;

  const needle = inputValue.trim().toLocaleLowerCase();
  return [data?.last_name, data?.first_name, data?.middle_name, data?.fn]
    .filter((name) => typeof name === 'string')
    .some((name: string) => name.trim().toLocaleLowerCase().search(needle) !== -1);
};

import { useId, useMemo, Fragment, useState, useCallback } from 'react';
import { DateTime, DateTimeFormatOptions, IANAZone } from 'luxon';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import { generatePath, Link } from 'react-router-dom';
import { TypedUseQueryStateResult, BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Input, Form, FormGroup, FormFeedback, Label, List, ListGroup, ListGroupItem, Collapse } from 'reactstrap';

import { contactsToReactSelectOptions, contactSelectFilterOption, ContactName, toContactName, toContactNameOptions, toContactInlineList } from '../contacts/Contact';
import { servicesToReactSelectOptions, toServiceName, serviceSelectFilterOption } from '../services/Service';
import { TemplatePreview, toMustacheVars } from '../templates/Template';
import { LoadingIndicator } from '../core/LoadingIndicator';
import { ItemsList } from '../core/ItemsList';
import timezones from './zones.json';
import { formSubmitHandler } from '../core/formSubmitHandler';
import type { ContactBasic, ContactsQueryResult, EventsQueryResult, Event, EventFormValues, FormMethods, TagNames, ServicesQueryResult, ApiCollection, Template, PaginationParams, GetEventsParams } from '../types/api';

interface toEventNameOptions {
  useServiceName?: boolean;
  useOrganizerName?: boolean;
  useAttendeeName?: boolean;
  maxAttendeeList?: number;
}

const toEventName = (
  event: Pick<Event, 'summary' | 'organizer' | 'attendee' | 'service'>,
  intl: IntlShape,
  options?: toEventNameOptions,
  contactOptions?: toContactNameOptions,
): string => {
  const {
    useServiceName = true,
    useOrganizerName = true,
    useAttendeeName = true,
    maxAttendeeList = 3,
  }: toEventNameOptions = options ?? {};

  // when event contains specified title just return it
  if (event.summary?.trim()) return event.summary.trim();

  // when event got service, use service name
  if (useServiceName && Array.isArray(event.service) && event.service[0]?.name) {
    return intl.formatMessage(
      { defaultMessage: 'Event with "{service_name}" service' },
      { service_name: event.service[0]?.name },
    );
  }

  // when event got attendee
  if (useAttendeeName && Array.isArray(event.attendee) && event.attendee.length) {
    return intl.formatMessage(
      { defaultMessage: 'Event with {contacts}' },
      { contacts: toContactInlineList(intl, event.attendee, { maxLength: maxAttendeeList }) },
    );
  }

  // when got organizer only
  if (useOrganizerName && Array.isArray(event.organizer) && event.organizer.length) {
    return intl.formatMessage(
      { defaultMessage: 'Event organized by {contact}' },
      { contact: toContactName(intl, event.organizer[0], contactOptions) },
    );
  }

  return intl.formatMessage({ defaultMessage: 'Unnamed Event' });
};

interface ToTimezoneLabel {
  tzName: string;
  intl: IntlShape;
}

export const toTimezoneLabel = ({
  tzName,
  intl,
}: ToTimezoneLabel): Record<'label' | 'value', string> => {
  const ts = DateTime.now().toMillis();
  const zone = IANAZone.create(tzName);
  const name = zone.offsetName(ts, { format: 'long', locale: intl?.locale });
  const offset = zone.formatOffset(ts, 'short');
  const label = `${tzName} (${offset}, ${name})`;

  return { label, value: tzName };
};

interface TimezoneSelectProps extends FormMethods<EventFormValues> {
  fieldName?: string;
  disabled?: boolean;
  id?: string;
}

export const TimezoneSelect: React.FC<TimezoneSelectProps> = ({
  hookForm,
  fieldName = 'timezone',
  disabled = false,
  id,
}) => {
  const { control, formState: { errors } } = hookForm;
  const px = useId();
  const intl = useIntl();
  const ts = DateTime.now().toMillis();
  const tzFiltered = useMemo(
    () => timezones
      .filter((z) => IANAZone.isValidZone(z))
      .sort((a, b) => {
        const offsetA = IANAZone.create(a).offset(ts);
        const offsetB = IANAZone.create(b).offset(ts);
        if (offsetA > offsetB) return 1;
        if (offsetA < offsetB) return -1;
        return 0;
      }).map((z) => toTimezoneLabel({ tzName: z, intl })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timezones, intl],
  );

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <Input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
          id={id ?? `${px}tz`}
          innerRef={(e) => field.ref(e)}
          invalid={Boolean(errors?.timezone)}
          disabled={disabled}
          type="select"
        >
          <option value="" aria-label={intl.formatMessage({ defaultMessage: 'not selected' })} />
          {tzFiltered.map(({ label, value }) => (
            <option value={value} key={`${px}${value}`}>
              {label}
            </option>
          ))}
        </Input>
      )}
    />
  );
};

interface EventFormProps extends FormMethods<EventFormValues>, TagNames<'head' | 'body' | 'foot'> {
  contacts: ContactsQueryResult | null;
  services: ServicesQueryResult | null;
  me?: ContactBasic['id'] | null;
}

export const EventForm: React.FC<EventFormProps> = ({
  hookForm,
  onSubmit,
  onSubmitError,
  contacts = null,
  services = null,
  me = null,
  components,
  disabled,
}) => {
  const intl = useIntl();
  const px = useId();
  const { control, formState: { errors }, watch, handleSubmit } = hookForm;
  const eventId = watch('id');
  const HeadWrap = components?.head ?? 'legend';
  const BodyWrap = components?.body ?? 'div';
  const FootWrap = components?.foot ?? 'fieldset';
  const options = contactsToReactSelectOptions(intl, contacts?.data?.items, me) ?? [];
  const serviceOptions = servicesToReactSelectOptions(intl, services?.data?.items) ?? [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formCallback = useMemo(() => formSubmitHandler<EventFormValues>({ handleSubmit, onSubmit, onSubmitError }), [handleSubmit, onSubmit]);

  const loadingMessage = useCallback(() => intl.formatMessage({ defaultMessage: 'Loading...' }), [intl]);
  const noOptionsMessage = useCallback(() => intl.formatMessage({ defaultMessage: 'No options' }), [intl]);

  return (
    <Form
      autoComplete="off"
      className="form-event"
      onSubmit={formCallback}
      action="test"
      noValidate
    >
      {/* form header is optional */}
      {components?.head !== undefined && (
        <HeadWrap className="form-heading">
          {eventId && <FormattedMessage defaultMessage="Edit event" />}
          {!eventId && <FormattedMessage defaultMessage="New event" />}
        </HeadWrap>
      )}
      <BodyWrap className="form-body">
        <fieldset>
          <FormGroup>
            <Label htmlFor={`${px}t`} hidden>
              <FormattedMessage defaultMessage="Title" />
            </Label>
            <Controller
              name="summary"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  id={`${px}t`}
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.summary)}
                  placeholder={intl.formatMessage({ defaultMessage: 'Title' })}
                  disabled={disabled}
                />
              )}
            />
            <FormFeedback>
              {errors?.summary?.message}
            </FormFeedback>
          </FormGroup>
          <FormGroup className="d-flex align-items-center">
            <Label htmlFor={`${px}sd`} className="me-auto">
              <FormattedMessage defaultMessage="Starts" />
            </Label>
            <div className="me-3">
              <Controller
                name="start_date"
                control={control}
                render={({ field }) => (
                  <Input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    id={`${px}sd`}
                    type="date"
                    innerRef={(e) => field.ref(e)}
                    invalid={Boolean(errors?.start_date)}
                    disabled={disabled}
                  />
                )}
              />
              <FormFeedback>
                {errors?.start_date?.message}
              </FormFeedback>
            </div>
            <div>
              <Label htmlFor={`${px}st`} hidden>
                <FormattedMessage defaultMessage="Start time" />
              </Label>
              <Controller
                name="start_time"
                control={control}
                render={({ field }) => (
                  <Input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    id={`${px}st`}
                    type="time"
                    innerRef={(e) => field.ref(e)}
                    invalid={Boolean(errors?.start_time)}
                    disabled={disabled}
                  />
                )}
              />
              <FormFeedback>
                {errors?.start_time?.message}
              </FormFeedback>
            </div>
          </FormGroup>
          <FormGroup className="d-flex align-items-center">
            <Label htmlFor={`${px}ed`} className="me-auto">
              <FormattedMessage defaultMessage="Ends" />
            </Label>
            <div className="me-3">
              <Controller
                name="end_date"
                control={control}
                render={({ field }) => (
                  <Input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    id={`${px}ed`}
                    type="date"
                    innerRef={(e) => field.ref(e)}
                    invalid={Boolean(errors?.end_date)}
                    disabled={disabled}
                  />
                )}
              />
              <FormFeedback>
                {errors?.end_date?.message}
              </FormFeedback>
            </div>
            <div>
              <Label htmlFor={`${px}et`} hidden>
                <FormattedMessage defaultMessage="End time" />
              </Label>
              <Controller
                name="end_time"
                control={control}
                render={({ field }) => (
                  <Input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    id={`${px}et`}
                    type="time"
                    innerRef={(e) => field.ref(e)}
                    invalid={Boolean(errors?.end_time)}
                    disabled={disabled}
                  />
                )}
              />
              <FormFeedback>
                {errors?.end_time?.message}
              </FormFeedback>
            </div>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={`${px}tz`}>
              <FormattedMessage defaultMessage="Timezone" />
            </Label>
            <TimezoneSelect
              hookForm={hookForm}
              fieldName="timezone"
              disabled={disabled}
            />
            <FormFeedback>
              {errors?.timezone?.message}
            </FormFeedback>
          </FormGroup>
        </fieldset>
        {/* extra options related to current service
        not presented in vCard spec */}
        <fieldset>
          <FormGroup>
            <Label>
              <FormattedMessage defaultMessage="Organizer" />
            </Label>
            {/**
              * @link https://codesandbox.io/s/react-hook-form-v7-controller-5h1q5
              */}
            <Controller
              name="organizer"
              control={control}
              render={({ field }) => (
                <ReactSelect
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  isDisabled={contacts?.isLoading || disabled}
                  isClearable
                  isLoading={contacts?.isLoading}
                  options={options}
                  filterOption={contactSelectFilterOption}
                  placeholder={intl.formatMessage({ defaultMessage: 'Select a contact...' })}
                  loadingMessage={loadingMessage}
                  noOptionsMessage={noOptionsMessage}
                />
              )}
            />
            <FormFeedback>
              {errors?.organizer?.message}
            </FormFeedback>
          </FormGroup>
        </fieldset>
        <fieldset>
          <FormGroup>
            <Label>
              <FormattedMessage defaultMessage="Attendee" />
            </Label>
            {/**
              * @link https://codesandbox.io/s/react-hook-form-v7-controller-5h1q5
              */}
            <Controller
              name="attendee"
              control={control}
              render={({ field }) => (
                <ReactSelect
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  isDisabled={contacts?.isLoading || disabled}
                  isClearable
                  isLoading={contacts?.isLoading}
                  options={options}
                  filterOption={contactSelectFilterOption}
                  placeholder={intl.formatMessage({ defaultMessage: 'Select one or more contacts...' })}
                  loadingMessage={loadingMessage}
                  noOptionsMessage={noOptionsMessage}
                  isMulti
                />
              )}
            />
            <FormFeedback>
              {errors?.organizer?.message}
            </FormFeedback>
          </FormGroup>
        </fieldset>
        <fieldset>
          <FormGroup>
            <Label>
              <FormattedMessage defaultMessage="Service" />
            </Label>
            {/**
              * @link https://codesandbox.io/s/react-hook-form-v7-controller-5h1q5
              */}
            <Controller
              name="service"
              control={control}
              render={({ field }) => (
                <ReactSelect
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  isDisabled={services?.isLoading || disabled}
                  isClearable
                  isLoading={services?.isLoading}
                  options={serviceOptions}
                  filterOption={serviceSelectFilterOption}
                  placeholder={intl.formatMessage({ defaultMessage: 'Select a service...' })}
                  loadingMessage={loadingMessage}
                  noOptionsMessage={noOptionsMessage}
                />
              )}
            />
            <FormFeedback>
              {errors?.organizer?.message}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={`${px}description`}>
              <FormattedMessage defaultMessage="Description" />
            </Label>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  id={`${px}description`}
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.description)}
                  disabled={disabled}
                  rows={3}
                  type="textarea"
                />
              )}
            />
            <FormFeedback>
              {errors?.description?.message}
            </FormFeedback>
          </FormGroup>
        </fieldset>
      </BodyWrap>
      {/* form footer is also optional */}
      {components?.foot !== undefined && (
        <FootWrap className="form-foot">
          <fieldset>
            <Button type="button">
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
            <Button type="submit">
              {eventId && <FormattedMessage defaultMessage="Done" />}
              {!eventId && <FormattedMessage defaultMessage="Add" />}
            </Button>
          </fieldset>
        </FootWrap>
      )}
    </Form>
  );
};

/**
 * TODO: jest testing
 */
export const compareEventWithDate = (
  event: Pick<Event, 'start' | 'end' | 'timezone'>,
  date: DateTime | null = null,
): { allDay: boolean; startsDay: boolean; endsDay: boolean; } => {
  if (!date?.isValid) return { allDay: false, startsDay: false, endsDay: false };

  const eventStart = DateTime.fromISO(event.start, { zone: event.timezone });
  const eventEnd = DateTime.fromISO(event.end, { zone: event.timezone });
  const dayStart = date.startOf('day');
  const dayEnd = dayStart.plus({ days: 1 });

  return {
    allDay: eventStart < dayStart && eventEnd >= dayEnd,
    startsDay: eventStart >= dayStart && eventStart < dayEnd,
    endsDay: eventEnd > dayStart && eventEnd < dayEnd,
  };
};

interface EventItemProps extends Readonly<
  Pick<Event, 'id' | 'start' | 'end' | 'summary' | 'organizer' | 'attendee' | 'service' | 'timezone'>
>, TagNames<'container'> {
  dateShown?: DateTime | null;
  timeFormat?: DateTimeFormatOptions | null;
}

/**
 * TODO: add address field right after service name
 */
export const EventItem: React.FC<EventItemProps> = ({
  id,
  start,
  end,
  summary,
  organizer,
  attendee,
  service,
  timezone,
  dateShown = null,
  timeFormat = { hour: '2-digit', minute: '2-digit' },
  components,
}) => {
  const intl = useIntl();
  const { allDay, startsDay, endsDay } = compareEventWithDate({ start, end, timezone }, dateShown);
  const eventStart = DateTime.fromISO(start, { zone: timezone });
  const eventEnd = DateTime.fromISO(end, { zone: timezone });
  const isPassed = DateTime.fromISO(end) < DateTime.now();
  const optFormat = timeFormat ?? { hour: '2-digit', minute: '2-digit' };
  const Wrapper = components?.container ?? 'div';
  // keep item visual two lines
  const placeholder = eventStart.toLocaleString(optFormat);
  const pathname = generatePath('/events/:id', { id });

  return (
    <Wrapper
      className={`event-item ${isPassed ? 'passed' : ''}`}
      to={{
        pathname,
      }}
      tag={Link}
    >
      <div className="summary text-start">
        <div className="title">
          {toEventName({ summary, organizer, attendee, service }, intl)}
        </div>
        {summary && (
          <div className="service">
            {service?.[0]?.name}
          </div>
        )}
      </div>
      <div className="time text-end">
        {allDay && (
          <Fragment>
            <div>
              <FormattedMessage defaultMessage="all-day" />
            </div>
            <div>
              <span aria-hidden style={{ visibility: 'hidden' }}>
                {placeholder}
              </span>
            </div>
          </Fragment>
        )}
        {!allDay && (
          <Fragment>
            <div>
              {startsDay && (
                <time dateTime={start}>
                  {eventStart.toLocaleString(optFormat)}
                </time>
              )}
              {!startsDay && endsDay && (
                <FormattedMessage defaultMessage="Ends" />
              )}
            </div>
            <div>
              {endsDay && (
                <time dateTime={end}>
                  {eventEnd.toLocaleString(optFormat)}
                </time>
              )}
              {!endsDay && (
                <span aria-hidden style={{ visibility: 'hidden' }}>
                  {placeholder}
                </span>
              )}
            </div>
          </Fragment>
        )}
      </div>
    </Wrapper>
  );
};

interface EventListProps {
  events?: EventsQueryResult | null;
  groupByDay?: boolean;
  dateShown?: DateTime | null;
}

// consider all items already sorted desc
export const EventList: React.FC<EventListProps> = ({
  events = null,
  groupByDay = true,
  dateShown = null,
}) => {
  // group items by day
  const daysMap = events?.data?.items?.reduce((prev: Record<string, Event[]>, item: Event) => {
    const isoDate = item.start;

    return {
      ...prev,
      [isoDate]: [
        ...prev?.[isoDate] ?? [],
        item,
      ],
    };
  }, {});

  if (events?.isLoading || events?.isFetching) {
    return (
      <div className="event-list text-center">
        <LoadingIndicator />
      </div>
    );
  }

  // no events
  if (events?.isSuccess && events.data.items_count < 1) {
    return (
      <div className="event-list text-center">
        <FormattedMessage defaultMessage="No Events Found" tagName="h3" />
      </div>
    );
  }

  return (
    <div className="event-list">
      {groupByDay && daysMap && Object.keys(daysMap).map((isoDate) => (
        <div key={isoDate}>
          <div className="heading">
            <time dateTime={isoDate}>
              {DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_HUGE)}
            </time>
          </div>
          <List type="unstyled">
            {daysMap[isoDate].map((item: Event) => (
              <li key={item?.id}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <EventItem {...item} dateShown={DateTime.fromISO(isoDate)} components={{ container: Button }} />
              </li>
            ))}
          </List>
        </div>
      ))}

      {!groupByDay && (
        <ListGroup flush>
          {events?.data?.items?.map((item: Event) => (
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            <EventItem {...item} key={item.id} dateShown={dateShown} components={{ container: ListGroupItem }} />
          ))}
        </ListGroup>
      )}
    </div>
  );
};

interface AttendeeItemProps {
  contact: ContactBasic;
  event: Event;
  templates?: Template[];
}

const AttendeeItem: React.FC<AttendeeItemProps> = ({
  contact,
  event,
  templates,
}) => {
  const hasTemplates = Array.isArray(templates) && templates.length > 0;
  const [messagesVisible, setMessagesVisible] = useState<boolean>(false);
  const onToggle = () => setMessagesVisible(!messagesVisible);
  const [copiedId, setCopiedId] = useState<string | null>(null);

  return (
    <li key={contact.id}>
      <div className="d-flex justify-content-between">
        <Button
          to={{
            pathname: `/contacts/${contact.id}`,
          }}
          color="link"
          className="p-0"
          tag={Link}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ContactName {...contact} />
        </Button>
        {hasTemplates && (
          <Button
            onClick={onToggle}
            active={messagesVisible}
            color="link"
            className="p-0"
          >
            <span className="visually-hidden">
              <FormattedMessage defaultMessage="Show generated messages" />
            </span>
            <FontAwesomeIcon icon={solid('robot')} />
          </Button>
        )}
      </div>
      {hasTemplates && (
        <Collapse isOpen={messagesVisible}>
          <div className="h6 text-center">
            <FormattedMessage defaultMessage="Messages from Templates" />
          </div>
          <List type="unstyled" className="ps-3 mt-1">
            {templates.map((template: Template) => (
              <li
                key={template.id}
                className={`mb-4 border-start border-primary border-2 ps-3 ${copiedId === template.id ? 'bg-light' : ''}`}
              >
                <TemplatePreview
                  template={template}
                  vars={toMustacheVars({
                    date: DateTime.fromISO(event?.start),
                    organizer: event?.organizer?.[0],
                    attendee: contact,
                    service: event?.service?.[0],
                    locale: template.locale,
                  })}
                  isLoading={false}
                  isCopied={copiedId === template.id}
                  onCopy={(text: string, result: boolean) => {
                    if (result) {
                      setCopiedId(template.id);
                    }
                  }}
                  components={{
                    container: 'div',
                  }}
                />
              </li>
            ))}
          </List>
        </Collapse>
      )}
    </li>
  );
};

interface EventDetailsPanelProps {
  eventDetails: TypedUseQueryStateResult<Event, string, BaseQueryFn>;
  templates: TypedUseQueryStateResult<ApiCollection<Template>, PaginationParams, BaseQueryFn>;
}

export const EventDetailsPanel: React.FC<EventDetailsPanelProps> = ({
  eventDetails,
  templates,
}) => {
  const intl = useIntl();
  const { data, isLoading, isSuccess } = eventDetails;
  const px = useId();

  if (isSuccess) {
    const { start, end, timezone, organizer, attendee: attendeeList, service: serviceList, description } = data;
    const eventStart = DateTime.fromISO(start, { zone: timezone });
    const eventEnd = DateTime.fromISO(end, { zone: timezone });
    const singleDay = eventStart.hasSame(eventEnd, 'day');
    const ts = eventStart.toMillis();
    // const timeFormat: DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
    const timeFormat: DateTimeFormatOptions = DateTime.TIME_SIMPLE;
    const dateFormat: DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const singleDayDateFormat: DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

    return (
      <div>
        <div className="h2">
          {toEventName(data, intl)}
        </div>
        <div>
          {singleDay && (
            <Fragment>
              <div>
                {eventStart.toLocaleString(singleDayDateFormat)}
              </div>
              <div>
                <FormattedMessage
                  defaultMessage="from {start_time} to {end_time}"
                  values={{
                    start_time: eventStart.toLocaleString(timeFormat),
                    end_time: eventEnd.toLocaleString(timeFormat),
                  }}
                />
              </div>
              <div>
                {IANAZone.create(timezone).offsetName(ts, { format: 'long', locale: intl.locale })}
              </div>
            </Fragment>
          )}
          {!singleDay && (
            <Fragment>
              <div>
                <FormattedMessage
                  defaultMessage="from {time} {date}"
                  values={{
                    time: eventStart.toLocaleString(timeFormat),
                    date: eventStart.toLocaleString(dateFormat),
                  }}
                />
              </div>
              <div>
                <FormattedMessage
                  defaultMessage="to {time} {date}"
                  values={{
                    time: eventEnd.toLocaleString(timeFormat),
                    date: eventEnd.toLocaleString(dateFormat),
                  }}
                />
              </div>
              <div>
                {IANAZone.create(timezone).offsetName(ts, { format: 'long', locale: intl.locale })}
              </div>
            </Fragment>
          )}

          {/* TODO: time grid with 3 hours range */}

          {Array.isArray(organizer) && organizer.length > 0 && (
            <div>
              <hr />
              <div className="h4">
                <FormattedMessage defaultMessage="Organizer" />
              </div>
              <div>
                <Button
                  to={{
                    pathname: `/contacts/${organizer[0].id}`,
                  }}
                  color="link"
                  tag={Link}
                  className="p-0"
                >
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <ContactName {...organizer[0]} />
                </Button>
              </div>
            </div>
          )}

          {Array.isArray(attendeeList) && attendeeList.length > 0 && (
            <div>
              <hr />
              <div className="d-flex">
                <div className="h4 flex-grow-1">
                  <FormattedMessage defaultMessage="Invitees" />
                </div>
                <div className="text-muted">
                  {attendeeList.length}
                </div>
              </div>
              <div>
                <List type="unstyled">
                  {attendeeList.map((attendee: ContactBasic) => (
                    <AttendeeItem
                      key={`${px}${attendee.id}`}
                      contact={attendee}
                      event={eventDetails.data}
                      templates={templates.data?.items}
                    />
                  ))}
                </List>
              </div>
            </div>
          )}

          {Array.isArray(serviceList) && serviceList.length > 0 && (
            <div>
              <hr />
              <div className="h4">
                <FormattedMessage defaultMessage="Service" />
              </div>
              <div>
                {toServiceName(serviceList?.[0], intl, { includeDuration: true, includePrice: true })}
              </div>
            </div>
          )}

          {/* description field */}
          {description && (
            <div>
              <hr />
              <div className="h4">
                <FormattedMessage defaultMessage="Description" />
              </div>
              <div
                style={{ whiteSpace: 'pre-line', verticalAlign: 'bottom' }}
              >
                {description}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      {isLoading && (
        <div className="text-center">
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
};

interface EventsPanelProps extends PaginationParams, TagNames<'counter'> {
  items: TypedUseQueryStateResult<ApiCollection<Event>, GetEventsParams, BaseQueryFn>;
  fields?: Record<'date' | 'description' | 'contact', EntityFieldView>;
}

export const EventsPanel: React.FC<EventsPanelProps> = ({
  items,
  itemsToGroup,
  onLoadMore,
  page_size = 10,
  sort_order = 'DESC',
  fields,
  components,
}) => {
  const CounterTag = components?.counter ?? 'div';
  const [loadedItems, setLoadedItems] = useState([]);
  const currentItems = items?.data?.items ?? [];
  // const allItems = reduceUnique([...loadedItems, ...currentItems]);
  const { isError, isLoading, isFetching, isSuccess } = items;
  // const itemRender = (e: Event) => (
  //   <EventItem
  //     // item={e}
  //     {...e}
  //     // fields={fields}
  //   />
  // );
  const itemRender = (e: Event) => (
    <span className="d-flex justify-content-between">
      <span>
        <EventItem
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...e}
        />
      </span>
      <span className="text-muted">
        <time dateTime={e.start}>
          {DateTime.fromISO(e.start).toLocaleString(DateTime.DATETIME_SHORT)}
        </time>
      </span>
    </span>
  );
  const itemHref = (e: Event) => ({ pathname: generatePath('/events/:id', { id: e.id }) });
  // const itemsGroupSort = (a: string, b: string) => {
  //   const sign = (sort_order === 'DESC') ? -1 : 1;
  //   if (a < b) return -1 * sign;
  //   if (a > b) return 1 * sign;
  //   return 0;
  // };
  // const itemsGroupFormat = (name: string): string => {
  //   const dt = DateTime.fromISO(name);
  //   if (DateTime.now().hasSame(dt, 'day')) {
  //     // return dt.toRelative({ unit: 'days' });
  //     return dt.toRelativeCalendar() ?? 'Today';
  //   }
  //   return dt.toLocaleString(DateTime.DATE_HUGE);
  // };
  const countRender = (count: number, total: number) => (
    <CounterTag className="text-muted text-center">
      <FormattedMessage
        defaultMessage="Showed {num} of { total, plural, one {# event} other {# events} }"
        // tagName="small"
        values={{
          num: count,
          total,
        }}
      />
    </CounterTag>
  );
  // const loadMoreHandler = (onLoadMore === undefined)
  //   ? () => {
  //     setLoadedItems([...loadedItems, ...items?.data?.items]);
  //     onLoadMore();
  //   }
  //   : undefined;

  return (
    <ItemsList
      items={currentItems}
      itemsTotal={items?.data?.items_total}
      // itemsToGroup={itemsToGroup}
      itemRender={itemRender}
      // itemsGroupSort={itemsGroupSort}
      // itemsGroupFormat={itemsGroupFormat}
      itemHref={itemHref}
      countRender={countRender}
      loadingState={{
        isError,
        isFetching,
        isLoading,
        isSuccess,
      }}
      pageSize={page_size}
      // onLoadMore={loadMoreHandler}
    />
  );
};

import { emptySplitApi } from '../api/fetchBaseQuery';
import type { BusinessUser, BusinessUserPayload, UsageLimit } from '../types/api';

export const businessUsersApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({

    getCurrentBusinessUser: build.query<BusinessUser, void>({
      query: () => 'business_users/me',
      providesTags: () => [{ type: 'BusinessUsers', id: 'ME' }],
    }),

    createBusinessUser: build.mutation<void, BusinessUserPayload>({
      query: (payload) => ({
        url: 'business_users',
        method: 'POST',
        body: payload,
      }),
    }),

    getUsageLimits: build.query<UsageLimit, void>({
      query: () => 'usage_limits',
      providesTags: () => [{ type: 'UsageLimits', id: 'ME' }],
    }),

    updateCurrentBusinessUser: build.mutation<BusinessUser, BusinessUserPayload>({
      query: (payload) => ({
        url: 'business_users/me',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'BusinessUsers', id: 'ME' }],
    }),

  }),
});

export const {
  useGetCurrentBusinessUserQuery,
  useCreateBusinessUserMutation,
  useGetUsageLimitsQuery,
  useUpdateCurrentBusinessUserMutation,
} = businessUsersApi;

import React, { useId, useMemo, useCallback } from 'react';
import { BaseQueryFn, TypedUseQueryHookResult } from '@reduxjs/toolkit/dist/query/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import { DateTime } from 'luxon';
import { Button, Form, FormGroup, FormFeedback, Label, Input, FormText } from 'reactstrap';

import { CountrySelect, CurrencySelect, LocaleSelect } from '../core/CurrencySelect';
import { TimezoneSelect } from '../calendar/Event';
import { contactsToReactSelectOptions } from '../contacts/Contact';
import { formSubmitHandler } from '../core/formSubmitHandler';
import type { ApiCollection, BusinessUserFormValues, ContactBasic, FormMethods, PaginationParams, TagNames } from '../types/api';

interface BusinessUserFormProps extends FormMethods<BusinessUserFormValues>, TagNames {
  contactsResult?: TypedUseQueryHookResult<ApiCollection<ContactBasic>, PaginationParams, BaseQueryFn>;
  onCancel?: (e: unknown) => unknown;
}

export const BusinessUserForm: React.FC<BusinessUserFormProps> = ({
  hookForm,
  onSubmit,
  onSubmitError,
  components,
  loadingState,
  contactsResult,
  onCancel,
}) => {
  const px = useId();
  const intl = useIntl();
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch,
  } = hookForm;
  const HeaderWrapper = components?.head ?? 'legend';
  const BodyWrapper = components?.body ?? 'div';
  const FooterWrapper = components?.foot ?? 'fieldset';
  const formCallback = useMemo(() => formSubmitHandler({ handleSubmit, onSubmit, onSubmitError }), [handleSubmit, onSubmit, onSubmitError]);
  const {
    id: watchId,
    locale: watchLocale,
  } = watch();
  const tomorrow = useMemo(() => DateTime.now().plus({ days: 1 }), []);
  const contactOptions = contactsToReactSelectOptions(intl, contactsResult?.data?.items) ?? [];
  const isUpdating = loadingState?.isFetching || loadingState?.isLoading;
  const loadingMessage = useCallback(() => intl.formatMessage({ defaultMessage: 'Loading...' }), [intl]);
  const noOptionsMessage = useCallback(() => intl.formatMessage({ defaultMessage: 'No options' }), [intl]);

  return (
    <Form
      autoComplete={watchId ? undefined : 'off'}
      onSubmit={formCallback}
      noValidate
    >
      <fieldset>
        {components?.head && (
          <HeaderWrapper>
            {!watchId && <FormattedMessage defaultMessage="New Account" />}
            {watchId && <FormattedMessage defaultMessage="Edit Account" />}
          </HeaderWrapper>
        )}
        <BodyWrapper>
          <FormGroup>
            <Label htmlFor={`${px}email`}>
              <FormattedMessage defaultMessage="Email" />
            </Label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  id={`${px}email`}
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.email)}
                  disabled={watchId || isUpdating || isSubmitting}
                  type="email"
                />
              )}
            />
            <FormFeedback>
              {errors?.email?.message}
            </FormFeedback>
          </FormGroup>
          <FormGroup hidden>
            <Label htmlFor={`${px}mobile`}>
              <FormattedMessage defaultMessage="Mobile" />
            </Label>
            <Controller
              name="mobile"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  id={`${px}mobile`}
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.mobile)}
                  disabled={isUpdating || isSubmitting}
                  type="tel"
                />
              )}
            />
            <FormFeedback>
              {errors?.mobile?.message}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={`${px}country`}>
              <FormattedMessage defaultMessage="Country" />
            </Label>
            <CountrySelect
              hookForm={hookForm}
              fieldName="country"
              isClearable={false}
              filter={(isoCode) => !['ZZ'].includes(isoCode)}
              disabled={watchId || isUpdating || isSubmitting}
            />
            <FormFeedback>
              {errors?.country?.message}
            </FormFeedback>
            <FormText>
              <FormattedMessage defaultMessage="You can't change country after registration." />
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={`${px}timezone`}>
              <FormattedMessage defaultMessage="Timezone" />
            </Label>
            <TimezoneSelect
              hookForm={hookForm}
              id={`${px}timezone`}
              fieldName="timezone"
              isClearable={false}
              disabled={isUpdating || isSubmitting}
            />
            <FormFeedback>
              {errors?.timezone?.message}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={`${px}default_currency`}>
              <FormattedMessage defaultMessage="Currency" />
            </Label>
            <CurrencySelect
              hookForm={hookForm}
              fieldName="default_currency"
              id={`${px}default_currency`}
              disabled={isUpdating || isSubmitting}
            />
            <FormFeedback>
              {errors?.default_currency?.message}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={`${px}locale`}>
              <FormattedMessage defaultMessage="Formats" />
            </Label>
            <LocaleSelect
              hookForm={hookForm}
              fieldName="locale"
              isClearable={false}
              disabled={isUpdating || isSubmitting}
            />
            <FormFeedback>
              {errors?.locale?.message}
            </FormFeedback>
            <FormText>
              <FormattedMessage
                defaultMessage="Changes how dates looks like. Example {date}"
                values={{
                  date: <mark>{tomorrow.toLocaleString(DateTime.DATETIME_SHORT, { locale: watchLocale?.value ?? 'en-US' })}</mark>,
                }}
              />
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={`${px}locale_messages`}>
              <FormattedMessage defaultMessage="Language" />
            </Label>
            <LocaleSelect
              hookForm={hookForm}
              id={`${px}locale_messages`}
              fieldName="locale_messages"
              isClearable={false}
              options={['en', 'ru']}
              disabled={isUpdating || isSubmitting}
            />
            <FormFeedback>
              {errors?.locale_messages?.message}
            </FormFeedback>
          </FormGroup>
          {contactsResult && (
            <FormGroup>
              <Label htmlFor={`${px}contact`}>
                <FormattedMessage defaultMessage="Contact" />
              </Label>
              {/**
              * @link https://codesandbox.io/s/react-hook-form-v7-controller-5h1q5
              */}
              <Controller
                name="contact"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    isDisabled={contactsResult?.isLoading || isSubmitting}
                    isClearable={false}
                    isLoading={contactsResult?.isLoading}
                    options={contactOptions}
                    placeholder={intl.formatMessage({ defaultMessage: 'Select a contact...' })}
                    isMulti={false}
                    loadingMessage={loadingMessage}
                    noOptionsMessage={noOptionsMessage}
                  />
                )}
              />
              <FormFeedback>
                {errors?.contact?.message}
              </FormFeedback>
              <FormText>
                <FormattedMessage defaultMessage="Default organizer of new events" />
              </FormText>
            </FormGroup>
          )}
        </BodyWrapper>
      </fieldset>
      {components?.foot && (
        <FooterWrapper>
          <div className="d-flex justify-content-end">
            <Button
              disabled={isUpdating}
              type="button"
              onClick={onCancel}
            >
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
            <Button
              type="submit"
              color="primary"
              className="ms-3"
              disabled={isUpdating || isSubmitting || loadingState?.isSuccess}
            >
              {isSubmitting && <FormattedMessage defaultMessage="Saving" />}
              {!isSubmitting && <FormattedMessage defaultMessage="Save" />}
            </Button>
          </div>
        </FooterWrapper>
      )}
    </Form>
  );
};

import { useId, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller } from 'react-hook-form';
import { Button, Form, FormGroup, Input, FormFeedback, Label } from 'reactstrap';

import { formSubmitHandler } from './formSubmitHandler';
import { useAppSelector } from '../hooks';
import type { FormMethods, TagNames } from '../types/api';

interface ResetPasswordFormProps extends FormMethods<{ password: string; force_weak_password: string; }>, TagNames {
  inputs?: {
    force_weak_password?: {
      hidden?: boolean;
    }
  }
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  hookForm: {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  },
  disabled,
  onSubmit,
  onSubmitError,
  components,
  loadingState,
  inputs,
}) => {
  const px = useId();
  const { locale_messages } = useAppSelector((store) => store.intl);
  const HeaderWrapper = components?.head ?? 'legend';
  const BodyWrapper = components?.body ?? 'div';
  const FooterWrapper = components?.foot ?? 'fieldset';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formCallback = useMemo(() => formSubmitHandler<{ password: string; force_weak_password: string; }>({ handleSubmit, onSubmit, onSubmitError }), [handleSubmit]);
  const isUpdating = loadingState?.isFetching || loadingState?.isLoading || isSubmitting;

  return (
    <Form
      autoComplete="off"
      onSubmit={formCallback}
      noValidate
    >
      <fieldset>
        {components?.head !== undefined && (
          <HeaderWrapper>
            <FormattedMessage defaultMessage="New Password" />
          </HeaderWrapper>
        )}
        <BodyWrapper>
          <FormGroup>
            <Label htmlFor={`${px}password`} hidden>
              <FormattedMessage defaultMessage="Password" />
            </Label>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  bsSize="sm"
                  id={`${px}password`}
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.password)}
                  disabled={disabled}
                  type="password"
                />
              )}
            />
            <FormFeedback>
              {locale_messages === 'en' && errors?.password?.message}
              {locale_messages !== 'en' && errors?.password?.type === 'weak' && (
                <FormattedMessage
                  defaultMessage="Password is too week, don't use common combinations like 'qwerty', 'zxcvb' etc. Also don't use your email as a password."
                />
              )}
            </FormFeedback>
          </FormGroup>
          <FormGroup check hidden={inputs?.force_weak_password?.hidden ?? true}>
            <Label htmlFor={`${px}force_weak`}>
              <FormattedMessage defaultMessage="Use weak password, I understand and accept all consequences" />
            </Label>
            <Controller
              name="force_weak_password"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  id={`${px}force_weak`}
                  innerRef={(e) => field.ref(e)}
                  disabled={disabled}
                  type="checkbox"
                />
              )}
            />
          </FormGroup>
        </BodyWrapper>
      </fieldset>
      {components?.foot !== undefined && (
        <FooterWrapper>
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              color="primary"
              disabled={isUpdating || isSubmitting}
              block
            >
              {isSubmitting && <FormattedMessage defaultMessage="Saving..." />}
              {!isSubmitting && <FormattedMessage defaultMessage="Save" />}
            </Button>
          </div>
        </FooterWrapper>
      )}
    </Form>
  );
};

import { useState } from 'react';
import { createIntl, createIntlCache, IntlShape, MissingTranslationError, RawIntlProvider } from 'react-intl';
import { Settings } from 'luxon';
import { Provider as ReduxProvider } from 'react-redux';
import { Routes, BrowserRouter, Route, Navigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en'; // locale-data for en
import '@formatjs/intl-numberformat/locale-data/ru'; // locale-data for ru
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en'; // locale-data for en
import '@formatjs/intl-pluralrules/locale-data/ru'; // locale-data for ru
import '@formatjs/intl-listformat/polyfill';
import '@formatjs/intl-listformat/locale-data/en'; // locale-data for en
import '@formatjs/intl-listformat/locale-data/ru'; // locale-data for ru
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en'; // locale-data for en;
import '@formatjs/intl-displaynames/locale-data/ru'; // locale-data for en;
import '@formatjs/intl-locale/polyfill';

import { store } from './store';
import { getBrowserLocales, getSupportedLang } from './intl/helpers';
import { RequireAuth } from './ui/RequireAuth';
import { RedirectIfAuthorized, SigninScreen, SignoutScreen, RegisterScreen, ResetPasswordScreen, NotFoundScreen, RecoverScreen } from './ui/PublicScreen';
import { CalendarRouteElement, ValidateCalendarLink, NewEventRouteElement, EventDetailsRouteElement, EditEventRouteElement } from './ui/CalendarScreen';
import { ContactsRouteElement, ContactDetailsRouteElement, NewContactRouteElement, EditContactRouteElement } from './ui/ContactsScreen';
import { toTodayPathname } from './calendar/Calendar';
import { EditServiceRouteElement, NewServiceRouteElement, ServicesRouteElement } from './ui/ServicesScreen';
import { ValidateFinancialReportLink, FinanceRouteElement } from './ui/FinanceScreen';
import { EditTemplateRouteElement, NewTemplateRouteElement, TemplatesRouteElement } from './ui/TemplatesScreen';
import { PlansRouteElement } from './ui/PlansScreen';
import { SettingsRouteElement } from './ui/SettingsScreen';
import { HomeRouteElement } from './ui/HomeScreen';
import ruMessages from '../compiled-lang/ru.json';

const initialLocale = 'en-US';
const translations = ['en', 'ru'];
const browserLocales: Set<string> = getBrowserLocales();
const primaryLocale = Array.from(browserLocales)[0] ?? 'en-US';
const closest = getSupportedLang({ locale: Array.from(browserLocales), languages: translations, fallbackLanguage: 'en' });
Settings.defaultLocale = primaryLocale ?? initialLocale;

/** @link https://github.com/react-ga/react-ga */
const { GA_TRACKING_CODE } = process.env;
ReactGA.initialize(GA_TRACKING_CODE ?? '', {
  testMode: process.env.NODE_ENV === 'development',
});

/** @link https://github.com/formatjs/formatjs/issues/465#issuecomment-643887472 */
const intlConfig = (process.env.NODE_ENV === 'development')
  ? { onError: (err: MissingTranslationError) => {
    if (['MISSING_TRANSLATION', 'MISSING_DATA'].includes(err.code)) {
      // ignore completely
      return;
    }
    throw err;
  } }
  : {};

export const App: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cache] = useState(createIntlCache());
  const [intl, setIntl] = useState<IntlShape>(createIntl(
    { ...intlConfig, locale: primaryLocale, defaultLocale: initialLocale, messages: closest === 'ru' ? ruMessages : undefined },
    cache,
  ));

  /**
   * @link https://github.com/formatjs/formatjs/blob/main/packages/react-intl/examples/HandleChange.tsx
   * You could use redux to get the locale or get it from the url.
   */
  const changeLanguage = (newLocale: string, messageLocale?: string): void => {
    document.documentElement.lang = newLocale;
    Settings.defaultLocale = newLocale;
    const lang = getSupportedLang({ locale: [messageLocale ?? newLocale], languages: translations, fallbackLanguage: 'en' });
    setIntl(
      createIntl(
        {
          ...intlConfig,
          locale: newLocale,
          messages: (lang === 'ru') ? ruMessages : undefined,
          defaultLocale: initialLocale,
        },
        cache,
      ),
    );
  };

  return (
    <RawIntlProvider value={intl}>
      <BrowserRouter>
        <ReduxProvider store={store}>
          <Routes>
            <Route path="/" element={<RedirectIfAuthorized changeLanguage={changeLanguage} />}>
              {/* public urls without authentication */}
              <Route index element={<HomeRouteElement />} />
              <Route path="signin" element={<SigninScreen />} />
              <Route path="recover" element={<RecoverScreen />} />
              <Route path="register" element={<RegisterScreen />} />
              {/* seems we don't need forgot password page, since
              we can trigger recovery mail from login page */}
              <Route path="reset-password" element={<ResetPasswordScreen />} />
              {/* fallback not found */}
              <Route path="*" element={<NotFoundScreen />} />
            </Route>
            <Route element={<RequireAuth changeLanguage={changeLanguage} />}>
              {/* private urls with required authentication */}
              <Route
                path="calendar/:year/:month/:day/"
                element={(
                  <ValidateCalendarLink>
                    <CalendarRouteElement />
                  </ValidateCalendarLink>
                )}
              />
              <Route
                path="calendar/:year/:month/"
                element={(
                  <ValidateCalendarLink>
                    <CalendarRouteElement />
                  </ValidateCalendarLink>
                )}
              />
              <Route
                path="calendar/:year/"
                element={(
                  <ValidateCalendarLink>
                    <CalendarRouteElement />
                  </ValidateCalendarLink>
                )}
              />
              {/* if date not specified redirect to today date */}
              <Route
                path="calendar/*"
                element={(
                  <Navigate
                    to={{
                      pathname: toTodayPathname(),
                    }}
                    replace
                  />
                )}
              />
              <Route path="contacts" element={<ContactsRouteElement />} />
              <Route path="contacts/new" element={<NewContactRouteElement />} />
              <Route path="contacts/:contactId" element={<ContactDetailsRouteElement />} />
              <Route path="contacts/:contactId/edit" element={<EditContactRouteElement />} />
              <Route path="events/new" element={<NewEventRouteElement />} />
              <Route path="events/:eventId" element={<EventDetailsRouteElement />} />
              <Route path="events/:eventId/edit" element={<EditEventRouteElement />} />
              <Route
                path="finance"
                element={(
                  <ValidateFinancialReportLink>
                    <FinanceRouteElement />
                  </ValidateFinancialReportLink>
                )}
              />
              <Route path="plans" element={<PlansRouteElement />} />
              <Route path="services" element={<ServicesRouteElement />} />
              <Route path="services/new" element={<NewServiceRouteElement />} />
              <Route path="services/:serviceId/edit" element={<EditServiceRouteElement />} />
              <Route path="templates" element={<TemplatesRouteElement />} />
              <Route path="templates/new" element={<NewTemplateRouteElement />} />
              <Route path="templates/:templateId/edit" element={<EditTemplateRouteElement />} />
              <Route path="settings" element={<SettingsRouteElement />} />
              <Route path="signout" element={<SignoutScreen />} />
            </Route>
          </Routes>
        </ReduxProvider>
      </BrowserRouter>
    </RawIntlProvider>
  );
};

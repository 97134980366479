import { Fragment, ReactElement, ReactNode, useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { TypedUseQueryStateResult, BaseQueryFn, skipToken } from '@reduxjs/toolkit/dist/query/react';
import { FormattedMessage } from 'react-intl';
import { useLocation, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { Container, Col } from 'reactstrap';

import { debounce } from 'lodash';
import { BaseToolbar } from '../core/BaseToolbar';
import { LoadingBar } from '../core/LoadingBar';
import { businessUsersApi } from '../business_user/businessUsersApi';
import { useDeleteFinancialRecordMutation, useGetFinancialRecordsQuery } from '../finance/financeApi';

import { FinancePanel, FiltersToolbar, reduceUsersBalances, BalanceList } from '../finance/Finance';
import { mergeRtkQueryStates } from '../api/fetchBaseQuery';
import useHeaderAndFooterHeight from '../core/useHeaderAndFooterHeight';
import { DemoUserDisclaimer } from '../core/DemoUserDisclaimer';
import type { GetFinancialRecordsParams, BusinessUser, FinancialRecord, FinancialRecordsResponse, UsageLimit } from '../types/api';

export const ValidateFinancialReportLink = ({ children }: { children: ReactElement }): JSX.Element => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  let redirectToDefaults = true;
  if (query.has('filter_from')) {
    const from = DateTime.fromISO(query.get('filter_rom'));
    const to = (query.has('filter_to')) ? DateTime.fromISO(query.get('filter_to')) : null;

    redirectToDefaults = from.isValid && (to === null || to.isValid);
  }

  if (redirectToDefaults) {
    query.set('filter_from', DateTime.now().minus({ days: 30 }).toISODate());
    return <Navigate to={{ search: query.toString() }} replace />;
  }

  return children;
};

interface FinanceScreenProps {
  businessUser: TypedUseQueryStateResult<BusinessUser, void, BaseQueryFn>;
  financialRecords: TypedUseQueryStateResult<FinancialRecordsResponse, GetFinancialRecordsParams, BaseQueryFn>;
  usageLimits: TypedUseQueryStateResult<UsageLimit, void, BaseQueryFn>;
  userIncomeResult: TypedUseQueryStateResult<FinancialRecordsResponse, GetFinancialRecordsParams, BaseQueryFn>;
  userExpensesResult: TypedUseQueryStateResult<FinancialRecordsResponse, GetFinancialRecordsParams, BaseQueryFn>;
  filters: GetFinancialRecordsParams;
  onFiltersChange: (filters: GetFinancialRecordsParams) => void;
}

export const FinanceScreen: React.FC<FinanceScreenProps> = ({
  businessUser,
  financialRecords,
  usageLimits,
  userIncomeResult,
  userExpensesResult,
  onLoadMore,
  pageSize,
  filters,
  onFiltersChange,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, footerHeight } = useHeaderAndFooterHeight({ headerRef });
  const mainPaddings = useMemo(() => ({
    paddingTop: headerHeight,
    paddingBottom: footerHeight,
  }), [headerHeight, footerHeight]);
  const [cachedItems, setCachedItems] = useState<Set<FinancialRecord>>(new Set());
  const [deleteRecord, deletionState] = useDeleteFinancialRecordMutation();
  const filtersForm = useForm<GetFinancialRecordsParams>({
    defaultValues: filters,
    mode: 'onBlur',
  });
  const queryState = mergeRtkQueryStates(financialRecords, deletionState);
  const userIncomeBalance = (userIncomeResult.data?.balances)
    ? reduceUsersBalances(userIncomeResult.data.balances)
    : null;
  const userExpensesBalance = (userExpensesResult.data?.balances)
    ? reduceUsersBalances(userExpensesResult.data.balances)
    : null;
  /* eslint-disable-next-line formatjs/no-literal-string-in-jsx */
  const userIncome = (userIncomeBalance && userIncomeBalance?.size > 0) ? <strong><BalanceList balances={Object.fromEntries(userIncomeBalance)} type="inline" /></strong> : <strong className="text-muted">0</strong>;
  /* eslint-disable-next-line formatjs/no-literal-string-in-jsx */
  const userExpenses = (userExpensesBalance && userExpensesBalance?.size > 0) ? <strong><BalanceList balances={Object.fromEntries(userExpensesBalance)} type="inline" /></strong> : <strong className="text-muted">0</strong>;
  const filtersWatch = filtersForm.watch();
  useEffect(() => {
    if (financialRecords.isSuccess && financialRecords.originalArgs?.page === 1 && cachedItems.size > 0) {
      setCachedItems(new Set());
    }
  }, [financialRecords.requestId, financialRecords.isSuccess]);

  useEffect(() => {
    onFiltersChange({ ...filtersWatch, page: 1 });
  }, [
    filtersWatch.filter_from,
    filtersWatch.filter_to,
    filtersWatch.filter_user_min,
    filtersWatch.filter_user_max,
    filtersWatch.filter_service,
    filtersWatch.filter_contact,
    filtersWatch.filter_event,
  ]);

  const now = DateTime.now();
  const fromDt = DateTime.fromISO(filtersWatch.filter_from);
  const toDt = DateTime.fromISO(filtersWatch.filter_to);
  const fromTime = (
    <time
      dateTime={filtersWatch.filter_from}
      title={fromDt.toLocaleString(DateTime.DATE_HUGE)}
    >
      {fromDt.toLocaleString(DateTime.DATE_SHORT)}
    </time>
  );
  const toTime = (
    <time
      dateTime={(toDt.isValid ? filtersWatch.filter_to : now.toISODate())}
      title={toDt.isValid ? toDt.toLocaleString(DateTime.DATE_HUGE) : now.toLocaleString(DateTime.DATE_HUGE)}
    >
      {toDt.isValid && toDt.toLocaleString(DateTime.DATE_SHORT)}
      {!toDt.isValid && now.toRelativeCalendar()}
    </time>
  );
  const c1: React.FC<ReactNode> = (chunk) => <span className="d-table-cell w-100 px-3 text-start">{chunk}</span>;
  const c2: React.FC<ReactNode> = (chunk) => <span className="d-table-cell px-3">{chunk}</span>;

  return (
    <div id="finance-screen">
      <header ref={headerRef}>
        <BaseToolbar
          title={<FormattedMessage defaultMessage="Budget Tracker" />}
        />
        <Container className="text-center visually-hidden" fluid="sm">
          <FiltersToolbar
            hookForm={filtersForm}
            loadingState={queryState}
          />
        </Container>
        {deletionState.isUninitialized && <LoadingBar loadings={[businessUser, financialRecords, usageLimits, userIncomeResult, userExpensesResult]} />}
        {/* always 25% while submitting form */}
        {deletionState.isLoading && <LoadingBar loadings={[{}]} />}
      </header>
      <main style={mainPaddings}>
        <Container className="pt-3" fluid="sm">
          <Col md={{ size: 6, offset: 3 }}>
            {businessUser.data?.is_demo && <DemoUserDisclaimer />}
            <section>
              <div className="h3">
                {(userIncomeBalance || userExpensesBalance) && (
                  <Fragment>
                    {!toDt.isValid && (
                      <FormattedMessage
                        defaultMessage="Totals since {from}:"
                        values={{
                          from: fromTime,
                        }}
                      />
                    )}
                    {toDt.isValid && (
                      <FormattedMessage
                        defaultMessage="Totals from {from} to {to}:"
                        values={{
                          from: fromTime,
                          to: toTime,
                        }}
                      />
                    )}
                  </Fragment>
                )}
              </div>
              <div className="d-table mb-3">
                <div className="h5 text-end d-table-row ps-0">
                  <FormattedMessage
                    defaultMessage="<c1>Incomes</c1><c2>{income}</c2>"
                    values={{
                      income: userIncome,
                      c1,
                      c2,
                    }}
                  />
                </div>
                <div className="h5 text-end d-table-row">
                  <FormattedMessage
                    defaultMessage="<c1>Expenses</c1><c2>{expenses}</c2>"
                    values={{
                      expenses: userExpenses,
                      c1,
                      c2,
                    }}
                  />
                </div>
              </div>
              <FinancePanel
                items={financialRecords}
                cachedItems={Array.from(cachedItems)}
                onLoadMore={() => {
                  const allCached = [...Array.from(cachedItems), ...(financialRecords.data?.items ?? [])];
                  setCachedItems(new Set(allCached));
                  onLoadMore();
                }}
                page_size={pageSize}
                onDelete={async (item: FinancialRecord) => {
                  try {
                    await deleteRecord(item.id).unwrap();
                  } catch (e) {
                    return false;
                  }
                  return true;
                }}
              />
            </section>
          </Col>
        </Container>
      </main>
    </div>
  );
};

export const FinanceRouteElement: React.FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const businessUser = businessUsersApi.endpoints.getCurrentBusinessUser.useQueryState();
  const usageLimits = businessUsersApi.endpoints.getUsageLimits.useQueryState();
  const skipUntilLoaded = (!businessUser.isSuccess) ? skipToken : undefined;
  const [commonParams, setCommonParams] = useState<GetFinancialRecordsParams>({
    page: 1,
    page_size: 10,
    sort_order: 'DESC',
    filter_from: query.get('filter_from') ?? undefined,
    filter_to: query.get('filter_to') ?? undefined,
    filter_business_user: query.get('filter_business_user') ?? undefined,
    filter_contact: query.get('filter_contact') ?? undefined,
    filter_event: query.get('filter_event') ?? undefined,
    filter_service: query.get('filter_service') ?? undefined,
    filter_user_min: (typeof query.get('filter_user_min') === 'string') ? parseFloat(query.get('filter_user_min')) : undefined,
    filter_user_max: (typeof query.get('filter_user_max') === 'string') ? parseFloat(query.get('filter_user_max')) : undefined,
    filter_contact_min: (typeof query.get('filter_contact_min') === 'string') ? parseFloat(query.get('filter_contact_min')) : undefined,
    filter_contact_max: (typeof query.get('filter_contact_max') === 'string') ? parseFloat(query.get('filter_contact_max')) : undefined,
  });
  const onFiltersChange = useCallback(debounce((filters: GetFinancialRecordsParams) => {
    setCommonParams(filters);
  }, 2000), []);

  const financialRecords = useGetFinancialRecordsQuery(skipUntilLoaded ?? commonParams);
  // all time income and expenses doesn't make sense to this screen
  // usually user wants stats for a month, quarter, year etc.
  // beside, he can change "filter_from" value to show all time numbers
  const userIncomeResult = useGetFinancialRecordsQuery(skipUntilLoaded ?? {
    ...commonParams,
    page: 1,
    page_size: 1,
    filter_user_min: 0,
    filter_user_max: undefined,
  });
  const userExpensesResult = useGetFinancialRecordsQuery(skipUntilLoaded ?? {
    ...commonParams,
    page: 1,
    page_size: 1,
    filter_user_min: undefined,
    filter_user_max: 0,
  });

  return (
    <FinanceScreen
      businessUser={businessUser}
      financialRecords={financialRecords}
      usageLimits={usageLimits}
      filters={commonParams}
      userIncomeResult={userIncomeResult}
      userExpensesResult={userExpensesResult}
      onFiltersChange={onFiltersChange}
      pageSize={commonParams.page_size}
    />
  );
};

import { ReactNode, Fragment, useRef, useEffect } from 'react';
import { DateTime, type MonthNumbers, type DayNumbers, type WeekNumbers, DateTimeFormatOptions } from 'luxon';
import { FormattedMessage } from 'react-intl';
import { Link, generatePath, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Row, Col, Button, Container } from 'reactstrap';

import { EventList, EventItem } from './Event';
import type { EventsMapQueryResult, EventsQueryResult, TagNames } from '../types/api';
import useNow from './useNow';

/**
 * TODO: handle loading and server side errors
 */
export interface toCalendarPathnameProps {
  readonly year: number;
  readonly month?: number | null;
  readonly day?: number | null;
}

export const toCalendarPathname = ({ year, month = null, day = null }: toCalendarPathnameProps): string => {
  let pathPlaceholder = '/calendar/:year/';
  const params: { year: string; month?: string; day?: string } = { year: year.toString() };
  if (typeof month === 'number' && month > 0 && month < 13) {
    pathPlaceholder = `${pathPlaceholder}:month/`;
    params.month = month.toString().padStart(2, '0');

    // add day only when month specified
    if (typeof day === 'number' && day > 0 && day < 32) {
      pathPlaceholder = `${pathPlaceholder}:day/`;
      params.day = day.toString().padStart(2, '0');
    }
  }

  return generatePath(pathPlaceholder, params);
};

export const toTodayPathname = (): string => toCalendarPathname(DateTime.now().toObject());

interface CalendarLinkProps extends toCalendarPathnameProps, TagNames<'btn' | 'mark' | 'inner'> {
  readonly active?: boolean | null;
  readonly events?: number | null;
  readonly hidden?: boolean | null;
  readonly dateFormat?: DateTimeFormatOptions | null;
}

const DateLink: React.FC<CalendarLinkProps> = ({
  year,
  month = null,
  day = null,
  active = false,
  events = 0,
  hidden = false,
  dateFormat = null,
  components,
}) => {
  const now = DateTime.now();
  const pathname = toCalendarPathname({ year, month, day });
  const yearDate = DateTime.fromObject({ year });
  const monthDate = (typeof month === 'number' && month > 0 && month < 13) ? DateTime.fromObject({ year, month }) : null;
  const dayDate = (typeof month === 'number' && monthDate?.isValid && typeof day === 'number' && day > 0 && day < 32) ? DateTime.fromObject({ year, month, day }) : null;
  const flags = {
    'ca-now-day': false,
    'ca-weekend': false,
    'ca-active-day': active === true,
    'ca-with-events': typeof events === 'number' && events > 0,
  } as Record<string, boolean>;

  let iso: string = year.toString();
  let dateLabel: string = yearDate.toLocaleString(dateFormat ?? { year: 'numeric' });
  let isToday = false;
  let isWeekend = false;
  if (monthDate?.isValid) {
    iso = `${iso}-${monthDate.month.toString().padStart(2, '0')}`;
    dateLabel = monthDate.toLocaleString(dateFormat ?? { year: 'numeric', month: 'long' });

    // extend formatting only when month specified
    if (dayDate?.isValid) {
      iso = dayDate.toISODate();
      dateLabel = dayDate.toLocaleString(dateFormat ?? DateTime.DATE_FULL);
      isToday = now.hasSame(dayDate, 'day') && now.hasSame(dayDate, 'year');
      isWeekend = [6, 7].includes(dayDate.weekday);
      flags['ca-now-day'] = isToday;
      flags['ca-weekend'] = isWeekend;
    }
  }

  const MarkTagElement = components?.mark ?? 'mark';
  const InnerTagElement = components?.inner ?? 'span';
  const InnerTag = (isWeekend || isToday || active) ? MarkTagElement : InnerTagElement;
  const innerTagClassName = Object.keys(flags).filter((f) => flags?.[f] === true).join(' ');
  const BtnTag = components?.btn ?? Button;

  return (
    <BtnTag
      to={{
        pathname,
      }}
      color="link"
      tag={Link}
    >
      <InnerTag className={innerTagClassName}>
        <time
          dateTime={iso}
          className={`${hidden === true ? 'visually-hidden' : ''}`}
        >
          {dateLabel}
        </time>
        {isToday && (
          <span className="visually-hidden">
            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            {' '}
            <FormattedMessage defaultMessage="(today)" />
          </span>
        )}
        {typeof events === 'number' && events > 0 && (
          <span className="visually-hidden">
            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            {' '}
            <FormattedMessage
              defaultMessage="This date contains {num, plural, =0 {no events} one {1 event} other {{num, number, ::compact-short} events}}"
              values={{ num: events }}
            />
          </span>
        )}
      </InnerTag>
    </BtnTag>
  );
};

interface CalendarWeekProps extends TagNames<'dayTag'> {
  year: number;
  week: WeekNumbers;
  month?: MonthNumbers | null;
  day?: DayNumbers | null;
  eventsMap?: EventsMapQueryResult | null;
}

export const CalendarWeek: React.FC<CalendarWeekProps> = ({
  year,
  week,
  month = null,
  day = null,
  components,
  eventsMap = null,
}) => {
  const start = DateTime.fromObject({ weekYear: year, weekNumber: week }).startOf('week');
  const weekDaysList = Array.from({ length: 7 }, (v, index) => start.plus({ days: index }));
  const dayTag = components?.dayTag ?? Button;

  return (
    <Fragment>
      {weekDaysList.map((weekDay) => {
        const iso = weekDay.toISODate();
        const isSameMonth = month === null || weekDay.month === month;
        const isActive = day !== null && day === weekDay.day;
        const dateFormat: DateTimeFormatOptions = { day: 'numeric' };

        return (
          <div key={`cal_cell_${iso}`}>
            <DateLink
              year={weekDay.year}
              month={weekDay.month}
              day={weekDay.day}
              active={isActive}
              events={eventsMap?.data?.map?.[iso]}
              hidden={isSameMonth === false}
              dateFormat={dateFormat}
              components={{
                btn: dayTag,
              }}
            />
          </div>
        );
      })}
    </Fragment>
  );
};

export const CalendarWeekdays: React.FC = () => (
  // TODO: add year, month, day params for screen readers
  <Fragment>
    {Array.from({ length: 7 }, (v, index) => {
      const day = index + 1;
      const dt = DateTime.fromObject({ weekday: day });
      const classes = [];
      // is weekend
      if ([6, 7].includes(day)) {
        classes.push('ca-weekend');
      }

      return (
        <div className={classes.join(' ')} key={`cal_wk_${day}`}>
          <span aria-hidden>
            {dt.toLocaleString({ weekday: 'narrow' })}
          </span>
          <span className="visually-hidden">
            {dt.toLocaleString({ weekday: 'long' })}
          </span>
        </div>
      );
    })}
  </Fragment>
);

interface CalendarToolbarProps {
  year?: number;
  month?: MonthNumbers | null;
  day?: DayNumbers | null;
  back?: ReactNode | null;
  forward?: ReactNode | null;
  eventsMap?: EventsMapQueryResult | null;
}

export const CalendarToolbar: React.FC<CalendarToolbarProps> = ({
  year,
  month = null,
  day = null,
  back = null,
  forward = null,
  eventsMap = null,
}) => {
  const date = DateTime.fromObject({
    year,
    month: typeof month === 'number' ? month : undefined,
    day: typeof day === 'number' ? day : undefined,
  });
  const { search } = useLocation();
  const nextWeekPath = toCalendarPathname(date.plus({ days: 7 }).toObject());
  const prevWeekPath = toCalendarPathname(date.minus({ days: 7 }).toObject());

  return (
    <div className="ca-toolbar">
      <div className="d-flex w-100 justify-content-between align-items-center">
        <div className="flex-shrink-1">
          {back}
        </div>
        {month && !day && (
          <div className="ca-active-month">
            {date.toLocaleString({ month: 'long' })}
          </div>
        )}
        <div className="flex-shrink-1">
          {forward}
        </div>
      </div>
      <Container className="ca">
        <div className="ca-body">
          {month && (
            <div className="ca-row ca-weekdays">
              {typeof day === 'number' && <div />}
              <CalendarWeekdays />
              {typeof day === 'number' && <div />}
            </div>
          )}
          {day && (
            <div className="ca-row ca-days">
              <div>
                <Button
                  color="link"
                  className="arrow"
                  tag={Link}
                  to={{
                    pathname: prevWeekPath,
                    search,
                  }}
                >
                  <span className="visually-hidden">
                    <FormattedMessage defaultMessage="Go to previous week" />
                  </span>
                  <FontAwesomeIcon icon={solid('chevron-circle-left')} size="1x" />
                </Button>
              </div>
              <CalendarWeek
                year={date.weekYear}
                week={date.weekNumber}
                day={date.day}
                eventsMap={eventsMap}
              />
              <div>
                <Button
                  color="link"
                  className="arrow"
                  tag={Link}
                  to={{
                    pathname: nextWeekPath,
                    search,
                  }}
                >
                  <span className="visually-hidden">
                    <FormattedMessage defaultMessage="Go to next week" />
                  </span>
                  <FontAwesomeIcon icon={solid('chevron-circle-right')} size="1x" />
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="ca-active-date">
          {day && (
            <time dateTime={date.toISODate()}>
              {date.toLocaleString(DateTime.DATE_HUGE)}
            </time>
          )}
        </div>
      </Container>
    </div>
  );
};

interface VerticalTimeGridProps {
  start?: DateTime | null;
  end?: DateTime | null;
  gridStepMins?: number;
  events?: EventsQueryResult | null;
}

export const VerticalTimeGrid: React.FC<VerticalTimeGridProps> = ({
  start = null,
  end = null,
  events = null,
  gridStepMins = 60,
}) => {
  const gridStart = (DateTime.isDateTime(start)) ? start : DateTime.now().startOf('day');
  const gridEnd = (DateTime.isDateTime(end)) ? end : DateTime.now().endOf('day');
  const totalMins = Math.round(gridEnd.diff(gridStart, 'minutes').get('minute'));
  const remPerUnit = 6; // approx 2 lines of text
  const remPerHour = (60 / gridStepMins) * 6;
  const remPerMin = remPerHour / 60;
  const unitsLength = Math.floor(totalMins / gridStepMins);
  const lastIndex = unitsLength - 1;
  const units = Array.from({ length: unitsLength }, (v, i) => gridStart.plus({ minutes: gridStepMins * i }));
  const gridTimeFormat: DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };

  const { now } = useNow();
  const visibleEvents = events?.data?.items?.filter(({ start: eventStart, end: eventEnd }) => {
    const s = DateTime.fromISO(eventStart);
    const e = DateTime.fromISO(eventEnd);
    return (gridStart <= s && s < gridEnd) || (gridStart < e && e <= gridEnd);
  });

  return (
    <div className="d-flex">
      <div className="text-end fw-semibold small text-muted position-relative">
        {units.map((slot, i) => (
          <div
            className="pe-2 position-relative"
            style={{ height: `${remPerUnit}rem` }}
            key={`grid_label_${slot.toUnixInteger()}`}
          >
            <time dateTime={`${slot.toISOTime()}`}>
              <span
                className="d-block"
                style={{ transform: 'translateY(-50%)' }}
              >
                {slot.toLocaleString(gridTimeFormat)}
              </span>
            </time>
            {/* add last timestamp at the bottom */}
            {i === lastIndex && (
              <time dateTime={`${slot.plus({ minutes: gridStepMins }).toISOTime()}`}>
                <span
                  className="d-block position-absolute"
                  style={{ transform: 'translateY(50%)', bottom: 0 }}
                >
                  {slot.plus({ minutes: gridStepMins }).toLocaleString(gridTimeFormat)}
                </span>
              </time>
            )}
          </div>
        ))}
        {/* add line with current time if presented */}
        {gridStart < now && now < gridEnd && (
          <div
            className="pe-2 position-absolute w-100"
            /**
             * can't round value because position becomes inaccurate
             * even half of percent does matter
             */
            style={{ top: `${now.diff(gridStart, 'minutes').get('minute') * remPerMin}rem` }}
            // style={{ top: `${now.diff(gridStart, 'minutes').get('minute') / totalMins * 100}%` }}
            // key={`grid_label_${i}`}
          >
            <time dateTime={`${now.toISOTime()}`}>
              <span
                className="d-block text-danger"
                style={{ transform: 'translateY(-50%)' }}
              >
                {now.toLocaleString(gridTimeFormat)}
              </span>
            </time>
          </div>
        )}
      </div>
      <div className="position-relative flex-grow-1 small">
        {units.map((slot, i) => (
          <div
            className={`border-top ${i === lastIndex ? 'border-bottom' : ''}`}
            style={{ height: `${remPerUnit}rem` }}
            key={`grid_cell_${slot.toUnixInteger()}`}
          />
        ))}

        {/* add events */}
        {visibleEvents?.map(({ id, start: eventStart, end: eventEnd, timezone, summary, organizer, attendee, service }) => {
          const s = DateTime.fromISO(eventStart);
          const e = DateTime.fromISO(eventEnd);
          return (
            <div
              key={`grid-${id}`}
              style={{
                position: 'absolute',
                left: 0,
                overflowY: 'hidden',
                /**
                 * can't round value because position becomes inaccurate
                 * even half of percent does matter
                 */
                top: `${s.diff(gridStart, 'minutes').get('minute') * remPerMin}rem`,
                // top: `${start.diff(gridStart, 'minutes').get('minute') / totalMins * 100}%`,
                height: `${e.diff(s, 'minutes').get('minute') * remPerMin}rem`,
              }}
            >
              <EventItem
                id={id}
                start={eventStart}
                end={eventEnd}
                timezone={timezone}
                summary={summary}
                organizer={organizer}
                attendee={attendee}
                service={service}
              />
            </div>
          );
        })}

        {/* add line with current time if presented */}
        {gridStart < now && now < gridEnd && (
          <div
            className="border-top border-danger w-100"
            style={{
              height: '1px',
              position: 'absolute',
              /**
               * can't round value because position becomes inaccurate
               * even half of percent does matter
               */
              // top: `${now.diff(gridStart, 'minutes').get('minute') / totalMins * 100}%`,
              top: `${now.diff(gridStart, 'minutes').get('minute') * remPerMin}rem`,
            }}
          />
        )}
      </div>
    </div>
  );
};

interface CalendarMonthProps extends TagNames<'monthContainer'> {
  year?: number | null;
  month?: MonthNumbers | null;
  alignMonthName?: boolean;
  isYearVisible?: boolean;
  isMonthVisible?: boolean;
  isWeekdaysVisible?: boolean;
  eventsMap?: EventsMapQueryResult | null;
}

export const CalendarMonth: React.FC<CalendarMonthProps> = ({
  year = null,
  month = null,
  alignMonthName = true,
  isYearVisible = true,
  isMonthVisible = true,
  isWeekdaysVisible = true,
  components,
  eventsMap = null,
}) => {
  const startDay = DateTime.fromObject({
    year: year ?? DateTime.now().year,
    month: month ?? DateTime.now().month,
    day: 1,
  });
  const current = DateTime.now();
  const isCurrentMonth = current.hasSame(startDay, 'year') && current.hasSame(startDay, 'month');
  const monthLocalFormat: DateTimeFormatOptions = (isYearVisible) ? { month: 'short', year: 'numeric' } : { month: 'short' };
  const monthAriaFormat: DateTimeFormatOptions = (isYearVisible) ? { month: 'long', year: 'numeric' } : { month: 'long' };
  const weeksEachDay = [];
  // 100 / 7 = ~14.2857143
  const startLeftPadding = (startDay.weekday - 1) * 14.285;
  const MonthContainer = components?.monthContainer ?? 'div';
  const dayTag = MonthContainer === 'div' ? Button : 'span';
  const monthPathname = toCalendarPathname({ year: startDay.year, month: startDay.month });

  for (let start = startDay; start.hasSame(startDay, 'month');) {
    weeksEachDay.push(JSON.stringify([start.weekYear, start.weekNumber]));
    start = start.plus({ days: 1 });
  }

  // unique filter
  const weeks = weeksEachDay.filter((v, i, a) => a.lastIndexOf(v) === i);

  const tableHead = (
    <Fragment>
      <span aria-hidden>
        {startDay.toLocaleString(monthLocalFormat)}
      </span>
      <span className="visually-hidden">
        {startDay.toLocaleString(monthAriaFormat)}
      </span>
    </Fragment>
  );
  const headStyle = alignMonthName ? { paddingLeft: `${startLeftPadding}%` } : undefined;

  return (
    <div className="ca-month">
      <MonthContainer
        to={{
          pathname: monthPathname,
        }}
        color="link"
        className="text-reset"
        tag={Link}
        title={startDay.toLocaleString({ year: 'numeric', month: 'long' })}
      >
        {(isMonthVisible || isYearVisible) && (
          <div className="ca-head" style={headStyle}>
            {!isCurrentMonth && tableHead}
            {isCurrentMonth && <mark>{tableHead}</mark>}
          </div>
        )}
        <div className="ca-body">
          {isWeekdaysVisible && (
            <div className="ca-row ca-weekdays">
              <CalendarWeekdays />
            </div>
          )}
          {weeks.map((json) => (
            <div className="ca-row" key={`cal_week_row_${json}`}>
              <CalendarWeek
                year={JSON.parse(json)[0]}
                week={JSON.parse(json)[1]}
                month={month}
                components={{
                  dayTag,
                }}
                eventsMap={eventsMap}
              />
            </div>
          ))}
        </div>
      </MonthContainer>
    </div>
  );
};

interface CalendarYearProps {
  year?: number | null;
  eventsMap?: EventsMapQueryResult | null;
  autoFocus?: boolean;
}

export const CalendarYear: React.FC<CalendarYearProps> = ({
  year = null,
  eventsMap = null,
  autoFocus,
}) => {
  let yearDate = DateTime.now();
  if (typeof year === 'number' && DateTime.fromObject({ year }).isValid) {
    yearDate = DateTime.fromObject({ year });
  }
  const isCurrentYear = DateTime.now().hasSame(yearDate, 'year');
  const headingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (autoFocus && headingRef.current) {
      headingRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [autoFocus]);

  return (
    <div
      className="ca-year multicolumn"
      ref={headingRef}
    >
      <div className="ca-heading">
        {!isCurrentYear && yearDate.year}
        {isCurrentYear && <mark>{yearDate.year}</mark>}
      </div>
      <Row xs="3" className="g-0 g-md-3">
        {Array.from({ length: 12 }, (v, index) => (
          <Col
            key={`cal_year_${yearDate.year}_${index}`}
            className="ca-column mb-5"
          >
            <CalendarMonth
              year={yearDate.year}
              month={(index + 1) as MonthNumbers}
              isYearVisible={false}
              isWeekdaysVisible={false}
              alignMonthName={false}
              components={{
                monthContainer: Button,
              }}
              eventsMap={eventsMap}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

interface CalendarProps {
  year?: number | null;
  month?: MonthNumbers | null;
  day?: DayNumbers | null;
  eventsMap?: EventsMapQueryResult | null;
  events?: EventsQueryResult | null;
}

export const Calendar: React.FC<CalendarProps> = ({
  year = null,
  month = null,
  day = null,
  eventsMap = null,
  events = null,
}) => {
  const yearNum = DateTime.now().year ?? DateTime.now().year;
  const prevYearNum = yearNum - 1;
  const nextYearNum = yearNum + 1;

  return (
    <div>
      {!month && !day && (
        <Fragment>
          <CalendarYear year={prevYearNum} eventsMap={eventsMap} />
          <CalendarYear year={yearNum} eventsMap={eventsMap} autoFocus />
          <CalendarYear year={nextYearNum} eventsMap={eventsMap} />
        </Fragment>
      )}

      {month && !day && (
        <CalendarMonth
          year={year}
          month={month}
          isYearVisible={false}
          isMonthVisible={false}
          isWeekdaysVisible={false}
          eventsMap={eventsMap}
        />
      )}

      {day && <EventList events={events} groupByDay={false} dateShown={DateTime.fromObject({ year: year ?? undefined, month: month ?? undefined, day })} />}
    </div>
  );
};

import { emptySplitApi } from '../api/fetchBaseQuery';
import type { Template, ApiCollection, PaginationParams, TemplatePayload } from '../types/api';

export const templatesApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({

    getTemplates: build.query<ApiCollection<Template>, PaginationParams>({
      query: (params) => ({
        url: 'templates',
        params,
      }),
      providesTags: (result) => {
        /** @link https://redux-toolkit.js.org/rtk-query/usage/mutations#revalidation-example */
        if (result && Array.isArray(result?.items)) {
          // successful query
          return [
            ...result.items.map(({ id }) => ({ type: 'Templates' as const, id })),
            { type: 'Templates', id: 'LIST' },
          ];
        }
        // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
        return [{ type: 'Templates', id: 'LIST' }];
      },
    }),

    getTemplateDetails: build.query<Template, Template['id']>({
      query: (id) => `templates/${id}`,
      providesTags: (result, error, id) => [{ type: 'Templates', id }],
    }),

    createTemplate: build.mutation<Template, TemplatePayload>({
      query: (payload) => ({
        url: 'templates',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [
        { type: 'Templates', id: 'LIST' },
        { type: 'UsageLimits', id: 'ME' },
      ],
    }),

    updateTemplate: build.mutation<Template, TemplatePayload>({
      query: ({ id, ...patch }) => ({
        url: `templates/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Templates', id: 'LIST' },
        { type: 'Templates', id },
      ],
    }),

    deleteTemplate: build.mutation<void, Template['id']>({
      query: (id: string) => ({
        url: `templates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Templates', id: 'LIST' },
        { type: 'Templates', id },
        { type: 'UsageLimits', id: 'ME' },
      ],
    }),

  }),
});

export const {
  useGetTemplatesQuery,
  useGetTemplateDetailsQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
} = templatesApi;

import { Fragment, ElementType, ReactNode, useCallback, useId } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, LinkProps } from 'react-router-dom';
import { Button, ListGroup, ListGroupItem, Row, Spinner } from 'reactstrap';

import { LoadingIndicator } from './LoadingIndicator';
import type { LoadingState } from '../types/api';

interface ListElement {
  id: string;
}

export const reduceUnique = (elements: ListElement[]): ListElement[] => {
  const ids = new Set();

  return elements.filter((e: Element) => {
    const exists = ids.has(e.id);
    ids.add(e.id);
    return !exists;
  });
};

export interface ItemsListProps {
  items: ListElement[];
  loadingState?: LoadingState;
  itemsTotal?: number;
  itemsToGroup?: (args: ListElement[]) => Record<string, Array<ListElement>>;
  itemsGroupSort?: (a: string, b: string) => number;
  itemsGroupFormat?: (arg: string) => string;
  contentColumnRender?: (child: ReactNode, arg?: ListElement, index?: number) => ReactNode;
  columnsRender?: (arg: ListElement, index?: number) => ReactNode | Element[];
  itemRender?: (arg: ListElement) => ReactNode;
  itemHref?: string | ((arg: ListElement) => LinkProps);
  itemTag?: ElementType;
  pageSize?: number;
  countRender?: (count: number, total: number) => ReactNode;
  onLoadMore?: () => void;
  loadMoreRender?: (counters: { itemsCount: number; itemsTotal: number; pageSize?: number; }) => ReactNode;
}

export const ItemsList: React.FC<ItemsListProps> = ({
  items,
  loadingState,
  itemsTotal,
  itemsToGroup,
  itemsGroupSort,
  itemsGroupFormat,
  contentColumnRender,
  columnsRender,
  itemRender,
  itemHref,
  itemTag,
  pageSize,
  countRender,
  onLoadMore,
  loadMoreRender,
}) => {
  const intl = useIntl();
  const px = useId();
  const defaultRender = (e: ListElement) => JSON.stringify(e);
  const itemWrap = itemRender ?? defaultRender;
  const itemsCount = items?.length ?? 0;
  const itemsLeft = (itemsTotal) ? itemsTotal - itemsCount : 0;
  const counter = (typeof countRender === 'function') ? countRender(itemsCount, itemsTotal ?? itemsCount) : null;
  const listItemTag = itemTag ?? 'li';
  const colRender = (typeof contentColumnRender === 'function') ? contentColumnRender : (arg: ReactNode) => arg;

  let itemsRendered = null;

  const renderListItem = useCallback((item: ListElement, index: number) => {
    const href = (typeof itemHref === 'function') ? itemHref(item) : itemHref;
    const ItemTag = (href) ? Link : 'span';

    return (
      <ListGroupItem
        key={`${px}list-item${item?.id}`}
        tag={listItemTag}
      >
        <Row>
          <Fragment>
            {colRender(
              (
                <ItemTag
                  to={(ItemTag?.displayName === Link.displayName) ? href : null}
                >
                  {itemWrap(item)}
                </ItemTag>
              ),
              item,
              index,
            )}
            {typeof columnsRender === 'function' && columnsRender(item, index)}
          </Fragment>
        </Row>
      </ListGroupItem>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  if (loadingState?.isLoading && items.length < 1) return <div className="text-center"><LoadingIndicator /></div>;

  const loadMoreButton = (typeof onLoadMore === 'function' && itemsTotal && itemsCount < itemsTotal) ? (
    <Fragment>
      {typeof loadMoreRender === 'function' && loadMoreRender({ itemsCount, itemsTotal, pageSize })}

      {typeof loadMoreRender !== 'function' && (
        <div className="text-center">
          <Button
            disabled={loadingState?.isFetching || loadingState?.isLoading}
            onClick={onLoadMore}
          >
            {loadingState?.isFetching && (
              <FormattedMessage
                defaultMessage="Loading {spinner}"
                values={{
                  spinner: <Spinner size="sm" />,
                }}
              />
            )}
            {!loadingState?.isFetching && (
              <FormattedMessage
                defaultMessage="Load {num, plural, one {# more item} other {# more items}}"
                values={{
                  num: Math.min(pageSize ?? 0, itemsLeft),
                }}
              />
            )}
          </Button>
        </div>
      )}
    </Fragment>
  ) : null;

  if (typeof itemsToGroup === 'function') {
    const map = itemsToGroup(items ?? []);
    itemsRendered = Object.keys(map)
      .sort(itemsGroupSort)
      .map((name) => (
        <ListGroup
          flush
          className="mb-3"
          key={`${px}-group-${name}`}
        >
          <ListGroupItem
            href={`#${name}`}
            id={`${name}`}
            tag="a"
          >
            <small className="text-muted">
              {(typeof itemsGroupFormat === 'function') ? itemsGroupFormat(name) : name}
            </small>
          </ListGroupItem>
          {map[name].map(renderListItem)}
        </ListGroup>
      ));
  } else {
    itemsRendered = (
      <ListGroup flush>
        {items?.map(renderListItem)}
      </ListGroup>
    );
  }

  return (
    <div className={`${loadingState?.isFetching ? 'opacity-50' : ''}`}>
      {itemsRendered}
      {loadMoreButton}
      {counter}
    </div>
  );
};

import { emptySplitApi } from '../api/fetchBaseQuery';

import type { ApiCollection, Import, GetImportsParams } from '../types/api';

export const importsApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({

    getImports: build.query<ApiCollection<Import>, GetImportsParams>({
      query: (params) => ({
        url: 'imports/contacts',
        params,
      }),
      providesTags: (result) => {
        /** @link https://redux-toolkit.js.org/rtk-query/usage/mutations#revalidation-example */
        if (result && Array.isArray(result?.items)) {
          // successful query
          return [
            ...result.items.map(({ id }) => ({ type: 'ImportsContacts' as const, id })),
            { type: 'ImportsContacts', id: 'LIST' },
          ];
        }
        // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
        return [{ type: 'ImportsContacts', id: 'LIST' }];
      },
    }),

    getImportDetails: build.query<Import, Import['id']>({
      query: (id) => `imports/contacts/${id}`,
      providesTags: (result, error, id) => [{ type: 'ImportsContacts', id }],
    }),

    importVCard: build.mutation<Import, File>({
      query: (file: File) => {
        const data = new FormData();
        data.append('file', file, file.name);

        return {
          url: 'imports/contacts',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: () => [{ type: 'ImportsContacts', id: 'LIST' }, { type: 'UsageLimits', id: 'ME' }],
    }),

  }),
  overrideExisting: false,
});

export const {
  useGetImportsQuery,
  useGetImportDetailsQuery,
  useImportVCardMutation,
} = importsApi;

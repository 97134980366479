import { ReactElement, Fragment, useId, MouseEventHandler, useCallback } from 'react';
import { Duration, ToHumanDurationOptions } from 'luxon';
import { TypedUseQueryStateResult, BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { FormatNumberOptions, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller } from 'react-hook-form';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { generatePath, Link } from 'react-router-dom';
import { Button, Badge, Form, Col, FormGroup, Input, Label, Row, FormFeedback, FormText } from 'reactstrap';

import { ItemsList } from '../core/ItemsList';
import { Amount, CurrencySelect } from '../core/CurrencySelect';
import { toContactName } from '../contacts/Contact';
import type { ApiCollection, ContactBasic, FormMethods, OptionReactSelect, PaginationParams, Service, ServiceFormValues, TagNames } from '../types/api';

export const toTimeDuration = (
  duration: Service['duration'],
  intlOptions?: ToHumanDurationOptions,
): string | null => {
  if (typeof duration !== 'number') return null;

  const durationOpts: ToHumanDurationOptions = { unitDisplay: 'short', ...intlOptions };
  const luxonDuration = Duration.fromObject({ minutes: duration });
  if (duration >= 60 && duration % 60 === 0) {
    return luxonDuration.shiftTo('hours').toHuman(durationOpts);
  }

  if (duration >= 60) {
    return luxonDuration.shiftTo('hours', 'minutes').toHuman(durationOpts);
  }

  return luxonDuration.toHuman(durationOpts);
};

export const toPrice = (
  price: Pick<Service, 'price_currency' | 'price_equal' | 'price_from' | 'price_to'>,
  intl: IntlShape,
): string | null => {
  const opts: FormatNumberOptions = {
    style: 'currency',
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 0,
  };
  const hasCurrency = price.price_currency?.length === 3;
  const hasEqual = typeof price.price_equal === 'number' && price.price_equal > 0;
  const hasFrom = typeof price.price_from === 'number' && price.price_from > 0;
  const hasTo = typeof price.price_to === 'number' && price.price_to > 0;

  if (hasCurrency) {
    opts.currency = price.price_currency as string;
  }

  if (hasCurrency && hasEqual) {
    return intl.formatNumber(price.price_equal as number, opts);
  }

  if (hasCurrency && hasFrom && hasTo) {
    return intl.formatMessage(
      { defaultMessage: '{min} - {max}' },
      { min: intl.formatNumber(price.price_from as number, opts), max: intl.formatNumber(price.price_to as number, opts) },
    );
  }

  if (hasCurrency && hasFrom) {
    return intl.formatMessage(
      { defaultMessage: 'from {min}' },
      { min: intl.formatNumber(price.price_from as number, opts) },
    );
  }

  if (hasCurrency && hasTo) {
    return intl.formatMessage(
      { defaultMessage: 'to {max}' },
      { max: intl.formatNumber(price.price_to as number, opts) },
    );
  }

  return null;
};

export interface toServiceNameOptions {
  includeDuration?: boolean;
  includePrice?: boolean;
  includeEditLink?: boolean;
}

export const toServiceName = (
  service: Pick<Service, 'id' | 'name' | 'duration' | 'price_currency' | 'price_equal' | 'price_from' | 'price_to'>,
  intl: IntlShape,
  options?: toServiceNameOptions,
): ReactElement => {
  const result = `${service?.name}`;
  let durationBadge = null;
  let priceBadge = null;
  const {
    includeDuration = true,
    includePrice = false,
    includeEditLink = true,
  } = options ?? {};
  if (includeDuration && service.duration) {
    durationBadge = (
      <Badge
        className="ms-1"
        color="secondary"
        pill
      >
        {toTimeDuration(service.duration)}
      </Badge>
    );
  }

  if (includePrice) {
    priceBadge = (
      <Badge
        className="ms-1"
        color="success"
        pill
      >
        {toPrice(service, intl)}
      </Badge>
    );
  }

  return (
    <span className="d-flex align-items-center">
      <span className="flex-grow-1 d-inline-block text-truncate">
        {result}
      </span>
      {durationBadge}
      {priceBadge}
      {includeEditLink && (
        <Button
          tag={Link}
          to={{
            pathname: generatePath('/services/:id/edit', { id: service.id }),
          }}
          className="ms-3 p-0"
          color="link"
        >
          <span className="visually-hidden">
            <FormattedMessage
              defaultMessage="Edit Service"
            />
          </span>
          <FontAwesomeIcon icon={solid('pen-to-square')} />
        </Button>
      )}
    </span>
  );
};

export const servicesToReactSelectOptions = (
  intl: IntlShape,
  services?: Service[],
): OptionReactSelect[] => {
  if (!Array.isArray(services)) return null;

  return services.map((item: Service) => ({
    ...item,
    value: item?.id,
    label: toServiceName(item, intl, { includeDuration: true, includePrice: true, includeEditLink: false }),
  }));
};

export const serviceSelectFilterOption = ({ data }: FilterOptionOption<Service>, inputValue: string): boolean => {
  // empty string by default
  if (!inputValue?.trim()) return true;

  const needle = inputValue.trim().toLocaleLowerCase();
  return [data.name, data.set_name]
    .filter((name) => typeof name === 'string')
    .some((name: string) => name.trim().toLocaleLowerCase().search(needle) !== -1);
};

interface ToServicePaymentDescriptionProps {
  service: Service;
  intl: IntlShape;
  contact?: ContactBasic;
  priceFields?: [string, string?, string?];
}

export const toServicePaymentDescription = ({
  service,
  intl,
  contact,
  priceFields = ['price_equal', 'price_from', 'price_to'],
}: ToServicePaymentDescriptionProps): string => {
  if (!service?.price_currency) return '';

  const priceField = priceFields
    .find((f) => ![null, undefined, 0, ''].includes(service?.[f]));
  const amount = service[priceField];

  const formatValues = {
    amount: intl.formatNumber(amount, {
      style: 'currency',
      currency: service.price_currency,
      minimumFractionDigits: 0,
    }),
    service: service.name,
    contact: (contact) ? toContactName(intl, contact) : undefined,
  };

  if (!contact) {
    return intl.formatMessage({ defaultMessage: 'Payment {amount} for "{service}".' }, formatValues);
  }

  return intl.formatMessage({ defaultMessage: 'Payment {amount} for "{service}" by {contact}.' }, formatValues);
};

interface ServicePaymentProps {
  service: Service;
  priceFields?: [string, string?, string?];
  onClick?: MouseEventHandler;
}

export const ServicePayment: React.FC<ServicePaymentProps> = ({
  service,
  priceFields = ['price_equal', 'price_from', 'price_to'],
  onClick,
}) => {
  const priceField = priceFields
    .find((f) => ![null, undefined, 0, ''].includes(service?.[f]));
  const amount = service[priceField];

  return (
    <Button
      size="sm"
      onClick={onClick}
    >
      <FormattedMessage
        defaultMessage='{sum} for "{service}"'
        values={{
          sum: (
            <Amount
              // should be always positive
              amount={Math.abs(amount)}
              currency={service.price_currency}
              colors={false}
              formatOps={{ minimumFractionDigits: 0, signDisplay: 'always' }}
            />
          ),
          service: service.name ?? service.template_var,
        }}
      />
    </Button>
  );
};

interface ToServiceExpensesDescriptionProps {
  service: Service;
  intl: IntlShape;
}

export const toServiceExpensesDescription = ({
  service,
  intl,
}: ToServiceExpensesDescriptionProps): string => {
  if (!service?.price_currency || typeof service.default_expenses !== 'number') return '';

  const formatValues = {
    amount: intl.formatNumber(service.default_expenses, {
      style: 'currency',
      currency: service.price_currency,
      minimumFractionDigits: 0,
    }),
    service: service.name,
  };

  return intl.formatMessage({ defaultMessage: 'Expenses {amount} for "{service}"' }, formatValues);
};

interface ServiceExpensesProps {
  service: Service;
  onClick?: MouseEventHandler;
}

export const ServiceExpenses: React.FC<ServiceExpensesProps> = ({
  service,
  onClick,
}) => (
  <Button
    size="sm"
    onClick={onClick}
  >
    <FormattedMessage
      defaultMessage='{sum} from "{service}"'
      values={{
        sum: (
          <Amount
            // should be always negative
            amount={Math.abs(service.default_expenses) * -1}
            currency={service.price_currency}
            colors={false}
            formatOps={{ minimumFractionDigits: 0, signDisplay: 'always' }}
          />
        ),
        service: service.name ?? service.template_var,
      }}
    />
  </Button>
);

interface ServiceFormProps extends FormMethods<ServiceFormValues>, TagNames<'head' | 'body' | 'foot'> {

}

export const ServiceForm: React.FC<ServiceFormProps> = ({
  hookForm,
  onSubmit,
  components,
  disabled: disabledInput,
}) => {
  const { control, handleSubmit, formState: { errors, isSubmitting }, watch } = hookForm;
  // form prefix
  const px = useId();
  const HeaderWrapper = components?.head ?? 'legend';
  const BodyWrapper = components?.body ?? 'div';
  const FooterWrapper = components?.foot ?? 'fieldset';
  const formCallback = (onSubmit) ? handleSubmit(onSubmit) : undefined;
  const {
    use_range: watchUseRange,
    duration: watchDuration,
    price_currency: watchCurrency,
    price_equal: watchPrice,
    price_from: watchPriceFrom,
    price_to: watchPriceTo,
  } = watch();
  const invalidNumber = ['', '0', 0, undefined];
  const disabled = disabledInput || isSubmitting;

  return (
    <Form
      autoComplete="off"
      onSubmit={formCallback}
      noValidate
    >
      {components?.head !== undefined && (
        <HeaderWrapper>
          <div className="h5">
            <FormattedMessage defaultMessage="New contact" />
          </div>
        </HeaderWrapper>
      )}

      <BodyWrapper>
        <fieldset>
          <FormGroup>
            <Label htmlFor={`${px}name`}>
              <FormattedMessage defaultMessage="Name" />
            </Label>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  id={`${px}name`}
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.name)}
                  disabled={disabled}
                />
              )}
            />
            <FormFeedback>
              {errors?.name?.message}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs="8" className="d-flex align-items-center">
                <Label htmlFor={`${px}duration`} className="mb-0">
                  <FormattedMessage defaultMessage="Duration" />
                </Label>
              </Col>
              <Col xs="4">
                <Controller
                  name="duration"
                  control={control}
                  render={({ field }) => (
                    <Input
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      id={`${px}duration`}
                      type="number"
                      className="text-center"
                      innerRef={(e) => field.ref(e)}
                      invalid={Boolean(errors?.duration)}
                      placeholder="60"
                      disabled={disabled}
                    />
                  )}
                />
                <FormFeedback>
                  {errors?.duration?.message}
                </FormFeedback>
              </Col>
              <FormText className="text-end">
                {parseInt(watchDuration, 10) > 0 && (
                  <Row>
                    <Col xs={{ size: 4, offset: 8 }} className="text-center">
                      {Duration.fromObject({ hours: 0, minutes: parseInt(watchDuration, 10) }).normalize().toHuman({ unitDisplay: 'short' })}
                    </Col>
                  </Row>
                )}
              </FormText>
            </Row>
          </FormGroup>
          <div className="d-flex justify-content-between">
            <Label htmlFor={`${px}${watchUseRange ? 'price_from' : 'price_equal'}`}>
              <FormattedMessage defaultMessage="Price" />
            </Label>
            <FormGroup switch>
              <Controller
                name="use_range"
                control={control}
                render={({ field }) => (
                  <Input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    type="switch"
                    innerRef={(e) => field.ref(e)}
                    role="switch"
                    checked={watchUseRange}
                    disabled={disabled}
                  />
                )}
              />
              <Label check>
                <FormattedMessage defaultMessage="Price range" />
              </Label>
            </FormGroup>
          </div>
          <Row>
            <Col xs="4">
              <FormGroup>
                <Label hidden>
                  <FormattedMessage defaultMessage="Currency" />
                </Label>
                <CurrencySelect
                  fieldName="price_currency"
                  isClearable={false}
                  hookForm={hookForm}
                  disabled={disabled}
                />
                <FormText>
                  <FormattedMessage defaultMessage="Preview" />
                </FormText>
              </FormGroup>
            </Col>
            {!watchUseRange && (
              <Col xs={{ size: 4, offset: 4 }}>
                <FormGroup>
                  <Controller
                    name="price_equal"
                    control={control}
                    render={({ field }) => (
                      <Input
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        id={`${px}price_equal`}
                        className="text-center"
                        type="number"
                        innerRef={(e) => field.ref(e)}
                        invalid={Boolean(errors?.price_equal)}
                        placeholder="0"
                        disabled={disabled}
                      />
                    )}
                  />
                  <FormFeedback>
                    {errors.price_equal?.message}
                  </FormFeedback>
                  <FormText className="d-block text-center">
                    {!watchUseRange && !invalidNumber.includes(watchPrice) && (
                      <Amount
                        amount={parseFloat(watchPrice)}
                        currency={watchCurrency.value}
                        colors={false}
                        formatOps={{
                          signDisplay: 'never',
                          minimumFractionDigits: 0,
                        }}
                      />
                    )}
                  </FormText>
                </FormGroup>
              </Col>
            )}
            {watchUseRange && (
              <Fragment>
                <Col xs={{ size: 4, offset: 0 }}>
                  <FormGroup>
                    <Label htmlFor={`${px}price_from`} hidden>
                      <FormattedMessage defaultMessage="Price from" />
                    </Label>
                    <Controller
                      name="price_from"
                      control={control}
                      render={({ field }) => (
                        <Input
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          id={`${px}price_from`}
                          type="number"
                          className="text-center"
                          innerRef={(e) => field.ref(e)}
                          invalid={Boolean(errors?.price_from)}
                          placeholder="0"
                          disabled={disabled}
                        />
                      )}
                    />
                    <FormFeedback>
                      {errors.price_from?.message}
                    </FormFeedback>
                    <FormText className="d-block text-center">
                      {watchUseRange && !invalidNumber.includes(watchPriceFrom) && (
                        <FormattedMessage
                          defaultMessage="from {amount}"
                          values={{
                            amount: (
                              <Amount
                                amount={parseFloat(watchPriceFrom)}
                                currency={watchCurrency.value}
                                colors={false}
                                formatOps={{
                                  signDisplay: 'never',
                                  minimumFractionDigits: 0,
                                }}
                              />
                            ),
                          }}
                        />
                      )}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={{ size: 4, offset: 0 }}>
                  <FormGroup>
                    <Label htmlFor={`${px}price_to`} hidden>
                      <FormattedMessage defaultMessage="Price to" />
                    </Label>
                    <Controller
                      name="price_to"
                      control={control}
                      render={({ field }) => (
                        <Input
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          id={`${px}price_to`}
                          type="number"
                          className="text-center"
                          innerRef={(e) => field.ref(e)}
                          invalid={Boolean(errors?.price_to)}
                          placeholder="0"
                          disabled={disabled}
                        />
                      )}
                    />
                    <FormFeedback>
                      {errors.price_to?.message}
                    </FormFeedback>
                    <FormText className="d-block text-center">
                      {watchUseRange && !invalidNumber.includes(watchPriceTo) && (
                        <FormattedMessage
                          defaultMessage="to {amount}"
                          values={{
                            amount: (
                              <Amount
                                amount={parseFloat(watchPriceTo)}
                                currency={watchCurrency.value}
                                colors={false}
                                formatOps={{
                                  signDisplay: 'never',
                                  minimumFractionDigits: 0,
                                }}
                              />
                            ),
                          }}
                        />
                      )}
                    </FormText>
                  </FormGroup>
                </Col>
              </Fragment>
            )}
          </Row>
          <FormGroup>
            <Row>
              <Col xs="8" className="d-flex align-items-center">
                <Label htmlFor={`${px}default_expenses`} className="mb-0">
                  <FormattedMessage defaultMessage="Default expenses" />
                </Label>
              </Col>
              <Col xs="4">
                <Controller
                  name="default_expenses"
                  control={control}
                  render={({ field }) => (
                    <Input
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      id={`${px}default_expenses`}
                      type="number"
                      className="text-center"
                      innerRef={(e) => field.ref(e)}
                      invalid={Boolean(errors?.default_expenses)}
                      placeholder="0"
                      disabled={disabled}
                    />
                  )}
                />
                <FormFeedback>
                  {errors?.default_expenses?.message}
                </FormFeedback>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={`${px}description`}>
              <FormattedMessage defaultMessage="Description" />
            </Label>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  id={`${px}description`}
                  type="textarea"
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.description)}
                  disabled={disabled}
                />
              )}
            />
            <FormFeedback>
              {errors?.description?.message}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={`${px}template_var`}>
              <FormattedMessage defaultMessage="Placeholder" />
            </Label>
            <Controller
              name="template_var"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  id={`${px}template_var`}
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.template_var)}
                  disabled={disabled}
                />
              )}
            />
            <FormFeedback>
              {errors?.template_var?.message}
            </FormFeedback>
            <FormText>
              <FormattedMessage
                defaultMessage="Used in message templates instead of name. Should be short."
              />
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={`${px}set_name`}>
              <FormattedMessage defaultMessage="Set name" />
            </Label>
            <Controller
              name="set_name"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  id={`${px}set_name`}
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.set_name)}
                  disabled={disabled}
                />
              )}
            />
            <FormFeedback>
              {errors?.set_name?.message}
            </FormFeedback>
            <FormText>
              <FormattedMessage
                defaultMessage="Price list contains several lists of services grouped by this heading."
              />
            </FormText>
          </FormGroup>
        </fieldset>
      </BodyWrapper>

      {components?.foot !== undefined && (
        <FooterWrapper>
          <fieldset className="d-flex justify-content-end">
            <Row>
              <Col>
                <Button>
                  <FormattedMessage defaultMessage="Cancel" />
                </Button>
              </Col>
              <Col>
                <Button type="submit" color="primary" disabled={disabled}>
                  <FormattedMessage defaultMessage="Save" />
                </Button>
              </Col>
            </Row>
          </fieldset>
        </FooterWrapper>
      )}
    </Form>
  );
};

type ServicesPanelProps = {
  items: TypedUseQueryStateResult<ApiCollection<Service>, PaginationParams, BaseQueryFn>;
}

export const ServicesPanel: React.FC<ServicesPanelProps> = ({
  items,
}) => {
  const intl = useIntl();
  const { isError, isLoading, isFetching, isSuccess } = items;
  const itemRender = useCallback((e: Service) => toServiceName(e, intl, { includeDuration: true, includePrice: true }), [intl]);
  const countRender = (count: number, total: number) => (
    <div className="text-muted text-center">
      <FormattedMessage
        defaultMessage="Showed {num} of { total, plural, one {# service} other {# services} }"
        // tagName="small"
        values={{
          num: count,
          total,
        }}
      />
    </div>
  );

  return (
    <ItemsList
      items={items?.data?.items ?? []}
      itemsTotal={items?.data?.items_total}
      itemRender={itemRender}
      countRender={countRender}
      loadingState={{
        isError,
        isFetching,
        isLoading,
        isSuccess,
      }}
    />
  );
};

import { Fragment, ReactElement, ReactNode, useId, useState, MouseEventHandler, useCallback } from 'react';
import { DateTime } from 'luxon';
import { TypedUseQueryStateResult, BaseQueryFn, TypedUseQueryHookResult } from '@reduxjs/toolkit/dist/query/react';
import { generatePath } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Col, Form, FormGroup, FormFeedback, Input, Label, List, ListGroupItemHeading, ListGroupItemText, ListInlineItem, Row, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { ItemsList, reduceUnique, type ItemsListProps } from '../core/ItemsList';
import { CurrencySelect, Amount } from '../core/CurrencySelect';
import { ContactNameShort, contactSelectFilterOption, contactsToReactSelectOptions } from '../contacts/Contact';
import { servicesToReactSelectOptions, serviceSelectFilterOption } from '../services/Service';
import type { ApiCollection, EntityFieldView, FinancialRecord, GetFinancialRecordsParams, FormMethods, InputOps, PaginationParams, FinancialRecordFormValues, TagNames, Currency, BusinessUser, Service, LoadingState, ContactBasic } from '../types/api';

interface BalanceListProps extends TagNames<'item'> {
  balances: Record<Currency, number>;
  type?: 'inline' | 'unstyled';
}

export const BalanceList: React.FC<BalanceListProps> = ({
  balances,
  components,
  type,
}) => {
  const defaultWrap = (type === 'inline') ? ListInlineItem : 'li';
  const ListWrapper = components?.item ?? defaultWrap;

  return (
    <List type={type} className="d-inline-block my-0">
      {Object.keys(balances).map((currency: string) => (
        <ListWrapper key={currency}>
          <Amount
            amount={balances[currency]}
            currency={currency}
            formatOps={{
              minimumFractionDigits: 0,
            }}
            colors
          />
        </ListWrapper>
      ))}
    </List>
  );
};

export const reduceUsersBalances = (
  balances: Record<BusinessUser['id'], Record<Currency | string, number>>,
): Map<Currency | string, number> => Object.keys(balances).reduce(
  (carry: Map<Currency | string, number>, userId: BusinessUser['id']) => {
    Object.keys(balances[userId]).forEach((currency: string) => {
      const amount = balances[userId][currency] + (carry.get(currency) ?? 0);
      carry.set(currency, amount);
    });

    return carry;
  },
  new Map(),
);

interface FiltersToolbarProps extends FormMethods<GetFinancialRecordsParams> {
  loadingState?: LoadingState,
}

export const FiltersToolbar: React.FC<FiltersToolbarProps> = ({
  hookForm,
  loadingState,
}) => {
  const [visibleInput, setVisibleInput] = useState<keyof GetFinancialRecordsParams | null>(null);
  const px = useId();
  const isUpdating: boolean = loadingState?.isLoading || loadingState?.isFetching || false;
  const { control, watch } = hookForm;
  const {
    filter_from,
    filter_to,
    filter_contact,
    filter_business_user,
    filter_service,
    filter_event,
    filter_user_min,
    filter_user_max,
  } = watch();

  const now = DateTime.now();
  const fromDt = DateTime.fromISO(filter_from);
  const toDt = DateTime.fromISO(filter_to);
  const fromTime = (
    <time
      dateTime={filter_from}
      title={fromDt.toLocaleString(DateTime.DATE_HUGE)}
    >
      {fromDt.toLocaleString(DateTime.DATE_SHORT)}
    </time>
  );
  const toTime = (
    <time
      dateTime={(toDt.isValid ? filter_to : now.toISODate())}
      title={toDt.isValid ? toDt.toLocaleString(DateTime.DATE_HUGE) : now.toLocaleString(DateTime.DATE_HUGE)}
    >
      {toDt.isValid && toDt.toLocaleString(DateTime.DATE_SHORT)}
      {!toDt.isValid && now.toRelativeCalendar()}
    </time>
  );

  const onFilterClick = (filterName: keyof GetFinancialRecordsParams): MouseEventHandler<HTMLButtonElement> => (): boolean => {
    if (visibleInput === filterName) {
      setVisibleInput(null);
      return false;
    }
    setVisibleInput(filterName);
    return false;
  };
  const onApplyClick: MouseEventHandler<HTMLButtonElement> = (): void => setVisibleInput(null);
  const applyBtnText = (
    <span>
      <span className="visually-hidden">
        <FormattedMessage defaultMessage="Apply" />
      </span>
      <FontAwesomeIcon icon={solid('check')} />
    </span>
  );
  const onToggle: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setVisibleInput(visibleInput !== 'filter_user_min' ? 'filter_user_min' : null);
    return false;
  };
  let recordType;
  if (filter_user_min === 0 && filter_user_max === undefined) {
    recordType = 'incomes';
  } else if (filter_user_min === undefined && filter_user_max === 0) {
    recordType = 'expenses';
  } else {
    recordType = undefined;
  }
  const onAnyType: MouseEventHandler<HTMLButtonElement> = () => {
    hookForm.setValue('filter_user_min', undefined);
    hookForm.setValue('filter_user_max', undefined);
  };
  const onIncomesType: MouseEventHandler<HTMLButtonElement> = () => {
    hookForm.setValue('filter_user_min', 0);
    hookForm.setValue('filter_user_max', undefined);
  };
  const onExpensesType: MouseEventHandler<HTMLButtonElement> = () => {
    hookForm.setValue('filter_user_min', undefined);
    hookForm.setValue('filter_user_max', 0);
  };

  return (
    <Form>
      <fieldset>
        <List type="inline" style={{ lineHeight: 2.5 }}>
          <ListInlineItem>
            <Button
              type="button"
              size="sm"
              onClick={onFilterClick('filter_from')}
              disabled={isUpdating}
            >
              <FormattedMessage
                defaultMessage="from: {date} {arrow}"
                values={{
                  date: fromTime,
                  arrow: <FontAwesomeIcon icon={solid('chevron-down')} />,
                }}
              />
            </Button>
            {visibleInput === 'filter_from' && (
              <InputGroup size="sm">
                <Controller
                  name="filter_from"
                  control={control}
                  render={({ field }) => (
                    <Input
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      type="date"
                      id={`${px}t`}
                      innerRef={(e) => field.ref(e)}
                      disabled={isUpdating}
                    />
                  )}
                />
                <Button
                  onClick={onApplyClick}
                  disabled={isUpdating}
                >
                  {applyBtnText}
                </Button>
              </InputGroup>
            )}
          </ListInlineItem>
          <ListInlineItem>
            <Button
              type="button"
              size="sm"
              onClick={onFilterClick('filter_to')}
              disabled={isUpdating}
            >
              <FormattedMessage
                defaultMessage="to: {date} {arrow}"
                values={{
                  date: toTime,
                  arrow: <FontAwesomeIcon icon={solid('chevron-down')} />,
                }}
              />
            </Button>
            {visibleInput === 'filter_to' && (
              <InputGroup size="sm">
                <Controller
                  name="filter_to"
                  control={control}
                  render={({ field }) => (
                    <Input
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      type="date"
                      id={`${px}t`}
                      innerRef={(e) => field.ref(e)}
                      disabled={isUpdating}
                    />
                  )}
                />
                <Button
                  onClick={onApplyClick}
                >
                  {applyBtnText}
                </Button>
              </InputGroup>
            )}
          </ListInlineItem>
          {/* drop down */}
          <ListInlineItem>
            <Dropdown
              toggle={onToggle}
              isOpen={visibleInput === 'filter_user_min'}
            >
              <DropdownToggle size="sm" disabled={isUpdating}>
                <FormattedMessage
                  defaultMessage="type: {type, select, incomes { incomes } expenses { expenses } other { any }} {arrow}"
                  values={{
                    type: recordType,
                    arrow: <FontAwesomeIcon icon={solid('chevron-down')} />,
                  }}
                />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={onAnyType} active={recordType === undefined} disabled={isUpdating}>
                  <FormattedMessage defaultMessage="any" />
                </DropdownItem>
                <DropdownItem onClick={onIncomesType} active={recordType === 'incomes'} disabled={isUpdating}>
                  <FormattedMessage defaultMessage="incomes" />
                </DropdownItem>
                <DropdownItem onClick={onExpensesType} active={recordType === 'expenses'} disabled={isUpdating}>
                  <FormattedMessage defaultMessage="expenses" />
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </ListInlineItem>
          {/* two inputs */}
          <ListInlineItem>
            <Button
              type="button"
              size="sm"
              onClick={onFilterClick('filter_user_max')}
              disabled={isUpdating}
            >
              <FormattedMessage
                defaultMessage="amount: any {arrow}"
                values={{
                  arrow: <FontAwesomeIcon icon={solid('chevron-down')} />,
                }}
              />
            </Button>
            {visibleInput === 'filter_user_max' && (
              <InputGroup size="sm">
                <Controller
                  name="filter_user_min"
                  control={control}
                  render={({ field }) => (
                    <Input
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      type="number"
                      id={`${px}t`}
                      innerRef={(e) => field.ref(e)}
                      disabled={isUpdating}
                    />
                  )}
                />
                <Controller
                  name="filter_user_max"
                  control={control}
                  render={({ field }) => (
                    <Input
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      type="number"
                      id={`${px}t`}
                      innerRef={(e) => field.ref(e)}
                      disabled={isUpdating}
                    />
                  )}
                />
                <Button
                  onClick={onApplyClick}
                >
                  {applyBtnText}
                </Button>
              </InputGroup>
            )}
          </ListInlineItem>
          {/* react select */}
          <ListInlineItem>
            <Button
              type="button"
              size="sm"
              onClick={onFilterClick('filter_service')}
              disabled={isUpdating}
            >
              <FormattedMessage
                defaultMessage="service: any {arrow}"
                values={{
                  arrow: <FontAwesomeIcon icon={solid('chevron-down')} />,
                }}
              />
            </Button>
          </ListInlineItem>
          {/* react select */}
          <ListInlineItem>
            <Button
              type="button"
              size="sm"
              onClick={onFilterClick('filter_contact')}
              disabled={isUpdating}
            >
              <FormattedMessage
                defaultMessage="contact: any {arrow}"
                values={{
                  arrow: <FontAwesomeIcon icon={solid('chevron-down')} />,
                }}
              />
            </Button>
          </ListInlineItem>
          {/* react select */}
          <ListInlineItem className="visually-hidden">
            <Button
              type="button"
              size="sm"
              onClick={onFilterClick('filter_business_user')}
              disabled={isUpdating}
            >
              <FormattedMessage
                defaultMessage="user: any {arrow}"
                values={{
                  arrow: <FontAwesomeIcon icon={solid('chevron-down')} />,
                }}
              />
            </Button>
          </ListInlineItem>
          {/* react select with load more */}
          <ListInlineItem className="visually-hidden">
            <Button
              type="button"
              size="sm"
              onClick={onFilterClick('filter_event')}
              disabled={isUpdating}
            >
              <FormattedMessage
                defaultMessage="event: any {arrow}"
                values={{
                  arrow: <FontAwesomeIcon icon={solid('chevron-down')} />,
                }}
              />
            </Button>
          </ListInlineItem>
        </List>
      </fieldset>
    </Form>
  );
};

interface FinancialRecordFormProps extends FormMethods<FinancialRecordFormValues>, TagNames<'head' | 'body' | 'foot'> {
  contactsResult: TypedUseQueryHookResult<ApiCollection<ContactBasic>, PaginationParams, BaseQueryFn>;
  servicesResult: TypedUseQueryHookResult<ApiCollection<Service>, PaginationParams, BaseQueryFn>;
  inputs?: Partial<Record<keyof FinancialRecordFormValues, InputOps>>;
}

export const FinancialRecordForm: React.FC<FinancialRecordFormProps> = ({
  hookForm,
  onSubmit,
  components,
  contactsResult,
  servicesResult,
  inputs,
  disabled: disabledInput,
}) => {
  const intl = useIntl();
  const px = useId();
  const { control, formState: { errors, isSubmitting }, watch, handleSubmit } = hookForm;
  const { id: itemId, event: watchEvent } = watch();
  const HeadWrap = components?.head ?? 'legend';
  const BodyWrap = components?.body ?? 'div';
  const attendeeSet = new Set(watchEvent?.attendee?.map((e: ContactBasic) => e.id) ?? []);
  const contactOptions = contactsToReactSelectOptions(
    intl,
    contactsResult?.data?.items,
    null,
    (e) => attendeeSet.has(e.id),
  ) ?? [];
  const serviceOptions = servicesToReactSelectOptions(intl, servicesResult?.data?.items) ?? [];
  const formCallback = (onSubmit) ? handleSubmit(onSubmit) : handleSubmit(() => false);
  const inputsProps: Record<keyof FinancialRecordFormValues, InputOps> = ['business_user', 'currency', 'user_amount', 'description', 'event', 'contact', 'service', 'date', 'time']
    .reduce((carry, key: string) => {
      if (inputs?.hasOwnProperty(key)) {
        const props = inputs[key];
        Object.assign(carry, { [key]: {
          readOnly: (props?.readOnly !== undefined) ? props?.readOnly : false,
          disabled: (props?.disabled !== undefined) ? props?.disabled : false,
          isClearable: (props?.isClearable !== undefined) ? props?.isClearable : true,
          isMulti: (props?.isMulti !== undefined) ? props?.isMulti : false,
        } });
      }

      return carry;
    }, {});

  const disabled = disabledInput || isSubmitting;
  const loadingMessage = useCallback(() => intl.formatMessage({ defaultMessage: 'Loading...' }), [intl]);
  const noOptionsMessage = useCallback(() => intl.formatMessage({ defaultMessage: 'No options' }), [intl]);

  return (
    <Form
      autoComplete="off"
      className="form-financial-record"
      onSubmit={formCallback}
      noValidate
    >
      {/* form header is optional */}
      {components?.head !== undefined && (
        <HeadWrap className="form-heading">
          {itemId && <FormattedMessage defaultMessage="Edit Record" />}
          {!itemId && <FormattedMessage defaultMessage="New Record" />}
        </HeadWrap>
      )}
      <BodyWrap className="form-body">
        <Row>
          <Col md={{ size: 4 }} xs={{ size: 6 }}>
            <FormGroup>
              <Label>
                <FormattedMessage defaultMessage="Currency" />
              </Label>
              <CurrencySelect
                hookForm={hookForm}
                disabled={inputsProps?.currency?.disabled || disabled}
              />
              <FormFeedback>
                {errors.currency?.message}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md={{ size: 4, offset: 4 }} xs={{ size: 6 }}>
            <FormGroup>
              <Label>
                <FormattedMessage defaultMessage="Amount" />
              </Label>
              <Controller
                name="user_amount"
                control={control}
                render={({ field }) => (
                  <Input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    type="number"
                    id={`${px}user_amount`}
                    innerRef={(e) => field.ref(e)}
                    invalid={Boolean(errors?.user_amount)}
                    disabled={inputsProps?.user_amount?.disabled || disabled}
                  />
                )}
              />
              <FormFeedback>
                {errors?.user_amount?.message}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label>
            <FormattedMessage defaultMessage="Description" />
          </Label>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Input
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                type="textarea"
                id={`${px}description`}
                innerRef={(e) => field.ref(e)}
                invalid={Boolean(errors?.description)}
                disabled={inputsProps?.description?.disabled || disabled}
              />
            )}
          />
          <FormFeedback>
            {errors?.description?.message}
          </FormFeedback>
        </FormGroup>
        <FormGroup className="d-flex align-items-center">
          <Label htmlFor={`${px}sd`} className="me-auto">
            <FormattedMessage defaultMessage="Date" />
          </Label>
          <div className="me-3">
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  id={`${px}sd`}
                  type="date"
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.date)}
                  disabled={inputsProps?.date?.disabled || disabled}
                />
              )}
            />
            <FormFeedback>
              {errors?.date?.message}
            </FormFeedback>
          </div>
          <div>
            <Label htmlFor={`${px}st`} hidden>
              <FormattedMessage defaultMessage="Time" />
            </Label>
            <Controller
              name="time"
              control={control}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  id={`${px}st`}
                  type="time"
                  innerRef={(e) => field.ref(e)}
                  invalid={Boolean(errors?.time)}
                  disabled={inputsProps?.time?.disabled || disabled}
                />
              )}
            />
            <FormFeedback>
              {errors?.time?.message}
            </FormFeedback>
          </div>
        </FormGroup>
        <FormGroup>
          <Label>
            <FormattedMessage defaultMessage="Service" />
          </Label>
          {/**
            * @link https://codesandbox.io/s/react-hook-form-v7-controller-5h1q5
            */}
          <Controller
            name="service"
            control={control}
            render={({ field }) => (
              <ReactSelect
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                isDisabled={servicesResult?.isLoading || inputsProps?.service?.disabled || disabled}
                isLoading={servicesResult?.isLoading}
                isClearable={inputsProps?.service?.isClearable}
                isMulti={inputsProps?.service?.isMulti}
                options={serviceOptions}
                filterOption={serviceSelectFilterOption}
                placeholder={inputsProps?.service?.isMulti
                  ? intl.formatMessage({ defaultMessage: 'Select one or more services...' })
                  : intl.formatMessage({ defaultMessage: 'Select a service...' })}
                loadingMessage={loadingMessage}
                noOptionsMessage={noOptionsMessage}
              />
            )}
          />
          <FormFeedback>
            {errors?.service?.message}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>
            <FormattedMessage defaultMessage="Contact" />
          </Label>
          {/**
            * @link https://codesandbox.io/s/react-hook-form-v7-controller-5h1q5
            */}
          <Controller
            name="contact"
            control={control}
            render={({ field }) => (
              <ReactSelect
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                isDisabled={contactsResult?.isLoading || inputsProps?.contact?.disabled || disabled}
                isLoading={contactsResult?.isLoading}
                isClearable={inputsProps?.contact?.isClearable}
                isMulti={inputsProps?.contact?.isMulti}
                options={contactOptions}
                filterOption={contactSelectFilterOption}
                placeholder={inputsProps?.service?.isMulti
                  ? intl.formatMessage({ defaultMessage: 'Select one or more contacts...' })
                  : intl.formatMessage({ defaultMessage: 'Select a contact...' })}
                loadingMessage={loadingMessage}
                noOptionsMessage={noOptionsMessage}
              />
            )}
          />
          <FormFeedback>
            {errors?.contact?.message}
          </FormFeedback>
        </FormGroup>
      </BodyWrap>
    </Form>
  );
};

interface FinancialRecordListItemProps {
  item: FinancialRecord,
  fields?: Record<'date' | 'description' | 'contact', EntityFieldView>;
}

const FinancialRecordListItem: React.FC<FinancialRecordListItemProps> = ({
  item,
  fields,
}) => {
  const dt = DateTime.fromISO(item.date);
  const userBalanceChange = (item.currency !== null && item.user_amount !== null) ? (
    <Amount
      amount={item.user_amount}
      currency={item.currency}
      discount={item.discount}
      formatOps={{
        minimumFractionDigits: 0,
      }}
      colors
    />
  ) : null;

  return (
    <Fragment>
      <ListGroupItemHeading className="d-flex" tag="h6">
        <span className="flex-grow-1 fw-normal">
          <FormattedMessage
            defaultMessage="For {service}, scheduled {event}"
            values={{
              service: (
                <strong>
                  {item.service?.name}
                </strong>
              ),
              event: (
                <strong>
                  <time dateTime={item.event?.start} title={DateTime.fromISO(item.event?.start).toLocaleString(DateTime.DATETIME_MED)}>
                    {DateTime.fromISO(item.event?.start).toLocaleString(DateTime.DATETIME_SHORT)}
                  </time>
                </strong>
              ),
            }}
          />
        </span>
        <span className="text-nowrap">
          {userBalanceChange}
        </span>
      </ListGroupItemHeading>
      {[undefined, true].includes(fields?.contact?.visible) && item.contact && (
        <ListGroupItemText tag="div">
          <FormattedMessage
            defaultMessage="Contact {contact}"
            // defaultMessage="From {contact} to {user}"
            values={{
              contact: (
                <strong>
                  <ContactNameShort
                    contact={item.contact}
                  />
                </strong>
              ),
              // user: (
              //   <strong>
              //     {item.business_user?.contact?.fn}(me)
              //   </strong>
              // ),
            }}
          />
        </ListGroupItemText>
      )}
      {[undefined, true].includes(fields?.description?.visible) && item?.description && (
        <ListGroupItemText tag="div">
          <div style={{ whiteSpace: 'pre-line', verticalAlign: 'bottom' }}>
            {item.description}
          </div>
        </ListGroupItemText>
      )}
      <ListGroupItemText tag="div" className="text-end">
        <time
          className="text-end text-nowrap text-muted"
          title={dt.toLocaleString(DateTime.DATETIME_HUGE)}
          dateTime={dt.toISO()}
        >
          {dt.toLocaleString(fields?.date?.dateFormat ?? DateTime.TIME_SIMPLE)}
        </time>
      </ListGroupItemText>
    </Fragment>
  );
};

const financeToIsoDateMap = (
  records: FinancialRecord[],
): Record<string, FinancialRecord[]> => records.reduce((map: Record<string, FinancialRecord[]>, item: FinancialRecord) => {
  const date = DateTime.fromISO(item.date).toISODate({ format: 'basic' });
  const datesList = map[date] ?? [];
  datesList.push(item);

  return Object.assign(map, { [date]: datesList });
}, {});

interface FinancePanelProps extends Pick<ItemsListProps, 'itemsToGroup' | 'onLoadMore'>, PaginationParams, TagNames<'counter'> {
  items: TypedUseQueryStateResult<ApiCollection<FinancialRecord>, PaginationParams, BaseQueryFn>;
  cachedItems: FinancialRecord[];
  fields?: Partial<Record<'date' | 'description' | 'contact' | 'event', EntityFieldView>>;
  onDelete?: (e: FinancialRecord) => void;
}

export const FinancePanel: React.FC<FinancePanelProps> = ({
  items,
  cachedItems,
  itemsToGroup = financeToIsoDateMap,
  onLoadMore,
  pageSize = 10,
  sortOrder = 'DESC',
  fields,
  components,
  onDelete,
}) => {
  const CounterTag = components?.counter ?? 'div';
  const currentItems = items?.data?.items ?? [];
  const allItems = reduceUnique([...(cachedItems ?? []), ...currentItems]);
  const { isError, isLoading, isFetching, isSuccess } = items;
  const hasDeleteColumns = typeof onDelete === 'function';
  const itemRender = (e: FinancialRecord) => (
    <FinancialRecordListItem
      item={e}
      fields={fields}
    />
  );
  const itemHref = (e: FinancialRecord) => generatePath('/finance/:id', { id: e.id });
  const itemsGroupSort = (a: string, b: string) => {
    const sign = (sortOrder === 'DESC') ? -1 : 1;
    if (a < b) return -1 * sign;
    if (a > b) return 1 * sign;
    return 0;
  };
  const itemsGroupFormat = (name: string): string => {
    const dt = DateTime.fromISO(name);
    if (DateTime.now().hasSame(dt, 'day')) {
      // return dt.toRelative({ unit: 'days' });
      return dt.toRelativeCalendar() ?? 'Today';
    }
    return dt.toLocaleString(DateTime.DATE_HUGE);
  };
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [deleted, setDeleted] = useState<Set<string>>(new Set());
  const onSelect = (id: string, checked: boolean) => {
    if (checked) {
      selected.add(id);
    } else {
      selected.delete(id);
    }
    // have to create new set
    // because the same ref doesn't trigger rerender
    setSelected(new Set(selected.values()));
  };
  const countRender = (count: number, total: number) => (
    <CounterTag className="text-muted text-center">
      <FormattedMessage
        defaultMessage="Showed {num} of { total, plural, one {# record} other {# records} }"
        // tagName="small"
        values={{
          num: count,
          total,
        }}
      />
    </CounterTag>
  );
  const columnRender = (child: ReactNode, e: FinancialRecord) => (
    <Col xs={{ size: deleted.has(e.id) ? 10 : 11, order: 2 }}>
      {child}
    </Col>
  );
  const columnsRender = (e: FinancialRecord): ReactElement => (
    <Fragment>
      {/* <Col xs={{ size: 1, order: 1 }} className="text-center">
        <Input
          type="checkbox"
          role="button"
          onChange={() => onSelect(e?.id, !selected.has(e.id))}
          checked={selected.has(e.id)}
        />
      </Col> */}
      {hasDeleteColumns && deleted.has(e.id) && (
        <Col xs={{ size: 1, order: 3 }} className="text-center">
          <Button
            color="link"
            className="p-0 text-danger"
            onClick={() => onDelete(e)}
          >
            <span className="visually-hidden">
              <FormattedMessage defaultMessage="Delete" />
            </span>
            <FontAwesomeIcon icon={solid('check-circle')} />
          </Button>
        </Col>
      )}
      {hasDeleteColumns && (
        <Col xs={{ size: 1, order: 4 }} className="text-center">
          <Button
            color="link"
            className={`p-0 ${deleted.has(e.id) ? 'text-secondary' : 'text-danger'}`}
            onClick={() => {
              if (deleted.has(e.id)) {
                deleted.delete(e.id);
              } else {
                deleted.add(e.id);
              }
              setDeleted(new Set(deleted));
            }}
          >
            <span className="visually-hidden">
              <FormattedMessage defaultMessage="Delete" />
            </span>
            {!deleted.has(e.id) && <FontAwesomeIcon icon={solid('minus-circle')} />}
            {deleted.has(e.id) && <FontAwesomeIcon icon={solid('times-circle')} />}
          </Button>
        </Col>
      )}
    </Fragment>
  );

  return (
    <ItemsList
      items={allItems}
      itemsTotal={items?.data?.items_total}
      itemsToGroup={itemsToGroup}
      itemRender={itemRender}
      itemsGroupSort={itemsGroupSort}
      itemsGroupFormat={itemsGroupFormat}
      // itemHref={itemHref}
      // itemTag={Link}
      countRender={countRender}
      loadingState={{
        isError,
        isFetching,
        isLoading,
        isSuccess,
      }}
      pageSize={pageSize}
      onLoadMore={onLoadMore}
      contentColumnRender={columnRender}
      columnsRender={columnsRender}
    />
  );
};
